import React from 'react';
import { withRouter } from 'react-router-dom';

class ApplyCompany  extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          enabled: true,
          info:"",
          fullName: "", 
          shortName: "", 
          taxId: "", 
          ticker: "",
          address: "", 
          city: "", 
          country: "", 
          phone: "", 
          email: ""
        }
      }
    
    cancel() {
      this.props.history.push('/apply')
    }
    applyInvoke() {
      if(this.state.fullName.length<2 || 
         this.state.address.length<2 ||
         this.state.swiftNumber.length<6) 
        {
          this.onRegisterComplete("Invalid input");
          return;
        }
        if(this.state.accountNumber.length<9)
        {
          this.onRegisterComplete("Invalid number");
          return;
        }

        var company = { 
           fullName: this.state.fullName,
           legalName: this.state.legalName,
           description: this.state.description,
           ticker: this.state.ticker,
           taxId: this.state.taxId,
           regId: this.state.regId, 
           phone: this.state.phone,
           email: this.state.phone, 
           address: this.state.address,
           city: this.state.city,
           country: this.state.country
        }; 
        console.log(company);
        this.setState({enabled:false});
        this.props.app.state.comm.newClientCompany(company, this);
    }
    onRegisterComplete(information)
    {
      this.setState({info:information});
      this.setState({enabled:true});
      if(information==="OK")
      {
        //this.props.loginScreen.register();
      }
    }

    hFullName(e) { this.setState({ fullName: e.target.value }); }
    hlegalName(e) { this.setState({ legalName: e.target.value }); }
    hDescription(e) { this.setState({ description: e.target.value }); }
    hTicker(e) { this.setState({ ticker: e.target.value }); }
    hTaxId(e) { this.setState({ taxId: e.target.value }); }
    hRegId(e) { this.setState({ regId: e.target.value }); }
    hPhone(e) { this.setState({ phone: e.target.value }); }
    hEmail(e) { this.setState({ email: e.target.value }); }
    hAddress(e) { this.setState({ address: e.target.value }); }
    hCity(e) { this.setState({ city: e.target.value }); }
    hCountry(e) { this.setState({ country: e.target.value }); }
    // hLocation(e) { this.setState({ location: e.target.value }); }
  
    render () {
      return <div className="settingsWindow">
                  <h3>Company / Legal Entity details</h3>
                  Company Full Name<br/>
                  <input type="text" placeholder="Company Full Name" value={this.state.fullName} onChange={(e)=>this.hFullName(e)} className="inputBox"/><br/>
                  {/* Company Legal Name<br/>
                  <input type="text" placeholder="Company Legal Name" value={this.state.legalName} onChange={(e)=>this.hAddress(e)} className="inputBox"/><br/> */}
                  {/* Company Short Name<br/>
                  <input type="text" placeholder="Company Legal Name" value={this.state.legalName} onChange={(e)=>this.hAddress(e)} className="inputBox"/><br/> 
                  Company Description<br/>
                  <input type="text" placeholder="Company Description" value={this.state.description} onChange={(e)=>this.hDescription(e)} className="inputBox"/><br/> */}
                  Ticker<br/>
                  <input type="text" placeholder="Ticker (if applicable)" value={this.state.ticker} onChange={(e)=>this.hTicker(e)} className="inputBox"/><br/> 
                  TAX ID<br/>
                  <input type="text" placeholder="TAX ID" value={this.state.taxId} onChange={(e)=>this.hTaxId(e)} className="inputBox"/><br/>
                  Registration ID<br/>
                  <input type="text" placeholder="Registration ID" value={this.state.regId} onChange={(e)=>this.hRegId(e)} className="inputBox"/><br/> 
                  Phone<br/>
                  <input type="text" placeholder="Phone" value={this.state.email} onChange={(e)=>this.hEmail(e)} className="inputBox"/><br/>
                  Email<br/>
                  <input type="text" placeholder="Email" value={this.state.phone} onChange={(e)=>this.hPhone(e)} className="inputBox"/><br/>
                  Address<br/>
                  <input type="text" placeholder="Address" value={this.state.address} onChange={(e)=>this.hAddress(e)} className="inputBox"/><br/>
                  City<br/>
                  <input type="text" placeholder="city" value={this.state.city} onChange={(e)=>this.hCity(e)} className="inputBox"/><br/>
                  Country<br/>
                  <input type="text" placeholder="country" value={this.state.country} onChange={(e)=>this.hCountry(e)} className="inputBox"/><br/>
                  {/* Location  (if applicable, othewise will be gathered from address)<br/>
                  <input type="text" placeholder="location" value={this.state.legalName} onChange={(e)=>this.hCountry(e)} className="inputBox"/><br/> */}

                  {this.state.info.length>0 ? <div style={{color:"red"}}>{this.state.info}</div> : null}
                  {this.state.enabled==false ? <div className="settingsWindow">
                                                    <h1>EnergonX</h1>          
                                                    <h2>Communicating</h2> 
                                                    <h3>Please wait ...</h3> 
                                              </div> : null }

                  <br/>
                  {this.state.enabled==true ? <button onClick={() => this.applyInvoke()}>Submit</button> : null }
                   <br/>
                  {this.state.enabled==true ? <button onClick={() => this.cancel()}>Cancel</button> : null }
            </div>
    }
}

export default withRouter(ApplyCompany);