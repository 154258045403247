import React from 'react';
import './../App.css';

import WorldMap from './Maps/WorldMap';
import Chart from 'react-apexcharts';
// open high low close 

var series = [{
    data: [{
        x: (1538778600000),
        y: [6629.81, 6650.5, 6623.04, 6633.33]
      }]
}];

class GraphView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          height: 395,
          width: 500,
          showMap: false, 
          series: series,
          options: {
            chart: {
              type: 'candlestick',
              //height: 150,
              toolbar: {
                show: true,
                tools: {
                  offsetX: 110,
                  offsetY: 100,
                  download: true,
                  selection: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: [{
                    icon: '<img src="/img/logo/eh2_logo_256.png" class="ico-download" width="20">',
                    html: '<i class="fa fa-angle-down">MAP</i>',
                    title: 'Map',
                    click: () => { this.setState({showMap:true}); },
                    appendTo: 'left' // left / top means the button will be appended to the left most or right most position
                   }]
                },
                export: {
                  csv: {
                    filename: undefined,
                    columnDelimiter: ',',
                    headerCategory: 'OHLC',
                    headerValue: 'value',
                    dateFormatter(timestamp) {
                      return new Date(timestamp).toDateString()
                    }
                  }
                },
                autoSelected: 'zoom' 
              },
            },
            plotOptions: {
              candlestick: {
                colors: {
                  upward: 'var(--greenColor)',
                  downward: 'var(--redColor)'
                },
                wick: {
                  useFillColor: true,
                }
              }
            },
            title: {
              text: 'Chart',
              align: 'left'
            },
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              tooltip: { enabled: true }
            }
          }
        };

        this.handleResize = this.handleResize.bind(this);
      }
    componentDidMount()
    {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() 
    {
      window.removeEventListener('resize', this.handleResize);
    }
    handleResize() 
    {
      var element = document.getElementById('viewGraph');
      //console.log(element);
      if(element===undefined) return;

      this.setState({ height: element.clientHeight });
      this.setState({ width: element.clientWidth });
    }    
      
    render () {
        //var ohlc = this.props.app.state.ohlc;
        var ohlc = this.props.ohlc;
        if(ohlc===null)
           return <div className="viewGraph" id="viewGraph">no graph data</div>

        if(ohlc[0].data.length<=1)
           return <div className="viewGraph" id="viewGraph">awaiting data</div>
        
        var graphOrMap = this.state.showMap ? 
                         <WorldMap height={this.state.height} width={this.state.width} comm={this.props.comm} market={this.props.market} /> :
                         <Chart options={this.state.options} series={ohlc} type="candlestick"  height="100%" parent={this}/>

        return <div className="viewGraph" id="viewGraph" ref={wrapper => (this._wrapper = wrapper)}>
                    {graphOrMap}
               </div>
    }
}

export default GraphView;

// height={350}
// height={this.state.height}