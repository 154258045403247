import React from 'react';
import { timeDurationWeeks } from '../helpers/Formating';
import { formatMoney } from '../helpers/Formating';

class TransportationsListView extends React.Component {
  constructor(props){
      super(props)
      this.state = {
        info:"",
      };
      this.update = this.update.bind(this);
  }
  onClickTransportation(transportation) 
  {
     this.props.distributionView.selectTransportation(transportation);
  }
  componentDidMount() { 
    this.timerHandle = setInterval(this.update, 1000); // every 1s
  }
  componentWillUnmount() { 
    clearInterval(this.timerHandle); 
  }  
  update() {  
    this.forceUpdate(); 
  }    

  render() {
    let transportations = this.props.transportations;
    if(transportations===null) return null; 
    var now = Date.now();
    
    return  transportations.map(t =>
      <tr key={"trns_"+t.deliveryId} className={t.inProgress===0 ? "orderBuyItem" : "orderSellItem"} onClick={()=>this.onClickTransportation(t)} >
          <td className="textRight">{t.quantity}</td> 
          <td className="textRight">{t.airDistance.toFixed(2)}</td> 
      </tr>
      ) 
  }
}

class TransportationsBookView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }

      renderHeader() {
        return <tr className="orderHeader">
                   <th className="textLeft">QUANTITY</th>
                   <th className="textRight">DISTANCE</th>
               </tr>
      }       
      render () {
        let transportations = this.props.transportations;
        return <div className="viewTransportBook">
               <div className="tableHeader">DELIVERIES WAITING</div>
               <table  >
                 <thead>
                   {this.renderHeader()}
                 </thead>
                 <tbody>
                  <TransportationsListView transportations={transportations} distributionView={this.props.distributionView}/>
                </tbody>     
               </table>
               </div>
    }
}

export default TransportationsBookView;