import React from "react";
import ReactDOM from "react-dom";
import ReactTooltip from 'react-tooltip';
//import { geoCylindricalEqualArea } from 'd3-geo-projection';
//import { cylindricalEqualArea } from 'd3-geo-projection';
//import * as d3geo from 'd3-geo-projection';

//import ReactDOM from 'react-dom';
// projections https://github.com/d3/d3-geo-projection 
//import { geoTimes, geoAitoff, geoCylindricalEqualArea } from 'd3-geo-projection';
//import { geoEqualEarth } from 'd3-geo';
//import * as d3  from "d3-geo";
//import { feature } from "topojson-client";
import {
  ComposableMap,
  Geographies, 
  Geography,
  ZoomableGroup,
  Graticule,
  Markers,
  Marker,
  Line,
  Lines 
} from "react-simple-maps";
import './../../App.css';

// globe https://codesandbox.io/s/218wpw3okn
//url('./fonts/Proxima Nova Alt Regular-webfont.eot');
//const geoUrl = "/topojson-maps/world-50m-simplified.json"; //const geoUrl =  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
//const geoUrl =  "/topojson-maps/world-50m-simplified.json";
//const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master//topojson-maps/world-110m.json";

const geoUrl =  "/topojson-maps/world-50m-simplified.json";
function clamp(min, max) {
  return Math.min(Math.max(this, min), max);
}

const cities = [
  { name: "Tokyo", coordinates: [139.6917, 35.6895], population: 37843000 },
  { name: "Jakarta", coordinates: [106.8650, -6.1751], population: 30539000 },
  { name: "Delhi", coordinates: [77.1025, 28.7041], population: 24998000 },
  { name: "Manila", coordinates: [120.9842, 14.5995], population: 24123000 },
  { name: "Seoul", coordinates: [126.9780, 37.5665], population: 23480000 },
  { name: "Shanghai", coordinates: [121.4737, 31.2304], population: 23416000 },
  { name: "Karachi", coordinates: [67.0099, 24.8615], population: 22123000 },
  { name: "Beijing", coordinates: [116.4074, 39.9042], population: 21009000 },
  { name: "New York", coordinates: [-74.0059, 40.7128], population: 20630000 },
  { name: "Guangzhou", coordinates: [113.2644, 23.1291], population: 20597000 },
  { name: "Sao Paulo", coordinates: [-46.6333, -23.5505], population: 20365000 },
  { name: "Mexico City", coordinates: [-99.1332, 19.4326], population: 20063000 },
  { name: "Mumbai", coordinates: [72.8777, 19.0760], population: 17712000 },
  { name: "Osaka", coordinates: [135.5022, 34.6937], population: 17444000 },
  { name: "Moscow", coordinates: [37.6173, 55.7558], population: 16170000 },
  { name: "Dhaka", coordinates: [90.4125, 23.8103], population: 15669000 },
  { name: "Greater Cairo", coordinates: [31.2357, 30.0444], population: 15600000 },
  { name: "Los Angeles", coordinates: [-118.2437, 34.0522], population: 15058000 },
  { name: "Bangkok", coordinates: [100.5018, 13.7563], population: 14998000 },
  { name: "Kolkata", coordinates: [88.3639, 22.5726], population: 14667000 },
  { name: "Buenos Aires", coordinates: [-58.3816, -34.6037], population: 14122000 },
  { name: "Tehran", coordinates: [51.3890, 35.6892], population: 13532000 },
  { name: "Istanbul", coordinates: [28.9784, 41.0082], population: 13287000 },
  { name: "Lagos", coordinates: [3.3792, 6.5244], population: 13123000 },
  { name: "Shenzhen", coordinates: [114.0579, 22.5431], population: 12084000 },
  { name: "Rio de Janeiro", coordinates: [-43.1729, -22.9068], population: 11727000 },
  { name: "Kinshasa", coordinates: [15.2663, -4.4419], population: 11587000 },
  { name: "Tianjin", coordinates: [117.3616, 39.3434], population: 10920000 },
  { name: "Paris", coordinates: [2.3522, 48.8566], population: 10858000 },
  { name: "Lima", coordinates: [-77.0428, -12.0464], population: 10750000 },
]



class DistributionMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 12,
      scale: 700,
      width: 800,
      height: 800,
      center: [10, 50],
      cities: [],
      geographies: [],
      d: 0.0 // renderInterval01
    }

    //this.projection = this.projection.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleMoveStart = this.handleMoveStart.bind(this);
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.refCallback = this.refCallback.bind(this);
    this.refZoomableMap = React.createRef();

    this.getGeoMap = this.getGeoMap.bind(this);
    this.getGeoMap();
  }
  /*
  useEffect()
  {
    fetch("/world-110m.json")
      .then(response => {
        if (response.status !== 200) {
          console.log(`There was a problem: ${response.status}`)
          return;
        }
        response.json().then(worlddata => {
          //this.setState( {(feature(worlddata, worlddata.objects.countries).features);
        })
      })
  }*/
  async componentDidMount() {
    //await this.getGeoMap();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillUpdate() {
    if (this.state.height !== this.props.height) {
        this.setState({ height: this.props.height });
        //this.setState({cities:cities});
    }
    if (this.state.width !== this.props.width) {
      this.setState({ width: this.props.width });
      //this.setState({ cities: cities });
      this.forceUpdate();
    }
  }

  handleResize() {
    /*console.log(this._refMap);
    console.log(this._wrapper);
    if(this._refMap===undefined) return;
    //const box = this._wrapper.querySelector("svg").getBoundingClientRect();
    const box = this._wrapper.getBoundingClientRect();
    //console.log(box);

    this.setState({height: box.height});
    this.setState({width: box.width});
    //this.setState({ zoom: this.state.zoom, center: this.state.center });
    //var width = svg.getBoundingClientRect();

    //this.setState({scale: width / MAP_MAX_WIDTH});
    this.forceUpdate();
    this._refMap.forceUpdate();*/
  }
  /* handleScroll(event) 
   {
     const { deltaY } = event;
     const clampedDeltaY = clamp(-50, 50, deltaY);
     const newZoom = this.state.zoom * (1 + (clampedDeltaY / 200));
     const clampedNewZoom = clamp(1, 10, newZoom);
 
     this.setState({zoom: clampedNewZoom});
     console.log("scroll: ", this.state.zoom);
   } */
  handleWheel(event) {
    event.stopPropagation();
    var oldZoom = this.state.zoom;
    const zoomDirectionFactor = event.deltaY > 0 ? -1 : 1;
    var newZoom = oldZoom + zoomDirectionFactor; // Set new zoom level
    // Ignore nonsens
    if (newZoom > 18 || newZoom < 0.2) return;
    // Set new center so to location under the cursor in the new map is the same as the location under the cursor in the old map
    const cursor = this.getCursorLocation(event.clientX, event.clientY);
    const oldCenter = this.state.center;
    const newCenter = [
      oldCenter[0] +
      ((cursor[0] - oldCenter[0]) / newZoom) * zoomDirectionFactor,
      oldCenter[1] +
      ((cursor[1] - oldCenter[1]) / newZoom) * zoomDirectionFactor
    ];
    this.setState({ zoom: newZoom, center: newCenter });
  }
  handleMoveStart(newCenter) {
    this.setState({ center: newCenter/*, bypassClick: true*/ }); //console.log(this.state.center);
  }
  handleMoveEnd(newCenter) {
    //debugger;
    this.setState({
      center: newCenter,
      //bypassClick: JSON.stringify(newCenter) !== JSON.stringify(this.state.center)
    });
    //console.log(this.state.center);
  }
  handleCountryClick(geo) {
    //console.log("Country: ", this.state.geographies[countryIndex]);
    //debugger;
    // var objects = this.state.geographies.objects;
    // var countries;// = undefined;
    // if (objects.ne_110m_admin_0_countries !== undefined) countries = objects.ne_110m_admin_0_countries.geometries;
    // if (objects.ne_50m_admin_0_countries !== undefined) countries = objects.ne_50m_admin_0_countries.geometries;
    // if (objects.ne_10m_admin_0_countries !== undefined) countries = objects.ne_10m_admin_0_countries.geometries;
    if (geo !== undefined) // && geo!=null)
        console.log("Country: ", geo.properties.NAME);
  }
  handleMarkerClick(name)  { console.log("Marker: ", name); }

  handleLineClick(city) { console.log("City: ", city.name); }
  async getGeoMap() {
    try {
      let response = await fetch(geoUrl);
      let responseJson = await response.json();
      this.setState({ geographies: responseJson });
      //console.log(responseJson); 
    } catch (error) {
      console.error(error);
    }
    this.setState({ cities: cities });
  }
  /*projection() {
    return geoCylindricalEqualArea()
          .translate([this.props.width / 2, this.props.height / 2])
          .scale(this.state.scale);
  }*/
  refCallback(element) // this one fires a lot
  {
    console.log("worldMap refCallback", element);
    /*
    if (element) {
      //this.props.getSize(element.getBoundingClientRect());
      const box = element.getBoundingClientRect();
      console.log("box", box);
      //this.setState({width : box.width});
      //this.setState({height : box.height});
    }*/
  }
  getTween(b/*begin*/, e/*end*/, i/*current value*/) {
    //return b + ((i/99) * (e-b)); // if 0-99
    return b + (i * (e-b));
  }

  render() {
    var geo = this.state.geographies;
    if (geo === undefined || geo.length === 0)
      return null;

    //console.log(this.props.company);
    var company = this.props.company;
    var coordinate = company.addressInfo.coordinate; 
    var transportation = this.props.transportation;
    var displayIndicator = false;
    var indicatorColor = "red";
    if(transportation!==null)
    {
      switch(transportation.state)
      {
        case "Preparing": indicatorColor = "cyan"; displayIndicator=true; break;
        case "Transit": indicatorColor = "green"; displayIndicator=true; break;
        case "Taken": indicatorColor = "yellow"; displayIndicator=false; this.state.d = 0; break;
        default: 
      }
      switch(transportation.cycle)
      {
        case "Waiting":  break;
        default: 
      }
      switch(transportation.status)
      {
        case "Active":  break;
        default: 
      }

      if(transportation.state==="Transit") // move position based on percentage
      {
        this.state.d+=0.05;
        if(this.state.d>1.0) this.state.d = 1.0;
      }
    }

    return <div ref={wrapper => (this._wrapper = wrapper)}
                // onWheel={(e) => this.handleWheel(e)}
                style={{ border: "1px solid black" }}> 

          <ComposableMap>
          <defs>
              <linearGradient id="deliveryGradient" x1="0%" y1="0%" x2="100%" y2="100%" >
                  <stop stop-color="rgba(52,200,32,1)" offset={this.state.d} />
                  <stop stop-color="rgba(239,0,255,1)" offset={this.state.d+0.01} />
                  <stop stop-color="rgba(239,0,255,1)" offset="1" />
              </linearGradient>
          </defs>            
            <ZoomableGroup zoom={this.state.zoom} center={this.state.center}
                           maxZoom={64}
                           minZoom={0.00002}
               //onMoveStart={this.handleMoveStart} 
               //onMoveEnd={this.handleMoveEnd} 
               > 
              <Graticule stroke="#A0A0A0A0" fill="transparent" strokeWidth={0.1} strokestyle='#ccc' linewidth={0.1} />               

              <Geographies geography={this.state.geographies}>
                {({ geographies, i }) =>
                  geographies.map((geo, y) => (
                    <Geography key={"geo"+ y} 
                               geography={geo} 
                               data-tip="country"
                               style={{
                                default: { fill: "#DCDFE1", outline: "none" },
                                hover: { fill: "#607D8B", outline: "#607DFF" },
                                pressed: { fill: "#FF5722", outline: "none" }
                               }}
                               onClick={() => this.handleCountryClick(geo)}
                    />
                  ))
                }
              </Geographies>
                {/* {this.state.cities.map((city, i) => (
                  <Marker key={"mrk"+ i} coordinates={city.coordinates}>
                    <circle r={city.population / 3000000} fill="var(--textHoverColor)" stroke="var(--textColorActive)" strokeWidth={1} onClick={() => this.handleMarkerClick(city)}/>
                    <rect x={-2} y={-2} width={1} height={1} fill="#3E3" />
                    <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "4px" }}>
                      {city.name}
                    </text>
                  </Marker>
                ))} */}
                
                {/* {this.props.stations.map((station, i) =>
                  <Marker key={"mrk"+ i} coordinates={[station.longitude, station.latitude]}>
                        {<circle r={0.1} fill="var(--textColorActive)" stroke="var(--exchangeHighliteColor)" strokeWidth={1} onClick={() => this.handleMarkerClick(station.name)}/>}
                        {station.state===undefined ? station.state=0.01 : station.state+=(Math.random()>0.6 ? 0.005 : 0)}
                        <circle r={station.state} fill="var(--textColorActive)" stroke="var(--exchangeHighliteColor)" strokeWidth={1} onClick={() => this.handleMarkerClick(station.name)}/>
                        {station.name}
                  </Marker>
                )}  */}

                <Marker key={"you_mrk"} coordinates={[coordinate.longitude, coordinate.latitude]} onClick={() => this.handleMarkerClick(company.legalName)}>
                        <circle r={0.1} fill="red" stroke="green" strokeWidth={0.5}  />
                        <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "1px" }}>
                           {company.ticker}
                        </text>
                </Marker>
                { transportation != null ?
                                        <Line key={"line"+transportation.deliveryId} 
                                            from={[transportation.from.longitude, transportation.from.latitude]}
                                            to={[transportation.to.longitude, transportation.to.latitude]}
                                            strokeLinecap="round"
                                            strokeWidth={1 / this.state.zoom}
                                            stroke="url(#deliveryGradient)"
                                            style={{
                                            default: { stroke: "#335722", fill: "#225799", strokewidth: 0.01 / this.state.zoom },
                                            hover: { stroke: "#1157FF", fill: "#1157FF" },
                                            pressed: { stroke: "#2257FF", fill: "#2257FF" }
                                            }}
                                            onClick={() => this.handleLineClick(transportation)}> 
                                        </Line>
                                    : null
                }
                { transportation != null ?
                                         <Marker key={"frm_mrk"} coordinates={[transportation.from.longitude, transportation.from.latitude]} onClick={() => this.handleMarkerClick(transportation.fromLegalName)}>
                                                        <circle r={0.1} fill="purple" stroke="purple" strokeWidth={0.5}  />
                                                        <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "1px" }}>
                                                           {transportation.fromLegalName}
                                                        </text>
                                         </Marker>
                                    : null
                }
                { transportation != null ?
                                         <>
                                          <Marker key={"position_mrk"} 
                                                          coordinates={[transportation.position.longitude, transportation.position.latitude]} onClick={() => this.handleMarkerClick(transportation.fromLegalName)}>
                                                          <circle r={0.1} fill="green" stroke="green" strokeWidth={0.5}  />
                                                          <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "1px" }}>
                                                            {transportation.fromLegalName}
                                                          </text>
                                          </Marker>

                                          {displayIndicator ? <>
                                            <Marker key={"position_mover"} 
                                                coordinates={
                                                  [this.getTween(transportation.from.longitude, transportation.to.longitude, this.state.d), 
                                                  this.getTween(transportation.from.latitude, transportation.to.latitude, this.state.d)]} 
                                                  onClick={() => this.handleMarkerClick(transportation.fromLegalName)}>
                                                <circle r={0.1} fill={indicatorColor} stroke={indicatorColor} strokeWidth={1}  />
                                                <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "1px" }}>
                                                  {transportation.fromLegalName}
                                                </text>
                                            </Marker>
                                          </> : null}
                                        </>
                                    : null
                }

                {/* {this.state.cities.map((city, i) =>
                  <Line key={"line"+i} 
                              from={city.coordinates}
                              to={[14.5058, 46.0569]}
                              strokeLinecap="round"
                              strokeWidth={1 / this.state.zoom}
                              stroke= "var(--exchangeHighliteColor)" 
                              style={{
                                default: { stroke: "#335722", fill: "#225799", strokewidth: 0.01 / this.state.zoom },
                                hover: { stroke: "#1157FF", fill: "#1157FF" },
                                pressed: { stroke: "#2257FF", fill: "#2257FF" }
                              }}
                              onClick={() => this.handleLineClick(city)}
                  />
                )} */}
                {/* {this.props.stations.map((s, i) =>
                  <Line key={"line"+i} 
                              from={[s.Longitude, s.Latitude]}
                              to={[14.5058, 46.0569]}
                              strokeLinecap="round"
                              strokeWidth={1 / this.state.zoom}
                              stroke= "var(--exchangeHighliteColor)" 
                              style={{
                                default: { stroke: "#335722", fill: "#225799", strokewidth: 0.01 / this.state.zoom },
                                hover: { stroke: "#1157FF", fill: "#1157FF" },
                                pressed: { stroke: "#2257FF", fill: "#2257FF" }
                              }}
                              onClick={() => this.handleLineClick(s)}
                  />
                )} */}

            </ZoomableGroup>
          </ComposableMap>
      </div>
  }

  getCursorLocation(clientPosX, clientPosY) {
    const zoom = this.state.zoom;

    console.log("Zoom: " + zoom);

    const { width, height } = this.state;
    const projection = this.projection();
    const box = this._wrapper.querySelector("svg").getBoundingClientRect();
    const { top, left } = box;

    const resizeFactorX = box.width / width;
    const resizeFactorY = box.height / height;

    // position cursor as position within width and height of composableMap
    const clientX = (clientPosX - left) / resizeFactorX;
    const clientY = (clientPosY - top) / resizeFactorY;

    const originalCenter = [width / 2, height / 2];

    // position in Composable map that current center has when map is centered
    const currentCenter = projection(this.state.center);
    console.log(currentCenter);

    // compensation in "Composable map units" needed due to being off-center(panned)
    const offsetX = currentCenter[0] - originalCenter[0];
    const offsetY = currentCenter[1] - originalCenter[1];

    console.log("offsetX: " + offsetX + " - offsetY: " + offsetY);

    // position in Composable map that cursor would have been if the map was centered at this zoom level???
    let x = clientX + offsetX;
    let y = clientY + offsetY;

    console.log("Corrected x: " + x + " - Corrected y: " + y);

    const uncompensatedCursor = projection.invert([x, y]);

    const cursor = [
      this.state.center[0] +
      (uncompensatedCursor[0] - this.state.center[0]) / zoom,
      this.state.center[1] +
      (uncompensatedCursor[1] - this.state.center[1]) / zoom
    ];

    return cursor;
  }
}

export default DistributionMap;