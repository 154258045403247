import React from 'react';
import { Link } from "react-router-dom";
import CompanyDetailView from './../Company/CompanyDetailView';

class ClientProfileCompanyView extends React.Component {
  constructor(props){
     super(props)
     this.state = {
     }
  }
  render()
  {
    let company = this.props.company;
    if(company===null) 
    {
        return  <div>
                    No company linked to this account. Users without linked company can not participate in marketplace <Link to="/apply/company">ADD COMPANY</Link>
                </div>;
    }
    return <div>
            <CompanyDetailView company={company} />
            {/* <table className="profileInformationSection"> 
                <thead>
                    <tr> 
                        <th></th>
                        <th></th>
                    </tr> 
                </thead>                       
              <tbody>
                    <tr><td>Legal Name</td><td><strong>{company.legalName}  </strong></td></tr>
                    <tr><td>Description</td><td><strong>{company.description}</strong></td></tr>
                    <tr><td>Ticker</td><td><strong>{company.ticker}</strong></td></tr>

                    <tr><td></td><td><strong></strong></td></tr>

                    <tr><td>Tax ID</td><td><strong>{company.taxId}</strong></td></tr>
                    <tr><td>Phone</td><td><strong>{company.phone}</strong></td></tr>
                    <tr><td>Email</td><td><strong>{company.email}</strong></td></tr>

                    <tr><td></td><td><strong></strong></td></tr>
                    <tr><td>Address</td><td><strong>{company.address}</strong></td></tr>
                    <tr><td>Postal Code</td><td><strong>{company.postalCode}</strong></td></tr>
                    <tr><td>City</td><td><strong>{company.city}</strong></td></tr>
                    <tr><td>Country</td><td><strong>{company.country}</strong></td></tr>

              </tbody>
            </table>                              */}
        </div>
  }
}

export default ClientProfileCompanyView;