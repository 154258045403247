import React from 'react';

class CompanyDetailView extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null
      }
    }

    render () {
        let company = this.props.company;
        if(company===null || company===undefined) return <h3 className="pageViewScrollable">Awaiting Company data</h3>

        return <div>
                    <h2>{company.legalName}</h2>
                    <table className="profileInformationSection"> 
                                <thead>
                                    <tr> 
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr> 
                                </thead>                       
                              <tbody>
                                    <tr><td>Legal Name</td><td><strong>{company.legalName}</strong></td></tr>
                                    <tr><td>Description</td><td><strong>{company.description}</strong></td></tr>
                                    <tr><td>Ticker</td><td><strong>{company.ticker}</strong></td></tr>

                                    <tr><td></td><td><strong></strong></td></tr>
                                    <tr><td>Tax ID</td><td><strong>{company.taxId}</strong></td></tr>
                                    <tr><td>Phone</td><td><strong>{company.phone}</strong></td></tr>
                                    <tr><td>Email</td><td><strong>{company.email}</strong></td></tr>

                                    <tr><td></td><td><strong></strong></td></tr>
                                    <tr><td>Language</td><td><strong>{company.language}</strong></td></tr>

                                    <tr><td></td><td><strong></strong></td></tr>
                                    <tr><td>Name</td><td><strong>{company.addressInfo.name}</strong></td></tr>
                                    <tr><td>Description</td><td><strong>{company.addressInfo.description}</strong></td></tr>
                                    
                                    <tr><td>Street</td><td><strong>{company.addressInfo.street}</strong></td></tr>
                                    <tr><td>PostalCode</td><td><strong>{company.addressInfo.postalCode}</strong></td></tr>
                                    <tr><td>City</td><td><strong>{company.addressInfo.city}</strong></td></tr>
                                    <tr><td>Country</td><td><strong>{company.addressInfo.country}</strong></td></tr>

                                    <tr><td></td><td><strong></strong></td></tr>
                                    <tr><td>Location</td><td><strong>{company.addressInfo.coordinate.latitude}, {company.addressInfo.coordinate.longitude}</strong></td></tr>

                                    {/* <tr><td>Company</td><td><strong><Link to={"/companyshipment/" + company.id} >{company.legalName} </Link></strong></td></tr> */}
                              </tbody>
                    </table>
            </div>
    }
  }

export default CompanyDetailView;
