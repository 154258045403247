import React from 'react';
import './../../App.css';

import {Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core/';

class NewsCardView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      page:0
    }
  }

  render () {
    return <Card style={{  maxWidth:"20rem",  background:"#FFFFFF01"}}>
                <CardActionArea>
                   <CardMedia component="img" alt={this.props.alt} height ="55vmax" src={this.props.image} title={this.props.title}/> 
                   <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" style={{ color:"#DDDDDD", fontSize:"1.1rem" }}>
                      {this.props.title}
                    </Typography>
                    <Typography variant="body2" component="p" style={{ color:"#CCCCCC" , fontSize:"0.8rem" }}>
                      {this.props.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
              </CardActions>                    
          </Card>
  }
}

export default NewsCardView;