import React from 'react';

var countDecimals = function(value) {
  if (Math.floor(value) !== value)
  {
      //return value.toString().split(".")[1].length || 0;
      var v = value.toString()
      var s = v.split(".")[1];
      if(s===undefined) return 0;
      var l = s.length;
      return l;
  }
  return 0;
}
var lastIsDot = function(str) {
  var s = str.toString();
  var nd = (s.match(/\./g) || []).length;
  return s.slice(-1) === '.' && nd===1; // true last character is dot
}
var maxDecimal = 9;

/* Same for buy and sell */
var priceChanged = function(context, newPrice) {
  var p = Number(newPrice);//.toFixed(8);
  if(!isNaN(p))// && p>0)
  {
    if(countDecimals(p)>=maxDecimal)
       p = p.toFixed(maxDecimal);      

    context.setState({price: p});    
    calculate(context, p, context.state.amount);
  }

  if(lastIsDot(newPrice))
  context.setState({price: newPrice});    

  //console.log("priceChanged", p);
}
var amountChanged = function(context, newAmount) {
  var a = Number(newAmount);
  if(!isNaN(a))// && a>0)
  {
    if(countDecimals(a)>=maxDecimal)
       a = a.toFixed(maxDecimal);      

       context.setState({amount: a}); 
       calculate(context, context.state.price, a);
  }

  if(lastIsDot(newAmount))
    context.setState({amount: newAmount}); 

  //console.log("amountChanged", a);
}
var totalChanged = function(context,newTotal) {
  var t = Number(newTotal);
  if(!isNaN(t))// && t>0)
  {
    if(context.state.price!==0)
    {
     var a = (t / context.state.price);
     //context.amountChanged(a);
     context.setState({amount: a}); 
     context.setState({total: t}); 
    }
  }
  //console.log("totalChanged", t);
}
var totalUpdated = function(context,newTotal) {
  var t = Number(newTotal);
  if(!isNaN(t))// && t>0)
  {
    //if(countDecimals(t)>=maxDecimal)
    //  t = t.toFixed(maxDecimal);      

    context.setState({total: t});
  }
  console.log("totalUpdated", t);
}
var calculate = function(context, price, amount)
{
  if(!isNaN(price) && !isNaN(amount))
  {
    var t = Number(price * amount);
    if(countDecimals(t)>maxDecimal)
       t=t.toFixed(maxDecimal);

    totalUpdated(context, t); 
  } else
  context.setState({total: NaN});
}

class AuctionBid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
         balance: null,
         price: 1.5,
         amount: 2,
         total: 3,
         auction: null
    };

    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeTotal = this.handleChangeTotal.bind(this);

    this.handleBid = this.handleBid.bind(this);
  }
  handleChangePrice(event)  {  priceChanged(this, event.target.value);  }
  handleChangeAmount(event) {  amountChanged(this, event.target.value);  }
  handleChangeTotal(event)  {  totalChanged(this, event.target.value);  }
  handleBid(event) {
    var p = Number(this.state.price);
    var a = Number(this.state.amount);
    var t = Number(this.state.total);
    var fail = !isNaN(p) && !isNaN(a) && !isNaN(t) && p>0 && a>0 && t>0 && t<=this.state.balance; 
    if(!fail)
    {
        alert('Invalid Bid!');    
        return;
    }
    //alert('Buy order was submitted: ' + this.state.total);
    console.log(this.state.auction, this.state.price);
    this.props.comm.createNewBidForAuction(this.state.auction.id, this.state.price );
    // this.props.comm.createNewBuyOrder(this.props.market.id, a, p);
    //this.setState({balance:this.state.balance-t});
  }

  componentDidUpdate(prevProps) {
    if(this.state.auction !== null && this.props.auction!==null) 
      if(this.state.auction.id === this.props.auction.id) 
        return;

    if(this.state.auction !== this.props.auction)
    {
        this.setState({auction: this.props.auction}); 
        
        var a = this.props.auction;
        if(a!==null)
        {
          //priceChanged(this, a.price);
          //amountChanged(this, a.amount);
          this.setState({price:a.price});
          this.setState({amount:a.amount});

          calculate(this, a.price, a.amount);
        }
    }  
    let market = this.props.market;
    let balance = 0; 
    let balances = this.props.balances;
    var order = this.props.template;
    /*if(order!==null && this.state.price!==order.price)
    {
        this.setState({price:order.price});
        this.setState({amount:order.amount});
        this.props.app.setClickSellOrder(null);
        calculate(this, order.price, order.amount);
    }*/

    if(balances!==null)
    {
      //balance = balances.find(ab => ab.asset.id === market.pair.baseAsset.id);
      balance = balances[market.pair.baseAsset.lookupIdx];
      if(balance!==null && this.state.balance!==balance.available)
      {
         this.setState({balance:balance.available});
         //console.log(balance);
      }
    }    
  }   

  render() {
    if(this.props.auction==null) return <div className="viewOrderBuy">
                                            <div className="viewOrderBuyInside">    
                                               select auction to bid to
                                             </div>
                                        </div>

    let market = this.props.market;
    var baseSymbol = "€"; 
    var quoteSymbol = "H";
    if(market===null)
    {
      baseSymbol = "€"; 
      quoteSymbol = "H";
    } else 
    {
      baseSymbol = market.pair.baseAsset.symbol;
      quoteSymbol = market.pair.quoteAsset.symbol;
    }


    return  <div className="viewOrderBuy">
              <div className="viewOrderBuyInside">
                 
              <div className="orderbuy-header">
                  <div>Balance: {this.state.balance} {baseSymbol}</div> 
                </div>
                                  
                <div className="orderbuy-line">
                  <div className="orderbuy-left">Price</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="price"  value={this.state.price} onChange={this.handleChangePrice} /></div> 
                  <div className="orderbuy-right">  {baseSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Amount</div> 
                  <div className="orderbuy-center"><input className="tradeinput inputdisabled" type="text" name="amount" value={this.state.amount} disabled onChange={this.handleChangeAmount}/></div>
                  <div className="orderbuy-right">  {quoteSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Total</div>  
                  <div className="orderbuy-center"> <input className="tradeinput inputdisabled" type="text" name="total"  value={this.state.total.toString()} disabled onChange={this.handleChangeTotal}/></div>
                  <div className="orderbuy-right"> {baseSymbol} </div>
                </div>  
                <button className="orderbuy-button" onClick={() => this.handleBid()}>BID</button>
              </div>
           </div>
   }
   //return <label>{t.type} {t.price} {t.amount} {t.total} {t.date} {t.time} {t.rateStop} {t.action} </label>
}

const auctionDefaultTimeToExpire = 10;
class AuctionCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
         balance: null,
         price: 1.5,
         amount: 2,
         total: 3,
         time: auctionDefaultTimeToExpire //(new Date().now()+1)
    };

    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeTotal = this.handleChangeTotal.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);

    this.handleCreateAuction = this.handleCreateAuction.bind(this);
  }
  handleChangePrice(event)  {  priceChanged(this, event.target.value);  }
  handleChangeAmount(event) {  amountChanged(this, event.target.value);  }
  handleChangeTotal(event)  {  totalChanged(this, event.target.value);  }

  handleChangeTime(event)  {  this.setState({time: Number(event.target.value)}); }

  handleCreateAuction(event) {
    var p = Number(this.state.price);
    var a = Number(this.state.amount);
    var t = Number(this.state.total);
    var fail = !isNaN(p) && !isNaN(a) && !isNaN(t) && p>0 && a>0 && t>0 && a<=this.state.balance; 
    if(!fail)
    {
        alert('Invalid Create Auction!');    
        return;
    }

    //alert('Sell order was submitted: ' + this.state.total);
    this.props.comm.createNewAuction(this.props.market.id, this.state.amount, this.state.price, this.state.time /*days*/); //.toFixed(8)
    // this.props.comm.createNewSellOrder(this.props.market.id, a, p); //.toFixed(8)
  }

  componentDidUpdate(prevProps) {
    let market = this.props.market;
    let balance = 0; 
    let balances = this.props.balances;


    var order = this.props.template;
    if(order!==null && this.state.price!==order.price)
    {
        this.setState({price:order.price});
        this.setState({amount:order.amount});
        this.props.app.setClickBuyOrder(null);
        calculate(this, order.price, order.amount);
    }
    
    //return;
    if(balances!==null)
    {
      balance = balances[market.pair.quoteAsset.lookupIdx];
      if(balance!==null && this.state.balance!==balance.available)
      {
        this.setState({balance:balance.available});
        //console.log(balance);
      }
    }    
  } 

  render() {
    let market = this.props.market;
    var baseSymbol = "€"; 
    var quoteSymbol = "H";
    //console.log(market);
    if(market===null)
    {
      baseSymbol = "€"; 
      quoteSymbol = "H";
    } else 
    {
      baseSymbol = market.pair.baseAsset.symbol;
      quoteSymbol = market.pair.quoteAsset.symbol;
    }

    return  <div className="viewOrderSell">
              <div className="viewOrderBuyInside">
                <div className="orderbuy-header">
                  <div>Quantity: {this.state.balance} {quoteSymbol} </div> 
                </div>
                                  
                <div className="orderbuy-line">
                  <div className="orderbuy-left">Price</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="price"  value={this.state.price} onChange={this.handleChangePrice} /></div> 
                  <div className="orderbuy-right">  {baseSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Amount</div> 
                  <div className="orderbuy-center"><input className="tradeinput" type="text" name="amount" value={this.state.amount} onChange={this.handleChangeAmount}/></div>
                  <div className="orderbuy-right">  {quoteSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Expires</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="total"  value={this.state.time} onChange={this.handleChangeTime}/></div>
                  <div className="orderbuy-right"> {"hours"} </div>
                </div>                  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Total</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="total"  value={this.state.total.toString()} onChange={this.handleChangeTotal}/></div>
                  <div className="orderbuy-right"> {baseSymbol} </div>
                </div>  
                    <button className="orderbuy-button" onClick={() => this.handleCreateAuction()}>Create Auction</button>
                    <br/>
                    
                </div>
            </div>
   }
   //return <label>{t.type} {t.price} {t.amount} {t.total} {t.date} {t.time} {t.rateStop} {t.action} </label>
}



class AuctionCreateOrBid extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }
    
  
    render () {
        let market = this.props.market;
        let balances = this.props.balances;
        let comm = this.props.comm;

        return <div className="viewAuctionCreateBid">
               <AuctionCreate market={market} balances={balances} template={this.props.orderTemplateBuy} comm={comm} app={this.props.app}/>
               <AuctionBid  market={market} balances={balances} template={this.props.orderTemplateSell} comm={comm} app={this.props.app} auction={this.props.app.state.auction}/>
               </div>
    }  
      
}

export default AuctionCreateOrBid;
