import React from 'react';
import { Link } from "react-router-dom";
//import './../../App.css';
import ClientProfileLimitsView from './Client_Profile_LimitsView.js';
import ClientProfileCompanyView from './Client_Profile_CompanyView.js';
import ClientProfileVerificationsView from './Client_Profile_VerificationView.js';

import {Badge, MailIcon } from '@material-ui/core/';

class ClientProfileView extends React.Component {
     constructor(props){
        super(props)
        this.state = {
        }

        this.getData = this.getData.bind(this);
    }
  
    componentDidMount() { 
        this.getData();
    }
    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {
    } 

    getData() { 
      this.props.comm.getClientProfile(); 
    }

    automatedTrader() {
      this.props.comm.toggleAutomatedTrader();
    }

    render () {
        if(this.props.profile===undefined || this.props.profile===null) return null;
        let profile = this.props.profile;
        let badge_login = <Badge badgeContent={1} color="primary"> </Badge>;

        return <div className="pageViewScrollable">
                  <div className="pageNormalViewContents">
                     <h1>PROFILE</h1>
                       <table className="profileInformationSection"> 
                          <thead>
                               <tr> 
                                   <th></th>
                                   <th></th>
                              </tr> 
                           </thead>                       
                        <tbody>
                              <tr><td>Profile name</td><td><strong>{profile.firstName} {profile.lastName}</strong></td></tr>
                              <tr><td>Email</td><td><strong>{profile.email}</strong></td></tr>
                              <tr><td>Eth Address</td><td><strong>{profile.ethAddress}</strong></td></tr>
                              <tr><td>Address</td><td><strong>{profile.address}</strong></td></tr>
                              <tr><td>Location</td><td><strong>{profile.location}</strong></td></tr>
                              <tr><td>Rating</td><td><strong>{badge_login}</strong></td></tr>
                        </tbody>
                       </table> 
                       <br/> 
                      
                       <h4><Link to="apply">Add Profile Details</Link></h4>

                       <ClientProfileCompanyView company={profile.company} />
                       <ClientProfileVerificationsView profile={profile} />
                       <ClientProfileLimitsView profile={profile} />

                       <br />
                       <span onClick={()=>this.automatedTrader()}> more information </span>
                  </div>
               </div>
    }
  }

  export default ClientProfileView;

/*
  <div><strong><small>Profile name</small>{profile.firstName} {profile.lastName}</strong><br/></div> 
  <div><strong><small>Email</small>{profile.email}</strong> <br/> </div>
  <div><strong><small>Signature</small> {profile.ethAddress}</strong> <br/> </div>

  <div><strong><small>Address</small>{profile.address}</strong> <br/> </div>
  <div><strong><small>Location</small>{profile.location}</strong> <br/>  </div>
  */