import React from 'react';
import './../App.css';
import { Link } from "react-router-dom";

class PlaceView extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        //console.log("props", this.props);
        this.getData();
    }
    componentWillUnmount() {
    }
    getData() { 
      if(this.props.comm!==undefined)
      {
          this.props.comm.getPlaceDetail(this.props.match.params.id); 
      }
    }


    render () {
        let data = this.props.company;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div className="pageViewScrollable">
                <h2>Company Detail</h2>
                <div className="pageNormalViewContents">

                    <h3>{data.description}</h3>
                    <table className="profileInformationSection"> 
                                <thead>
                                    <tr> 
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr> 
                                </thead>                       
                              <tbody>
                                    <tr><td>Company</td><td><strong><Link to={"/place/" + data.id} >{data.legalName} </Link></strong></td></tr>
                              </tbody>
                    </table>

                </div>
               </div>
    }
  }

export default PlaceView;
