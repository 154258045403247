import React from 'react';
import { timeDurationWeeks } from '../helpers/Formating';
import { formatMoney } from '../helpers/Formating';

class TransportationsListView extends React.Component {
  constructor(props){
      super(props)
      this.state = {
        info:"",
      };
      this.update = this.update.bind(this);
  }
  onClickTransportation(transportation,i) 
  {
     this.props.distributionView.selectYourTransportation(transportation,i);
  }
  componentDidMount() { 
    this.timerHandle = setInterval(this.update, 1000); // every 1s
  }
  componentWillUnmount() { 
    clearInterval(this.timerHandle); 
  }  
  update() {  
    this.forceUpdate(); 
  }    

  render() {
    let transportations = this.props.transportations;
    if(transportations===null) return null; 
    var now = Date.now();
    
    return  transportations.map((t,i) =>
      <tr key={"trns_"+t.deliveryId} className={t.inProgress===0 ? "orderBuyItem" : "orderSellItem"} onClick={()=>this.onClickTransportation(t,i)} >
          <td className="textRight">{t.state}</td> 
          <td className="textRight">{t.status}</td> 
          <td className="textRight">{t.cycle}</td> 
          <td className="textRight">{t.quantity}</td> 
          <td className="textRight">{t.airDistance.toFixed(2)}</td>
      </tr>
      ) 
  }
}

class YourTransportationsView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }

      renderHeader() {
        return <tr className="orderHeader">
                   {/* <th className="textLeft">EXPIRES</th> */}
                   {/* <th className="textCenter">PRICE</th> */}
                   <th className="textLeft">STATE</th> 
                   <th className="textLeft">STATUS</th> 
                   <th className="textLeft">CYCLE</th> 
                   <th className="textCenter">QUANTITY</th>
                   <th className="textCenter">DISTANCE</th>
               </tr>
      }       
      render () {
        let transportations = this.props.transportations;
        return <div className="viewYourTransportBook">
                <div className="tableHeader">YOUR DELIVERIES</div>
                <table >
                    <thead>
                      {this.renderHeader()}
                    </thead>
                    <tbody>
                    <TransportationsListView transportations={transportations} distributionView={this.props.distributionView}/>
                    </tbody>     
                </table>
               </div>
    }
}

export default YourTransportationsView;