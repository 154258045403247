import React from 'react';
import './../App.css';
import NumberFormat from 'react-number-format';
import {Tooltip} from '@material-ui/core/';
class SummaryRender extends React.Component {
  render() {
    let last = this.props.value;
    //let p = <small><span style={{ color: 'green' }}> <NumberFormat value={last} format="#######" displayType={'text'} decimalScale={3} fixedDecimalScale={true}/> </span></small>; 
    let p = <small><span> <NumberFormat value={last} format="#######" displayType={'text'} decimalScale={3} fixedDecimalScale={true}/> </span></small>; 
            

    return  p; 
  }
}

class MarketSummary extends React.Component {
    selectMarket(market)
    {
      console.log("MarketSummary Select", market);
    }
    render() {
      //console.log("MarketSummary", this.props.market);
      var s, quoteAsset, m = this.props.market;
      if(m!==null) 
      {
         s = this.props.summary;
         quoteAsset = m.pair.quoteAsset;
      }

      //if(s===null || s===undefined) return null; 
      let sum = (s===null || s===undefined) ? <tr><td>nd</td></tr> : <tr>
                                                                            <td className="tableSummaryText"><strong>{m.name}</strong></td> 
                                                                            <td className="tableSummaryText"><small>{quoteAsset.purityLevelQualification.type.description}</small></td> 
                                                                            <td className="tableSummaryText"><small>{quoteAsset.purityLevelQualification.purity}</small></td> 
                                                                            <td className="tableSummaryText"><small>{m.pair.symbol}</small></td> 
                                                                            <td className="tableSummaryText"><SummaryRender value={s.last} /></td> 
                                                                            <td className="tableSummaryText"><SummaryRender value={s.percentage} /><small>%</small></td>
                                                                            <td className="tableSummaryText"><SummaryRender value={s.high} /></td>
                                                                            <td className="tableSummaryText"><SummaryRender value={s.low} /></td>
                                                                            <td className="tableSummaryText"><SummaryRender value={s.volume}  /> <small>{m.pair.baseAsset.symbol}</small></td>
                                                                            <td className="tableSummaryText"><SummaryRender value={s.quantity} /> <small>{m.pair.quoteAsset.symbol}</small></td>
                                                                        </tr>
       
      return  <div className="viewMarketSummary">
               <Tooltip title={quoteAsset!=undefined ? quoteAsset.purityLevelQualification.typicalUses : null} placement="bottom">
               <table className = "viewMarketSummaryTable">  
                <thead>
                    <tr>
                      <th className="tableHeaderSummary"><small>MARKET</small></th>
                      <th className="tableHeaderSummary"><small>TYPE</small></th>
                      <th className="tableHeaderSummary"><small>QUALITY</small></th>
                      <th className="tableHeaderSummary"><small>PAIR</small></th>
                      <th className="tableHeaderSummary"><small>PRICE</small></th>
                      <th className="tableHeaderSummary"><small>CHANGE</small></th>
                      <th className="tableHeaderSummary"><small>HIGH</small></th>
                      <th className="tableHeaderSummary"><small>LOW</small></th>
                      <th className="tableHeaderSummary"><small>VOLUME</small></th>
                      <th className="tableHeaderSummary"><small>QUANTITY</small></th>
                    </tr>
                </thead>    
                <tbody>    
                    {sum}
                </tbody>
              </table>
              </Tooltip>
             </div>
     }
     
  }

  export default MarketSummary;