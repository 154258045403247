import React from 'react';
import { Link } from 'react-router-dom';

import ExchangesAvailable from '../View_ExchangesAvailable.js';
import MarketsView from '../View_Markets.js';
import AuctionCreateOrBid from './View_AuctionCreateOrBid';
import AuctionsBookView from './View_AuctionsBook.js';
import CurrentAuctionView from './View_CurrentAuction';


class AuctionsView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          info:""
        }

        this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        this.getData();
        this.intervalHandle = setInterval(this.getData, 10000); // every n
    }
    componentWillUnmount() { 
      clearInterval(this.intervalHandle); 
    }
    getData() {  
      var m = this.props.app.state.market;
      //debugger;
      if(this.props.app.state.comm!==undefined && m!==null)
         this.props.app.state.comm.getAuctionsForMarket(m.id); 
    }

    async selectExchange(exchange)
    {
        await this.props.app.setExchange(exchange);
        this.getData();
    }  
    async selectMarket(market)
    {
        await this.props.app.setMarket(market);
        this.getData();
    } 

    selectAuction(auction)
    {
        console.log("Auction selected", auction)
        this.props.app.setAuction(auction);
    }
      
    render () {
        let app = this.props.app;
        let data = this.props.data;

        let comm = app.state.comm; 
        let auctions = data.marketAuctions; // view all auctions for market

        let market = data.market;
        let balances = data.balances;
        let orderTemplateSell = data.clickSellOrder;
        let orderTemplateBuy = data.clickBuyOrder;

        let auctionBook = null;
        let auctionCreateOrBid = null;
        let openorders = null;
        if(this.props.app.state.accessToken!==null && this.props.app.state.accessToken!==undefined)
        {
           
           auctionBook = <AuctionsBookView app={app} auctions={auctions} auctionsView={this}/>; 
           auctionCreateOrBid = <AuctionCreateOrBid app={app} balances={balances} market={market} orderTemplateSell={orderTemplateSell} orderTemplateBuy={orderTemplateBuy} comm={comm}/>;

           /*
              openorders = <OpenOrdersView app={app} marketTrades={marketTrades}/>;
           */
        }
   

        return <div className="viewExchange">
                 <ExchangesAvailable app={app} exchangeView={this} />
                 <MarketsView /*app={app}*/ allMarkets={this.props.allMarkets} markets={this.props.markets} market={market} exchangeView={this}/> 
                 <div className="viewMarketSummary">
                      <h2>&nbsp;&nbsp;&nbsp;Auctions</h2>
                      
                  </div> 
                  <CurrentAuctionView auction={this.props.app.state.auction} comm={comm}/>
                  {auctionCreateOrBid}
                  {auctionBook}

                  <div className="viewAuctionTextBottom" >
                    <ol>Steps to the process:
                      <li>The requirements are established by the seller</li>
                      <li>When auction expires bids are sealed. The highest qualified bidder receives the goods or, in the case of a service, the lowest qualified bid wins.</li>
                      <li>Each bidder is allowed to bid and update bid price until expiration of auction. </li>
                      <li>If auction fails, its amount and price is moved to spot marketplace </li>
                    </ol>
                  </div>                 

               </div>
    }
}

export default AuctionsView;