import React from 'react';
import './../App.css';
import CompanyDetailView from './Company/CompanyDetailView';

class CompanyView extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        this.getData();
    }
    componentWillUnmount() {
    }
    getData() { 
      console.log("CompanyView", this.props);
      if(this.props.comm!==undefined)
      {
          this.props.comm.getCompanyDetail(this.props.match.params.id); 
      }
    }


    render () {
        let company = this.props.company;
        if(company===null || company===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div className="pageViewScrollable">
                <h4>Company Detail</h4>
                <div className="pageNormalViewContents">
                    <CompanyDetailView company={company} />
                </div>
               </div>
    }
  }

export default CompanyView;
