import React from 'react';
import './../../App.css';

import { Grid } from '@material-ui/core/';

import ChartCardView from '../../components/Small/ChartCardView.js'; 

class StaticMarketAnalysis extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        page:0,
        card: {
          maxWidth: 345,
        },
      }
    }

    componentDidMount() { 
    }
    componentWillUnmount() {
    }

 
    render () { 
        var justify = "flex-start";
        return <div> 

{this.props.insights ? <div>
<h2>Insights</h2>
                  <Grid container item col={1}  direction="row" justify="center">
                    
                    <ChartCardView id="7" alt="MDad" title="Processes" text="" 
                    image="./img/market/demand_and_supply_side_contracts.png"/>

                    <ChartCardView id="8" alt="MDad" title="Gateway" text="" 
                    image="./img/market/gateway_to_consumers.png"/>

                    <ChartCardView id="10" alt="MDad" title="Objectives" text="" 
                    image="./img/market/objectives_hydrogen_trading_platform.png"/>

                    <ChartCardView id="10" alt="MDad" title="Management" text=""    
                    image="./img/market/market_management.png"/>

                    <ChartCardView id="10" alt="MDad" title="Strategies" text=""    
                    image="./img/market/strategies.png"/>

                    <ChartCardView id="10" alt="MDad" title="Coupling" text=""    
                    image="./img/market/sector_coupling.png"/>

                    <ChartCardView id="10" alt="MDad" title="Electricity and gas models" text=""    
                    image="./img/market/electricity_purchages.png"/>
                    
                    <ChartCardView id="10" alt="MDad" title="Integration" text="" 
                    image="./img/market/hydrogenMarket_integraiton_and_management.png"/>

                    <ChartCardView id="9" alt="MDad" title="Infrastructure" text="" 
                    image="./img/market/hydrogen_delivery_infrastructure_analysis.png"/>

                    <ChartCardView id="9" alt="MDad" title="Needs And Design" text="" 
                    image="./img/market/market_needs definition_and_design.png"/>
                  </Grid> </div> : null } 

{this.props.hubs ? <div> 
<h2>Hubs</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="EU Exchange Hubs" text="" 
                    image="./img/market/hydrogen-exchange-hubs-in-eu.png"/>

                    <ChartCardView id="1" alt="MDad" title="Electricity Prices" text="" 
                    image="./img/m2/electricity-prices-in-stringent-climate-polity.png"/>

                    <ChartCardView id="1" alt="MDad" title="FCEV Cost calculation" text="" 
                    image="./img/m2/fcev-cost-calculation.png"/>
                  </Grid> </div> : null } 

{this.props.resourcesParticipation ? <div>
<h2>Resources Participation</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="Linked" text="" 
                    image="./img/m2/separate-linked-resource-participation.png"/>

                    <ChartCardView id="1" alt="MDad" title="Independant" text="" 
                    image="./img/m2/separate-linked-resource-participation-b.png"/>

                    <ChartCardView id="1" alt="MDad" title="Self Managed" text="" 
                    image="./img/m2/separate-linked-resource-participation-c.png"/>

                    <ChartCardView id="1" alt="MDad" title="ISO Managed" text="" 
                    image="./img/m2/separate-linked-resource-participation-d.png"/>

                  </Grid> </div> : null } 

{this.props.hydrogenMarket ? <div>
<h2>Hydrogen Market </h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="EU Market" text="" 
                    image="./img/m2/hydrogen-eu-market-penetration-forecast.png"/>

                    <ChartCardView id="1" alt="MDad" title="Supply Chains" text="" 
                    image="./img/m2/hydrogen-green-supply-chains.png"/>

                    <ChartCardView id="1" alt="MDad" title="Power Society" text="" 
                    image="./img/m2/hydrogen-path-scaling-to-power-society.png"/>

                  </Grid> </div> : null } 

{this.props.storage ? <div>
<h2>Storage</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="Storage Benefits" text="" 
                    image="./img/m2/normalized-energy-storage-benefits.png"/>

                     <ChartCardView id="1" alt="MDad" title="Storage Cost" text="" 
                    image="./img/m2/normalized-sotrage-cos-2.png"/>

                    <ChartCardView id="1" alt="MDad" title="Normalized Storage" text="" 
                    image="./img/m2/storage-noramlized-by-function.png"/>

                    <ChartCardView id="1" alt="MDad" title="Cost At Harbour" text="" 
                    image="./img/market/sea-transport-liquified-hydrogen-cost-at-harbour.png"/>

                    <ChartCardView id="1" alt="MDad" title="Storage price decline" text="" 
                    image="./img/m2/year-over-year-energy-storage-price-declines.png"/>
                    
                    <ChartCardView id="1" alt="MDad" title="Price duration" text="" 
                    image="./img/market/price-duration-curve-with-and-without.png"/>

                  </Grid>  </div> : null } 

{this.props.connectedMarkets ? <div>
<h2>Connected Markets </h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="PV + HS" text="" 
                    image="./img/m2/solar-pv+hs-outlok-2030.png"/>

                    <ChartCardView id="1" alt="MDad" title="Liquid" text="" 
                    image="./img/m2/solar-pv-and-h2-liquid.png"/>

                    <ChartCardView id="1" alt="MDad" title="Unsubsidized" text="" 
                    image="./img/m2/unsubsidized-solar-pov-lcoe.png"/>                    

                    <ChartCardView id="1" alt="MDad" title="LCOE" text="" 
                    image="./img/m2/unsubsidized-solar-pv-code.png"/>  

                    <ChartCardView id="1" alt="MDad" title="Value of Location" text="" 
                    image="./img/market/value-of-solar-location-specific.png"/>  

                    <ChartCardView id="1" alt="MDad" title="Solar Utility Value" text="" 
                    image="./img/market/utility-value-of-solar.png"/>  

                    <ChartCardView id="1" alt="MDad" title="Behind Meter" text="" 
                    image="./img/market/behind-the-meter-value-of-solar.png"/>  


                    <ChartCardView id="1" alt="MDad" title="Generation Options" text="" 
                    image="./img/market/renewable-electricity-generation-real-options.png"/>                    
                    </Grid> </div> : null } 

{this.props.energyReturn ? <div>
<h2>Energy Returns</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="Solar" text="" 
                    image="./img/market/new-solar-net-EROCI.png"/>                    

                    <ChartCardView id="1" alt="MDad" title="Gasoline" text="" 
                    image="./img/market/gasoline-net-EROCI-informative.png"/>

                    <ChartCardView id="1" alt="MDad" title="Gasoline net" text="" 
                    image="./img/market/gasoline-net-EROCI.png"/>

                    <ChartCardView id="1" alt="MDad" title="Diesel" text="" 
                    image="./img/market/diesel-net-EROCI.png"/>

                  </Grid> </div> : null } 

{this.props.costOfProduction ? <div>
<h2>Cost of production</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="NG Cost" text="" 
                      image="./img/m2/hydrogen-turbine-peaker-compaarison-with-ng-cost.png"/>

                    <ChartCardView id="1" alt="MDad" title="Turbine Load" text="" 
                      image="./img/m2/hydroge-turbine-load-following-ccgt.png"/>

                    <ChartCardView id="1" alt="MDad" title="Flexibility" text="" 
                      image="./img/m2/power-systems-felxibility.png"/>

                    <ChartCardView id="1" alt="MDad" title="Alkaline Electrolysis" text="" 
                      image="./img/market/cost-of-h2-production-from-alkaline-electrolysis.png"/>

                    <ChartCardView id="1" alt="MDad" title="Solar" text="" 
                      image="./img/market/solar-pv-ch2-DNV.png"/>

                    <ChartCardView id="1" alt="MDad" title="Natural Gas" text="" 
                      image="./img/market/hydrogen-production-costo-of-natural-gas.png"/>


                  </Grid> </div> : null } 

{this.props.levelizedCosts ? <div>
<h2>Levelized Costs</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="Levelized Cost" text="" 
                    image="./img/m2/hydrogen-levelized-cost-of-production.png"/>

                    <ChartCardView id="1" alt="MDad" title="Levelized Cost 2" text="" 
                    image="./img/m2/hydrogen-levelized-cost-of-production-2.png"/>
                  </Grid> </div> : null } 

{this.props.consumption ? <div>
<h2>Consumption</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="1" alt="MDad" title="Rise of BEV and FCEV" text="" 
                    image="./img/market/decline-of-ice-and-rise-of-bev-and-fcev.png"/>             

                    <ChartCardView id="1" alt="MDad" title="TCO for Synthetic fuel" text="" 
                    image="./img/market/aviatio-tco-for-synthetic-fuel.png"/>                    

                    <ChartCardView id="1" alt="MDad" title="Regional Transport" text="" 
                    image="./img/market/consumption-regional-transport.png"/>

                    <ChartCardView id="1" alt="MDad" title="Highways" text="" 
                    image="./img/market/consumption-highway-transport.png"/>

                    <ChartCardView id="1" alt="MDad" title="Consumption per capita" text="" 
                    image="./img/market/consumption_per_capita_eu.png"/>

                  </Grid> </div> : null } 

{this.props.transportation ? <div>
<h2>Transportation</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>

                    <ChartCardView id="1" alt="MDad" title="Heavy Duty" text="" 
                    image="./img/market/heavy-duty-trcuk.png"/>        

                    <ChartCardView id="1" alt="MDad" title="Medium Duty" text="" 
                    image="./img/market/medium-duty-vehicle.png"/>

                    <ChartCardView id="1" alt="MDad" title="Light Vehicles" text="" 
                    image="./img/market/light-commercial-vehicle.png"/>             

                    <ChartCardView id="1" alt="MDad" title="International - Long Haul" text="" 
                    image="./img/market/bus-long-haul-international-transportation.png"/>

                    <ChartCardView id="1" alt="MDad" title="Long Distance Bus" text="" 
                    image="./img/market/bus-long-distance-urba-transportation.png"/>

                    <ChartCardView id="1" alt="MDad" title="Urban Bus" text="" 
                    image="./img/market/bus-short-distance-urban-transportation.png"/>                    

                    <ChartCardView id="1" alt="MDad" title="Commuting" text="" 
                    image="./img/market/train-commutin-train-in-slovenia.png"/>
                  </Grid> </div> : null } 

{this.props.growthForecast ? <div>
<h2>Growth Forecast</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="2" alt="MDad" title="Contribution" text="" 
                    image="./img/market/contribution_of_hydrogen_to_final_energy_demand.png"/>

                    <ChartCardView id="2" alt="MDad" title="Demand" text="" 
                    image="./img/market/annual_h2_demand_vs_highway_transport_loading2.png"/>

                    <ChartCardView id="2" alt="MDad" title="Cost per km" text="" 
                    image="./img/market/bus_cost_per_km.png"/>

                    <ChartCardView id="2" alt="MDad" title="Trucks on highways" text="" 
                    image="./img/market/fcev_trucks_on_slovenia_highway.png"/>

                    <ChartCardView id="3" alt="MDad" title="Electrolysis Cost" text="" 
                    image="./img/market/cost_of_alkaline_electrolysis.png"/>

                    <ChartCardView id="4" alt="MDad" title="Micro grids" text="" 
                    image="./img/market/energy_access_growth_of_pico_micro_grids.png"/>
                  </Grid> </div> : null } 

{this.props.costs ? <div>
<h2>Costs</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="11" alt="MDad" title="Transport Multipliers" text="" 
                    image="./img/market/green_tech_energy.png"/>

                    <ChartCardView id="11" alt="MDad" title="Liquefaction" text="" 
                    image="./img/market/hydrogen_liquefaction.png"/>

                    <ChartCardView id="12" alt="MDad" title="Vertical Supply Chain" text="" 
                    image="./img/market/hydrogen_vertical_integrated_supply_chain.png"/>

                    <ChartCardView id="13" alt="MDad" title="Transport" text="" 
                    image="./img/market/novelized_transport_costs_of_hydrogen.png"/>

                    <ChartCardView id="13" alt="MDad" title="Electrolyzer Cost" text="" 
                    image="./img/market/PEM_Electorlyzer_cost_reduction.png"/>
                    </Grid> </div> : null } 

{this.props.tables ? <div>
<h2>Tables</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="5" alt="MDad" title="Efficiency" text="" 
                    image="./img/market/energy_efficiency_imrpovement.png"/>

                    <ChartCardView id="6" alt="MDad" title="Transmission" text="" 
                    image="./img/market/energy_transmision_jobs_green_gas.png"/>

                    <ChartCardView id="6" alt="MDad" title="Short Term Scenarios" text="" 
                    image="./img/market/hydrogen_economy_working_scenarios_short.png"/>

                    <ChartCardView id="6" alt="MDad" title="Long Term Scenarios" text="" 
                    image="./img/market/hydrogen_economy_working_scenarios.png"/>

                    <ChartCardView id="6" alt="MDad" title="Supply cost with logistics" text="" 
                    image="./img/market/total_supply_cost_for_hydrogen_with_cost_of_logistics.png"/>

                    <ChartCardView id="6" alt="MDad" title="Prosumer annual costs" text=""
                    image="./img/market/prosumer_annual.png"/>

                    <ChartCardView id="6" alt="MDad" title="Charging storage" text=""
                    image="./img/m2/capital-cost-charging-storage.png"/>

                    <ChartCardView id="6" alt="MDad" title="Planned details" text=""
                    image="./img/m2/esif-2014-2020-finances-planed-details.png"/>

                    <ChartCardView id="6" alt="MDad" title="Screening Scope" text=""
                       image="./img/m2/screening-analysis.scope.png"/>

                    <ChartCardView id="6" alt="MDad" title="Hydrogen Color Coding" text=""
                       image="./img/market/hydrogen-color-coding.png"/>

                    <ChartCardView id="6" alt="MDad" title="Tank to wheel" text=""
                       image="./img/market/efficiency-of-tank-to-wheel.png"/>

                  </Grid></div> : null } 

{this.props.colorCoding ? <div>
<h2>Color Coding</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="5" alt="MDad" title="Color Coding" text="" 
                    image="./img/tables/hydrogen-color-coding.png"/>

                  </Grid></div> : null } 

{this.props.certifications ? <div>
<h2>Certifications</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>
                    <ChartCardView id="5" alt="MDad" title="Hydrogen Certification" text="" 
                    image="./img/tables/tuv-sud-green-hydrogen-certification.png"/>

                    <ChartCardView id="5" alt="MDad" title="CertiHy Certification" text="" 
                    image="./img/tables/certihy-green-hydrogen-certification.png"/>

                    <ChartCardView id="5" alt="MDad" title="Harmonization" text="" 
                    image="./img/tables/harmonisation-eu-renewable-gas-registry.png"/>

                  </Grid></div> : null } 


{this.props.guaranteesOfOrigin ? <div>
<h2>Guarantees Of Origin</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>

                  <ChartCardView id="5" alt="MDad" title="Guarantees of Origin" text="" 
                    image="./img/tables/guaranties-of-origin.png"/>

                  <ChartCardView id="5" alt="MDad" title="Trading" text="" 
                    image="./img/tables/trading-guaranties-of-origin.png"/>

                  <ChartCardView id="5" alt="MDad" title="Stakeholders" text="" 
                    image="./img/tables/main-stakeholders.png"/>

                  <ChartCardView id="5" alt="MDad" title="Quantitative evaluation" text="" 
                    image="./img/tables/linde-bmw-quantitative-evaluation.png"/>

                   <ChartCardView id="5" alt="MDad" title="System Functions" text="" 
                    image="./img/tables/linde-system-functions.png"/>

                  </Grid></div> : null } 


{this.props.infrastructure ? <div>
<h2>Infrastructure</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>

                  <ChartCardView id="5" alt="MDad" title="Quantitative evaluation" text="" 
                    image="./img/tables/linde-bmw-quantitative-evaluation.png"/>

                   <ChartCardView id="5" alt="MDad" title="System Functions" text="" 
                    image="./img/tables/linde-system-functions.png"/>

                  <ChartCardView id="5" alt="MDad" title="Efficiency" text="" 
                    image="./img/tables/lh2-efficiencs-vs-synfuels.png"/>                    

                  </Grid></div> : null }

{this.props.cryogenicCompression ? <div>
<h2>Cryogenic Compression</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>

                  <ChartCardView id="5" alt="MDad" title="700 bar" text="" 
                    image="./img/tables/hydrogen-cryogenic-compressed.png"/>

                   <ChartCardView id="5" alt="MDad" title="500 bar" text="" 
                    image="./img/tables/hydrogen-cryogenic-compressed-500-bar.png"/>

                   <ChartCardView id="5" alt="MDad" title="350 bar" text="" 
                    image="./img/tables/hydrogen-cryogenic-compressed-350-bar.png"/>

                  <ChartCardView id="5" alt="MDad" title="100 bar" text="" 
                    image="./img/tables/hydrogen-cryogenic-compressed-100-bar.png"/>

                   <ChartCardView id="5" alt="MDad" title="50 bar" text="" 
                    image="./img/tables/hydrogen-cryogenic-compressed-50-bar.png"/>

                  <ChartCardView id="5" alt="MDad" title="5 bar" text="" 
                    image="./img/tables/hydrogen-cryogenic-compressed-5-bar.png"/>

                  <ChartCardView id="5" alt="MDad" title="5 bar 40K" text="" 
                    image="./img/tables/hydrogen-cryogenic-compressed-5-bar-40k.png"/>


                  </Grid></div> : null }     

{this.props.impactOnPrices ? <div>
<h2>Impact On Energy Prices</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>

                  <ChartCardView id="5" alt="MDad" title="Prices" text="" 
                    image="./img/tables/eu-impact-on-energy-prices.png"/>

                  <ChartCardView id="5" alt="MDad" title="EU Battery vs Hydrogen" text="" 
                      image="./img/tables/eu-battery-vs-hydrogen-systems.png"/>

                   <ChartCardView id="5" alt="MDad" title="SI Battery vs Hydrogen" text="" 
                    image="./img/tables/slo-battery-vs-hydrogen-systems.png"/>

                  <ChartCardView id="5" alt="MDad" title="Storage" text="" 
                    image="./img/tables/energy-storage-battery-vs-hydrogen.png"/>

                  </Grid></div> : null }

{this.props.pathwayToDecarbonisaion ? <div>
<h2>Pathway to decarbonisation</h2>
                  <Grid container item col={1}  direction="row" justify={justify}>

                  <ChartCardView id="5" alt="MDad" title="Pathway" text="" 
                    image="./img/tables/slo-ng-and-net-zero-decarbonisation.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Capital Cost" text="" 
                    image="./img/tables/capital-cost-comparisons-joint-italy-slovenia.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Capital Cost" text="" 
                    image="./img/tables/capital-cost-comparisons-joint-italy-slovenia-2.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Capital Cost" text="" 
                    image="./img/tables/capital-cost-comparisons-joint-italy-slovenia-3.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Schedule Comparisons" text="" 
                     image="./img/tables/project-schedule-comparisons-joint-italy-croatia-slovenia.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Zero Carbonization" text="" 
                      image="./img/tables/slovenia-ng-and-net-zero-decarbonisation-pathway.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Pathway" text="" 
                      image="./img/tables/slovenia-ng-and-net-zero-decarbonisation-pathway-2050.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Supply" text="" 
                      image="./img/tables/eu-h2-supply-in-accelerated-decarboniyation-pathway.png"/>  

                  <ChartCardView id="5" alt="MDad" title="Roadmap" text="" 
                      image="./img/tables/hydrogen-economy-roadmap-eu-and-slovenia.png"/>  


                  </Grid></div> : null }                                                                           

                </div>
    }
}

export default StaticMarketAnalysis;

