import React from 'react';
import './../App.css';

import ContractHeader from "./Contract_Header.js";
import ContractShipmentContracts from "./Contract_ShipmentContracts.js";
import ContractShipmentHeader  from "./Contract_ShipmentHeader.js";
import ContractShipmentFooter  from "./Contract_ShipmentFooter.js";

// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class CompanyDelivery extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        shipment:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
       this.getData();
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps) {
      if(this.props.companyDelivery !== null && this.state.shipment===null)
      {
         this.setState({shipment:this.props.companyDelivery});
         console.log(this.props.companyDelivery);
      }
    } 

    packageShipment(fromClientId) {
        if(this.props.comm!==undefined)
        {
            //this.props.comm.getCompanyShipment(this.props.match.params.id); 
        }  
    }

    getData() { 
      if(this.props.comm!==undefined)
      {
          this.props.comm.getCompanyDelivery(this.props.match.params.id); 
      }
    }


    render () {
        let data = this.state.shipment;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>
        let c = data.contracts[0];
        //if(o===null || o===undefined) return <h3 className="pageViewScrollable">No trade detail</h3>

        return <div className="pageViewScrollable">
                  <div className="pageNormalViewContents">
                     <h2>Initiate delivery</h2>

                    {/* <ContractHeader contract={c}/> */}
                    <ContractShipmentHeader shipment={data} />
                    <Accordion>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}> 
                            <Typography>View Included Contracts ({data.contracts.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ContractShipmentContracts contracts={data.contracts} markets={this.props.markets} assets={this.props.assets} />
                            </AccordionDetails>
                    </Accordion>
                    <ContractShipmentFooter shipment={data} />

                    <div onClick={()=>this.packageShipment(data.sellerExporter.id)}>  
                         <button to={"#"}>Package shipment</button> 
                    </div>
                </div>
               </div>
    }
  }

export default CompanyDelivery;
