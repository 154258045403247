import React from 'react';
import './../../App.css';

import RegisterNewUser  from './RegisterNewUser.js';

class RegisterAccount extends React.Component {
    render () {
      return <RegisterNewUser userType="user" loginScreen={this.props.loginScreen}/>
    }
}

export default RegisterAccount;