import React from 'react';
import './../App.css';
import { Link } from "react-router-dom";
import SmallWorldMap from './Maps/SmallWorldMap';
import {Sankey, Hint, makeWidthFlexible} from 'react-vis'; 
import { useForkRef } from '@material-ui/core';

// https://github.com/plouc/nivo

// https://nivo.rocks/swarmplot/ -- visualize buy sell orders
// https://nivo.rocks/area-bump/ -- buyers over time
// https://nivo.rocks/funnel/ -- marketplace analysis
// https://nivo.rocks/bump/ -- visualize  volume comparisons between markets
// https://nivo.rocks/stream/ -- vendors volume stream

const MODE = ['justify', 'center', 'left', 'right'];
var nodes = [{name: 'You'}, {name: 'Aggregate'}, {name: 'Source'}];
var links = [
  {source: 0, target: 1, value: 1},
  {source: 1, target: 2, value: 1},
  {source: 0, target: 2, value: 2}
];

class SellerCompanies extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null,
        companies: null, 
        selectCoord: null // what coord to display center
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        //console.log("props", this.props);
        this.getData();
    }
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }
    shouldComponentUpdate(nextProps, nextState)
    {
      if(this.state.companies===null && nextProps.companies!==null)
      {
        //this.setState({marketId:nextProps.market.id})
        //this.getData();
        console.log("companies", nextProps.companies);
        this.setState({companies: nextProps.companies});
        // create nodes 
        var d = nextProps.companies;
        nodes= [];
        nodes.push({name: 'You'});
        nodes.push({name: 'Aggregate'});
        links= [];
        //links.push({source: 0, target: 1, value: 5});
        // if(d.length===0) return; 
        var all=0;
        d.map((c,i) => {
            nodes.push({name: c.city});
            links.push({source: 1, target: i+2/*c.outstandingOrders*/, value: c.outstandingOrders});
            all+=c.outstandingOrders;
        }); 

        links.push({source: 0, target: 1, value: all});
      } 

      return true;
    } 

    getData() { 
      if(this.props.comm!==undefined)
      {
          this.props.comm.getSellerCompanies(this.props.match.params.id); 
      }
    }
    markerClick(m) {
      console.log("markerClick",m);
    }
    poiClick(e) {
      console.log("poiClick",e);
    }
    selectCompanyFocus(company) {
      console.log("company", company);
      this.setState({selectCoord:company.coordinates});
    }

    render () {
        let data = this.props.companies;
        if(this.props.profile===null) return <h2 className="pageViewScrollable">No profile</h2>
        if(this.props.profile.addressInfo===null) return <h2 className="pageViewScrollable">No address info</h2>
        const coord = this.props.profile.addressInfo.coordinate;
        

        if(data===null || data===undefined) return <h3 className="pageViewScrollable">No data for sellers</h3>
        if(data.length===0) return <h2 className="pageViewScrollable">No sellers to request from</h2>

        var poiLocations = []; 
        data.map(o =>  poiLocations.push({ name: o.city /*legalName*/ , coordinates: o.coordinates, amt: o.outstandingOrders, population:0, avg: o.distance }) );
        // [o.coordinates[1], o.coordinates[0]]

        //let o = data.order;
        //if(o===null || o===undefined) return <h3 className="pageViewScrollable">No trade detail</h3>

        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents">
                    <h2>Vendors</h2>
                    Listed here are companies that you bought goods from. You can request deliveries for these goods from sellers here. 
                    <hr/>
                    <div className="rightSide">
                      <table className="profileInformationSection balancesSection tableFinancialView"> 
                                  <thead>
                                      <tr> 
                                          <th><h3>COMPANY NAME</h3></th>
                                          <th><h3>OWED ORDERS</h3></th>
                                          <th><h3>CITY</h3></th>
                                          <th><h3>DISTANCE</h3></th>
                                      </tr> 
                                  </thead>  
                                <tbody>
                                      {data.map(o => 
                                          <tr key={o.clientId} >
                                            <td> <Link to={"/companyshipment/"+o.clientId} >{o.legalName} </Link> </td>
                                            <td> {o.outstandingOrders} </td>
                                            <td onMouseEnter={(e) => this.selectCompanyFocus(o)}> {o.city} </td>
                                            <td> {o.distance} </td>
                                          </tr>
                                        )}
                                </tbody>
                      </table>
                      <div>
                      <Sankey
                        align={MODE[3]}
                        nodes={nodes}
                        links={links}
                        width={800}
                        height={300}
                        layout={24}
                        margin={50}
                        style={{
                          links: {
                            opacity: 0.1,
                            color: 'red'
                          },
                          labels: {
                            fontSize: '8px',
                            color: '#103177'
                          },
                          rects: {
                            strokeWidth: 1,
                            stroke: '#103177'
                          }
                        }}
                      />
                    </div>

                    </div>

                    <div className="leftSide" style={{position:'fixed'}}>
                      <SmallWorldMap zoom={32}
                                     center={this.state.selectCoord!=null ? this.state.selectCoord : [coord.longitude, coord.latitude]}
                                     marker={[coord.longitude, coord.latitude]}
                                     markerClick={this.markerClick}
                                     poiClick={this.poiClick}

                                     supplyLocations={[]}
                                     demandLocations={[]}
                                     poiLocations={poiLocations}
                      />
                      
                        {/* <Hint x={10} y={10} value={Hint}/>
                      </Sankey> */}
                    </div>
                </div>
               </div>
    }
  }

export default SellerCompanies;
