import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwitchNetworkNotice from './SwitchNetworkNotice'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import DownloadMetaMaskButton from './DownloadMetaMaskButton.js';
import Eth from 'ethjs-query';
import etherscanLink from 'etherscan-link';
import { Link } from 'react-router-dom'
import logo from './coin.jpg';
import queryString from 'querystringify';

//import tokenLogo from './../../../public/logo/eh2_logo_256_white.png';  

import '../../App.css';

const coinAddress = '0xA7d768EbD9915793393F117f8aB10F4A206875d8';

class AddTokenPanel extends Component {

  constructor (props) {
    const {
        tokenName = 'EnergonX Hydrogen H₂', 
        tokenSymbol = 'EH2',
        tokenDecimals = 18,
        tokenAddress = coinAddress,
        tokenImage = 'https://energonx.com/img/logo/eh2_logo_256.png',
        tokenNet = '1',
        message = '',
        errorMessage = '',
        net = '1',
    } = props; 

    super()
    this.state = {
      tokenName,
      tokenSymbol, 
      tokenDecimals,
      tokenAddress,
      tokenImage,
      tokenNet,
      message,
      errorMessage,
      net,
    }

    //const search = window.location.search
    //const params = queryString.parse(search)
    /*
    for (let key in params) {
      this.state[key] = params[key]
    }
    */
    this.updateNet()
  }

  componentDidMount() {
    //const search = this.props.location.search
    //const params = queryString.parse(search)
    //this.setState(params)
  }

  async updateNet () {

    try{
    const provider = window.web3.currentProvider
    const eth = new Eth(provider)
    const realNet = await eth.net_version();
    this.setState({ net: realNet })
    } catch(e)
    {
      this.setState({metamaskAvailable:false})
    }

  }

  async OnClickAddWatch(event)
  {
    try 
    {
      const provider = window.web3.currentProvider
      provider.sendAsync({
        method: 'metamask_watchAsset',
        params: {
          "type":"ERC20",
          "options":{
            "address": this.state.tokenAddress,
            "symbol": this.state.tokenSymbol,
            "decimals": this.state.tokenDecimals,
            "image": this.state.tokenImage,
          },
        },
        id: Math.round(Math.random() * 100000),
      }, (err, added) => {
        console.log('provider returned', err, added)
        if (err || 'error' in added) {
          this.setState({
            errorMessage: 'There was a problem adding the token.',
            message: '',
          })
          return
        }
        this.setState({
          message: 'Token added!',
          errorMessage: '',
        })
      });
    } catch(e)
    {
      window.appComponent.displayError("No Metamask Installed");
    }
  }
  OnClickOpenPool(){
    // route to new page by changing window.location
    window.open("https://uniswap.info/pair/0xFe9845EdFea4b8eFEd936B9c1294E7Fb0D65bc55", "_blank") //to open new page
 }

  render (props, context) {
    const {
      tokenName,
      tokenSymbol,
      tokenDecimals,
      tokenNet,
      net,
      tokenImage,
      tokenAddress,
      message,
      errorMessage,
    } = this.state

    let error
    if (errorMessage !== '') {
      error = <p className="errorMessage">
        There was a problem adding this token to your wallet. Make sure you have the latest version of MetaMask installed!
        <DownloadMetaMaskButton/>
      </p>
    }

    if (tokenNet !== net) {
      return <SwitchNetworkNotice net={net} tokenNet={tokenNet}/>
    }

    return (
      <div className="values">
        {/* <header className="App-header"> */}
          
          {/* <img src={tokenLogo} className="logo" alt="EH2 Token" height="64px"/> */}
          {/* <h4 className="App-title">Watch {tokenName}</h4> */}
        {/* </header> */}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Symbol <img src={tokenImage} className="logo" alt="EH2 Token" style={{height:"16px"}}/> </TableCell>
              <TableCell>{tokenSymbol}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Decimals</TableCell>
              <TableCell>{tokenDecimals}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div>
          <Button
            onClick={() => { const { tokenAddress, net } = this.state
                             window.location.href = etherscanLink.createAccountLink(tokenAddress, net) }}
            href={etherscanLink.createAccountLink(tokenAddress, net)}
          >View on Etherscan</Button>
          <Button onClick={(e)=>this.OnClickAddWatch(e)}>Watch in Wallet</Button>

          <Button onClick={(e)=>this.OnClickOpenPool(e)}>Pool Analysis</Button>
          
          {/* <Button
            onClick = {async (event) => {
              const provider = window.web3.currentProvider
              provider.sendAsync({
                method: 'metamask_watchAsset',
                params: {
                  "type":"ERC20",
                  "options":{
                    "address": tokenAddress,
                    "symbol": tokenSymbol,
                    "decimals": tokenDecimals,
                    "image": tokenImage,
                  },
                },
                id: Math.round(Math.random() * 100000),
              }, (err, added) => {
                console.log('provider returned', err, added)
                if (err || 'error' in added) {
                  this.setState({
                    errorMessage: 'There was a problem adding the token.',
                    message: '',
                  })
                  return
                }
                this.setState({
                  message: 'Token added!',
                  errorMessage: '',
                })
              })
            }}
          >Watch in Wallet</Button> */}

        </div>

        <p>{message}</p>
        {error}
        {/* <img src={tokenLogo} className="logo" alt="EH2 Token" height="64px"/> */}
      </div>
    )
  }
}

AddTokenPanel.contextTypes = {
  web3: PropTypes.object,
}

export default AddTokenPanel;