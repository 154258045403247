import React from 'react';
import './../App.css';

class ExchangesRender extends React.Component {
    render() {
      //console.log("Render", this.props.app.state.exchange, this.props.exchange);
      let e = this.props.app.state.exchange.id === this.props.exchange.id ?
       <strong style={{ color: 'var(--itemHighliteColor)' }} >{this.props.exchange.name}</strong> :
       <small> {this.props.exchange.symbol}</small>; 
  
       return e;
    }
  }

class ExchangesAvailable extends React.Component {
    selectExchange(exchange)
    {
        this.props.exchangeView.selectExchange(exchange);
    }
    render() {
      let exchanges = this.props.app.state.exchanges;
      if(exchanges.length===0) 
         return null;
      if(this.props.app.state.exchange===undefined || this.props.app.state.exchange===null) 
         return null;
  
      //console.log("AvailableExchanges", exchanges);
      return <div className="exchangesBanner" >
            <div className="exchangesTable">
              {exchanges.map(e => 
                        <span key={e.id} className="exchangeItem" onClick={()=>this.selectExchange(e)}> <ExchangesRender exchange={e} app={this.props.app}/> 
                        </span>
                        )}
            </div>
              {/* <table className="exchangesTable">
                <tbody>
                  <tr>
                  {exchanges.map(e => 
                    <th key={e.id} className="exchangeItem" onClick={()=>this.selectExchange(e)}> <ExchangesRender exchange={e} app={this.props.app}/> 
                    </th>
                    )}
                  </tr> 
                </tbody> 
              </table> */}
        </div>
     }
  }

  export default ExchangesAvailable;

  /*
  <span className="exchangeItem" onClick={()=>this.selectExchange(e)}> <ExchangesRender exchange={e} app={this.props.app}/> </span>
  */ 