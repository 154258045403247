import React from 'react';
import { Link } from "react-router-dom";
//import './../../App.css';
import Pager from "./../helpers/Pager.js";

class ClientOrdersViewSells extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        this.getData();
        this.intervalHandle = setInterval(this.getData, 41000); // every 50s

        if(this.props.tradeInfo!=null)
           this.setState({numPages:Math.ceil(this.props.tradeInfo.sells/25)});             
    }
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }

    getData() { 
      if(this.props.comm!==undefined)
         this.props.comm.getClientSellOrders(this.state.page); 
    }

    async setPage(p) { if(p>0 && p<=this.state.numPages) { await this.setState({page:p}); this.getData(); }}
    nextPage() { this.setPage(this.state.page+1); }
    prevPage() { this.setPage(this.state.page-1); }
    firstPage(){ this.setPage(1); }
    lastPage() { this.setPage(this.state.numPages); }

    cancelSellOrder(orderId) {
      if(this.props.comm!==undefined)
      {
         this.props.comm.cancelSellOrder(orderId); 
         var component = this;
         setTimeout(function() { component.getData(); }, 3000);
      }
    }

    render () {
        let data = this.props.sells;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">No data</h3>
        if(data.length===0) return <h3 className="pageViewScrollable">No open sell orders</h3>

        return <div className="pageViewScrollable">
                 <h2>SELL ORDERS</h2>
                  <div className="pageNormalViewContents">
                         <table className="balancesSection tableFinancialView"> 
                           <thead>
                               <tr> 
                               <th>MARKET</th>
                                <th>PRICE</th>
                                <th>AMOUNT</th>
                                <th>TOTAL VALUE</th>
                                <th>FILLED</th>
                                <th>ACTION</th>
                              </tr> 
                           </thead>
                           <tbody>
                           {data.map(o => 
                              <tr key={o.id}>
                                <td> {this.props.markets[o.marketId-1].name} </td>
                                <td> {o.price} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                                <td> {o.amount} </td>
                                <td> {(o.price * o.amount).toFixed(8)} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                                <td> {o.filled} </td>
                                <td onClick={()=>this.cancelSellOrder(o.id)}> <Link to="#">Cancel</Link> </td>
                              </tr>
                            )}
                           </tbody>
                         </table> 

                         <Pager component={this}/>                            
                  </div>
               </div>
    }
  }

  export default ClientOrdersViewSells;