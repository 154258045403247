import React from 'react';
import '../../App.css';
import { Link } from "react-router-dom";
//import {Card, CardActionArea, CardActions, CardContent, CardMedia, CardHeader, Collapse, Papar, Typography, Button, Grid } from '@material-ui/core/';
import { Grid } from '@material-ui/core/';

import Chart from 'react-apexcharts';
import NewsCardView from '../Small/NewsCardView.js'; 
import StaticMarketAnalysis from '../Analysis/StaticMarketAnalysis.js';   
//import Modal from './node_modules/react';


const weBelieve = 
[
  {
    id: 0,
    title: "A clean energy",
    description: "Advantages of hydrogen as an energy source lies in the fact that its combustion does not emit carbon dioxide (CO2), the main cause of the greenhouse effect.",
    image: ""
  },
  {
    id: 1,
    title: "Pure Zero Emission",
    description: "Possiblity to obtain hydrogen itself from water by electrolysis where only by-product of this process is pure oxygen. Clean energy from hydrogen has no polluting by-products and zero emissions.",
    image: ""
  },  
  {
    id: 2,
    title: "It's storage",
    description: "Hydrogen is very versatile energy carrier, capable of absorbing energy; a reason why it may solve one of the main problems related to energy management: its storage.",
    image:""
  },
  {
    id: 3,
    title: "Availability",
    description: "The availability of hydrogen is almost unlimited; it exists in several compounds and its molecule has highest density of energy per weight.",
    image:""
  },
  {
    id: 4,
    title: "An ally of renewable energy sources",
    description: "According to experts from the Hydrogen Council approximately 23% of our energy production comes from renewable sources and this amount will grow to 68% by 2050. The efficiency of solar- or wind-powered plants depends on the variable presence of sun and wind, with considerable discrepancies between energy production and its demand: for example in Europe, during the winter, just when the need is higher, the production of solar energy drops by 60%.Thanks to its capacity to store energy, hydrogen could be used in integrated renewable source systems to recover excess energy, which would otherwise be wasted, and make it available as and when required.",
    image:""
  },
  {
    id: 5,
    title: "Environmental impact",
    description: "The environmental impact of using fossil fuels and growing demand for energy make it essential that we find renewable and clean sources of energy.  ",
    image:""
  },
  {
    id: 6,
    title: "Produce and Store",
    description: "The most widely available element in the universe, Hydrogen is a crucial element in solving our planet's energy needs and environment. Can easly be produced, efficiently stored and safely transported.", 
    image:""
  },
  {
    id: 7,
    title: "Transportation",
    description: "Current methods permit hydrogen to be stored as a gas in high-pressure tanks, as a liquid in cryogenic containers, and as a solid in combination with other elements (metal hydrides).", 
    image:""
  }
  
]

class WeBelieveHydrogen extends React.Component {
  render() {
   let data = weBelieve;
    return <div>
           <h2>In Hydrogen We Trust 💧</h2>
            <table className="profileInformationSection" style={{ width:"85%" }}>
              <thead>
                <tr >
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(o => (
                    <tr key={o.id} >
                      <td><h3 style={{ verticalAlign:"top", textAlign:"right", margin:"1rem" }}>{o.title} </h3> </td>
                      <td className="MuiTypography-body2" style={{ verticalAlign:"center" }}> {o.description}</td>
                    </tr>
                  )
                )}
                </tbody>
            </table>
          </div>
  }
}

const energonXFeatures = 
[
  { id:0, alt:"Contemplative", title:"User Experience", text:"Non-stop online customer service to ensure the smooth operation of every user’s transactions.", 
  image:"./img/liquid-heart.png"},
  { id:1, alt:"Contemplative", title:"Support", text:"Constantly introduce and list quality assets from all over the world with Guarantee of Origin and provide users with various types of transaction services in digital world." ,
  image:"./img/hydrogen-sphere.png"},
  { id:2, alt:"Contemplative", title:"Liquidity", text:"With built-in artificial market-maker digital engine that meets the industrial standards", 
  image:"./img/hydrogen-splash.png"},
  { id:3, alt:"Contemplative", title:"AI", text:"Model analysis based on market parameters provides non-stop analytics.",
  image:"./img/liquid-c.png"},
  { id:4, alt:"Contemplative", title:"Secure", text:"Every login, every order, every claim backed with cryptographic signatures and proofs", 
  image:"./img/liquid-drops.png"},
  { id:5, alt:"Contemplative", title:"Structure", text:"Multimodular structure that meets the requirement of the IT surveillance logical structure in financial industry",
  image:"./img/hydrogen-splash.png"},
  { id:6, alt:"Contemplative", title:"Steady", text:"Front and back end designs with the multi-node guaranteed steady operation of system.",
  image:"./img/water-flow.png"},
  { id:7, alt:"Contemplative", title:"Multimodular", text:"Multimodular distributed deployment scales out our capacity and thus provide better service for our customers.",
  image:"./img/water-splat.png"},
  { id:8, alt:"Contemplative", title:"Partners", text:"Our reliable trustworthy partners provide certified quality products and services",
  image:"./img/water-flow.png"},
  { id:9, alt:"Contemplative", title:"Transportation", text:"Get your contracts delivered safe. cost efficient and on-time",
  image:"./img/liquid-c.png"},
  { id:10, alt:"Contemplative", title:"Purpose", text:"Made for producers, consumers and transportation providers",
  image:"./img/water-pin.png"},
  { id:11, alt:"Contemplative", title:"Location", text:"Unique location aware market brings you closer to your goods",
  image:"./img/water-splash.png"},
  { id:12, alt:"Contemplative", title:"Traceability", text:"Rely on complex network of supply chains that address present enormous economic, social and environmental risks.",
  image:"./img/water-splat.png"},
  { id:13, alt:"Contemplative", title:"Transparency", text:"Mitigate risks by enhancing transparency to create more efficient and sustainable supply chains.",
  image:"./img/water-wide.png"},
  { id:14, alt:"Contemplative", title:"Cross Industry", text:"Neutral platform encourages collaboration and use public and private sector inputs to shape our future.",
  image:"./img/water-splash.png"
  }
]

class EnergonXFeatures extends React.Component {
  render() {
    let data = energonXFeatures;
    return <div>
           <h1>Efficient, flexible, stable and secure marketplace</h1>
           <Grid	container spacing={0} direction="row" alignItems="flex-start" justify="center">
                 {data.map(o => <div key={o.id}><NewsCardView alt={o.alt} title={o.title} text={o.text} image={o.image}/></div> )}
           </Grid>
          </div>
  }
}

class ProjectOverview extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <div className="profileInformationSection">
              <ol className="text-left">
                <h3>An overview of the market the company is looking to penetrate.</h3>
                <h3>What problem the company is proposing to solve and why they need to be solved.</h3>
                <h3>A full description of the system’s architecture.</h3>
                <h3>How users will interact with the system itself.</h3>
                <h3>An introduction to the team behind the project and their specialisms.</h3>
                <h3>Information on the market cap and growth.</h3>
                <h3>Plans for, or existing partnerships.</h3>
                <h3>The terms and conditions of using the token.</h3>
                <h3>The philosophies and ethoses that underpin the company.</h3>
              </ol>
             </div>
           </div>
  }
}


class AboutExchange extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        page:0,
        card: {
          maxWidth: 345,
        },
      }
    }

    componentDidMount() {  }
    componentWillUnmount() { }

    render () {
        
        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents">

                   <EnergonXFeatures />
                   <StaticMarketAnalysis insights={true}/> 
                   <WeBelieveHydrogen />

                   <h2><Link to="/aboutproject">Read More About Project</Link></h2>

                </div> 
               </div>
    }
  }

export default AboutExchange;


