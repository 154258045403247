import React from 'react';
import AddTokenPanel from './AddTokenPanel.js';
import './../../App.css';
import { InitialMenuSelector } from './../project/InitialView';

class WelcomeGetTokens extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        iframe:  '<iframe src="https://uniswap.exchange/swap?outputCurrency=0xA7d768EbD9915793393F117f8aB10F4A206875d8"  height="660px" width="100%" style="border: 0;margin: 0 auto;display: block;border-radius: 10px;max-width: 600px;min-width: 300px;" id="uniswap_window"/>'  ,
        page:0
      }
    }
    //DAI: 0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359
    //https://uniswap.exchange/swap?outputCurrency=0xea0bea4d852687c45fdc57f6b06a8a92302baabc

    componentDidMount() { 
    }
    componentWillUnmount() {
    }

    iframe() {
      return {
        __html: this.state.iframe
      }
    }

    render () {
        return <div className="pageViewScrollable">
                {/* <h3>Get EnergonX Hydrogen H₂ Token (EH2) </h3> */}
                <div className="pageNormalViewContents">
                  {/* <div dangerouslySetInnerHTML={ this.iframe() } />
                  <div className="profileInformationSection">
                      <AddTokenPanel />
                      <hr/>
                          <div>Enter the amount of ETH you want to exchange for EH2 tokens</div>
                          <div>Be sure EH2 token is selected as output</div>
                          <div>Uniswap will auto-fill the amount of EH2 tokens you will receive for ETH amount</div>
                          <div>Click on the Swap button</div>
                          <div>Metamask Notification popup shows, click on the Confirm button</div>
                      <hr/>
                          <div>Check that Output is EnergonX Hydrogen H₂ token with symbol EH2 </div>
                          <div>Paste EH2 token's address (0xA7d768EbD9915793393F117f8aB10F4A206875d8) to Token Address field then select it from dropdown</div>
                          <p>Alternatively you can get tokens directly on <a href="https://uniswap.exchange/swap?outputCurrency=0xA7d768EbD9915793393F117f8aB10F4A206875d8">Uniswap</a></p>
                  </div> */}
                  {/* <InitialMenuSelector /> */}
                  
                  <div className="profileInformationSection">
                      
                      <hr/>
                          <div>Bootstrap time has ended.</div>
                          <div><h3>Uniswap market will be deprecated on 2nd of November 2021</h3></div>
                          <div>Utility token will be available through our platform soon.</div>
                          <p>You can still get tokens directly on <a href="https://uniswap.exchange/swap?outputCurrency=0xA7d768EbD9915793393F117f8aB10F4A206875d8">Uniswap</a> until 2.November 2021</p>
                      <hr/>
                        <br/>
                        <div>EnergonX Hydrogen H₂ token symbol EH2 <img src={"https://energonx.com/img/logo/eh2_logo_256.png"} className="logo" alt="EH2 Token" style={{height:"16px"}}/></div>
                        <div>EH2 token's address (0xA7d768EbD9915793393F117f8aB10F4A206875d8) </div>

                      <AddTokenPanel />
                  </div>

                  <hr/>
                  <Disclamer />
                </div>
               <p><br/><br/><br/></p> 
               </div>
    }
  }

export default WelcomeGetTokens;
/*
1. navigate to https://uniswap.exchange/remove-liquidity
3. click on Select a token button
4. paste your token's address to Token Address field, and select it from dropdown
4. Uniswap will show you your pool balance
5. enter the amount you want to withdraw
6. Uniswap will show you both ETH and MST funds you will receive
7. click on the Remove Liquidity button
8. Metamask Notification popup shows, click on the Confirm button
*/

class Disclamer extends React.Component 
{
  constructor(props){
    super(props)
    this.state = {
    }
  }  
  render() {
    return <>
<div class="section-content documentInformationSection">
          <h2> DISCLAIMER   </h2>

The statutory aim of EnergonX is to contribute to the creation of an distributed marketplace platform and marketplace services (the "EnergonX Marketplace"). 
{/* To this end, the Foundation will promote and support a sustainable, independent and meritocratic community for the development of user-centric free/libre open source software (FLOSS) based on open format standards that connect users in a trustless peer-to-peer network, enabling privacy-centric processing and storage of data coordinated through crypto-economic incentives (hereafter: “EnergonX”). For more details, please refer to the Articles of Association. <br/> */}
<br/>

At its sole discretion, EnergonX may sell EH2 Tokens to the public. EnergonX shall not be held liable for any delay and/or postponement and/or impossibility to use EH2 Tokens. <br/><br/>

The EnergonX Token Purchase Agreement shall apply to any public sale of EH2 Tokens. Purchasers of EH2 Tokens in any such public sale understand that they will be bound by the EnergonX Token Purchase Agreement available and as updated from time to time. EH2 Tokens are not intended to give Tokens Owners any other right than those described in the Token Purchase Agreement and do not provide Token Owners with any interest in equity, assets, bonds, securities, interest, yield, debt, right to repayment, or intellectual property right. <br/><br/>

EH2 TOKENS WILL NOT QUALIFY AS PURE CRYPTOCURRENCIES LIKE, FOR INSTANCE, BITCOIN OR ETHEREUM AND MAY NOT BE USED AS SUCH IN ANY WAY. THEY WILL ALSO NOT AND SHALL NOT BE USED IN ANY WAY AS EQUITY, ASSETS, BONDS, SECURITIES, DERIVATIVES OR ANY OTHER FINANCIAL INSTRUMENTS. <br/><br/>

EnergonX is neither a bank, a private bank, an exchange, a securities dealer, a fund, a collective scheme investment manager or distributor, a financial intermediary, an asset manager or otherwise a financial institution and is neither authorized to act as such nor monitored by any financial market supervisory authority, including the Financial Market Supervisory Authority. Neither this documentation nor any other information material relating to EnergonX and/or the EH2 Tokens, including but not limited to the EnergonX Token Purchase Agreement, have been or will be filed with or approved by any regulatory authority. In particular, this documentation will not be filed with the Financial Market Supervisory Authority. The EH2 Tokens and the EH2 Token public sale are not and will not be subject to any supervision and/or authorization  and EH2 Tokens Owners or Potential EH2 Tokens Owners will not benefit from protection or supervision by such authority. <br/><br/>

Any purchase of a digital asset involves risks, which are described in the EH2 Tokens documentation, in particular, but not limited to, the EnergonX Token Purchase Agreement. <br/><br/>

Furthermore, each EH2 Tokens Owner or Potential EH2 Tokens Owner is aware, understands and accepts the inherent risks associated with Blockchain technology and cryptocurrencies in general and the EH2 Tokens in particular, including, but not limited to, those listed hereinafter. More comprehensive risk factors describing risks associated with EH2 Tokens are set out in the EH2 Tokens documentation in particular, but not limited to, the EH2 Token Purchase Agreement. <br/><br/>

EH2 Tokens Owners are aware, understand and accept that any smart contract, and/or any underlying software application and/or any blockchain network are new technology that is still in an early stage of development. EH2 Tokens Owners are aware, understand and accept that the operation of blockchain networks, including the Ethereum blockchain, may be interrupted or may contain errors. EH2 Tokens Owners are aware, understand and accept that there is an inherent risk that the smart contract, and/or the software and/or the blockchain network could contain weaknesses, vulnerabilities, viruses, bugs, or could be subject to exploitation causing, inter alia, the complete loss of ETH, EH2 Tokens, or other (financial) means and/or support. EH2 Tokens Owners are aware, understand and accept that any smart contract and/or underlying protocols and/or any other software, and/or any blockchain network may be subject to delays in transaction processing and/or fail to properly execute valid instructions due to various factors, including, but not limited to the overall traffic volume, mining attacks, viruses and/or similar events. EnergonX shall not be held liable for any suspension, theft, fraud, or loss of EH2 Tokens that might occur in connection with any of the risks outlined in this paragraph. <br/><br/>

EnergonX shall not be liable for the technical risks related to, among others, power outage, disconnection, time-out or system failure, delays, transmission errors, disturbance or the overloading or locking-up of the systems or networks involved therewith. If EnergonX detects any security risks, it reserves the right, if and when possible, to freeze or shut down ongoing smart contracts for the protection of EH2 Tokens Owners at any time until the risk is removed or forever; ; provided, however, that EnergonX Association may not be able to freeze or shut down malfunctioning or destructive smart contract. EnergonX shall not be liable for any damages incurred as a result of such interruption or shut down. EnergonX cannot guarantee the availability of a connection to the internet. <br/><br/>

The information related to the EH2 Tokens and any public sale of EH2 Tokens will be made available exclusively to interested persons requesting this documentation for their own use. <br/><br/>

This documentation does not purport to contain all information relating to EH2 Tokens. Information in this documentation is based on facts available on the date hereof. No representation or warranty, express or implied, is made as to the fairness, accuracy, completeness or correctness of the information or opinions contained herein by EnergonX and its advisors. The information set out herein may be subject to updating, completion, revision, verification and amendment, without notice, all of which may impact this presentation. It should be understood that subsequent developments may affect the information contained in this document, which neither EnergonX nor any of their advisors are under an obligation to update, revise or affirm. <br/><br/>

This documentation contains general information on EnergonX and is intended for general informational purposes only, upon request of interested persons. With respect to the sale of any EH2 Tokens, EH2 Tokens Owners (or Potential Tokens Owners) must rely on the relevant EH2 Token documentation, in particular, but not limited to, the EnergonX Token Purchase Agreement. <br/><br/>

THE CONTENT OF THIS DOCUMENTATION DOES NOT CONSTITUTE INVESTMENT ADVICE OR A RECOMMENDATION OR INVITATION FOR PURCHASING, HOLDING OR SELLING ANY EH2 TOKENS. IN PARTICULAR, IT IS NOT DEEMED TO PROVIDE (AND MUST NOT BE HELD AS SUCH) ANY ADVICE RELATING TO ANY DECISION WHETHER OR NOT TO PURCHASE EH2 TOKENS AND MUST NOT BE CONSIDERED AS PROVIDING COMPLETE INFORMATION IN RELATION TO SUCH A DECISION. IF YOU ARE IN ANY DOUBT AS TO THE ACTION YOU SHOULD TAKE, YOU SHOULD CONSULT YOUR LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S). 

THE CONTENT OF THIS DOCUMENATION DOES NOT CONSTITUTE AN OFFER OR INVITATION TO SUBSCRIBE FOR OR PURCHASE ANY EH2 TOKENS. THE CONTENT OF THIS DOCUMENTATION IS NOT INTENDED TO BE READ IN THE “PROHIBITED COUNTRIES” (AS LISTED IN SCHEDULE 1 AS AMENDED FROM TIME TO TIME). IF YOU ARE LOCATED IN THE PROHIBITED COUNTRIES, PLEASE DESTROY THIS DOCUMENTATION IMMEDIATELY. <br/><br/>

EH2 WILL BE OFFERED ONLY OUTSIDE OF THE UNITED STATES TO NON-U.S. PERSONS. EH2 WILL NOT BE REGISTERED UNDER THE SECURITIES ACT. <br/><br/>

THIS DOCUMENT DOES NOT CONSTITUTE A PROSPECTUS ACCORDING TO SLOVENIAN LAW OR A PROSPECTUS WITHIN THE MEANING OF DIRECTIVE 2003/71/EC, AS AMENDED FROM TIME TO TIME, INCLUDING BY DIRECTIVE 2010/73/EU, “THE PROSPECTUS DIRECTIVE”). <br/><br/>

Potential and actual EH2 Tokens Owners are aware and understand that certain jurisdictions restrict or may in the future restrict their residents or citizens from participating in any token sales, use of cryptocurrencies, or use of any cryptocurrency exchanges for any reason. EnergonX does not bear any liability for any current or future legal prohibition on the conversion, holding, of EH2 Tokens. <br/><br/>

EH2 Tokens may not be converted, held, used or otherwise kept if legal restrictions apply, in particular but non-exclusively, to residents or citizens from Prohibited Countries. It is the responsibility of Potential and actual EH2 Tokens Owners to seek legal advice in their jurisdiction to identify any such legal restrictions. EnergonX shall have the right at any anytime, at its sole discretion and by any means, to exclude, ban or otherwise restrict the participation in the Token Sale or otherwise restrict the EH2 Tokens Owners’ possibility of converting, holding, using or in any other way keeping EH2 Tokens if the respective customer does not meet eligibility requirements set forth by EnergonX for the purpose of the EH2 Tokens Sale or on other grounds. <br/><br/>

All information contained in this documentation is made available upon request of interested persons with the understanding that the authors, publishers, distributors and advisors are not rendering legal, accounting, tax or other professional advice or opinions on specific facts or matters and accordingly assume no liability whatsoever in connection with its use. In no event shall EnergonX and/or its advisors be liable for any direct, indirect, special, incidental or consequential damages arising out of the use of any opinion or information expressly or implicitly contained in this documentation. <br/><br/>

          
          <h3>SCHEDULE 1</h3>
          The United States (including its territories and dependencies, any state of the United States and the District of Columbia), Afghanistan, Angola, Aruba, Balkans, Bangladesh, Belarus, Benin, Bhutan, Bolivia, Bosnia, Botswana, Brunei Darussalam, Burkina Faso, Burma, Burundi, BVI, Cambodia, Cameroon, Canada Cape Verde, Central Africa Republic, Chad, China, Comorros, Congo, Democratic Republic of Congo, Cote d’Ivoire (Ivory Coast), Cuba, Djibouti, Dominica, Ecuador, Egypt, El Salvador, Equatorial Guinea, Eritrea, Ethiopia, Gabon, Gambia, Ghana, Guatemala, Guinea, Guinea Bissau, Guyana, Haiti, Honduras, Iran, Iraq, Jordan, Kenya, Kyrgyz Republic, Laos People’s Republic, Lesotho, Lebanon, Liberia, Libya, Madagascar, Malawi, Maldives, Mali, Mauritania, Micronesia, Moldova, Mongolia, Montenegro, Mozambique, Myanmar, Nauru, Nepal, New Caledonia, Nicaragua, Niger, Nigeria, Niue, North Korea, Oman, Pakistan, Palestinian Areas, Papua New Guinea, Reunion, Rwanda, Samoa Sao Tome and Principe, Senegal, Serbia, Sierra Leone, Somalia, South Georgia, South Korea, South Sudan, Sri Lanka, Sudan, Suriname, Swaziland, Syria, Tajikistan, Tanzania, Timor, Togo, Tonga, Trinidad and Tobago, Tunisia, Turkmenistan, Ukraine, Uganda, Uzbekistan, Venezuela, Western Sahara, Yemen, Zambia, Zimbabwe, all countries and territories listed by the Financial Action Task Force as a “non-cooperative country or territory” and all countries, persons or entities listed on the State Secretariat for Economic Affairs’ website (SECO), as amended from time to time (sanktionsmassnahmen).
          
        </div>    
    </>
  }
}