import React from 'react';
import { Link } from 'react-router-dom';

import ExchangesAvailable from '../View_ExchangesAvailable.js';
import MarketsView from '../View_Markets.js';
//import AuctionCreateOrBid from './View_AuctionCreateOrBid';
import TransportationsBookView from './View_TransportationsBook.js';
import YourTransportationsView from './View_YourTransportations.js';
import TransportationControlView from './View_TransportationControl.js';
import DistributionMap from './View_DistributionMap.js';


class DistributionView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          info:"",
          height: 595,
          width: 500,
          selectedTransportationIdx: 0
        }

        this.getData = this.getData.bind(this);
        this.getFuelStations = this.getFuelStations.bind(this);
    }

    componentDidMount() { 
        this.getData();
        this.getFuelStations(); 
        
        this.intervalHandle = setInterval(this.getData, 3000); // every n
        //this.intervalHandle2 = setInterval(this.getFuelStations, 20000); // every n
    }
    componentWillUnmount() { 
      clearInterval(this.intervalHandle); 
      //clearInterval(this.intervalHandle2); 
    }
    getData() {  
      var app = this.props.app;
      var m = app.state.market;
      if(app.state.comm!==undefined && m!==null)
      {
         app.state.comm.getMarketTransportations(m.id); 
         app.state.comm.getYourTransportations(m.id); 
      }
    }

    getFuelStations() {  
      var app = this.props.app;
      var m = app.state.market;
      //debugger;
      if(app.state.comm!==undefined && m!==null)
      {
         app.state.comm.getFuelStations("H2"); 
      }
    }

    async selectExchange(exchange)
    {
        await this.props.app.setExchange(exchange);
        this.getData();
    }  
    async selectMarket(market)
    {
        await this.props.app.setMarket(market);
        this.getData();
    } 

    selectTransportation(transportation, index)
    {
        console.log("Transportation selected", transportation, index);
        this.props.app.setTransportation(transportation);
    }
    
    selectYourTransportation(transportation)
    {
        console.log("Your Transportation selected", transportation);
        this.props.app.setTransportation(transportation);
    }
      
    render () {
        let app = this.props.app;
        let data = this.props.data;
        let comm = app.state.comm; 
        let fuelStations = data.fuelStations; // view all auctions for market
        let transportation = data.transportation; // view all auctions for market
        let marketTransportations = data.marketTransportations; // view all auctions for market
        let yourTransportations = data.yourTransportations; // view all auctions for market

        let market = data.market;
        let balances = data.balances;
        let orderTemplateSell = data.clickSellOrder;
        let orderTemplateBuy = data.clickBuyOrder;

        //console.log(marketTransportations);

        let transportationBook = null;
        let yourTransportationBook = null;
        let transportationControl = null;
        if(this.props.app.state.accessToken!==null && this.props.app.state.accessToken!==undefined)
        {
            transportationBook = <TransportationsBookView app={app} transportations={marketTransportations} distributionView={this}/>; 
            yourTransportationBook = <YourTransportationsView app={app} transportations={yourTransportations} distributionView={this}/>; 
        }

        var company = data.profile !=null ? data.profile.company : { name: "",  addressInfo: { coordinate: { latitude: 46, longitude: 21 }}};
        if(transportation!=null)  
        {
          transportationControl = <TransportationControlView transportation={transportation} comm={comm} distributionView={this} />
        } 
        else 
        {
          transportationControl = <div className="viewAuctionTextBottom" >
                                    <ol>A Transportation Company:
                                      <li>Views available deliveries</li>
                                      <li>Bids for distribution rights</li>
                                      <li>Each bidder is allowed to bid and update bid price until expiration.</li>
                                    </ol>
                                  </div>
        } 

        return <div className="viewExchange">
                 <ExchangesAvailable app={app} exchangeView={this} />
                 <MarketsView allMarkets={this.props.allMarkets} markets={this.props.markets} market={market} exchangeView={this}/> 
                  <div className="viewMarketSummary">
                      <h2>&nbsp;&nbsp;&nbsp;Distribution</h2>
                  </div> 

                  <div className="viewDistributionMap">
                    <DistributionMap height={this.state.height} width={this.state.width} 
                                     stations={fuelStations} 
                                     company={company} 
                                     transportation={transportation} 
                                     />
                  </div>

                  {/* <CurrentTransportationView auction={this.props.app.state.auction} comm={comm}/> */}
                  {transportationBook}
                  {yourTransportationBook}
                  {transportationControl}
                  
                                   

               </div>
    }
}

export default DistributionView;