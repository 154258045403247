import React from 'react';
import './../App.css';

import ExchangesAvailable from './View_ExchangesAvailable.js';
import MarketsView from './View_Markets.js';
import MarketSummary from './View_MarketSummary.js';
import OrderbookViewBids from './View_OrderbookBids.js';
import OrderbookViewAsks from './View_OrderbookAsks.js';
import TradebookView from './View_Tradebook.js';
import OpenOrdersView from './View_OpenOrders.js';
import GraphView from './View_Graph.js';
import OrderBuySell from './View_OrderBuySell.js';


class ExchangeView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }

    async selectExchange(exchange)
    {
        await this.props.app.setExchange(exchange);
    } 
    async selectMarket(market)
    {
      await this.props.app.setMarket(market);
    }   
      
    render () {
        let app = this.props.app;
        let data = this.props.data;

        let comm = app.state.comm; 
        let trades = data.trades;
        let bids = data.bids;
        let asks = data.asks;
        let marketTrades = data.marketTrades;

        let market = data.market;
        let balances = data.balances;
        let orderTemplateSell = data.clickSellOrder;
        let orderTemplateBuy = data.clickBuyOrder;

        let tradebook = null;
        let orderbuysell = null;
        let openorders = null;
        if(this.props.app.state.accessToken!==null && this.props.app.state.accessToken!==undefined)
        {
           tradebook = <TradebookView app={app} trades={trades}/>;
           orderbuysell = <OrderBuySell app={app} balances={balances} market={market} orderTemplateSell={orderTemplateSell} orderTemplateBuy={orderTemplateBuy} comm={comm}/>;
           openorders = <OpenOrdersView app={app} marketTrades={marketTrades}/>;
        }

        return <div className="viewExchange">
                 <ExchangesAvailable app={app} exchangeView={this} />
                 <MarketsView allMarkets={this.props.allMarkets} markets={this.props.markets} market={market} exchangeView={this}/>

                 <MarketSummary market={market} summary={app.state.summary}/>;
                 <GraphView ohlc={app.state.ohlc} comm={app.state.comm} market={market}/>
                 {/* <OrderbookView app={app} bids={bids} asks={asks}/> */}
                 
                 <OrderbookViewAsks app={app} bids={bids} asks={asks} />
                 <OrderbookViewBids app={app} bids={bids} asks={asks} />

                 {tradebook}
                 {orderbuysell}
                 {openorders} 
                 {/* <TradebookView app={app} trades={trades}/>
                 <OrderBuySell app={app}/>
                 <OpenMarketTradesView app={app} marketTrades={marketTrades}/> */}
               </div>
    }
}

export default ExchangeView;