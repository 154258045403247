import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

class Apply extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          enabled: true,
          info:"",
          bankName: "", 
          address: "", 
          city: "", 
          country: "", 
          accountNumber: "", 
          swiftNumber: ""
        }
      }
    
    cancel() {
      this.props.history.push('/profile')
    }
    applyInvoke() {
      if(this.state.bankName.length<2 || 
         this.state.address.length<2 ||
         this.state.swiftNumber.length<6) 
        {
          this.onRegisterComplete("Invalid input");
          return;
        }
        if(this.state.accountNumber.length<9)
        {
          this.onRegisterComplete("Invalid number");
          return;
        }

        var bank = { 
           bankName: this.state.bankName,
           address: this.state.bankAddress,
           city: this.state.city,
           country: this.state.country,
           accountNumber: this.state.accountNumber,
           swift: this.state.swiftNumber
        }; 
        console.log(bank);
        this.setState({enabled:false});
        //this.props.app.state.comm.newRegistration(client, this);
    }
    onRegisterComplete(information)
    {
      this.setState({info:information});
      this.setState({enabled:true});
      if(information==="OK")
      {
        //this.props.loginScreen.register();
      }
    }

    hBankName(e) { this.setState({ bankName: e.target.value }); }
    hBankAddress(e) { this.setState({ address: e.target.value }); }
    hBankCity(e) { this.setState({ city: e.target.value }); }
    hBankCountry(e) { this.setState({ country: e.target.value }); }
    hAccountNumber(e) { this.setState({ accountNumber: e.target.value }); }
    hSWIFT(e) { this.setState({ swiftNumber: e.target.value }); }
  
    render () {
      return <div className="settingsWindow">
                  <h2>Please enter following details</h2> 
                  <Link to="/apply/company">Add Company / Legal Entity</Link> <br/>
                  <Link to="/apply/bank">Add Bank</Link> <br/>
                  <Link to="/apply/cerficationAuthority">Add Certification Authority</Link> <br/>
                  <Link to="/apply/customsAuthority">Add Customs Authority</Link> <br/>
                  <Link to="/apply/placeOfLoading">Add Place Of Loading</Link> <br/>
                  <Link to="/apply/transportationCompany">Add Transportation Company</Link> <br/>
                  <Link to="/apply/certificates">Add Certificates</Link> <br/>
                  <button onClick={() => this.cancel()}>OK</button> 
            </div>
    }
  }

  export default withRouter(Apply);