import React from 'react';
import './../../App.css';

import { Link } from "react-router-dom";
import RegisterProcurementNetworkAccount from "../registrations/RegisterProcurementAccount.js";

class ViewProcurementNetwork extends React.Component {
    constructor(props){
      super(props)
      this.state = {
      }
    }

    render () {
        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents">

                  <Link to="/hydrogenx/search"> <h1>Search Procurement Network</h1> </Link>

                  <RegisterProcurementNetworkAccount />

                </div> 
               </div>
    }
  }


export default ViewProcurementNetwork;
