import React from 'react';
import { withRouter } from 'react-router-dom';

class ApplyCustomsAuthority extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      enabled: true,
      info:"",
      name: "", 
      address: "", 
      city: "", 
      country: "", 
      email: "", 
      phone: ""
    }
  }

cancel() {
  this.props.history.push('/apply')
}
applyInvoke() {
  if(this.state.name.length<2 || 
     this.state.address.length<2) 
    {
      this.onRegisterComplete("Invalid input");
      return;
    }
    if(this.state.accountNumber.length<9)
    {
      this.onRegisterComplete("Invalid number");
      return;
    }

    var custAuthority = { 
      name: this.state.name,
      address: this.state.address,
      city: this.state.city,
      country: this.state.country,
      email: this.state.email,
      phone: this.state.phone
    }; 
    console.log(custAuthority);
    this.setState({enabled:false});
    window.energonxapp.state.comm.newClientCustomsAuthority(custAuthority, this);
}
onRegisterComplete(information)
{
  this.setState({info:information});
  this.setState({enabled:true});
  if(information==="OK")
  {
    //this.props.loginScreen.register();
  }
}

hName(e) { this.setState({ name: e.target.value }); }
hAddress(e) { this.setState({ address: e.target.value }); }
hCity(e) { this.setState({ city: e.target.value }); }
hCountry(e) { this.setState({ country: e.target.value }); }
hPhone(e) { this.setState({ phone: e.target.value }); }
hEmail(e) { this.setState({ email: e.target.value }); }

render () {
  return <div className="settingsWindow">
              <h3>Customs Authority</h3>
              Customs Authority Name<br/>
              <input type="text" placeholder="bank name" defaultValue={this.state.name} value={this.state.name} onChange={(e)=>this.hName(e)} className="inputBox"/><br/>
              Address<br/>
              <input type="text" placeholder="address" value={this.state.address} onChange={(e)=>this.hAddress(e)} className="inputBox"/><br/>
              City<br/>
              <input type="text" placeholder="city" value={this.state.city} onChange={(e)=>this.hCity(e)} className="inputBox"/><br/>
              Country<br/>
              <input type="text" placeholder="country" value={this.state.country} onChange={(e)=>this.hCountry(e)} className="inputBox"/><br/>

              Phone<br/>
              <input type="text" placeholder="Phone" value={this.state.email} onChange={(e)=>this.hPhone(e)} className="inputBox"/><br/>
              Email<br/>
              <input type="text" placeholder="Email" value={this.state.phone} onChange={(e)=>this.hEmail(e)} className="inputBox"/><br/>                  


              {this.state.info.length>0 ? <div style={{color:"red"}}>{this.state.info}</div> : null}
              {this.state.enabled==false ? <div className="settingsWindow">
                                                <h1>EnergonX</h1>          
                                                <h2>Communicating</h2> 
                                                <h3>Please wait ...</h3> 
                                          </div> : null }

              <br/>
              {this.state.enabled==true ? <button onClick={() => this.applyInvoke()}>Submit</button> : null }
               <br/>
              {this.state.enabled==true ? <button onClick={() => this.cancel()}>Cancel</button> : null }
        </div>
  }
}

export default withRouter(ApplyCustomsAuthority);