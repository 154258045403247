import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "#ff00ff10",
    height: 56,
    width: 56,
    boxShadow: "0 0 1.0em #ff1dff"
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceGreen: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  differenceRed: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const DashboardCard = ({ className, ...props }) => {
  const classes = useStyles();
  var propsIcon = props.icon==undefined ? null :  <Grid item>
                                                    <Avatar className={classes.avatar}>
                                                        {props.icon} 
                                                    </Avatar>
                                                    </Grid>  
  return (
    <Card className={clsx(classes.root, className)} {...props} >
      <CardContent>
        <Grid container justify="space-between" spacing={3} >
          <Grid item>
            <Typography  gutterBottom variant="h6">
              {props.title}
            </Typography>
            <Typography variant="h3">
              {props.amount}
            </Typography>
          </Grid>
          {propsIcon}
        </Grid>
        <Box mt={2} display="flex" alignItems="center" >
          {props.percentage>0 ? <ArrowUpwardIcon className={classes.differenceIcon} /> :  <ArrowDownwardIcon className={classes.differenceIcon} /> }
          <Typography className={props.percentage>0 ? classes.differenceGreen : classes.differenceRed} variant="body2">
             {props.percentage}
          </Typography>
          <Typography variant="caption">{props.caption}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

DashboardCard.propTypes = {
  className: PropTypes.string
};

export default DashboardCard;