import React from 'react';
import './../../App.css';

import { Link } from "react-router-dom";
import { Grid, Card, CardHeader, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core/';
import AccountSelector from "./AccountSelector.js"; 


class RegisterProcurementNetworkAccount extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      page:0
    }
  }

  render () {
    return <div>
              <Grid container spacing={0} direction="row" alignItems="flex-start" justify="center">
                {/* <AccountSelector title="Search" image="./img/liquid-c.png"
                                 subheader="Products And Services"
                                 subheaderLink="/hydrogenx/search"
                                 text="View available products and services in hydrogen Economy" 
                                 /> */}
                <AccountSelector title="Supplier" image="./img/liquid-heart.png"
                                 subheader="Register as Supplier"
                                 subheaderLink="/register/supplier"
                                 text="Suppliers are producers of hydrogen equipment"
                                 />
                <AccountSelector title="Buyer" image="./img/liquid-drops.png"
                                 subheader="Register as Buyer" 
                                 subheaderLink="/register/buyer"
                                 text="Match requests with best suppliers on platform"
                                 />
            </Grid>
           </div>
  }
}


export default RegisterProcurementNetworkAccount;
