import React from 'react';
import './../App.css';

var countDecimals = function(value) {
  if (Math.floor(value) !== value)
  {
      //return value.toString().split(".")[1].length || 0;
      var v = value.toString()
      var s = v.split(".")[1];
      if(s===undefined) return 0;
      var l = s.length;
      return l;
  }
  return 0;
}
var lastIsDot = function(str) {
  var s = str.toString();
  var nd = (s.match(/\./g) || []).length;
  return s.slice(-1) === '.' && nd===1; // true last character is dot
}
var maxDecimal = 9;

/* Same for buy and sell */
var priceChanged = function(context, newPrice) {
  var p = Number(newPrice);//.toFixed(8);
  if(!isNaN(p))// && p>0)
  {
    if(countDecimals(p)>=maxDecimal)
       p = p.toFixed(maxDecimal);      

    context.setState({price: p});    
    calculate(context, p, context.state.amount);
  }

  if(lastIsDot(newPrice))
  context.setState({price: newPrice});    

  console.log("priceChanged", p);
}
var amountChanged = function(context, newAmount) {
  var a = Number(newAmount);
  if(!isNaN(a))// && a>0)
  {
    if(countDecimals(a)>=maxDecimal)
       a = a.toFixed(maxDecimal);      

       context.setState({amount: a}); 
       calculate(context, context.state.price, a);
  }

  if(lastIsDot(newAmount))
    context.setState({amount: newAmount}); 

  console.log("amountChanged", a);
}
var totalChanged = function(context,newTotal) {
  var t = Number(newTotal);
  if(!isNaN(t))// && t>0)
  {
    if(context.state.price!==0)
    {
     var a = (t / context.state.price);
     //context.amountChanged(a);
     context.setState({amount: a});
     context.setState({total: t});  
    }
  }
  console.log("totalChanged", t);
}
var totalUpdated = function(context,newTotal) {
  var t = Number(newTotal);
  if(!isNaN(t))// && t>0)
  {
    //if(countDecimals(t)>=maxDecimal)
    //  t = t.toFixed(maxDecimal);      

    context.setState({total: t});
  }
  console.log("totalUpdated", t);
}
var calculate = function(context, price, amount)
{
  if(!isNaN(price) && !isNaN(amount))
  {
    var t = Number(price * amount);
    if(countDecimals(t)>maxDecimal)
       t=t.toFixed(maxDecimal);

    totalUpdated(context, t); 
  } else
  context.setState({total: NaN});
}

class OrderBuyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
         balance: null,
         price: 1.5,
         amount: 2,
         total: 3
    };

    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeTotal = this.handleChangeTotal.bind(this);

    this.handleBuy = this.handleBuy.bind(this);
  }
  handleChangePrice(event)  {  priceChanged(this, event.target.value);  }
  handleChangeAmount(event) {  amountChanged(this, event.target.value);  }
  handleChangeTotal(event)  {  totalChanged(this, event.target.value);  }
  handleBuy(event) {
    var p = Number(this.state.price);
    var a = Number(this.state.amount);
    var t = Number(this.state.total);
    var fail = !isNaN(p) && !isNaN(a) && !isNaN(t) && p>0 && a>0 && t>0 && t<=this.state.balance; 
    if(!fail)
    {
        alert('Invalid Buy order!');    
        return;
    }
    //alert('Buy order was submitted: ' + this.state.total);
    //this.props.comm.createNewBuyOrder(this.props.market.id, this.state.amount, this.state.price);
    this.props.comm.createNewBuyOrder(this.props.market.id, a, p);
    //this.setState({balance:this.state.balance-t});
  }

  componentDidUpdate(prevProps) {
    let market = this.props.market;
    let balance = 0; 
    let balances = this.props.balances;
    var order = this.props.template;
    if(order!==null && this.state.price!==order.price)
    {
        this.setState({price:order.price});
        this.setState({amount:order.amount});
        this.props.app.setClickSellOrder(null);
        calculate(this, order.price, order.amount);
    }

    if(balances!==null)
    {
      //balance = balances.find(ab => ab.asset.id === market.pair.baseAsset.id);
      balance = balances[market.pair.baseAsset.lookupIdx];
      if(balance!==null && this.state.balance!==balance.available)
      {
         this.setState({balance:balance.available});
         //console.log(balance);
      }
    }    
  }   

  render() {
    let market = this.props.market;
    var baseSymbol = "€"; 
    var quoteSymbol = "H";
    if(market===null)
    {
      baseSymbol = "€"; 
      quoteSymbol = "H";
    } else 
    {
      baseSymbol = market.pair.baseAsset.symbol;
      quoteSymbol = market.pair.quoteAsset.symbol;
    }


    return  <div className="viewOrderBuy">
              <div className="viewOrderBuyInside">
                 
              <div className="orderbuy-header">
                  <div>Balance: {this.state.balance} {baseSymbol}</div> 
                </div>
                                  
                <div className="orderbuy-line">
                  <div className="orderbuy-left">Price</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="price"  value={this.state.price} onChange={this.handleChangePrice} /></div> 
                  <div className="orderbuy-right">  {baseSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Amount</div> 
                  <div className="orderbuy-center"><input className="tradeinput" type="text" name="amount" value={this.state.amount} onChange={this.handleChangeAmount}/></div>
                  <div className="orderbuy-right">  {quoteSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Total</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="total"  value={this.state.total.toString()} onChange={this.handleChangeTotal}/></div>
                  <div className="orderbuy-right"> {baseSymbol} </div>
                </div>  
                  <button className="orderbuy-button" onClick={() => this.handleBuy()}>BUY</button>
                </div>
            </div>
   }
   //return <label>{t.type} {t.price} {t.amount} {t.total} {t.date} {t.time} {t.rateStop} {t.action} </label>
}
class OrderSellView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
         balance: null,
         price: 1.5,
         amount: 2,
         total: 3
    };

    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeTotal = this.handleChangeTotal.bind(this);

    this.handleSell = this.handleSell.bind(this);
  }
  handleChangePrice(event)  {  priceChanged(this, event.target.value);  }
  handleChangeAmount(event) {  amountChanged(this, event.target.value);  }
  handleChangeTotal(event)  {  totalChanged(this, event.target.value);  }

  handleSell(event) {
    var p = Number(this.state.price);
    var a = Number(this.state.amount);
    var t = Number(this.state.total);
    var fail = !isNaN(p) && !isNaN(a) && !isNaN(t) && p>0 && a>0 && t>0 && a<=this.state.balance; 
    if(!fail)
    {
        alert('Invalid Sell order!');    
        return;
    }
    //alert('Sell order was submitted: ' + this.state.total);
    //this.props.comm.createNewSellOrder(this.props.market.id, this.state.amount, this.state.price); //.toFixed(8)
    this.props.comm.createNewSellOrder(this.props.market.id, a, p); //.toFixed(8)
  }

  componentDidUpdate(prevProps) {
    let market = this.props.market;
    let balance = 0; 
    let balances = this.props.balances;


    var order = this.props.template;
    if(order!==null && this.state.price!==order.price)
    {
        this.setState({price:order.price});
        this.setState({amount:order.amount});
        this.props.app.setClickBuyOrder(null);
        calculate(this, order.price, order.amount);
    }
    
    //return;
    if(balances!==null)
    {
      //balance = balances.find(ab => ab.asset.id === market.pair.quoteAsset.id);
      balance = balances[market.pair.quoteAsset.lookupIdx];
      if(balance!==null && this.state.balance!==balance.available)
      {
        this.setState({balance:balance.available});
        //console.log(balance);
      }
    }    
  } 

  render() {
    let market = this.props.market;
    var baseSymbol = "€"; 
    var quoteSymbol = "H";
    //console.log(market);
    if(market===null)
    {
      baseSymbol = "€"; 
      quoteSymbol = "H";
    } else 
    {
      baseSymbol = market.pair.baseAsset.symbol;
      quoteSymbol = market.pair.quoteAsset.symbol;
    }

    return  <div className="viewOrderSell">
              <div className="viewOrderBuyInside">
                 
                <div className="orderbuy-header">
                  <div>Quantity: {this.state.balance} {quoteSymbol}  <small>Fees:{market.fee.txMakerFee*100}%/{market.fee.txTakerFee*100}% </small></div> 
                </div>
                                  
                <div className="orderbuy-line">
                  <div className="orderbuy-left">Price</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="price"  value={this.state.price} onChange={this.handleChangePrice} /></div> 
                  <div className="orderbuy-right">  {baseSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Amount</div> 
                  <div className="orderbuy-center"><input className="tradeinput" type="text" name="amount" value={this.state.amount} onChange={this.handleChangeAmount}/></div>
                  <div className="orderbuy-right">  {quoteSymbol} </div>
                </div>  

                <div className="orderbuy-line">
                  <div className="orderbuy-left">Total</div>  
                  <div className="orderbuy-center"> <input className="tradeinput" type="text" name="total"  value={this.state.total.toString()} onChange={this.handleChangeTotal}/></div>
                  <div className="orderbuy-right"> {baseSymbol} </div>
                </div>  
                    <button className="orderbuy-button" onClick={() => this.handleSell()}>SELL</button>
                    <br/>
                    
                </div>
            </div>
   }
   //return <label>{t.type} {t.price} {t.amount} {t.total} {t.date} {t.time} {t.rateStop} {t.action} </label>
}



class OrderBuySellView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }
    
      renderHeader() {
        return  <tr className="orderHeader">
                  <th className="textLeft">TYPE</th> 
                  <th className="textCenter">PRICE</th> 
                  <th className="textRight">AMOUNT</th>
                  <th className="textRight">TOTAL</th>
                  <th className="textRight">DATE</th>
                  <th className="textRight">TIME</th>
                  <th className="textRight">RATE/STOP</th>
                  <th className="textRight">ACTION</th>
                </tr>
      }       
      render () {
        //let app = this.props.app;
        let market = this.props.market;
        let balances = this.props.balances;
        let comm = this.props.comm;

        return <div className="viewOrdersBuySell">
               <OrderBuyView  market={market} balances={balances} template={this.props.orderTemplateSell} comm={comm} app={this.props.app}/>
               <OrderSellView market={market} balances={balances} template={this.props.orderTemplateBuy} comm={comm} app={this.props.app}/>
               </div>
    }  
      
}

export default OrderBuySellView;
