import React from 'react';
import { Link } from "react-router-dom";

class ClientProfileVerificationsView extends React.Component {
  constructor(props){
     super(props)
     this.state = {
     }
  }
  render()
  {
    if(this.props.profile.verifications==null || this.props.profile.verifications.length===0)
    {
        return <div>
               <h3>VERIFY YOUR ACCOUNT</h3>
               <table className="profileInformationSection"> 
                      <thead>
                          <tr> 
                              <th></th>
                              <th></th>
                          </tr> 
                      </thead>                       
                    <tbody>
                          <tr>
                              <td><Link to="verify/email">Verify Email</Link></td>
                              <td>Complete email verification</td>
                          </tr>
                          <tr>
                              <td><Link to="verify/phone">Verify Phone</Link></td>
                              <td>Complete phone number verification</td>
                          </tr>
                          <tr>
                              <td><Link to="verify/certificate">Verify Certificate</Link></td>
                              <td>Complete certificate verification</td>
                          </tr>
                    </tbody>
                  </table>

              </div>
    }
      return <div>
              <h3>VERIFICATION STATUS</h3>
              <table className="profileVerificationSection"> 
                <thead>
                    <tr> 
                        <th>Verification</th>
                        <th>Information</th>
                        <th>Valid</th>
                        <th>Date</th>
                  </tr> 
                </thead>
                <tbody>
                {this.props.profile.verifications.map(b => 
                  <tr key={b.id}>
                    <td>{b.verificationType}</td>
                    <td>{b.information}</td>
                    <td>{b.valid ? "true" : "false"}</td>
                    <td>{new Date(b.dateOfConfirmation).toLocaleString()}</td>
                  </tr>
                )}
                </tbody>
              </table> 
            </div> 
  }

}
export default ClientProfileVerificationsView;