import React from 'react';
import './../App.css';
import { Link } from "react-router-dom";

class ContractHeader extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
      }

    }

    render () {
        let data = this.props.contract;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div> 
                  <table className="contractsSection profileInformationSection fixed ">
                      <thead>
                          <tr>
                              <th>Importer (Buyer)</th>
                              <th>Exporter (Seller)</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td> <Link to={"/companyview/" + data.buyerImporter.id} >{data.buyerImporter.legalName} </Link></td>  
                            <td> <Link to={"/companyview/" + data.sellerExporter.id}>{data.sellerExporter.legalName} </Link></td>                                    
                          </tr>
                          <tr>
                            <td> <strong>Buyer Bank</strong></td>  
                            <td> <strong>Seller Bank</strong></td>                                    
                          </tr>
                          <tr>
                            <td> {data.buyerBank ?  <Link to={"/bank/"+ data.buyerBank.id}>{data.buyerBank.legalName}</Link> : "Ethereum"} </td>  
                            <td> {data.sellerBank ? <Link to={"/bank/"+ data.sellerBank.id}>{data.sellerBank.legalName}</Link> : "Ethereum"} </td>                                    
                          </tr>

                          <tr>
                            <td> <strong>Buyer Certification Authority</strong></td>  
                            <td> <strong>Seller Certification Authority</strong></td>                                    
                          </tr>
                          
                          <tr>
                            <td> {data.buyerCertification ?  <Link to={"/certification/"+ data.buyerCertification.id}>{data.buyerCertification.legalName} </Link>: "N/A buyerCertification"}</td>  
                            <td> {data.sellerCertification ?  <Link to={"/certification/"+ data.sellerCertification.id}>{data.sellerCertification.legalName}</Link>: "N/A sellerCertification"} </td>                                    
                          </tr>

                          <tr>
                            <td> <strong>Buyer Authority</strong></td>  
                            <td> <strong>Seller Authority</strong></td>                                    
                          </tr>

                          <tr>
                            <td> {data.buyerAuthority ?  <Link to={"/authority/"+ data.buyerAuthority.id}>{data.buyerAuthority.legalName}</Link>: "N/A buyerAuthority"} </td>  
                            <td> {data.sellerAuthority ?  <Link to={"/authority/"+ data.sellerAuthority.id}>{data.sellerAuthority.legalName}</Link>: "N/A sellerAuthority"} </td>                                    
                          </tr>

                          <tr>
                            <td> <strong>Buyer Insurance</strong></td>  
                            <td> <strong>Seller Insurance</strong></td>                                    
                          </tr>
                          <tr>
                            <td> {data.buyerInsurance ?  <Link to={"/placeview/"+ data.buyerInsurance.id}>{data.buyerInsurance.City}</Link>: "N/A buyerInsurance"} </td>  
                            <td> {data.sellerInsurance ?  <Link to={"/placeview/"+ data.sellerInsurance.id}>{data.sellerInsurance.City}</Link>: "N/A sellerInsurance"} </td>                                    
                          </tr>


                          <tr>
                            <td> <strong>Place of Entry</strong></td>  
                            <td> <strong>Place of Loading</strong></td>                                    
                          </tr>
                          <tr>
                            <td> {data.buyerPlaceOfEntry ?  <Link to={"/placeview/"+ data.buyerPlaceOfEntry.id}>{data.buyerPlaceOfEntry.City}</Link>: "N/A buyerPlaceOfEntry"} </td>  
                            <td> {data.sellerPlaceOfLoading ?  <Link to={"/placeview/"+ data.sellerPlaceOfLoading.id}>{data.sellerPlaceOfLoading.City}</Link>: "N/A sellerPlaceOfLoading"} </td>                                    
                          </tr>
                          <tr>

                            <td> <strong>Buyer Bought Date</strong></td>  
                            <td> <strong>Seller Sold Date</strong></td>                                    
                          </tr>
                          <tr>
                            <td> {/*this.state.buyerSignDate*/}</td>  
                            <td> {/*this.state.sellerSignDate*/}</td>                                    
                          </tr>

                        </tbody>
                  </table>
              </div>
        /*
        return <table className="profileInformationSection"> 
                                <thead>
                                    <tr> 
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr> 
                                </thead>                       
                              <tbody>
                                    <tr><td>BUYER</td><td><strong><Link to={"/companyview/" + data.buyerImporter.id} >{data.buyerImporter.legalName} </Link></strong></td></tr>
                                    <tr><td>BANK</td><td><strong><Link to={"/bank/"}>{data.buyerBank ?  data.buyerBank.legalName: null} </Link></strong></td></tr>
                                    <tr><td>AUTHORITY</td><td><strong><Link to={"/authority/"}>{data.buyerAuthority ?  data.buyerAuthority.legalName: null} </Link></strong></td></tr>
                                    <tr><td>CERTIFICATION</td><td><strong><Link to={"/certification/"}>{data.buyerCertification ?  data.buyerCertification.legalName: null} </Link></strong></td></tr>
                                    <tr><td>PLACE OF ENTRY</td><td><strong><Link to={"/placeview/"}>{data.buyerPlaceOfEntry ?  data.buyerPlaceOfEntry.City: null} </Link></strong></td></tr>

                                    <tr><td>SELLER</td><td><strong><Link to={"/companyview/" + data.sellerExporter.id}>{data.sellerExporter.legalName} </Link></strong></td></tr>
                                    <tr><td>BANK</td><td/><td><strong><Link to={"/bank/"}>{data.sellerBank ? data.sellerBank.legalName : null} </Link></strong></td></tr>
                                    <tr><td>AUTHORITY</td><td/><td><strong><Link to={"/authority/"}>{data.sellerAuthority ?  data.sellerAuthority.legalName: null} </Link></strong></td></tr>
                                    <tr><td>CERTIFICATION</td><td/><td><strong><Link to={"/certification/"}>{data.sellerCertification ?  data.sellerCertification.legalName: null} </Link></strong></td></tr>
                                    <tr><td>PLACE OF LOADING</td><td/><td><strong><Link to={"/placeview/"}>{data.sellerPlaceOfLoading ?  data.sellerPlaceOfLoading.City: null} </Link></strong></td></tr>

                                    <tr><td> </td></tr>
                              </tbody>
                    </table>*/
    }
  }

export default ContractHeader;
