import React from 'react';
import { timeDurationWeeks } from '../helpers/Formating';
import { formatMoney } from '../helpers/Formating';

class TransportationsControlView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        info:""
    }
  }

  handleTakeDelivery()
  {
    this.props.comm.takeDelivery(this.props.transportation.deliveryId);
    this.props.distributionView.selectYourTransportation(null);
  }

  // Waiting, Taken, Preparing, Transit, Completed
  handleChangeState(newState)
  {
    this.props.comm.updateDeliveryState(this.props.transportation.deliveryId, newState);
    this.props.distributionView.selectYourTransportation(null);

    //this.props.comm.updateDeliveryStatus(this.props.transportation.deliveryId, newState);
    //this.props.comm.updateDeliveryCycle(this.props.transportation.deliveryId, newState);
    //this.props.comm.updateDeliveryPosition(this.props.transportation.deliveryId, longitude, latitude);
  }
  getNextState(currentState)
  {
      if(currentState=="Waiting") return "Taken";
      if(currentState=="Taken") return "Preparing";
      if(currentState=="Preparing") return "Transit";
      if(currentState=="Transit") return "Completed";
      //if(currentState=="Completed") return "";
      return null;
  }

  render () {
    let t = this.props.transportation;
    let control = null;
    var nextState =  this.getNextState(t.state);
    if(t.state==="Waiting")
    {
       control =<div>
                    <ul>
                        <li>Distance: <strong>{t.airDistance.toFixed(2)}</strong> km</li>
                        <li>Quantity: <strong>{t.quantity}</strong> kg</li> 
                        <li>Total: <strong>{t.total}</strong> €</li> 
                        
                        <li> <strong>{t.fromLegalName}</strong> to <strong>{t.toLegalName}</strong> </li>
                        <li>From:     <strong>{t.from.longitude}, {t.from.latitude}</strong> To: <strong>{t.to.longitude}, {t.to.latitude}</strong> </li>
                    </ul>
                    <ol>
                        <button className="orderbuy-button" onClick={() => this.handleTakeDelivery()}>Take {t.state} Delivery</button>
                    </ol>
                </div>
    }
    else if(t.state==="Completed")
    {
        control =<div style={{width:"100%"}}>
                    <ol>
                        Delivery has been completed. 
                    </ol>
                </div>
    }
    else 
    {
        control =<div style={{width:"100%"}}>
                    <ol>
                        <button className="orderbuy-button" onClick={() => this.handleChangeState(nextState)}>Change to {nextState}</button>
                    </ol>
                </div>
    }
    return <div className="viewAuctionTextBottom">
                {control}
            </div>
    }
}

export default TransportationsControlView;