import React from 'react';
// import MarketsAvailable from './View_MarketsAvailable.js';
import {Tooltip} from '@material-ui/core/';

class DataplaceCategoriesView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          info:"",
          selected: null
        }
    }
    componentDidMount() { 
        // this.getData();
        // this.getDataplaceMarkets(); 
        // this.intervalHandle = setInterval(this.getData, 30000); // every n
    }
    componentWillUnmount() { 
        // clearInterval(this.intervalHandle); 
    }

    selectDataplaceCategory(market)
    {
        this.setState({selected: market});
        console.log(market);
        this.props.exchangeView.selectDataplaceCategory(market);
    }      
    render () {
        let categories = this.props.categories;
        //let markets = this.props.markets;
        //let market = this.props.market;
        //let selected = true;
        //let name = "dataplace";
        //let marketSummary = this.props.app.state.summary;
        //let summary = markets.length>0 ? <MarketSummary market={market} summary={marketSummary}/> : null;
        //let summary = <MarketSummary market={market} summary={marketSummary}/>;

        return <div className="dataplaceInfo">
                    {/* {summary} */}
                    <div className="viewDataplaceCategories">
                    <table width="100%">
                        <thead> 
                        <tr>
                            <th className="orderHeader marketName">CATEGORY</th>
                            <th className="orderHeader marketPrice"></th>
                        </tr>
                        </thead>
                        <tbody>
                            {categories.map(c =>
                               <Tooltip title={c.description} placement="bottom" key={"dpoi"+c.id}>
                                <tr className="marketItem">
                                        <td className="textLeft" onClick={() => this.selectDataplaceCategory(c)}>   {this.state.selected===c ? <strong style={{ color: 'var(--itemHighliteColor)' }} >{c.name}</strong> : c.name} </td>
                                        <td className="textRight" onClick={() => this.selectDataplaceCategory(c)}>  {this.state.selected===c ? 
                                            <strong>  </strong> : <span>  </span> }
                                        </td> 
                                </tr>
                                </Tooltip>

                            )}
                        </tbody>
                    </table>

                    </div>
                    {/* <MarketsAvailable markets={markets} marketsView={this} market={market} allMarkets={allMarkets}/> */}
               </div>
    }
}

export default DataplaceCategoriesView;