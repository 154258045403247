import React from 'react';
import { Link } from 'react-router-dom';
import { timeDurationWeeks } from './../helpers/Formating';
import { formatMoney } from './../helpers/Formating';

class AuctionBidsView extends React.Component {
    render() {
        var bids =this.props.auctionBids;
        if(bids===undefined || bids===null) return null; 
        if(bids.length===0) return null; 
        return bids.map((b,i) =>(
            <div key={"abv_" + i + b.id}>
                 <Link to={"/companyclientview/"+b.clientBidderId}>{i+1} Bidder: {b.price} €</Link> <br/>
            </div>)
            ) 
    }
}

class CurrentAuctionView extends React.Component {
  constructor(props){
      super(props)
      this.state = {
        info:"",
        auction:null
      };
      this.getData = this.getData.bind(this);
      this.update = this.update.bind(this);
  }
  componentDidMount() { 
    this.getData();
    this.intervalHandle = setInterval(this.getData, 3000); // every 1s
    this.timerHandle = setInterval(this.update, 333); // every 1s
  }
  componentDidUpdate(prevProps) {
  if(this.state.auction!==this.props.auction)
      this.setState({auction:this.props.auction});
  } 
  componentWillUnmount() { 
    clearInterval(this.intervalHandle); 
    clearInterval(this.timerHandle); 
  }  
  getData() {  
    if(this.props.comm!==undefined && this.state.auction!==null)
       this.props.comm.getAuction(this.state.auction.id); 
  }
  update() {  
       this.forceUpdate(); 
  }

  render() {
    var a = this.state.auction;
    if(a===null)
       return <div className="viewAuction"> <div>No Auction Selected</div> </div>
    
    // days =  {(a.expires-a.created)/(60*60*24)}  
    var mName = window.appComponent.state.allMarkets[a.marketId-1].name;
    return <div className="viewAuction">
            {a.expires<Date.now() ? <div class="red">Auction Expired</div> : <div>Auction in Progress For</div>}
              <div>
                    {a.expires<Date.now() ? <h2 class="red">{mName}</h2> : <h2>{mName}</h2>}
                    <table className="viewAuctionTable">
                        <thead>
                          <tr>
                            {a.expires<Date.now() ? <th> EXPIRED</th> : <th> EXPIRES</th> }
                            <th>PRICE</th>
                            <th>AMOUNT</th>
                            <th>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{timeDurationWeeks(a.expires,Date.now()/*a.created*/)}</td>
                            <td>{a.price} </td>
                            <td>{a.amount}</td>
                            <td>{formatMoney(a.total)}€</td>
                          </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <small>
                        {a.expires<Date.now() ? <Link to={"/companyclientview/"+a.clientCreatorId}> View Result </Link> : null} 
                        <AuctionBidsView auctionBids={a.auctionBids} />

                        <br/><br/><br/><br/><br/>
                        <Link to={"/companyclientview/"+a.clientCreatorId}> View Owner </Link><br/>
                        <small>{a.id}</small> <br/>
                    </small>
                    {/* <br />
                    Expires: {a.expires} <br/>
                    Amount:  {a.amount} <br/>
                    Price:   {a.price} <br/>
                    Total:   {a.total} <br/>                    
                    MarketId:   {a.marketId} <br/> */}
              </div>
          </div>
  }
}

export default CurrentAuctionView;