import React from 'react';
import { Link } from "react-router-dom";
import './../../App.css';
import { InitialMenuSelector } from './../project/InitialView';
const blogData = 
[
  {
    id: 0,
    title: "Hydrogen Economy Market Evolution",
    description: "The world's energy system must be transformed completely",
    documentUrl: "/publications/EnergonX-HydrogenEconomyMarketEvolution.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"9th July 2020",
    md: null,
    expand: false
  },
  {
    id: 1,
    title: "Hydrogen inroads into EU Economy",
    description: "Low productivity and marginal land area to be assigned to solar PV build up and to co-sitting with PEM electrolysis",
    documentUrl: "/publications/EnergonX 0 Hydrogen inroads into EU Economy.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"20th September 2020", 
    md: null,
    expand: false
  },
  {
    id: 2,
    title: "Electrolysis Water Requirements",
    description: "Hydrogen electrolysis requires water",
    documentUrl: "/publications/EnergonX 1 Water - Electrolysis of hydrogen.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"20th October 2020", 
    md: null,
    expand: false
  },
  {
    id: 4,
    title: "Hydrogen network, storage, transmission & distribution",
    description: "Pan-European hydrogen network and infrastructure planning path",
    documentUrl: "/publications/EnergonX 4 Hydrogen transmission - distribution.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"20th October 2020", 
    md: null,
    expand: false
  },
  {
    id: 5,
    title: "Hydrogen trading - future to be",
    description: "EnergonX market fit - hydrogen auctions, a constructive EU market development",
    documentUrl: "/publications/EnergonX 9 Hydrogen Trading - future to be.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"20th October 2020", 
    md: null,
    expand: false
  },
  {
    id: 6,
    title: "Renewable and low-carbon hydrogen production",
    description: "Overview of Electrolysis",
    documentUrl: "/publications/EnergonX 3 Renewable and low-carbon hydrogen production.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"20th October 2020", 
    md: null,
    expand: false
  },
  {
    id: 7,
    title: "Hydrogen Economy Market Valuation",
    description: "Eurostat data and assumptions",
    documentUrl: "/publications/EnergonX 11 EnergonX hydrogen market valuation.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"23th October 2020", 
    md: null,
    expand: false
  },
  {
    id: 8,
    title: "Power sector entails H2 synergies",
    description: "Large-scale adoption of H2 would entail significant synergies with the power sector",
    documentUrl: "/publications/EnergonX 10 Adoption of H2 entail synergies with the power sector.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"23th October 2020", 
    md: null,
    expand: false
  },
  {
    id: 9,
    title: "H2 Trading under Cybersecurity Risks",
    description: "Challenges to execute a national cybersecurity strategy",
    documentUrl: "/publications/EnergonX 13 H2 Trading under intensified cybersecurity risks.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"12th January 2021", 
    md: null,
    expand: false
  },
  {
    id: 10,
    title: "Developing Hydrogen Infrastructure",
    description: "Energy transitions relies on strategically integrated infrastructure and storage solutions",
    documentUrl: "/publications/EnergonX 14 Developing Hydrogen Infrastructure.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"20th January 2021", 
    md: null,
    expand: false
  },
  {
    id: 11,
    title: "Analytics",
    description: "Analytics is indispensable in business development, strategic planning, regulatory work, and more",
    documentUrl: "/publications/EnergonX 15 Analytics.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"25th January 2021", 
    md: null,
    expand: false
  },
  {
    id: 12,
    title: "Power to Liquids",
    description: "Evolving Hydrogen Economy, technology progress",
    documentUrl: "/publications/EnergonX 29 Power to Liquids.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"17th February 2021", 
    md: null,
    expand: false
  },
  {
    id: 13,
    title: "Hydrogen for Mobility",
    description: "Hydrogen Economy - Europe’s transport decarbonization",
    documentUrl: "/publications/EnergonX 6 Hydrogen for mobility.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"10th March 2021", 
    md: null,
    expand: false
  },
  {
    id: 14,
    title: "Hydrogen as a whole System Transition Solution",
    description: "Adoption of long-term energy strategies and cross sector coupling",
    documentUrl: "/publications/EnergonX 18 Hydrogen as a whole System Transition Solution.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"30th March 2021", 
    md: null,
    expand: false
  },
  {
    id: 15,
    title: "Thermal decomposition of methane, microwave-initiated dehydrogenation, and real compound options",
    description: "Location-specific real options, hydrogen supply in Central Europe",
    documentUrl: "/publications/Pyrolysis compound real options.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"3rd August 2021", 
    md: null,
    expand: false
  },
  {
    id: 16,
    title: "Electrolysis Water Requirements",
    description: "Hydrogen electrolysis requires water",
    documentUrl: "/publications/EnergonX 1-3  Water - Electrolysis of hydrogen.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.3",
    md: null,
    expand: false
  }
  ,
  {
    id: 17,
    title: "Hydrogen market valuation",
    description: "H2 Economy - Environmental, Societal, and Energy value",
    documentUrl: "/publications/EnergonX 11-2 EnergonX hydrogen market valuation.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.2",
    md: null,
    expand: false
  }
  ,
  {
    id: 18,
    title: "Hydrogen for mobility",
    description: "Hydrogen Economy - Europe’s transport decarbonization",
    documentUrl: "/publications/EnergonX 6-2 Hydrogen for mobility.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.2",
    md: null,
    expand: false
  }
  ,
  {
    id: 19,
    title: "Hydrogen’s Economy development broader economic impacts",
    description: "Total Cost-effectiveness",
    documentUrl: "/publications/EnergonX 16-1 Hydrogen Economy development broader economic impacts.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.01",
    md: null,
    expand: false
  }
  ,
  {
    id: 20,
    title: "EnergonX Analytics",
    description: "Analytics is indispensable in business development, strategic planning, regulatory work, and more",
    documentUrl: "/publications/EnergonX 15-3 Analytics.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.3",
    md: null,
    expand: false
  }
  ,
  {
    id: 21,
    title: "Hydrogen as a whole System Transition Solution",
    description: "Adoption of long-term energy strategies and cross-sector coupling",
    documentUrl: "/publications/EnergonX 18-1 Hydrogen as a whole System Transition Solution.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.1",
    md: null,
    expand: false
  }
  ,
  {
    id: 22,
    title: "Renewables and low-carbon hydrogen production",
    description: "Understanding Energy flow - cradle to grave, Introduction to water electrolysis",
    documentUrl: "/publications/EnergonX 3-2 Renewable and low-carbon hydrogen production.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.2",
    md: null,
    expand: false
  }
  ,
  {
    id: 23,
    title: "Developing Hydrogen Infrastructure",
    description: "Value for the green transition",
    documentUrl: "/publications/EnergonX 14-4 Developing Hydrogen Infrastructure.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.4",
    md: null,
    expand: false
  }
  ,
  {
    id: 24,
    title: "Hydrogen network, storage, transmission & distribution",
    description: "Pan-European hydrogen network and infrastructure planning path",
    documentUrl: "/publications/EnergonX 4-3 Hydrogen network storage transmission distribution.pdf",
    linkDescription : "View PDF",
    author: "EnergonX",
    image: "",
    date:"21th December 2021", 
    revision: "1.2",
    md: null,
    expand: false
  }
];
blogData.reverse(); 

const publicationsData = 
[
  {
    id: 0,
    title: "A hydrogen strategy for a climate-neutral Europe",
    description: "Ambition of the European Green Deal and building on the New Industrial Strategy for Europe and its recovery plan, this document sets out a vision of how we can turn clean hydrogen into a viable solution to decarbonise sectors over time",
    documentUrl: "https://ec.europa.eu/energy/sites/ener/files/hydrogen_strategy.pdf",
    linkDescription : "View PDF",
    author: "EU Commision",
    image: "",
    date:"10th July 2020",
    md: null,
    expand: false
  },
  {
    id: 1,
    title: "Guarantees of Origin EU Renewable Energy Directive (2009/28/EC)",
    description: "The Directive states that Guarantees of Origin prove to the final customer that a given quantity of energy was produced from renewable energy sources. Guarantees of Origin were established to create greater environmental awareness, provide customers with an opportunity to choose renewable energy and signal this choice to the market. They provide credible and verifiable documentation for a sustainability report or an environmental audit, and to help stimulate further renewable energy production.",
    documentUrl: "http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2009:140:0016:0062:en:PDF",
    linkDescription : "View PDF",
    author: "EU Jurnal",
    image: "",
    date:"13th November 2020",
    md: null,
    expand: false
  },
  {
    id: 2,
    title: "Clean energy for all",
    description: "In 2018 the EU approved a new Renewable Energy Directive (REDII), which included text strengthening the system of Guarantees of Origin toward 2030. REDII clearly states that electricity suppliers and consumers of power shall (read: have to) use Guarantees of Origin to document and report renewable electricity claims in Europe.",
    documentUrl: "https://ec.europa.eu/energy/en/topics/energy-strategy-and-energy-union/clean-energy-all-europeans",
    linkDescription : "View PDF",
    author: "EU Commision",
    image: "",
    date:"14th November 2020",
    md: null,
    expand: false
  }
];

class BlogList extends React.Component {
  render() {
    let data = blogData;
    return <div>
           <h1 style={{ textAlign:"center" }}>Blog</h1>
           <p>Documents, Articles And Links</p>
            <table className="profileInformationSection" style={{ width:"85%" }}>
              <thead>
                <tr >
                  <th></th>
                  <th></th>
                  <th ></th>
                </tr>
              </thead>
              <tbody>
                {data.map(o => (
                    <tr key={o.id} style={{ margin:"4em" }}>
                      <td style={{ verticalAlign:"top"}}>  
                         <h2>{o.title}</h2> 
                         {o.description} <br/>
                         {o.revision != undefined ? <small><br/>Revision: {o.revision} </small>: null} 
                      </td>

                      <td style={{width:"10em", verticalAlign:"top"}}>
                        <br/>
                        <small>{o.date}</small><br/>
                        <small>{o.author}</small><br/>
                        <a target="_blank" href={o.documentUrl}>{o.linkDescription}</a>
                      </td>
                    </tr>
                  )
                )}
                </tbody>
            </table>
          </div>
  }
}

class PublicationsList extends React.Component {
    render() {
     let data = publicationsData;
      return <div>
             <h1 style={{ textAlign:"center" }}>Publications</h1>
             <p>Contains links to interesting Hydrogen publications </p>
              <table className="profileInformationSection" style={{ width:"85%" }}>
                <thead>
                  <tr >
                    <th></th>
                    <th></th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(o => (
                      <tr key={o.id} style={{ margin:"4em" }}>
                        <td style={{ verticalAlign:"top"}}>  
                           <h2>{o.title}</h2> 
                           {o.description} <br/>
                        </td>
                        <td style={{width:"5.5em", verticalAlign:"top"}}>
                          <br/>
                          <small>{o.date}</small><br/>
                          <small>{o.author}</small><br/>
  
                          <br/>
                          <a target="_blank" href={o.documentUrl}>{o.linkDescription}</a>
                        </td>
                      </tr>
                    )
                  )}
                  </tbody>
              </table>
            </div>
    }
  }

class BlogListView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
    }
    render () {

        return <div>
                  <div className="pageViewScrollable">
                    <div className="pageNormalViewContents">
                        <BlogList />
                        <PublicationsList /> 
                        {/* <InitialMenuSelector/> */}
                    </div>
                </div>
               </div>
    }
}

export default BlogListView;