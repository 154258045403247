import React from 'react';
import { withRouter } from 'react-router-dom';

class ApplyBank extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          enabled: true,
          info:"",
          bankName: "", 
          address: "", 
          city: "", 
          country: "", 
          accountNumber: "", 
          swiftNumber: ""
        }
      }
    
    cancel() {
      this.props.history.push('/apply')
    }
    applyInvoke() {
      if(this.state.bankName.length<2 || 
         this.state.address.length<2 ||
         this.state.swiftNumber.length<6) 
        {
          this.onRegisterComplete("Invalid input");
          return;
        }
        if(this.state.accountNumber.length<9)
        {
          this.onRegisterComplete("Invalid number");
          return;
        }

        var bank = { 
           bankName: this.state.bankName,
           address: this.state.bankAddress,
           city: this.state.city,
           country: this.state.country,
           accountNumber: this.state.accountNumber,
           swift: this.state.swiftNumber
        }; 
        console.log(bank);
        this.setState({enabled:false});
        window.energonxapp.state.comm.newClientBank(bank, this);
    }
    onRegisterComplete(information)
    {
      this.setState({info:information});
      this.setState({enabled:true});
      if(information==="OK")
      {
        //this.props.loginScreen.register();
      }
    }

    hBankName(e) { this.setState({ bankName: e.target.value }); }
    hAddress(e) { this.setState({ address: e.target.value }); }
    hCity(e) { this.setState({ city: e.target.value }); }
    hCountry(e) { this.setState({ country: e.target.value }); }
    hAccountNumber(e) { this.setState({ accountNumber: e.target.value }); }
    hSWIFT(e) { this.setState({ swiftNumber: e.target.value }); }
  
    render () {
      return <div className="settingsWindow">
                  <h3>Bank details</h3>
                  {/* <div>Please enter following details</div> <br/> */}
                  Bank Name<br/>
                  <input type="text" placeholder="bank name"  value={this.state.bankName} onChange={(e)=>this.hBankName(e)} className="inputBox"/><br/>
                  Bank Address<br/>
                  <input type="text" placeholder="address" value={this.state.bankAddress} onChange={(e)=>this.hAddress(e)} className="inputBox"/><br/>
                  Bank City<br/>
                  <input type="text" placeholder="city" value={this.state.bankAddress} onChange={(e)=>this.hCity(e)} className="inputBox"/><br/>
                  Bank Country<br/>
                  <input type="text" placeholder="country" value={this.state.bankAddress} onChange={(e)=>this.hCountry(e)} className="inputBox"/><br/>
                  Account Number<br/>
                  <input type="text" placeholder="account number" value={this.state.accountNumber} onChange={(e)=>this.hAccountNumber(e)} className="inputBox"/><br/>
                  SWIFT<br/>
                  <input type="text" placeholder="SWIFT" defaultValue={this.state.swiftNumber} value={this.state.swiftNumber} onChange={(e)=>this.hSWIFT(e)} className="inputBox"/><br/>


                  {this.state.info.length>0 ? <div style={{color:"red"}}>{this.state.info}</div> : null}
                  {this.state.enabled==false ? <div className="settingsWindow">
                                                    <h1>EnergonX</h1>          
                                                    <h2>Communicating</h2> 
                                                    <h3>Please wait ...</h3> 
                                              </div> : null }

                  <br/>
                  {this.state.enabled==true ? <button onClick={() => this.applyInvoke()}>Apply</button> : null }
                   <br/>
                  {this.state.enabled==true ? <button onClick={() => this.cancel()}>Cancel</button> : null }
            </div>
    }
}

export default withRouter(ApplyBank);