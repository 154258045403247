import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './../../App.css';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// https://www.golangprograms.com/example-of-react-js-with-material-ui-components.html

const companies = 
[
  { 
    id:1,
    name:"Company 1",
  }, 
  { 
    id:2,
    name:"Company 2",
  }, 
  { 
    id:3,
    name:"Company 3",
  }, 
]
const banks = 
[
  { 
    id:1,
    name:"Bank 1",
  }, 
  { 
    id:2,
    name:"Bank 2",
  }, 
  { 
    id:3,
    name:"Bank 3",
  }, 
]
const places = 
[
  { 
    id:1,
    name:"Place 1",
  }, 
  { 
    id:2,
    name:"Place 2",
  }, 
  { 
    id:3,
    name:"Place 3",
  }, 
]

/*
class CompanySelector extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           status:null,
           selectorOpen:false,
           selectorValue:"Company"
        }
    }
  
    render() {
        return <tr>
                <td>{this.props.title}</td>  
                <td><Select className="orderSellItem" 
                        color="#FFFFFF"
                        name={this.props.selectorValue}
                        open={this.state.selectorOpen}
                        onClose={()=>this.setState({selectorOpen:false})}
                        onOpen= {()=>this.setState({selectorOpen:true})}
                        value={this.state.selectorValue}
                        onChange={(e)=>this.setState({selectorValue:e.target.name})}
                        inputProps={{
                            name: 'Company',
                            id: 'company-controlled-open-select',
                        }}>
                        {this.props.selection.map(b => 
                            <MenuItem value={b.Id}>{b.name}</MenuItem> 
                        )}
                    </Select>
                </td>    
              </tr>   
    }
}*/
const insuranceOptions = [
  'Insurance 1',
  'Insurance 2',
  'Insurance 3',
]; 
const importerOptions = [
    'None',
    'Atria',
    'Callisto',
    'Dione'//,
    // 'Ganymede',
    // 'Luna',
    // 'Oberon',
    // 'Phobos',
    // 'Pyxis',
    // 'Sedna',
    // 'Titania',
    // 'Triton',
    // 'Umbriel',
  ];
  const bankOptions = [
    'None',
    'DVB Bank',
    'HSBC',
    'Amsterdam Trade Bank'
  ];
  const shippingOptions = [
    'None - EnergonX provider',
    'Maersk Group',
    'MSC - Mediterranean Shipping Company S.A.',
    'CMA CGM Group',
    'China Ocean Shipping (Group) Company (COSCO)',
    'Evergreen Marine',
    'Hapag-Lloyd',
    'Hamburg Süd Group',
    'Orient Overseas Container Line (OOCL)',
    'Mitsui O.S.K. Lines, Ltd. (MOL)',
    'Nippon Yusen Kabushiki Kaisha (NYK Line)',
    'Hyundai Merchant Marine (HMM)',
    'Yang Ming Marine Transport Corporation',
    'Deme', 'Engie', 'Exmar', 'Fluxys' // , 'Port of Antwerp', 'Port of Zeebrugge', 'WaterstofNet'
  ]; 

  const loadingPlaceOptions = [
    'None',
    'Account Address',
    'Port Of Hamburg',
    'Rotterdam',
    'Paris',
    'Berlin'
  ];    
  
  const customsAuthorityOptions = [
    'None',
    'EU',
    'US',
    'Russia',
    'China',
  ]; 

  const certificationAuthorityOptions = [
    'None',
    'iCERT',
    'H2CERT',
  ];      

class ImporterSelectDialogRaw extends React.Component  {
    constructor(props){
        super(props)
        this.state = {
           value:"",
           open:true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleEntering() {  }
    onClose(b) {
        this.setState({open:b});
        this.props.parent.importerSelectorClose(true);
    }
    handleCancel() {  this.onClose(false);  }
    handleOk() {
        this.onClose(false);
        this.props.parent.setState({importer:this.state.value})
    }
    handleChange(event) { this.setState({value:event.target.value}); } 
  
    render() {
    return (
            <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={()=>this.handleEntering()} 
                aria-labelledby="confirmation-dialog-title"  open={this.props.open} {...this.state.props}>
                <DialogTitle id="confirmation-dialog-title">Available Importers</DialogTitle>
                <DialogContent dividers>
                <RadioGroup  aria-label="importers"  name="importers" value={this.state.value} onChange={this.handleChange}>
                    {importerOptions.map(option => (
                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>this.handleCancel()} color="primary"> Cancel </Button>
                <Button onClick={()=>this.handleOk()} color="primary"> Ok </Button>
                </DialogActions>
            </Dialog>
            );
    }
}

class BankSelectDialogRaw extends React.Component  {
    constructor(props){
        super(props)
        this.state = {
           value:"",
           open:true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleEntering() {    }
    onClose(b) {
        this.setState({open:b});
        this.props.parent.bankSelectorClose(true);
    }
    handleCancel() { this.onClose(false);    }
    handleOk() {
        this.onClose(false);
        this.props.parent.setState({bank:this.state.value})
    }
    handleChange(event) { this.setState({value:event.target.value}); } 
  
    render() {
    return (
            <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={()=>this.handleEntering()} 
                aria-labelledby="confirmation-dialog-title"  open={this.props.open} {...this.state.props}>
                <DialogTitle id="confirmation-dialog-title">Available Banks</DialogTitle>
                <DialogContent dividers>
                <RadioGroup  aria-label="banks"  name="banks"value={this.state.value} onChange={this.handleChange}>
                    {bankOptions.map(option => (
                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>this.handleCancel()} color="primary"> Cancel </Button>
                <Button onClick={()=>this.handleOk()} color="primary"> Ok </Button>
                </DialogActions>
            </Dialog>
            );
    }
}  
class ShippingSelectDialogRaw extends React.Component  {
    constructor(props){
        super(props)
        this.state = {
           value:"",
           open:true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleEntering() {    }
    onClose(b) {
        this.setState({open:b});
        this.props.parent.shippingSelectorClose(true);
    }

    handleCancel() { this.onClose(false);    }
    handleOk() {
        this.onClose(false);
        this.props.parent.setState({shipping:this.state.value})
    }
    handleChange(event) { this.setState({value:event.target.value}); } 
  
    render() {
    return (
            <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={()=>this.handleEntering()} 
                aria-labelledby="confirmation-dialog-title"  open={this.props.open} {...this.state.props}>
                <DialogTitle id="confirmation-dialog-title">Available Shipping Companies</DialogTitle>
                <DialogContent dividers>
                <RadioGroup  aria-label="shipping"  name="shipping" value={this.state.value} onChange={this.handleChange}>
                    {shippingOptions.map(option => (
                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>this.handleCancel()} color="primary"> Cancel </Button>
                <Button onClick={()=>this.handleOk()} color="primary"> Ok </Button>
                </DialogActions>
            </Dialog>
            );
    }
}
class LoadingPlaceSelectDialogRaw extends React.Component  {
    constructor(props){
        super(props)
        this.state = {
           value:"",
           open:true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleEntering() {    }
    onClose(b) {
        this.setState({open:b});
        this.props.parent.loadingPlaceSelectorClose(true);
    }

    handleCancel() { this.onClose(false);    }
    handleOk() {
        this.onClose(false);
        this.props.parent.setState({loadingPlace:this.state.value})
    }
    handleChange(event) { this.setState({value:event.target.value}); } 
  
    render() {
    return (
            <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={()=>this.handleEntering()} 
                aria-labelledby="confirmation-dialog-title"  open={this.props.open} {...this.state.props}>
                <DialogTitle id="confirmation-dialog-title">Available Loading places</DialogTitle>
                <DialogContent dividers>
                <RadioGroup  aria-label="shipping"  name="shipping" value={this.state.value} onChange={this.handleChange}>
                    {loadingPlaceOptions.map(option => (
                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>this.handleCancel()} color="primary"> Cancel </Button>
                <Button onClick={()=>this.handleOk()} color="primary"> Ok </Button>
                </DialogActions>
            </Dialog>
            );
    }
}
class CertificationSelectDialogRaw extends React.Component  {
  constructor(props){
      super(props)
      this.state = {
         value:"",
         open:true,
      }
      this.handleChange = this.handleChange.bind(this);
  }

  handleEntering() {    }
  onClose(b) {
      this.setState({open:b});
      this.props.parent.certificationSelectorClose(true);
  }

  handleCancel() { this.onClose(false);    }
  handleOk() {
      this.onClose(false);
      this.props.parent.setState({certificationAuthority:this.state.value})
  }
  handleChange(event) { this.setState({value:event.target.value}); } 

  render() {
  return (
          <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={()=>this.handleEntering()} 
              aria-labelledby="confirmation-dialog-title"  open={this.props.open} {...this.state.props}>
              <DialogTitle id="confirmation-dialog-title">Certification Authority</DialogTitle>
              <DialogContent dividers>
              <RadioGroup  aria-label="shipping"  name="shipping" value={this.state.value} onChange={this.handleChange}>
                  {certificationAuthorityOptions.map(option => (
                  <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                  ))}
              </RadioGroup>
              </DialogContent>
              <DialogActions>
              <Button onClick={()=>this.handleCancel()} color="primary"> Cancel </Button>
              <Button onClick={()=>this.handleOk()} color="primary"> Ok </Button>
              </DialogActions>
          </Dialog>
          );
  }
}
class CustomsSelectDialogRaw extends React.Component  {
  constructor(props){
      super(props)
      this.state = {
          value:"",
          open:true,
      }
      this.handleChange = this.handleChange.bind(this);
  }

  handleEntering() {    }
  onClose(b) {
      this.setState({open:b});
      this.props.parent.customsSelectorClose(true);
  }

  handleCancel() { this.onClose(false);    }
  handleOk() {
      this.onClose(false);
      this.props.parent.setState({customsAuthority:this.state.value})
  }
  handleChange(event) { this.setState({value:event.target.value}); } 

  render() {
  return (
          <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={()=>this.handleEntering()} 
              aria-labelledby="confirmation-dialog-title"  open={this.props.open} {...this.state.props}>
              <DialogTitle id="confirmation-dialog-title">Customs Authority</DialogTitle>
              <DialogContent dividers>
              <RadioGroup  aria-label="shipping"  name="shipping" value={this.state.value} onChange={this.handleChange}>
                  {customsAuthorityOptions.map(option => (
                  <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                  ))}
              </RadioGroup>
              </DialogContent>
              <DialogActions>
              <Button onClick={()=>this.handleCancel()} color="primary"> Cancel </Button>
              <Button onClick={()=>this.handleOk()} color="primary"> Ok </Button>
              </DialogActions>
          </Dialog>
          );
  }
}  
class InsuranceSelectDialogRaw extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      open: true,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleEntering() {}
  onClose(b) {
    this.setState({ open: b })
    this.props.parent.insuranceSelectorClose(true)
  }

  handleCancel() {
    this.onClose(false)
  }
  handleOk() {
    this.onClose(false)
    this.props.parent.setState({ insuranceCompany: this.state.value })
  }
  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  render() {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={() => this.handleEntering()}
        aria-labelledby="confirmation-dialog-title"
        open={this.props.open}
        {...this.state.props}
      >
        <DialogTitle id="confirmation-dialog-title">Insurance Companies</DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            aria-label="insurance"
            name="insurance"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {insuranceOptions.map(option => (
              <FormControlLabel
                value={option}
                key={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleCancel()} color="primary">
            {' '}
            Cancel{' '}
          </Button>
          <Button onClick={() => this.handleOk()} color="primary">
            {' '}
            Ok{' '}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
  
class TransportationDashboard extends React.Component {
   
    constructor(props){
        super(props);
        this.state = {
          status: null,
          importer: 'Importer company',
          exporter: 'Exporter company',
          bank: 'Default bank',
          shipping: 'Shipping company',
          loadingPlace: 'Account Address',
          customsAuthority: 'Customs Authority',
          certificationAuthority: 'Certification Authority',
          insuranceCompany: 'Insurance Company',
          importerSelectorOpen: false,
          bankSelectorOpen: false,
          shippingSelectorOpen: false,
          loadingPlaceSelectorOpen: false,
          customsSelectorOpen: false,
          certificationSelectorOpen: false,
          insuranceSelectorOpen: false,
        }
        this.getData = this.getData.bind(this);
    }
  
    componentDidMount() { 
        if(this.props.account!==undefined && this.props.account!==null) {
           this.getData();
           this.intervalHandle = setInterval(this.getData, 5000); // every 2s
        }
    }
    componentWillUnmount() { clearInterval(this.intervalHandle); }
    componentDidUpdate(prevProps) {} 
    getData() {  /*this.props.comm.getClientBalances();*/ }
    
    handleChange(event) { }
    handleEntering(event) { }

    //setOpen(t)  { this.setState({open:t})  }
    
    handleCancel() { /*setOpen(false);*/ }
    handleClose() { this.setOpen(false); }
    handleOpen()  { /*setOpen(true);*/  }

    importerSelectorOpen() { this.setState({importerSelectorOpen:true})  }
    importerSelectorClose() { this.setState({importerSelectorOpen:false})  }

    bankSelectorOpen() { this.setState({bankSelectorOpen:true}); }
    bankSelectorClose() { this.setState({bankSelectorOpen:false}); }

    shippingSelectorOpen() { this.setState({shippingSelectorOpen:true}); }
    shippingSelectorClose() { this.setState({shippingSelectorOpen:false}); }

    loadingPlaceSelectorOpen() { this.setState({loadingPlaceSelectorOpen:true}); }
    loadingPlaceSelectorClose() { this.setState({loadingPlaceSelectorOpen:false}); }

    customsSelectorOpen() { this.setState({customsSelectorOpen:true}); }
    customsSelectorClose() { this.setState({customsSelectorOpen:false}); }

    certificationSelectorOpen() { this.setState({certificationSelectorOpen:true}); }
    certificationSelectorClose() { this.setState({certificationSelectorOpen:false}); }

    insuranceSelectorOpen() { this.setState({ insuranceSelectorOpen: true }) }
    insuranceSelectorClose() { this.setState({ insuranceSelectorOpen: false }) }

    render () {
        //if(this.props.balances===undefined || this.props.balances===null) return <h3 className="pageViewScrollable">No input data</h3>
        return <div className="pageViewScrollable">
                  <div className="pageViewContents">
                  <h1>Transportation Settings</h1>
                  Configure your prefered partners
                  <a href="https://www.youtube.com/watch?v=r0LsnzAe1Yg">bill of lading</a><br/>

                  <div>
                  <List component="div" role="list">
                     <ListItem button divider aria-haspopup="true"  aria-controls="Importers-menu" aria-label="Importers"  role="listitem"
                         onClick={()=>this.importerSelectorOpen()}>
                        <ListItemText primary="Importer" secondary={this.state.importer} />
                     </ListItem>
                     <ImporterSelectDialogRaw id="Importers-menu" keepMounted
                            open={this.state.importerSelectorOpen}
                            onClose={()=>this.importerSelectorClose()}
                            value={this.state.importer}
                            parent={this}
                     />                     
                  </List>
                  </div>
                  <div>
                  <List component="div" role="list">
                     <ListItem button divider aria-haspopup="true"  aria-controls="Bank-menu" aria-label="Bank"  role="listitem"
                         onClick={()=>this.bankSelectorOpen()}>
                        <ListItemText primary="Bank" secondary={this.state.bank} />
                     </ListItem>
                     <BankSelectDialogRaw id="Bank-menu" keepMounted
                            open={this.state.bankSelectorOpen}
                            onClose={()=>this.bankSelectorClose()}
                            value={this.state.bank}
                            parent={this}
                     />                     
                  </List>
                  </div>                  
                  <div>
                  <List component="div" role="list">
                     <ListItem button divider aria-haspopup="true"  aria-controls="Shipping-menu" aria-label="Shipping"  role="listitem"
                         onClick={()=>this.shippingSelectorOpen()}>
                        <ListItemText primary="Shipping Company" secondary={this.state.shipping} />
                     </ListItem>
                     <ShippingSelectDialogRaw id="Shipping-menu" keepMounted
                            open={this.state.shippingSelectorOpen}
                            onClose={()=>this.shippingSelectorClose()}
                            value={this.state.shipping}
                            parent={this}
                     />                     
                  </List>
                  </div>
                  <div>
                  <List component="div" role="list">
                     <ListItem button divider aria-haspopup="true"  aria-controls="LoadingPlace-menu" aria-label="LoadingPlace"  role="listitem"
                         onClick={()=>this.loadingPlaceSelectorOpen()}>
                        <ListItemText primary="Loading place" secondary={this.state.loadingPlace} />
                     </ListItem>
                     <LoadingPlaceSelectDialogRaw id="Shipping-menu" keepMounted
                            open={this.state.loadingPlaceSelectorOpen}
                            onClose={()=>this.loadingPlaceSelectorClose()}
                            value={this.state.loadingPlace}
                            parent={this}
                     />                     
                  </List>
                  </div>       
                  <div>
                  <List component="div" role="list">
                     <ListItem button divider aria-haspopup="true"  aria-controls="Certification-menu" aria-label="Customs"  role="listitem"
                         onClick={()=>this.certificationSelectorOpen()}>
                        <ListItemText primary="Certification Authority" secondary={this.state.certificationAuthority} />
                     </ListItem>
                     <CertificationSelectDialogRaw id="Certification-menu" keepMounted
                            open={this.state.certificationSelectorOpen}
                            onClose={()=>this.certificationSelectorClose()}
                            value={this.state.certificationAuthority}
                            parent={this}
                     />                     
                  </List>
                  </div>

                  <div>
                  <List component="div" role="list">
                     <ListItem button divider aria-haspopup="true"  aria-controls="Customs-menu" aria-label="Customs"  role="listitem"
                         onClick={()=>this.customsSelectorOpen()}>
                        <ListItemText primary="Customs Authority" secondary={this.state.customsAuthority} />
                     </ListItem>
                     <CustomsSelectDialogRaw id="Customs-menu" keepMounted
                            open={this.state.customsSelectorOpen}
                            onClose={()=>this.customsSelectorClose()}
                            value={this.state.customsAuthority}
                            parent={this}
                     />                     
                  </List>
                  </div>                             

                   <div>
                  <List component="div" role="list">
                     <ListItem button divider aria-haspopup="true"  aria-controls="Insurance-menu" aria-label="Insurance"  role="listitem"
                         onClick={()=>this.insuranceSelectorOpen()}>
                        <ListItemText primary="Insurance Company" secondary={this.state.insuranceCompany} />
                     </ListItem>
                     <InsuranceSelectDialogRaw id="Insurance-menu" keepMounted
                            open={this.state.insuranceSelectorOpen}
                            onClose={()=>this.insuranceSelectorClose()}
                            value={this.state.insuranceCompany}
                            parent={this}
                     />                     
                  </List>
                  </div>                        
                  </div>
               </div>
    }
  }

  export default TransportationDashboard;

  /*
                     <div> 

                         <h2>Transportation contract</h2>
                         <table className="balancesSection">
                             <thead>
                                 <tr>
                                     <th>Importer</th>
                                     <th>Exporter</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                     <td>
                                        <strong>Buyer</strong>    
                                        <CompanySelector title="Company"  company={this.state.importer} selection={companies}/>
                                        <strong>Buyer Bank</strong>    
                                        <CompanySelector title="Bank"     company={this.state.importer} selection={banks}/>
                                        <strong>Shipping Company</strong>
                                         <CompanySelector title="Company"  company={this.state.importer} selection={companies}/>
                                        <strong>Place of Loading</strong>    
                                         <CompanySelector title="Place"    company={this.state.importer} selection={places}/>
                                     </td>
                                     <td>
                                        <strong>Seller</strong>    
                                        <CompanySelector title="Company"  company={this.state.importer} selection={companies}/>
                                        <strong>Seller Bank</strong>    
                                        <CompanySelector title="Bank"     company={this.state.importer} selection={banks}/>
                                    <strong>Customs Authority</strong>
                                        <CompanySelector title="Company"  company={this.state.importer} selection={companies}/>
                                    <strong>Place of Entry</strong>    
                                        <CompanySelector title="Place"    company={this.state.importer} selection={places}/>
                                     </td>

                                 </tr>

                                </tbody>
                         </table>
                     </div>  
  */
  /*
                                  
                                <div>Bank</div>
                                <strong>Shipping Company</strong>
                                <div>Company</div>
                                <strong>Place of Loading</strong>
                                <div>Place</div>

                                <strong>Exporter</strong>
                                <div>Company</div>
                                <div>Bank</div>
                                <strong>Customs Authority</strong>
                                <div>Company</div>
                                <strong>Place of Entry</strong>
                                <div>Place</div>
*/

/*
<Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="xs"
                        onEntering={this.handleEntering()}
                        aria-labelledby="confirmation-dialog-title"
                        open={open}
                        {...other}
                        >
                        <DialogTitle id="confirmation-dialog-title">Phone Ringtone</DialogTitle>
                        <DialogContent dividers>
                            <RadioGroup
                            ref={radioGroupRef}
                            aria-label="ringtone"
                            name="ringtone"
                            value={value}
                            onChange={this.handleChange()}
                            >
                            {options.map(option => (
                                <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                            ))}
                            </RadioGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancel()} color="primary">
                            Cancel
                            </Button>
                            <Button onClick={this.handleOk()} color="primary">
                            Ok
                            </Button>
                        </DialogActions>
                    </Dialog> */