import React from 'react';
import './../App.css';
import { Link } from "react-router-dom";

class FooterBanner extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:"",
          email:"info@energonx.com"
        }
      }

      
    render () {
       if(this.props.isExchangeEnabled==false)
       {
        return (<div className="footerBanner">
                  {/* <dl> 
                     <dt className="footerText"><Link to="/aboutexchange">ENERGONX</Link></dt> 
                  </dl> */}
                  <dl> 
                     <dt className="footerText"><Link to="/aboutproject">PROJECT</Link></dt>
                  </dl>
                  <dl> 
                      <dt className="footerText"><Link to="/news">NEWS</Link></dt> 
                  </dl> 
                  <dl> 
                    <dt className="footerText"><Link to="/blog">BLOG</Link></dt>  
                  </dl> 
                  {/* <dl> 
                    <dt className="footerText"><Link to="/gettoken">TOKEN</Link></dt> 
                  </dl>   */}
                  <dl> 
                     <dt className="footerText"><Link to="/aboutprojectcontactinfo">CONTACT</Link></dt> 
                  
                    
                  </dl>           
               </div>);
       }

        return <div className="footerBanner">
               <dl> 
                  <dt className="footerText"><Link to="/aboutexchange">EnergonX</Link></dt> 
                  <dd className="footerText"><Link to="/aboutproject">Project</Link></dd>
                  <dd className="footerText"><Link to="/news">News</Link></dd>
                  <dd className="footerText"><Link to="/blog">Blog</Link></dd>  
               </dl>
               <dl> 
                  <dt className="footerText"><Link  to="/hydrogenx">HydrogenX</Link></dt>
                  <dd className="footerText"><Link  to="/page?hydrogenx-about-procurement-network">Procurement Network</Link></dd>
               </dl>

               <dl> 
                  <dt className="footerText"><Link  to="/page?AffiliateProgram">Affiliates</Link></dt>
                  <dd className="footerText"><Link  to="/page?ApplyToList">Apply To List</Link></dd>
                  <dd className="footerText"><Link  to="/page?EliteProgram">Elite Program</Link> </dd>
               </dl>
               <dl> 
                  <dt className="footerText"><Link to="/page?Reference">Reference</Link></dt>
                  <dd className="footerText"><Link to="/page?API">API</Link></dd>
                  <dd className="footerText"><Link to="/page?QuickStart">Quick Start</Link></dd>
                  <dd className="footerText"><Link to="/page?FAQ">FAQ</Link></dd>
               </dl>
               <dl> 
                  <dt className="footerText"><Link to="/page?TradingInfo">Trading</Link></dt> 
                  <dd className="footerText"><Link to="/gettoken">Get Token</Link></dd> 
                  <dd className="footerText"><Link to="/page?Fees">Fees</Link> </dd>
                  <dd className="footerText"><Link to="/page?Volume">Volume</Link></dd>
               </dl>         

               <dl> 
                  {/* <dt className="footerText"><Link to="/news">NEWS</Link></dt> */}
                  <dt className="footerText"><Link to="/aboutprojectcontactinfo">Contact</Link></dt>
                  <dd className="footerText"><Link to="/page?SubmitProblem">Submit Problem</Link></dd>
                  <dd className="footerText"><Link to="/page?Forums">Forums</Link></dd>
               </dl>

               <dl> 
                  <dt className="footerText"><Link to="/page?About">About</Link></dt>
                  <dd className="footerText"><Link to="/page?PrivacyPolicy">Privacy Policy</Link></dd>
                  <dd className="footerText"><Link to="/page?TermsOfService">Terms of Service</Link></dd>
                  <dd className="footerText"><Link  to="/page?Disclaimer">Disclaimer</Link> | <Link to="/page?Imprint">Imprint</Link> </dd>
               </dl>
{/* 
               <dl> 
                  <dt className="footerText"><Link  to="/page?Disclaimer">LEGAL</Link></dt>
                  <dd className="footerText"><Link  to="/page?Disclaimer">Disclaimer</Link></dd>
                  <dd className="footerText"><Link  to="/page?Imprint">Imprint</Link></dd>
               </dl> */}

               </div>
    }
}

export default FooterBanner;
