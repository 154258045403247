import React from 'react';
import './../App.css';
import { Link } from "react-router-dom";

class ContractShipmentHeader extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
      }

    }

    render () {
        let data = this.props.shipment;
        //console.log("shipment", data);
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div> 
                  <table className="contractsSection shipmentSectionHeader fixed">
                      <thead>
                          <tr>
                              <th>Importer (Buyer)</th>
                              <th>Exporter (Seller)</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td> <Link to={"/companyview/" + data.buyerImporter.id} >{data.buyerImporter.legalName} </Link></td>  
                            <td> <Link to={"/companyview/" + data.sellerExporter.id}>{data.sellerExporter.legalName} </Link></td>                                    
                          </tr>
                          <tr>
                            <td> <strong>Buyer Bank</strong></td>  
                            <td> <strong>Seller Bank</strong></td>                                    
                          </tr>
                          <tr>
                            <td> {data.buyerBank ?  <Link to={"/bank/"+ data.buyerBank.id}>{data.buyerBank.legalName}</Link> : "Ethereum"} </td>  
                            <td> {data.sellerBank ? <Link to={"/bank/"+ data.sellerBank.id}>{data.sellerBank.legalName}</Link> : "Ethereum"} </td>                                    
                          </tr>

                          <tr>
                            <td> <strong>Buyer Certification Authority</strong></td>  
                            <td> <strong>Seller Certification Authority</strong></td>                                    
                          </tr>
                          
                          <tr>
                            <td> {data.buyerCertification ?  <Link to={"/certification/"+ data.buyerCertification.id}>{data.buyerCertification.legalName} </Link>: "N/A buyerCertification"}</td>  
                            <td> {data.sellerCertification ?  <Link to={"/certification/"+ data.sellerCertification.id}>{data.sellerCertification.legalName}</Link>: "N/A sellerCertification"} </td>                                    
                          </tr>

                          <tr>
                            <td> <strong>Buyer Authority</strong></td>  
                            <td> <strong>Seller Authority</strong></td>                                    
                          </tr>

                          <tr>
                            <td> {data.buyerAuthority ?  <Link to={"/authority/"+ data.buyerAuthority.id}>{data.buyerAuthority.legalName}</Link>: "N/A buyerAuthority"} </td>  
                            <td> {data.sellerAuthority ?  <Link to={"/authority/"+ data.sellerAuthority.id}>{data.sellerAuthority.legalName}</Link>: "N/A sellerAuthority"} </td>                                    
                          </tr>
                          
                          <tr>
                            <td> <strong>Buyer Insurance</strong></td>  
                            <td> <strong>Seller Insurance</strong></td>                                    
                          </tr>
                          <tr>
                            <td> {data.buyerInsurance ?  <Link to={"/placeview/"+ data.buyerInsurance.id}>{data.buyerInsurance.City}</Link>: "N/A buyerInsurance"} </td>  
                            <td> {data.sellerInsurance ?  <Link to={"/placeview/"+ data.sellerInsurance.id}>{data.sellerInsurance.City}</Link>: "N/A sellerInsurance"} </td>                                    
                          </tr>

                          <tr>
                            <td> <strong>Place of Entry</strong></td>  
                            <td> <strong>Place of Loading</strong></td>                                    
                          </tr>
                          <tr>
                            <td> {data.buyerPlaceOfEntry ?  <Link to={"/placeview/"+ data.buyerPlaceOfEntry.id}>{data.buyerPlaceOfEntry.City}</Link>: "N/A buyerPlaceOfEntry"} </td>  
                            <td> {data.sellerPlaceOfLoading ?  <Link to={"/placeview/"+ data.sellerPlaceOfLoading.id}>{data.sellerPlaceOfLoading.City}</Link>: "N/A sellerPlaceOfLoading"} </td>                                    
                          </tr>
                          <tr>
                            <td> <strong>Requested Date</strong></td>                                                                
                            <td> <strong>Created Date</strong></td>  
                          </tr>
                          <tr>
                            <td> {data.DeliverySealed}</td>                                    
                            <td> {data.CreatedDate}</td>  
                          </tr>

                          <tr>
                            <td> <strong></strong></td>                                                                
                            <td> <strong>Guarantee Of Origin</strong></td>  
                          </tr>
                          <tr>
                            <td> ... {data.DeliverySealed} </td>
                            <td><Link to={"/guaranteeoforigin/" + data.id}> View GoG {data.CreatedDate} </Link> </td>
                          </tr>                          

                          <tr>
                            <td> <strong></strong></td>                                                                
                            <td> <strong>Certificate</strong></td>  
                          </tr>
                          <tr>
                            <td> ... {data.DeliverySealed} </td>
                            <td> View Certificates {data.CreatedDate} </td>
                          </tr>                          
                          <tr>
                            <td> Distance </td>
                            <td> {(data.airDistance).toFixed(2)} km</td>
                          </tr>   
                        </tbody>
                  </table>
              </div>
    }
  }

export default ContractShipmentHeader;
