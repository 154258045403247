import React from 'react';
//import './../../App.css';
import { Link } from "react-router-dom";
import Form from "@rjsf/core";
import { Bee } from "@ethersphere/bee-js";

// import ContractShipmentHeader  from "./../Contract_ShipmentHeader.js";
// import ContractShipmentContracts from "./../Contract_ShipmentContracts.js";
// import ContractShipmentFooter  from "./../Contract_ShipmentFooter.js";

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// based on  https://www.powerorigin.org/how-does-the-guarantee-of-origin-look-like/
const GoG_JSONSchema = `{
    "title": "Cancellation Statement",
    "description": "With this cancellation Statement the indicated Certificates are no longer tradable. Onward sale of this Cancellation Statement is prohibited.",
    "type": "object",
    "properties": { 
      "transactionDate": {
        "type": "string",
        "title": "Transaction Date",
        "default": "2020-10-10 13:38:54",
        "format": "date-time"
      },
      "transactionNumber": {
        "type": "string",
        "title": "Transaction Number",
        "default": "20201010000000010"
      },
      "transactionStatus": {
        "type": "string",
        "title": "Transaction Status",
        "default": "Completed"
      },
      "totalGO": {
        "type": "string",
        "title": "Total GO",
        "default": "134"
      },
      "totalAmount": {
        "type": "string",
        "title": "Total Amount",
        "default": "134"
      },      
      "Certificates": {
        "type": "object",
        "properties": {
          "fromNumber": {
            "type": "string",
            "title": "From Certificate #",
            "default": "5982347234214085432"
          },
          "toNumber": {
            "type": "string",
            "title": "To Certificate #",
            "default": "5982347234215421354"
          },
          "volume": {
            "type": "string",
            "title": "Volume",
            "default": "134"
          },
          "domain": {
            "type": "string",
            "title": "Domain",
            "default": "Slovenia"
          },
          "fuel": {
            "type": "string",
            "title": "Fuel",
            "default": "F01050100"
          },
          "technology": {
            "type": "string",
            "title": "Tecnology",
            "default": "T02003"
          },
          "issuingDate": {
            "type": "string",
            "title": "Issuing Date",
            "default": "2020-10-10 13:08:54",
            "format":"date-time"
          },
          "productionPeriodFrom": {
            "type": "string",
            "title": "Production Period From",
            "default": "2020-10-10 13:38:54",
            "format":"date-time"
          },
          "productionPeriodTo": {
            "type": "string",
            "title": "Production Period To",
            "default": "2020-10-10 13:38:54",
            "format":"date-time"
          },
          "tradingScheme": {
            "type": "string",
            "title": "Trading Scheme",
            "default": "GO"
          },
          "supportScheme": {
            "type": "string",
            "title": "Support Scheme",
            "default": "Investment And Production Support"
          }
        }
      },
      "Guarantee Of Origin Issuer": {
        "type": "object",
        "properties": {
          "guaranteeIssuerName": {
            "type": "string",
            "title": "Issuer Name",
            "default": "EnergonX"
          },
          "guaranteeIssuerIdentifier": {
            "type": "string",
            "title": " Identifier",
            "default": "0xdaa070D909E010211606144eDe5B2ca6864C2c1c"
          },
          "guaranteeIssuerValidator": {
            "type": "string",
            "title": "Validator",
            "default": "0xC99C422018c4272a077Dd719eD974A1B3e29BFcD"
          }
        }
      },
  
      "Production Device": {
        "type": "object",
        "properties": {
          "deviceName": {
            "type": "string",
            "title": "Device Name",
            "default": "Electrolyser DF131"
          },
          "productionDeviceGSRN": {
            "type": "string",
            "title": "Production Device GSRN",
            "default": "5550005550005550000"
          },
          "productionDeviceDomain": {
            "type": "string",
            "title": "Production Device Domain",
            "default": "Slovenia"
          },
          "installedCapacity": {
            "type": "string",
            "title": "Installed Capacity",
            "default": "30"
          },
          "dateOfCommissioning": {
            "type": "string",
            "title": "Date Of Commissioning",
            "default": "2005-10-10 13:38:54",
            "format": "date-time"
          },
          "locationOfProductionDevice": {
            "type": "string",
            "title": "Location Of Production Device",
            "default": "Ljubljana, Slovenia"
          },
          "operatorName": {
            "type": "string",
            "title": "Operator Name",
            "default": "Hydrogen Company Producer"
          },
          "operatorAddress": {
            "type": "string",
            "title": "Address Of Operator",
            "default": "Street X 32, Ljubljana, Slovenia"
          },
          "productionDeviceTechnology": {
            "type": "string",
            "title": "Technology",
            "default": "PEM Electrolyser"
          },
          "productionDeviceFuel": {
            "type": "string",
            "title": "Fuel / Energy source",
            "default": "Wind/Offshore"
          }
        }
      },

      "swarmHash": {
        "type": "string",
        "title": "Swarm location",
        "default": "0x0",
        "format": "string"
      }
    }
  } 
`;

// insert before swarmHash
/*
      "From": {
        "type": "object",
        "properties": {
          "accountHolder": {
            "type": "string",
            "title": "Account Holder",
            "default": ""
          },
          "account": {
            "type": "string",
            "title": "Account",
            "default": ""
          },
          "domain": {
            "type": "string",
            "title": "Domain",
            "default": ""
          },
          "street": {
            "type": "string",
            "title": "Street",
            "default": ""
          },
          "postalCode": {
            "type": "string",
            "title": "Postal Code",
            "default": ""
          },
          "city": {
            "type": "string",
            "title": "City",
            "default": ""
          },
          "country": {
            "type": "string",
            "title": "Country",
            "default": ""
          }
        }
      },
      "To": {
        "type": "object",
        "properties": {
          "beneficiary": {
            "type": "string",
            "title": "Name of Beneficiary",
            "default": ""
          },
          "cancellationPurpose": {
            "type": "string",
            "title": "Cancellation Purpose",
            "default": ""
          },
          "consumptionPeriod": {
            "type": "string",
            "title": "Consumption Period",
            "default": ""
          },
          "countryOfConsumption": {
            "type": "string",
            "title": "Country Of Consumption",
            "default": ""
          },
          "beneficiaryLocation": {
            "type": "string",
            "title": "Beneficiary Location",
            "default": ""
          },
          "usageCategory": {
            "type": "string",
            "title": "Usage Category",
            "default": "Disclosure"
          },
          "typeOfBeneficiary": {
            "type": "string",
            "title": "Type Of Beneficiary",
            "default": "Energy supplier"
          }
        }
      },
*/

class GuaranteeOfOrigin extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        GuaranteeOfOrigin:null,
        JSONSchema:{},
        formData:{},
        uiSchema:{},
        updated:false
      };

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
       this.getData();
       this.intervalHandle = setInterval(this.getData, 10000); // every 11s
    }

    componentDidUpdate(prevProps) {
      if(this.props.GuaranteeOfOrigin !== null && this.state.GuaranteeOfOrigin===null)
      {
         this.setState({GuaranteeOfOrigin:this.props.GuaranteeOfOrigin});
         
      }
    } 

    componentWillUnmount() {
       clearInterval(this.intervalHandle); 
    }

    async storeGuarantee(incomingData)
    {
      console.log("storing GoG statement"); 
      try 
      {
         //var bee = new Bee("https://swarm.fairdatasociety.org/"); 
         var bee = new Bee("https://bee-gateway.duckdns.org/"); 
        // var bee = new Bee("https://localhost:1633"); 
        const fileHash = await bee.uploadData(JSON.stringify(incomingData));
        const data = await bee.downloadData(fileHash);
        console.log("swarm filehash", fileHash, data.text());
        incomingData.properties.swarmHash.default = fileHash;
      } catch(e)
      {
        console.log(e) 
      }
    }

    requestDelivery(fromClientId) {
        if(this.props.comm!==undefined)
        {
            //this.props.comm.requestDeliveryForShipment(fromClientId, this.state.shipment); 
        }  
    }

    async getData() { 
      if(this.props.comm!==undefined)
      {
          //this.props.comm.getSealedDelivery(this.props.match.params.id); 
      }

      if(this.state.updated===false)
      {
        var o = JSON.parse(GoG_JSONSchema);
        await this.storeGuarantee(o);
        await this.setState({JSONSchema: o});
        console.log(this.state.JSONSchema);
      }

      let data = this.state.JSONSchema;
      if(data.properties!==undefined && this.state.updated===false)
      {
          data.properties.transactionDate.default = Date.now(); //.toISOString(); 
          console.log(data.properties.transactionDate); 
          this.setState({updated: true});
      }
    }
    onSubmit(value, callback) {
        console.log('onSubmit: %s', JSON.stringify(value)); // eslint-disable-line no-console
        setTimeout(() => callback && callback(), 2000); // just an example in real world can be your XHR call
    }
    onCancel()  {
        console.log('on reset being called');
    }
    onFormChanged(formData) 
    {
        console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    onUpload (value) {
        console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    render () {
        let gog = this.state.GuaranteeOfOrigin;
        let data = this.state.JSONSchema;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents">
                    <h2>Guarantee Of Origin</h2>
                    <Form 
                        schema={this.state.JSONSchema}
                        uiSchema={this.state.uiSchema}
                        formData={this.state.formData}
                        onCancel={this.onCancel}
                        //onSubmit={this.onSubmit}
                        onUpload={this.onUpload}
                        onChange={this.onFormChanged}
                        submitOnEnter
                        activityIndicatorEnabled
                        disabled
                        children={<br/>} // hides submit button
                    />

                    {/* <Accordion>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}> 
                            <Typography>
                                Buyer:  <strong>{data.buyerImporter.legalName}</strong> &nbsp; 
                                Seller: <strong>{data.sellerExporter.legalName}</strong>  
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ContractShipmentHeader shipment={data} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}> 
                                <Typography>View Contracts ({data.contracts.length})</Typography> 
                            </AccordionSummary>
                            <AccordionDetails>
                                <ContractShipmentContracts contracts={data.contracts} markets={this.props.markets} assets={this.props.assets} /> 
                                <ContractShipmentFooter shipment={data} /> 
                            </AccordionDetails>
                    </Accordion> */}

                    
                    <hr />                    
                </div>
               </div>
    }
  }

export default GuaranteeOfOrigin;
