import React from 'react';
import './../../App.css';
import { formatMoney } from '../helpers/Formating';
import { Link } from 'react-router-dom';
import {
   Container,
   Grid,
   makeStyles
 } from '@material-ui/core';

//  import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
//  import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
/*
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import MoneyIcon from '@material-ui/icons/Money';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import DashboardCard from './dashboard/DashboardCard';
import DashboardPercentageCard from './dashboard/DashboardPercentageCard';
import DashboardProgressCard from './dashboard/DashboardProgressCard';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { transpile } from 'typescript';

 const useStyles = makeStyles((theme) => ({
   root: {
     backgroundColor: theme.palette.background.dark,
     minHeight: '100%',
     paddingBottom: theme.spacing(3),
     paddingTop: theme.spacing(3)
   }
 }));
 */
 class MarketplaceDash extends React.Component {
   render() {
      var marketplace = this.props.marketplace;
      return <div>
               {/* <h2>Volume {formatMoney(marketplace.volume)} €</h2> */}
                  {marketplace.exchanges.map(e=>
                     <div key={"mpd"+e.exchangeId}> 
                        <ExchangeDash exchange={e}/>
                     </div>
                  )}
               <hr/>
               <div>Fees: {formatMoney((marketplace.volume * (0.6/100)))}€ </div> <br/> 
               <hr/>
             </div>
   }
  }

 class ExchangeDash extends React.Component {
  render() {
     var e = this.props.exchange;
     var eName = window.appComponent.state.exchanges[e.exchangeId-1].name;
     return <div>
                  <h2>{eName}<small> Volume {formatMoney(e.volume)}€</small></h2> 
                  {e.markets.map(m=>
                       <div key={"ed"+m.marketId}>
                           <MarketDash market={m} />
                       </div>
                  )}
               {/* <hr/> */}
               {/* <div>Fees:{(e.volume * (3/1000)).toFixed(2)}€ </div> */}
            </div>
  }
 }
 class MarketDash extends React.Component {
   render() {
      var m = this.props.market;
      var mName = window.appComponent.state.allMarkets[m.marketId-1].name;
      return <div>
                <h3><Link to={"/analytics/percountry/" + m.marketId}> {mName}</Link> <small> Volume {formatMoney(m.volume)}€</small>  </h3>
                <div>
                  <table className="balancesSection tableFinancialView fixed">
                        <thead>
                           <tr>
                              <th></th>
                              <th>Count</th>
                              <th>Min</th>
                              <th>Average</th>
                              <th>Max</th>
                              <th>Quantity</th> 
                              <th>Total</th> 
                              <th>Fees</th> 
                           </tr>
                        </thead>
                        <tbody>
                           <MarketViewAnalytics stats={m.buysStats[0]} title="Bids" id={5}/>
                           <MarketViewAnalytics stats={m.sellsStats[0]} title="Asks" id={6}/>
                           {m.completedBuysStats.map((s,i)=> (
                              <MarketViewAnalytics stats={s} title="Buys" id={i} key={i+"mvacbs"} displayProcessed={true}/>
                              )
                           )}
                           {/* {m.completedSellsStats.map((s,i)=> (
                              <MarketViewAnalytics stats={s} title="Sells" id={i} key={i+"mvacss"} displayProcessed={true}/>
                              )
                           )} */}
                        </tbody>
                  </table>
                </div>
                <br/>
             </div>
   }
  }

class MarketViewAnalytics extends React.Component {
   render() {
      var x = this.props.stats;
      if(x===undefined) return null;
      return <tr key={this.props.id + this.props.title}>
                  <td>{this.props.displayProcessed  ? (x.processed==0 ? "Executed" : "Processed") : null}  {this.props.title}</td>
                  <td>{x.count} </td>
                  <td>{x.min.toFixed(2)}€ </td>
                  <td>{x.average.toFixed(2)}€ </td>
                  <td>{x.max.toFixed(2)}€ </td>
                  <td>{x.amount.toFixed(0)} </td> 
                  <td>{formatMoney(x.amount * x.average)}€ </td> 
                  
                  <td>{x.fees===0 ? null : formatMoney(x.fees)+"€"} </td> 
                  {/* <td>{(x.count*x.average).toFixed(2)}€ </td> */}
             </tr>
   }
}

class MarketplaceIndex extends React.Component {
   render() {
      var marketplace = this.props.marketplace;
      var energonIndex = 0; 
      var indexValue = 0; 
      var count = 0;
      try 
      {
         marketplace.exchanges.map((e)=>{
            e.markets.map((m)=>{
               if(m.sellsStats!==undefined && m.buysStats!==undefined && m.sellsStats!==undefined) {
                  energonIndex += m.buysStats[0].average + m.sellsStats[0].average;
                  indexValue += (m.buysStats[0].amount+m.sellsStats[0].amount) * (m.sellsStats[0].average - m.buysStats[0].average);
                  count++;
               }
            })
         });
      } catch(e) { console.log(e) }
      // indexValue = sum of all markets average price * quantity
      return <div className="center MuiGrid-root MuiGrid-container MuiGrid-align-items-xs-flex-start MuiGrid-justify-xs-center">
                 <h1 className="">
                   Marketplace: <strong>{formatMoney(energonIndex/count)}</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                   Index: <strong>{formatMoney(indexValue/count)}</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                   Total Volume <strong>{formatMoney(marketplace.volume)}</strong> € <br/>
                  </h1>
                 <hr />
             </div>
   }
} 

class MarketplaceAnalyticsDashboard extends React.Component {
    constructor(props){
        super(props)
        this.state = { marketplaceAnalytics: null }
        this.getData = this.getData.bind(this);
    }
  
    componentDidMount() { 
         this.getData();
         this.intervalHandle = setInterval(this.getData, 987); // every 2s
    }
    componentDidUpdate(prevProps) {
      /*if(this.props.marketplaceAnalytics !== null && this.state.marketplaceAnalytics===null)
      {
         this.setState({marketplaceAnalytics:this.props.marketplaceAnalytics});
      }*/
    } 

    componentWillUnmount() { 
       clearInterval(this.intervalHandle); 
    }
    getData() {  
      if(this.props.comm!==undefined)
         this.props.comm.getAnalyticsForMarketplace(this); 
    } 

    render () {
       var d = this.props.marketplaceAnalytics;
       if(d==null) return <div className="pageViewScrollable">Please Wait</div>

        return <div className="pageViewScrollable">
                  <MarketplaceIndex marketplace={d}/>
                  {/* <h1>Marketplace</h1> */}

                  <div className="pageNormalViewContents">
                     {/* View Current Statistics for All Markets. */}
                     <MarketplaceDash marketplace={d}/>
                  </div>
                  <br/><br/><br/><br/><br/><br/><br/><br/>
               </div>
    }
  }

  export default MarketplaceAnalyticsDashboard;