import React from 'react';
import '../../App.css';

//import {Card, CardActionArea, CardActions, CardContent, CardMedia, CardHeader, Collapse, Papar, Typography, Button, Grid } from '@material-ui/core/';
import { Link } from "react-router-dom";
import RegisterMarketplaceAccountSelector from '../registrations/RegisterMarketplaceAccountSelector.js';   
import RegisterProcurementNetworkAccount from '../registrations/RegisterProcurementAccount.js';
import Ticker from 'react-ticker';
import PriceRender from './../helpers/PriceRender.js';
//import Modal from './node_modules/react';

class InitialSmallMenuSelector extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <div className="profileInformationSection">
                <h3><Link to="/news">News</Link></h3>
                <h3><Link to="/blog">Blog</Link></h3>
                <h3><Link to="/gettoken">Token</Link></h3>
             </div>
           </div>
  }
}

class InitialMenuSelector extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return (<div className="profileInformationSection">
                <h2><Link to="/aboutexchange">Marketplace</Link> &nbsp;&nbsp;&nbsp;
                <Link to="/aboutproject">Project</Link> &nbsp;&nbsp;&nbsp;
                <Link to="/news">News</Link> &nbsp;&nbsp;&nbsp;
                <Link to="/blog">Blog</Link> &nbsp;&nbsp;&nbsp; 
                <Link to="/aboutprojectcontactinfo">Contact</Link></h2> &nbsp;&nbsp;&nbsp;

                <div>
                  For producers, transportation companies and consumers.<br/>
                  From households to large enterprises to buy, sell and deliver. <br/>
                  Track production, availability, storage, transfers and consumption.<br/><br/>

                  Fully automated for machines with user interface for humans. <br/>
                  <br/>
                  Autonomous isolated marketplaces with connectivity on-demand.<br/>
                  <br/>
                  AI/ML predictions, market analysis, availability, spikes, trends, indexes. <br/>
                  <br/>
                  Decentralized storage.<br/>
                  Decentralized ledger.<br/>
                  Geolocated.<br/>

                  <br/>
                  Built for green future. <br/>
                  EnergonX is for hydrogen. <br/>

                </div>
                {/* <h3><Link to="/aboutexchange">Marketplace</Link></h3>
                <h3><Link to="/aboutproject">Project</Link></h3>
                <h3><Link to="/news">News</Link></h3>
                <h3><Link to="/blog">Blog</Link></h3> */}
                {/* <h3><Link to="/gettoken">Token</Link></h3> */}
                {/* <h3><Link to="/aboutprojectcontactinfo">Contact</Link></h3> */}
           </div>)
  }
}

class MarketTicker extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
    this.update = this.update.bind(this);
  }
  componentDidMount() { 
    this.intervalHandle = setInterval(this.update, 10000); // every n
  }
  componentWillUnmount() { 
    clearInterval(this.intervalHandle); 
  }
  update() {  
    this.forceUpdate();
    console.log("WIP ticker refresh");
  }
  render() {
    //console.log(this.props.markets)
    if(this.props.markets===undefined || this.props.markets.length===0) return null;
    
    var data = <p style={{ whiteSpace: "nowrap" }}>
                  {this.props.markets.map((m,i)=>(
                      <span key={m.id}>{m.name} <PriceRender summary={m.summary}/>  </span>
                  ))}
                </p>
    return (
                <Ticker offset="run-in" speed={10}>
                   {() => (data)}
                </Ticker>
                // <>{data}</>
           )
  }
}

class InitialView extends React.Component {
    constructor(props){
      super(props)
      this.state = {
      }
    }

    render () {
        
        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents"> 
                {(window.energonxapp.state.isExchangeEnabled===true ? <MarketTicker markets={this.props.markets}/> : null)}

                {(window.energonxapp.state.isExchangeEnabled===false || window.energonxapp.state.isProcurementEnabled===false) ? 
                  <InitialMenuSelector /> : null}

                  {window.energonxapp.state.isExchangeEnabled ? 
                   <>
                     <Link to="/marketplace"><h1>Marketplace</h1></Link>
                     <RegisterMarketplaceAccountSelector /> 
                   </> : null
                  }

                {window.energonxapp.state.isProcurementEnabled ? 
                   <>
                       <Link to="/hydrogenx/search"><h1>Procurement Network</h1></Link>
                      <RegisterProcurementNetworkAccount />
                   </> : null
                  } 

{/*                   
                  {window.energonxapp.state.isExchangeEnabled ? 
                   null : <h4>Marketplace is not enabled</h4>
                  }
                  {window.energonxapp.state.isProcurementEnabled ? 
                   null : <h4>Procurement network is not enabled</h4>
                  } 
*/}


                </div> 
               </div>
    }
  }

export default InitialView;
export { InitialMenuSelector, InitialSmallMenuSelector }



