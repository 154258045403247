import React from 'react';
//import './../../App.css';

class ClientBalancesView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
           balance:null
        }

        this.getData = this.getData.bind(this);
    }
  
    componentDidMount() { 
        if(this.props.account!==undefined && this.props.account!==null)
        {
           this.getData();
           this.intervalHandle = setInterval(this.getData, 11000); // every 11s
        }

    }
    componentWillUnmount() { clearInterval(this.intervalHandle); }
    componentDidUpdate(prevProps) {    } 
    getData() { this.props.comm.getClientBalances(); }

    onBalanceClick(asset)
    {
        console.log(asset);
    }
    

    render () {
        if(this.props.balances===undefined || this.props.balances===null) return <h3 className="pageViewScrollable">No balances data</h3>

        let assets = this.props.appstate.assets;
        let balances = this.props.balances;

        return <div className="pageViewScrollable">
                  <h1>BALANCES, DEPOSITS & WITHDRAWALS</h1>
                  <div className="pageNormalViewContents pageFixedHeaderViewContents">
                     {/* <label> <input type="checkbox" label="hide0" defaultChecked={this.props.balances.hide0balances} onChange={this.onCheckboxChange} key="key1"/>Hide 0 Balances</label>
                     <label> <input type="checkbox" label="delisted" defaultChecked={this.props.balances.hideDelisted} onChange={this.onCheckboxChange} key="key2"/>Hide Delisted</label> */}
                     <div>
                         <table className="balancesSection tableFinancialView"> 
                           <thead>
                               <tr>
                                   <th>NAME</th> 
                                   <th>ASSET</th>
                                   <th>AVAILABLE</th>
                                   <th>ON ORDERS</th>
                                   <th>IN CONTRACTS</th>
                                   {/* <th>TOTAL</th> */}
                                   {/* <th>VALUE</th> */}
                                   <th>ACTIONS</th>
                              </tr> 
                           </thead>
                           <tbody>
                           {balances.filter(b => assets[b.aIx].disabled===false ).map(b => 
                             (
                              <tr key={b.id} onClick={()=>this.onBalanceClick(assets[b.aIx])}>
                                  <td> {assets[b.aIx].name}</td>
                                  <td> {assets[b.aIx].symbol}</td>
                                  <td> {b.available} </td>
                                  <td> {b.onOrders} </td>
                                  <td> {b.inContracts} </td>
                                  {/* <td> {b.total} </td> */}
                                  {/* <td>  </td> */}
                                  <td> {assets[b.aIx].fiat ? <span>Deposit Withdraw</span> : <span> Add Supply </span>} </td>
                              </tr>
                             )
                            )}
                           </tbody>
                         </table> 
                     </div>
                  </div>
                  <hr/>
               </div>
    }
  }

  export default ClientBalancesView;