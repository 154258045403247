import React from 'react';
import ContractOrders from "./Contract_Orders.js";

class ContractShipmentContracts extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
      }

    }

    render () {
        let data = this.props.contracts; //
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div> {data.map(c => 
                      <ContractOrders contract={c} markets={this.props.markets} assets={this.props.assets} key={"contract" + c.serialNum}/> 
                  )}
              </div>
    }
  }

export default ContractShipmentContracts;
