import React from 'react';
import NumberFormat from 'react-number-format';

class PriceRender extends React.Component {
  render() {
    let s = this.props.summary;
    //console.log(s);
    let p = s.change > 0 ? <span style={{ color: 'var(--greenColor)' }}> <NumberFormat value={s.last} format="#######" displayType={'text'} decimalScale={3} fixedDecimalScale={true}/> </span> : 
                           <span style={{ color: 'var(--redColor)' }} >  <NumberFormat value={s.last} format="#######" displayType={'text'} decimalScale={3} fixedDecimalScale={true}/> </span>

    return  p; 
  }
}

export default PriceRender;