import React from 'react';
import { Link } from "react-router-dom";
import './../App.css';

import RegisterAccount from './registrations/RegisterAccount';

const ethUtil = require('ethereumjs-util');

class LoginScreen extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          register: false,
          accessToken: null,
          info:""
        }

        this.performlogin = this.performlogin.bind(this);
        this.autologin = this.autologin.bind(this);
      }
      
    createAccount() {
    }

    componentDidMount()
    {
      this.autologin();
      //this.intervalHandle = setInterval(this.autologin, 1000); 
    }

    register() { this.setState({ register: !this.state.register }); }
    
    applyCompany() { this.setState({ applyCompany: !this.state.applyCompany }); }
    applyCertificationAuthority() { this.setState({ applyCertificationAuthority: !this.state.applyCertificationAuthority }); }
    applyTransportationCompany() { this.setState({ applyTransportationCompany: !this.state.applyTransportationCompany }); }
    
    autologin()
    {
      /*if(this.props.app.state.isExchangeEnabled==false) return;
      if(this.props.app===undefined) return;

      console.log("autologin", this.props.app.state.account);
      var defLog = 
      {
        signer: this.props.app.state.account, //"0xdaa070d909e010211606144ede5b2ca6864c2c1c",
        signature: "0x5d5c4e8ff2b321dc662d621282850f4790471a6f78c27e49243be2272d76f92a630f6b0266657231f946db4fde25a13773e0b63f6b77da0c15ad22a0839009531c",
        message: "0x5265717565737420746f206c6f67696e20746f20456e6572676f6e582045786368616e67652e20506c65617365207369676e2074686973206d6573736167652e2054686973206973206e6f742061207472616e73616374696f6e2c20697320636f6d706c6574656c79206672656520616e642031303025207365637572652e205765276c6c2075736520796f7572207369676e617475726520746f2070726f766520796f7572206f776e657273686970207365727665722073696465206f6e20456e6572676f6e58",
        hash: "0x8ccc04b23378581fbaee13e43b0f5f8f19623a3cfba6cc33856dcc72b6411d88"
      };
      
      this.performlogin(this.props.app, defLog);  */
    }
    performlogin(appContext, loginData)
    {
        appContext.displayInfo("Signing in...");
        //console.log("performlogin", loginData);
        appContext.request({
        url: window.energonUrl + 'api/signin',
        body: JSON.stringify(loginData),
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ', // + context.state.accessToken,
            'Content-type': 'application/json'
        }
        }).then(data => {
            var json = JSON.parse(data);
            console.log("login received", json);
            appContext.setState({accessToken:json.token});
            //appContext.setBalances(json.balances);
            appContext.state.comm.storeServer("account", loginData.signer);// appContext.state.account);
            appContext.state.comm.setToken(json.token);

            console.log("logged with account", appContext.state.account);
            appContext.setStatus("logged with account "+ appContext.state.account);
            appContext.setError("");

            appContext.state.comm.getClientBalances(); 
            appContext.setState({redirectToProfile:true}); // redirect to profile
        }).catch(error => {
            console.error("Login failure", error);
            appContext.setError(error.mesage);
            appContext.displayError("Failed to sign-in");
        });
    }

    loginWithWeb3Signing()
    {
      try 
      {
        let plain = 'Request to login to EnergonX Exchange. Please sign this message. This is not a transaction, is completely free and 100% secure. We\'ll use your signature to prove your ownership server side on EnergonX';
        let msg  = ethUtil.bufferToHex(new Buffer(plain, 'utf8'));
        let hash = ethUtil.bufferToHex(ethUtil.keccak256(new Buffer("\x19Ethereum Signed Message:\n" + plain.length + plain)));
        let from = this.props.app.state.account;
        console.log("logging in with account", this.props.app.state.account);
    
        let params = [msg, from];
        let method = 'personal_sign';
    
        let appContext = this.props.app;
        let component = this;
        this.props.web3.givenProvider.sendAsync({ method, params, from,
          }, function (err, result) {
              if (err || result.error) {
                  appContext.setError(err.message);
                  return console.error(err, result.error);
              }
    
              appContext.setStatus('Requesting token...');
              let loginData = {};

              loginData.signer = from;
              loginData.signature = result.result;
              loginData.message = msg;
              loginData.hash = hash;
              
              component.performlogin(appContext, loginData);
          });
      } catch(e)
      {
        this.props.app.displayError('There was error signing message with.');
      }
    }    
  
    render () {
      let register =  this.state.register===true ? <RegisterAccount app={this.props.app} loginScreen={this}/> : null;   

      if(register!==null) return register;

      let login = this.props.accessToken===null ? 
               <div>Web3 <Link className="bannerLink" to="#" onClick={()=>this.loginWithWeb3Signing()}><strong> Login</strong></Link></div> :
                null;                                                   
 
      return <div className="settingsWindow">
          <div className="dialogLogo">EnergonX</div>
          <div>Login with your EnergonX account</div><br/>

          {login} <br />

             <div> <Link className="bannerLink" to="#" onClick={()=>this.register()}><strong>Register</strong></Link>  for new account   
             </div> <br/>
             <div> <Link className="bannerLink" to="#" onClick={()=>this.props.app.justTry()}><strong>Cancel</strong></Link>
             </div>
          </div>
    }
  }

  export default LoginScreen;

  //          <div><label onClick={() => this.register()} className="textAsLink"><strong>Register</strong></label> for new account</div> <br/>
  //       let login = this.props.accessToken===null ? 
  // <div>Web3 <label className="textAsLink" onClick={() => this.loginWithWeb3Signing()}><strong>  Login</strong> </label></div> : null;                                                   
