import React from 'react';
import './../../App.css';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core/';
import {Modal, Backdrop, Fade, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

class ChartCardView extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      page:0,
      modalOpen: false
    }
  }

  imageClicked()
  {
    //console.log("image clicked")
    this.setState({modalOpen: !this.state.modalOpen});
  }
  handleClose()
  {
    //console.log("closing modal")
    this.setState({modalOpen: false});
  }
  // Card style={{  maxWidth:"50%",  background:"#FFFFFF01", margin:"0.1em"}}>
  render () {  
    return <div><Card style={{  maxWidth:"80px",  maxHeight:"150px", background:"#FFFFFF01", margin:"0.1em"}}> 
                    <CardActionArea onClick={()=>this.imageClicked()}>
                            <CardMedia 
                              style={{ width: 300, height: 80 }}
                              component="img" alt={this.props.alt} src={this.props.image} title={this.props.title}/> 
                            <CardContent style={{padding:"0.5vh"}}>
                                <Typography gutterBottom variant="h5" component="h2" style={{ color:"#DDDDDD", fontSize:"1.2vh", height:"100%" }}>
                                {this.props.title}
                                </Typography>
                                <Typography variant="body2" component="p" style={{ color:"#CCCCCC", fontSize:"0.8vh"  }}>
                                {this.props.text}
                                </Typography>
                            </CardContent>
                            </CardActionArea>
                            <CardActions>
                        </CardActions>
                </Card> 
                <Modal aria-labelledby="transition-modal-title"
                       aria-describedby="transition-modal-description"
                       className="centerModalBackground"
                       open={this.state.modalOpen}
                       onClose={()=>this.handleClose()}
                       closeAfterTransition
                       BackdropComponent={Backdrop}
                       BackdropProps={{ timeout: 500, }}>

                      <Zoom in={this.state.modalOpen} onClick={()=>this.handleClose()} className="centerModalImageBackground">
                        <div > 
                          <h2 style={{color:"white", textAlign:"center", paddingTop:"15px"}}>{this.props.title}</h2>
                          <img className="centerModalImage" alt={this.props.alt} src={this.props.image} title={this.props.title} ></img>
                          <h3 style={{color:"white", textAlign:"center", paddingTop:"15px"}}>{this.props.text}</h3>
                        </div>
                      </Zoom>
                </Modal>                 
            </div> 
  }
}

export default ChartCardView;

/*

    <Card style={{  maxWidth:"50%",  background:"#FFFFFF01"}}>
                <CardActionArea>
                   <CardMedia component="img" alt={this.props.alt} height ="500vmax" src={this.props.image} title={this.props.title}/> 
                   <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" style={{ color:"#DDDDDD", fontSize:"1.1rem" }}>
                      {this.props.title}
                    </Typography>
                    <Typography variant="body2" component="p" style={{ color:"#CCCCCC", fontSize:"0.8rem"  }}>
                      {this.props.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
              </CardActions>                    
          </Card>
*/ 