import React from "react";
import ReactDOM from "react-dom";
import ReactTooltip from 'react-tooltip';
//import { geoCylindricalEqualArea } from 'd3-geo-projection';
//import { cylindricalEqualArea } from 'd3-geo-projection';
//import * as d3geo from 'd3-geo-projection';

//import ReactDOM from 'react-dom';
// projections https://github.com/d3/d3-geo-projection 
//import { geoTimes, geoAitoff, geoCylindricalEqualArea } from 'd3-geo-projection';
//import { geoEqualEarth } from 'd3-geo';
//import * as d3  from "d3-geo";
//import { feature } from "topojson-client";
import {
  ComposableMap,
  Geographies, 
  Geography,
  ZoomableGroup,
  Graticule,
  Markers,
  Marker,
  Line,
  Lines 
} from "react-simple-maps";
import './../../App.css';

// globe https://codesandbox.io/s/218wpw3okn
//url('./fonts/Proxima Nova Alt Regular-webfont.eot');
//const geoUrl = "/topojson-maps/world-50m-simplified.json"; //const geoUrl =  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
//const geoUrl =  "/topojson-maps/world-50m-simplified.json";

//const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master//topojson-maps/world-110m.json";
const geoUrl =  "/topojson-maps/world-50m-simplified.json";
function clamp(min, max) {
  return Math.min(Math.max(this, min), max);
}

const supplyLocations = [
  { name: "Tokyo", coordinates: [139.6917, 35.6895], amt: 0, population:37843000, avg: 2 },
]



class SmallWorldMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 2,
      markerScale: 2,
      scale: 500,
      width: 700,
      height: 100,
      center: [10, 45],
      geographies: [],
      marketId: 0
    }

    //this.projection = this.projection.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleMoveStart = this.handleMoveStart.bind(this);
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.refCallback = this.refCallback.bind(this);
    this.refZoomableMap = React.createRef();

    this.getGeoMap = this.getGeoMap.bind(this);
    this.getGeoMap();
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.handleResize();
    this.getData();
    window.addEventListener('resize', this.handleResize);
    this.intervalHandle = setInterval(this.getData, 4678); // every 2s
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.intervalHandle); 
  }

  /*
  shouldComponentUpdate(nextProps, nextState)
  {
    if(nextProps.market.id!==this.state.marketId)
    {
      this.setState({marketId:nextProps.market.id})
      this.getData();
    } 

    return true;
  } */

  UNSAFE_componentWillUpdate() {
    if(this.state.mark)
    {
      if (this.state.height !== this.props.height) {
          this.setState({ height: this.props.height });
      }
      if (this.state.width !== this.props.width) {
        this.setState({ width: this.props.width });
        this.forceUpdate();
      }
    }
  }

  handleResize() {
  }

  handleWheel(e) {
  }
  handleMoveStart(e) {
  }
  handleMoveEnd(e) {
    this.setState({markerScale:e.zoom})
  }
  handleCountryClick(geo) {
    //console.log("Country: ", this.state.geographies[countryIndex]);
    //debugger;
    // var objects = this.state.geographies.objects;
    // var countries;// = undefined;
    // if (objects.ne_110m_admin_0_countries !== undefined) countries = objects.ne_110m_admin_0_countries.geometries;
    // if (objects.ne_50m_admin_0_countries !== undefined) countries = objects.ne_50m_admin_0_countries.geometries;
    // if (objects.ne_10m_admin_0_countries !== undefined) countries = objects.ne_10m_admin_0_countries.geometries;
    if (geo !== undefined) // && geo!=null)
        console.log("Country: ", geo.properties.NAME);
  }
  handleMarkerSupplyClick(location)  { 
    console.log("Marker: ", location); 
    window.appComponent.displayPopupInfo(
        <div className="popup-left">
            <h4>{location.city}</h4>
            <h3>{location.name}</h3> 
            {location.amt} {"kg"} <strong>Available</strong> in {location.cnt} orders<br/> 
            {location.min} {"€"} <strong>Min</strong><br/> 
            {location.max} {"€"} <strong>Max</strong><br/> 
            {location.avg} {"€"} <strong>Avg</strong>
        </div>, "toast-info-popup-supply"); 
  }
  handleMarkerDemandClick(location)  { 
    console.log("Marker: ", location); 
    window.appComponent.displayPopupInfo(
        <div className="popup-left">
            <h4>{location.city}</h4>
            <h3>{location.name}</h3>
            <strong>Requires</strong> {location.amt} {"kg"} <br/>
            {location.cnt} <strong>orders</strong><br/> 
            {location.min} {"€"} <strong>Min</strong><br/> 
            {location.max} {"€"} <strong>Max</strong><br/> 
            {location.avg} {"€"} <strong>Avg</strong>
        </div>, "toast-info-popup-demand"); 
  }

  handleLineClick(city) { console.log("City: ", city.name); }
  async getGeoMap() {
    try {
      let response = await fetch(geoUrl);
      let responseJson = await response.json();
      this.setState({ geographies: responseJson });
      console.log(responseJson); 
    } catch (error) {
      console.error(error);
    }
    this.setState({ supplyLocations: supplyLocations });
  }
  refCallback(element) // this one fires a lot
  {
    console.log("worldMap refCallback", element);
  }

  setSupplyLocations(data) {
    this.setState({supplyLocations:data});
  }
  setDemandLocations(data) {
    this.setState({demandLocations:data});
  }


  getData() {  
    if(this.props.comm!==undefined)
    {
       const latitude = 46.0;
       const longitude = 14.0; 
       const range = 1000;
       this.props.comm.getSupplyLocations(this, this.props.market.id, latitude, longitude, range); 
       this.props.comm.getDemandLocations(this, this.props.market.id, latitude, longitude, range); 
    }
  } 

  render() {
    var geo = this.state.geographies;
    if (geo === undefined || geo.length === 0)
      return null;

    return <div ref={wrapper => (this._wrapper = wrapper)}
                style={{ border: "1px solid black" }}> 

          <ComposableMap>
            <defs>
                <radialGradient id="gradSupply" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                  <stop offset="0%" style={{stopColor:"var(--exchangeColor)", stopOpacity:0.5}} />
                  <stop offset="100%" style={{stopColor:"var(--supplyLocationColor)", stopOpacity:1}} />
                </radialGradient>
            </defs>
            <defs>
                <radialGradient id="gradDemand" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                  <stop offset="0%" style={{stopColor:"var(--exchangeColor)", stopOpacity:0.5}} />
                  <stop offset="100%" style={{stopColor:"var(--demandLocationColor)", stopOpacity:1}} />
                </radialGradient>
            </defs>                           
            <ZoomableGroup zoom={this.props.zoom} center={this.props.center} maxZoom={512} minZoom={0.915} 
                onWheel={(e)=>this.handleWheel(e)}
                onMoveStart={(e)=>this.handleMoveStart(e)} 
                onMoveEnd={(e)=>this.handleMoveEnd(e)} 
               > 
              <Geographies geography={this.state.geographies}>
                {({ geographies, i }) =>
                  geographies.map((geo, y) => (
                    <Geography key={"geo"+ y} 
                               geography={geo} 
                               data-tip="country"
                               style={{
                                default: { fill: "#DCDFE1", outline: "none" },
                                hover: { fill: "#607D8B", outline: "#607DFF" },
                                pressed: { fill: "#FF5722", outline: "none" }
                               }}
                               onClick={() => this.handleCountryClick(geo)}
                    />
                  ))
                }
              </Geographies>
                          
              <Graticule stroke="#A0A0A0A0" fill="transparent" strokeWidth={0.1} strokestyle='#ccc' linewidth={0.1} />

                {this.props.supplyLocations.map((location, i) => (
                  <Marker key={"sup-mrk"+ i} coordinates={location.coordinates} onClick={() => this.handleMarkerSupplyClick(location)}>
                    <circle r={location.amt/(this.state.markerScale*5)} fill="url(#gradSupply)"  stroke="var(--textColorActive)" strokeWidth={0} />
                    {/* <rect x={-0.5} y={-0.5} width={1} height={1} fill="#F3E300" /> */}
                    {/* <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "2px" }}>
                      {city.name}
                    </text> */}
                  </Marker>
                ))}

               {this.props.demandLocations.map((location, i) => (
                  <Marker key={"dmd-mrk"+ i} coordinates={location.coordinates} onClick={() => this.handleMarkerDemandClick(location)}>
                    <circle r={location.amt/(this.state.markerScale*5)} fill="url(#gradDemand)"  stroke="var(--textColorActive)" strokeWidth={0} />
                    {/* <rect x={-0.5} y={-0.5} width={1} height={1} fill="#F3E300" /> */}
                    {/* <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "2px" }}>
                      {city.name}
                    </text> */}
                  </Marker>
                ))}

              {this.props.poiLocations.map((poi, i) => (
                  <Marker key={"dmd-mrk"+ i} coordinates={poi.coordinates} onClick={(e) => this.props.poiClick(e)}>
                    <circle r={poi.amt/2} fill="url(#gradDemand)"  stroke="var(--textColorActive)" strokeWidth={0} />
                    {/* <rect x={-0.5} y={-0.5} width={1} height={1} fill="#F3E300" />   */}
                    <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "0.07vmax" }}>
                      {poi.name}
                    </text> 
                  </Marker>
              ))}
              <Marker key={"you_mrk"} coordinates={this.props.marker} onClick={(e) => this.props.markerClick(e)}>
                        <circle r={0.1} fill="red" stroke="green" strokeWidth={0.5}  /> 
                        <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "1px" }}>
                           {/* {company.ticker} */}
                        </text>
              </Marker>
            </ZoomableGroup>
          </ComposableMap>
      </div>
  }

  getCursorLocation(clientPosX, clientPosY) {
    const zoom = this.state.zoom;

    console.log("Zoom: " + zoom);

    const { width, height } = this.state;
    const projection = this.projection();
    const box = this._wrapper.querySelector("svg").getBoundingClientRect();
    const { top, left } = box;

    const resizeFactorX = box.width / width;
    const resizeFactorY = box.height / height;

    // position cursor as position within width and height of composableMap
    const clientX = (clientPosX - left) / resizeFactorX;
    const clientY = (clientPosY - top) / resizeFactorY;

    const originalCenter = [width / 2, height / 2];

    // position in Composable map that current center has when map is centered
    const currentCenter = projection(this.state.center);
    console.log(currentCenter);

    // compensation in "Composable map units" needed due to being off-center(panned)
    const offsetX = currentCenter[0] - originalCenter[0];
    const offsetY = currentCenter[1] - originalCenter[1];

    console.log("offsetX: " + offsetX + " - offsetY: " + offsetY);

    // position in Composable map that cursor would have been if the map was centered at this zoom level???
    let x = clientX + offsetX;
    let y = clientY + offsetY;

    console.log("Corrected x: " + x + " - Corrected y: " + y);

    const uncompensatedCursor = projection.invert([x, y]);

    const cursor = [
      this.state.center[0] +
      (uncompensatedCursor[0] - this.state.center[0]) / zoom,
      this.state.center[1] +
      (uncompensatedCursor[1] - this.state.center[1]) / zoom
    ];

    return cursor;
  }
}

export default SmallWorldMap;