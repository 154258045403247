//import * as SignalR from '@aspnet/signalr';
//import { throwStatement } from '@babel/types';
import * as SignalR from '@microsoft/signalr';

class CRunicationsLib  {
    constructor(app)
    {
        this.transport = 0;
        this.connection = new SignalR.HubConnectionBuilder().
                              withUrl(window.energonUrl + "dataHub", 
                              { accessTokenFactory: () => { return this.loginToken; }, 
                                transport: this.transport
                              })
                              //.configureLogging(SignalR.LogLevel.Debug)
                              .build();
        this.app = app;
        this.cid = "";
        this.loginToken = "";
        this.debug = true;
    }

    async startCommunication()
    {
        this.connection.start({ withCredentials: false })
        .then(res => {
                console.log("WS Connected");
                this.app.setError("");
                window.clearTimeout(this.timeoutID);
                this.app.setTimeoutID(0);
            })
            .catch(err => { 
               this.app.setError("No connection to server " + err);
               if(this.app.timeoutID===0)
               {
                   this.app.setTimeoutID(window.setTimeout(this.startCommunication(), 5000));
                   //console.error(err);
               }
        });
    }

    async setup() 
    {
        this.startCommunication();

        this.connection.serverOrderedDisconnect = function (value) {
          this.connection.hub.stop();
          this.app.setError("Server ordered disconnect");
          //console.log("Server ordered disconnect");
        };
    
        this.connection.onclose(async () => {
          //console.log("Connection closed");
          this.app.setError("Server ordered disconnect");
          this.app.logout();
          await this.startCommunication();
        });

        //console.log(this.connection);
        //debugger;
        /*
        this.connection.stateChanged(async (state) => {
            var stateConversion = {0: 'connecting', 1: 'connected', 2: 'reconnecting', 4: 'disconnected'};
            this.app.setError("Server state" + stateConversion[state.newState]);      
        });*/
    
        // signalR register methods
        this.connection.on("ReceiveMessage", (user, message)  => { if(this.debug===true) console.log("CR ReceiveMessage", user, message); });

        // CRunication 
        this.connection.on("Direct", (cid, message, data)  => { 
            this.cid = cid; 
            if(this.debug===true) console.log("CR direct", cid, message, data);
            if(message=="DisplayToast") 
               this.app.displayError(data); 

         }); // direct message to client
        this.connection.on("Public", (fromcid, message, data)  => { if(this.debug===true) console.log("CR public", fromcid, message, data); }); // public message over network
        this.connection.on("Anonymous", (message, data)  => { if(this.debug===true) console.log("CR anonymous", message, data); }); // anonymous message
        this.connection.on("Relay", (fromcid, tocid, message, data)  => { if(this.debug===true) console.log("CR relay", fromcid, tocid, message, data); }); // relay message to another id 
        this.connection.on("Store", (tocid, key, value)  => { if(this.debug===true) console.log("CR store", tocid, key, value); }); // store user data at server
        this.connection.on("NetworkStatus", ()  => { if(this.debug===true) console.log("CR NetworkStatus"); }); // get network status 
        //
    
        this.connection.on("Exchanges", (exchangesInfo)  => {  this.app.setExchanges(exchangesInfo);
            if(this.debug===true) console.log("CR Exchanges", exchangesInfo); 
        }); // get all exchanges 

        this.connection.on("Exchange", (exchange)  => {  this.app.setExchange(exchange);
            if(this.debug===true) console.log("CR Exchange", exchange); 
        }); // get one exchanges 

        this.connection.on("AllMarkets", (markets)  => { 
             if(this.debug===true) console.log("CR AllMarkets", markets); 
             this.app.setAllMarkets(markets);
        }); // get all markets 
        this.connection.on("Assets", (assets)  => { this.app.setAssets(assets);
            if(this.debug===true) console.log("CR Assets", assets); 
        }); 

        this.connection.on("Market", (user, message)  => { 
            if(this.debug===true) console.log("CR Market", user, message); 
        }); // new market selected
        this.connection.on("MarketSummary", (summary)  => {  
            this.app.setSummary(summary[0]);
            //if(this.debug===true) console.log("CR MarketSummary", summary); 
        }); // new market selected
        this.connection.on("MarketAsks", (asks)  => {  
            this.app.setAsks(asks[0]);
            //if(this.debug===true) console.log("CR MarketAsks", asks[0]); 
        }); // user created new sell order
        this.connection.on("MarketBids", (bids)  => { 
            this.app.setBids(bids[0]); 
            // if(this.debug===true) console.log("CR MarketBids", bids[0]);   
        }); // user created new buy order


        this.connection.on("MarketTradeBookDiff", (trades)  => { 
            this.app.setTrades(trades[0]);
            //if(this.debug===true) console.log("CR MarketTradeBookDiff", trades);   
        }); // new trade opened

        this.connection.on("MarketTradeBook", (trades)  => { 
            this.app.setTrades(trades);
            if(this.debug===true) console.log("CR MarketTradeBook", trades);   
        }); // new trade opened

        //this.connection.on("MarketOrderbook", (user, message)  => { console.log(user, message);  }); // existing orderbook
        //this.connection.on("MarketTradebook", (user, message)  => { console.log(user, message);  }); // existing tradebook
        //this.connection.on("UserOpenTrades", (user, message)  => { console.log(user, message);  }); // all open trades 
        
        this.connection.on("MarketsStatus", (marketsStatus)  => { 
            //if(this.debug===true) console.log("CR MarketsStatus", marketsStatus); 
            this.app.setMarketsStatus(marketsStatus[0]);  
            // this will receive an array of all markets to be updated with new status data
        }); // user created new sell order

        this.connection.on("MarketOHLC", (data) => { 
            //if(this.debug===true) console.log("CR MarketOHLC", data);   
            this.app.setOHLC(data); 
        });

    
        /*
        this.connection.on("NewSellOrder", (user, message)  => { console.log(user, message); }); // new sell order
        this.connection.on("NewBuyOrder", (user, message)  => { console.log(user, message);  }); // new buy order
        this.connection.on("NewTradeHistory", (user, message)  => { console.log(user, message); }); // new trade happened
        */
        this.connection.on("News", (user, message)  => { console.log(user, message);     });

        this.connection.on("Toast", (text) => { this.app.displayInfo(text.toString()); 
            if(this.debug===true) console.log("CR Toast", text);   
        });  

        this.connection.on("PageInfo", (pageInfo)  => {
            //if(this.debug===true) console.log("CR PageInfo", pageInfo);   
            if(this.debug===true) console.log(pageInfo, this.pageInfoContext);
            if(this.pageInfoContext)
               this.pageInfoContext.setPageInfo(pageInfo);
        }); // n

        this.connection.on("Balances", (data) => {  this.app.setBalances(data);
            if(this.debug===true) console.log("CR Balances", data);   
        }); 
        this.connection.on("Profile", (data) => {  this.app.setProfile(data);
            if(this.debug===true) console.log("CR Profile", data);   
         }); 
        this.connection.on("TradeInfo", (data) => { this.app.setTradeInfo(data); 
            if(this.debug===true) console.log("CR TradeInfo", data);   
        }); 
        this.connection.on("BuyOrders", (data) => { this.app.setBuyOrders(data); 
            if(this.debug===true) console.log("CR BuyOrders", data);   
        });
        this.connection.on("SellOrders", (data) => { this.app.setSellOrders(data); 
            if(this.debug===true) console.log("CR SellOrders", data);   
        }); 

        this.connection.on("CompletedBuys", (data) => { this.app.setCompletedBuys(data); 
            if(this.debug===true) console.log("CR CompletedBuys", data);   
        });
        this.connection.on("CompletedSells", (data) => { this.app.setCompletedSells(data); 
            if(this.debug===true) console.log("CR CompletedSells", data);   
        });

        this.connection.on("OrderState", (data) => { this.app.setOrderState(data); 
            if(this.debug===true) console.log("CR OrderStatus", data);   
        });   


        this.connection.on("CompletedTrades", (data) => { this.app.setCompletedTrades(data); 
            if(this.debug===true) console.log("CR CompletedTrades", data);   
        }); 
        this.connection.on("OpenMarketTrades", (data) => { this.app.setMarketTrades(data); 
            if(this.debug===true) console.log("CR OpenMarketTrades", data);   
        }); 
        this.connection.on("Alerts", (data) => { this.app.setAlerts(data); 
            if(this.debug===true) console.log("CR Alerts", data);   
        }); 
        this.connection.on("Deposits", (data) => { this.app.setDeposits(data); 
            if(this.debug===true) console.log("CR Deposits", data);   
        }); 
        this.connection.on("Withdrawals", (data) => { this.app.setWithdrawals(data); 
            if(this.debug===true) console.log("CR Withdrawals", data);   
        }); 
        this.connection.on("LoginInfo", (data) => { this.app.setLoginInfo(data); 
            if(this.debug===true) console.log("CR LoginInfo", data);   
        }); 
        this.connection.on("LegalInfo", (data) => { this.app.setLegalInfo(data); 
            if(this.debug===true) console.log("CR LegalInfo", data);   
        });

        this.connection.on("CompletedTradeDetail", (data) => { this.app.setCompletedTradeDetail(data); 
            if(this.debug===true) console.log("CR CompletedTradeDetail", data);   
        });

        this.connection.on("CompanyShipment", (data) => { this.app.setCompanyShipment(data); 
            if(this.debug===true) console.log("CR CompanyShipment", data);   
        });
        this.connection.on("DeliverySealed", (data) => { //this.app.setCompanyShipment(data); 
            if(this.debug===true) console.log("CR DeliversSealed", data);   
        });
        this.connection.on("CompanyDelivery", (data) => { this.app.setCompanyDelivery(data); 
            if(this.debug===true) console.log("CR CompanyDelivery", data);   
        });
        
        this.connection.on("CompanyDetail", (data) => { this.app.setCompanyDetail(data); 
            if(this.debug===true) console.log("CR CompanyDetail", data);   
        });
        // clientId
        this.connection.on("ClientCompanyView", (data) => { this.app.setCompanyDetail(data); 
            if(this.debug===true) console.log("CR ClientCompanyView", data);   
        });

        this.connection.on("SellerCompanies", (data) => { this.app.setSellerCompanies(data); 
            if(this.debug===true) console.log("CR SellerCompanies", data);   
        });
        this.connection.on("BuyerCompanies", (data) => { this.app.setBuyerCompanies(data); 
            if(this.debug===true) console.log("CR BuyerCompanies", data);   
        });

        this.connection.on("RegistrationResult", (data) => { 
            if(this.debug===true) console.log("CR RegistrationResult", data);   
            if(this.registerContext)
               this.registerContext.onRegisterComplete(data);
        });

        this.connection.on("SealedDeliveriesInProgress", (data) => {  this.app.setSealedDeliveries(data); 
            if(this.debug===true) console.log("CR SealedDeliveriesInProgress", data);   
        });
        this.connection.on("SealedDeliveriesCompleted", (data) => {  this.app.setSealedDeliveries(data); 
            if(this.debug===true) console.log("CR SealedDeliveriesCompleted", data);   
        });
        this.connection.on("SealedDelivery", (data) => { this.app.setSealedDelivery(data); 
            if(this.debug===true) console.log("CR SealedDelivery", data);   
        });
        this.connection.on("ClientAnalytics", (data) => { this.app.setClientAnalytics(data); 
            if(this.debug===true) console.log("CR ClientAnalytics", data);   
        });
        

        /* Analytics for Markets  */
        this.connection.on("AnalyticsForMarketplace", (data) => { 
            this.app.setMarketplaceAnalytics(data); 
            if(this.debug===true) console.log("CR AnalyticsForMarketplace", data);   
        });
        this.connection.on("AnalyticsForExchange", (data) => { 
            this.app.setMarketplaceAnalytics(data); 
            if(this.debug===true) console.log("CR AnalyticsForExchange", data);   
        });
        this.connection.on("AnalyticsForMarket", (data) => { 
            this.app.setMarketplaceAnalytics(data); 
            if(this.debug===true) console.log("CR AnalyticsForMarket", data);   
        });

        /* Auctions for Markets  */
        this.connection.on("AuctionCreated", (data) => { 
            //this.app.setMarketplaceAnalytics(data); 
            if(this.debug===true) console.log("CR AuctionCreated", data);   
        });
        this.connection.on("AuctionBidCreated", (data) => { 
            //this.app.setMarketplaceAnalytics(data); 
            if(this.debug===true) console.log("CR AuctionBidCreated", data);   
        });

        this.connection.on("Auction", (data) => { 
            this.app.setAuction(data); 
            if(this.debug===true) console.log("CR Auction", data);   
        });

        this.connection.on("AuctionsForMarket", (data) => { 
            this.app.setMarketAuctions(data); 
            if(this.debug===true) console.log("CR AuctionsForMarket", data);   
        });

        this.connection.on("ClientAuctions", (data) => { 
            this.app.setClientAuctions(data); 
            if(this.debug===true) console.log("CR ClientAuctions", data);   
        });

        this.connection.on("GetClientAuctionsForMarket", (data) => { 
            this.app.setClientMarketAuctions(data); 
            if(this.debug===true) console.log("CR GetClientAuctionsForMarket", data);   
        });

        this.connection.on("Transportation", (data) => { 
            this.app.setTransportation(data); 
            if(this.debug===true) console.log("CR Transportation", data);   
        });

        this.connection.on("MarketTransportations", (data) => { 
            this.app.setMarketTransportations(data); 
            if(this.debug===true) console.log("CR MarketTransportations", data);   
        });

        this.connection.on("YourTransportations", (data) => { 
            this.app.setYourTransportations(data); 
            if(this.debug===true) console.log("CR YourTransportations", data);   
        });
        

        this.connection.on("FuelStations", (dataText) => { 
            var data = JSON.parse(dataText);
            this.app.setFuelStations(data); 
            if(this.debug===true) console.log("CR FuelStations", data);   
        });
        //////////////////////////////////////////////////////////////////////////////
        this.connection.on("StoreProductGroups", (data) => { 
            this.searchContext.setProductGroups(data); 
            //if(this.debug===true) 
               console.log("CR StoreProductGroups", data);   
        });
        this.connection.on("StoreSupplierGroups", (data) => { 
            this.searchContext.setSupplierGroups(data); 
            //if(this.debug===true) 
                console.log("CR StoreSupplierGroups", data);   
        });
        this.connection.on("StoreSearchResults", (data) => { 
            this.searchContext.setSearchResults(data); 
            //if(this.debug===true) 
               console.log("CR StoreSearchResults", data);   
            if(this.searchContext)
               this.searchContext.onSearchComplete(data);
        });
        //////////////////////////////////////////////////////////////////////////////

        // supply locations
        this.connection.on("SupplyLocations", (data) => {
            console.log(data);
            if(this.registerContext!=undefined &&  this.registerContext.setSupplyLocations!==undefined)
                this.registerContext.setSupplyLocations(data); 
            //this.app.setSupplyLocations(data); 
            //if(this.debug===true) 
               console.log("CR SupplyLocations", data);   
        });
        this.connection.on("DemandLocations", (data) => {
            console.log(data);
            if(this.registerContext!=undefined &&  this.registerContext.setSupplyLocations!==undefined)
                this.registerContext.setDemandLocations(data); 
            //this.app.setSupplyLocations(data); 
            //if(this.debug===true) 
               console.log("CR DemandLocations", data);   
        });
        //////////////////////////////////////////////////////////////////////////////
        this.connection.on("AmountsPerCountry", (data) => {
            if(this.registerContext!==undefined && this.registerContext.setData!==undefined)
               this.registerContext.setData(data); 
            console.log("CR AmountsPerCountry", data);   
        });

        //////////////////////////////////////////////////////////////////////////////
        
        this.connection.on("DataCategories", (data) => {
            if(this.registerContext!==undefined && this.registerContext.setData!==undefined)
               this.registerContext.setData(data); 
            console.log("CR DataCategories", data);   
        });

        this.connection.on("DataObjectsForMarket", (data) => {
            if(this.registerContext!==undefined && this.registerContext.setDataObjectsForMarket!==undefined)
               this.registerContext.setDataObjectsForMarket(data); 
            console.log("CR DataObjectsForMarket", data);   
        });

        this.connection.on("BuyDataObjectFail", (id,data) => {
            if(this.registerContext!==undefined && this.registerContext.onBuyFail!==undefined)
               this.registerContext.onBuyFail(data);

            //dataObjectView
            console.log("CR BuyDataObjectFail", id,data);   
        });
        //
        this.connection.on("BoughtDataObject", (order) => {
            // this should talk to dataObjectView directly as registerContext is overriden by multiple requests
            /*if(this.registerContext!==undefined && this.registerContext.setDataObjectsForMarket!==undefined)
                this.registerContext.setDataObjectsForMarket(data);  */
            if(this.registerContext!==undefined && this.registerContext.onBuyCompleted!==undefined)
                this.registerContext.onBuyCompleted(order);
             //dataObjectView
            console.log("CR BoughtDataObject", order);   
        });
    }

    displayError(error)
    {
        this.app.setError(error); 
    }

    setToken(token) {
        this.loginToken = token;
        //console.log("Token set", this.loginToken);
    }

    sendData(user,message) {
        var context = this;        
        this.connection.invoke("SendMessage", user, message).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    storeServer(name, account) {
        var context = this;        
        this.connection.invoke("Store", this.cid, name, account).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    setGroup(marketId) {
        var context = this;        
        this.connection.invoke("AddToGroup", marketId+"").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Pages and information from exchange
    getPageInfo(pageToGet, context) {
        //var context = this;        
        this.pageInfoContext = context;
        this.connection.invoke("GetPageInfo", pageToGet).catch(function (err) {
            return console.error(err.toString());
        });
    } 

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Client data 
    getClientBalances() {
        var context = this;        
        this.connection.invoke("GetClientBalances").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientProfile() {
        var context = this;        
        this.connection.invoke("GetClientProfile").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getClientTradeInfo() {
        var context = this;        
        this.connection.invoke("GetClientTradeInfo").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientBuyOrders(page) {
        var context = this;        
        this.connection.invoke("GetClientBuyOrders", page).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientSellOrders(page) {
        var context = this;        
        this.connection.invoke("GetClientSellOrders", page).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientCompletedBuys(page) {
        var context = this;        
        this.connection.invoke("GetClientCompletedBuys", page).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientCompletedSells(page) {
        var context = this;        
        this.connection.invoke("GetClientCompletedSells", page).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientCompletedBuy(tradeId) {
        var context = this;        
        this.connection.invoke("GetCompletedBuy", tradeId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientCompletedSell(tradeId) {
        var context = this;        
        this.connection.invoke("GetCompletedSell", tradeId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientCompletedTrades(page) {
        var context = this;        
        this.connection.invoke("GetClientCompletedTrades", page).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientMarketTrades(page) {
        var context = this;        
        this.connection.invoke("GetClientMarketTrades", page).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Orders
    createNewBuyOrder(marketId, amount, price) {
        var context = this;        
        this.connection.invoke("CreateBuyOrder", marketId, amount, price).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    createNewSellOrder(marketId, amount, price) {
        var context = this;        
        this.connection.invoke("CreateSellOrder", marketId, amount, price).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    cancelSellOrder(orderId) {
        var context = this;        
        this.connection.invoke("CancelSellOrder", orderId.toString()).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    cancelBuyOrder(orderId) {
        var context = this;        
        this.connection.invoke("CancelBuyOrder", orderId.toString()).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getCompanyShipment(companyId) {
        var context = this;        
        this.connection.invoke("GetCompanyShipment", companyId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    requestDeliveryForShipment(companyId, companyShipment){
        var context = this;        
        this.connection.invoke("RequestDeliveryForCompanyShipment", companyId, companyShipment).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getCompanyDelivery(companyId) {
        var context = this;        
        this.connection.invoke("GetCompanyDelivery", companyId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getCompanyDetail(companyId) {
        var context = this;        
        this.connection.invoke("GetCompanyDetail", companyId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getClientCompanyView(clientId) {
        var context = this;        
        this.connection.invoke("GetClientCompanyView", clientId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    

    getBuyerCompanies(id) {
        var context = this;        
        this.connection.invoke("GetBuyerCompanies", id).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getSellerCompanies(id) {
        var context = this;        
        this.connection.invoke("GetSellerCompanies", id).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    
    
    getInProgressSealedDeliveries() {
        var context = this;        
        this.connection.invoke("GetClientInProgressSealedDeliveries").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getCompletedSealedDeliveries() {
        var context = this;        
        this.connection.invoke("GetClientCompletedSealedDeliveries").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getSealedDelivery(id) {
        var context = this;        
        this.connection.invoke("GetClientSealedDelivery", id).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getMarketOHLC(marketId, timestamp) {
        var context = this;        
        this.connection.invoke("GetMarketOHLC", marketId, timestamp).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getSearchGroups(search) {
        this.searchContext = search;
        var context = this;
        this.connection.invoke("GetStoreProductGroups").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
        this.connection.invoke("GetStoreSupplierGroups").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    } 
    newStoreSearch(query, search) {
        this.searchContext = search;
        var context = this;
        this.connection.invoke("NewStoreSearch", query).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    } 

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    newRegistration(client, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientRegistration", client).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    newClientCompany(company, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientCompany", company).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    newClientBank(bank, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientBank", bank).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    newClientCertificationAuthority(certAuthority, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientCertificationAuthority", certAuthority).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    newClientCustomsAuthority(custAuthority, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientCustomsAuthority", custAuthority).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    newClientPlaceOfLoading(placeOfLoading, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientPlaceOfLoading", placeOfLoading).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    newClientTransportationCompany(transportationCompany, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientTransportationCompany", transportationCompany).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    newClientCertificate(certificate, register) {
        this.registerContext = register;
        var context = this;
        this.connection.invoke("NewClientCertificate", certificate).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }  
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    getAnalyticsForMarketplace(register) {
        //debugger;  
        this.registerContext = register;
        var context = this;
        this.connection.invoke("GetAnalyticsForMarketplace").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    /*
       *******************************************************************************************************
       CLIENT ANALYTICS 
    */
   getClientAnalytics() {
        //debugger;  
        var context = this;
        this.connection.invoke("GetClientAnalytics").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    /*
       *******************************************************************************************************
       AUCTIONS 
    */
    createNewAuction(marketId, amount, price, expires)
    {
        var context = this;
        this.connection.invoke("CreateNewAuction",marketId, amount, price, expires).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    createNewBidForAuction(auctionId, price)
    {
        var context = this;
        this.connection.invoke("CreateNewBidForAuction", auctionId,  price).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getAuctionsForMarket(marketId)
    {
        //console.log("getAuctionsForMarket", marketId);
        var context = this;
        this.connection.invoke("GetAuctionsForMarket", marketId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getClientAuctions()
    {
        var context = this;
        this.connection.invoke("GetClientAuctions").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    getAuction(auctionId)
    {
        var context = this;
        this.connection.invoke("GetAuction", auctionId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    
    getClientAuctionsForMarket(marketId)
    {
        var context = this;
        this.connection.invoke("GetClientAuctionsForMarket", marketId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    /*
       END AUCTIONS 
       *******************************************************************************************************
    */

     /*
        *******************************************************************************************************
        Fueling Stations
    */
    getMarketTransportations(marketId) {
        var context = this;
        this.connection.invoke("GetMarketTransportations", marketId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    takeDelivery(deliveryId) {
        var context = this;
        this.connection.invoke("TakeDelivery", deliveryId.toString()).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    updateDeliveryState(deliveryId, newState) {
        var context = this;
        this.connection.invoke("UpdateDeliveryState", deliveryId, newState).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    updateDeliveryStatus(deliveryId, newState) {
        var context = this;
        this.connection.invoke("UpdateDeliveryStatus", deliveryId, newState).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    updateDeliveryCycle(deliveryId, newState) {
        var context = this;
        this.connection.invoke("UpdateDeliveryCycle", deliveryId, newState).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }
    updateDeliveryPosition(deliveryId, longitude, latitude) {
        var context = this;
        this.connection.invoke("UpdateDeliveryPosition", deliveryId, longitude, latitude).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    getYourTransportations(marketId) {
        var context = this;
        this.connection.invoke("GetYourTransportations", marketId).catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
    }

    /*
        *******************************************************************************************************
        Fueling Stations
    */
   getFuelStations(fueling_type) {
    var context = this;
    //debugger;
    this.connection.invoke("GetFuelStations", fueling_type).catch(function (err) {
        context.displayError(err.toString());
        return console.error(err.toString());
    });
   }

   getSupplyLocations(register, marketId, latitude, longitude, range) {
    var context = this;
    this.registerContext = register;
    this.connection.invoke("GetSupplyLocations", marketId, latitude, longitude, range).catch(function (err) {
        context.displayError(err.toString());
        return console.error(err.toString());
    });
   }
   getDemandLocations(register, marketId, latitude, longitude, range) {
    var context = this;
    this.registerContext = register;
    this.connection.invoke("GetDemandLocations", marketId, latitude, longitude, range).catch(function (err) {
        context.displayError(err.toString());
        return console.error(err.toString());
    });
   }

   toggleAutomatedTrader()
   {
        var context = this;
        this.connection.invoke("ToggleSimulatedTrader").catch(function (err) {
            context.displayError(err.toString());
            return console.error(err.toString());
        });
   }


   getCompletedBuysPerCountry(register, marketId) {
    var context = this;
    this.registerContext = register;
    this.connection.invoke("GetCompletedBuysPerCountry", marketId).catch(function (err) {
        return console.error(err.toString());
    });
    }

    
    getDataCategories(register, query) {
        var context = this;
        this.registerContext = register;
        this.connection.invoke("GetDataCategories", query).catch(function (err) {
            return console.error(err.toString());
        });
    }

    getDataObjectsForMarket(register, marketId, categoryId) {
        var context = this;
        this.registerContext = register;
        this.connection.invoke("GetDataObjectsForMarket", marketId, categoryId).catch(function (err) {
            return console.error(err.toString());
        });
    }

    buyDataObject(register, dataObjectView, dataobjectId) {
        var context = this;
        this.registerContext = register;
        this.dataObjectView = dataObjectView;
        this.connection.invoke("BuyDataObject", dataobjectId).catch(function (err) {
            return console.error(err.toString());
        });
    }
}  

export default CRunicationsLib;