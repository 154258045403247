import React from 'react';
import './../App.css';
import { Link } from "react-router-dom";

class BuyerCompanies extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        //console.log("props", this.props);
        this.getData();
    }
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }

    getData() { 
      if(this.props.comm!==undefined)
      {
          this.props.comm.getBuyerCompanies(this.props.match.params.id); 
      }
    }


    render () {
        let data = this.props.companies;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">No data for buyers</h3>

        if(data.length===0) return <h2 className="pageViewScrollable">No buyers to send to</h2>

        return <div className="pageViewScrollable">
                <h2>Buyers</h2>
                Listed here are companies that bought contracts from you. Prepare shipments for contracts for them. 
                <div className="pageNormalViewContents">
                    <hr/>
                    <table className="profileInformationSection balancesSection tableFinancialView"> 
                                <thead>
                                    <tr> 
                                        <th><h3>COMPANY NAME</h3></th>
                                        <th><h3>UNSHIPPED ORDERS</h3></th>
                                    </tr> 
                                </thead>  
                              <tbody>
                                    {data.map(o => 
                                        <tr key={o.clientId}>
                                          <td> <Link to={"/companydeliver/"+o.clientId} >{o.legalName} </Link> </td>
                                          <td> {o.outstandingOrders} </td>
                                        </tr>
                                      )}
                              </tbody>
                            </table>                  
                </div>
               </div>
    }
  }

export default BuyerCompanies;
