import React from "react";
import ReactDOM from "react-dom";
import ReactTooltip from 'react-tooltip';

import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";

//import { geoCylindricalEqualArea } from 'd3-geo-projection';
//import { cylindricalEqualArea } from 'd3-geo-projection';
//import * as d3geo from 'd3-geo-projection';

//import ReactDOM from 'react-dom';
// projections https://github.com/d3/d3-geo-projection 
//import { geoTimes, geoAitoff, geoCylindricalEqualArea } from 'd3-geo-projection';
//import { geoEqualEarth } from 'd3-geo';
//import * as d3  from "d3-geo";
//import { feature } from "topojson-client";
import {
  ComposableMap,
  Geographies, 
  Geography,
  ZoomableGroup,
  Graticule,
  Markers,
  Marker,
  Line,
  Lines 
} from "react-simple-maps";
import './../../App.css';

// globe https://codesandbox.io/s/218wpw3okn
//url('./fonts/Proxima Nova Alt Regular-webfont.eot');
//const geoUrl = "/topojson-maps/world-50m-simplified.json"; //const geoUrl =  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
//const geoUrl =  "/topojson-maps/world-50m-simplified.json";

//const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master//topojson-maps/world-110m.json";
//const geoUrl =  "/topojson-maps/world-50m-simplified.json";
const geoUrl =  "/topojson-maps/world-50m.json";
//const geoUrl =  "/topojson-maps/world-10m.json";
//const geoUrl =  "/topojson-maps/countries-10m.json";
function clamp(min, max) {
  return Math.min(Math.max(this, min), max);
}

const supplyLocations = [
  { name: "Tokyo", coordinates: [139.6917, 35.6895], amt: 0, population:37843000, avg: 2 },
  /*{ name: "Jakarta", coordinates: [106.8650, -6.1751], amt:0, population: 30539000, avg: 2 },
  { name: "Delhi", coordinates: [77.1025, 28.7041], amt:0, population: 24998000, avg: 2 },
  { name: "Manila", coordinates: [120.9842, 14.5995], amt:0, population: 24123000, avg: 2 },
  { name: "Seoul", coordinates: [126.9780, 37.5665], amt:0, population: 23480000, avg: 2 },
  { name: "Shanghai", coordinates: [121.4737, 31.2304], amt:0, population: 23416000, avg: 2 },
  { name: "Karachi", coordinates: [67.0099, 24.8615], amt:0, population: 22123000, avg: 2 },
  { name: "Beijing", coordinates: [116.4074, 39.9042], amt:0, population: 21009000, avg: 2 },
  { name: "New York", coordinates: [-74.0059, 40.7128], amt:0, population: 20630000, avg: 2 },
  { name: "Guangzhou", coordinates: [113.2644, 23.1291], amt:0, population: 20597000 , avg: 2},
  { name: "Sao Paulo", coordinates: [-46.6333, -23.5505], amt:0, population: 20365000, avg: 2 },
  { name: "Mexico City", coordinates: [-99.1332, 19.4326], amt:0, population: 20063000 , avg: 2},
  { name: "Mumbai", coordinates: [72.8777, 19.0760], amt:0, population: 17712000, avg: 2 },
  { name: "Osaka", coordinates: [135.5022, 34.6937], amt:0, population: 17444000, avg: 2 },
  { name: "Moscow", coordinates: [37.6173, 55.7558], amt:0, population: 16170000, avg: 2},
  { name: "Dhaka", coordinates: [90.4125, 23.8103], amt:0, population: 15669000, avg: 2 },
  { name: "Greater Cairo", coordinates: [31.2357, 30.0444], amt:0, population: 15600000, avg: 2 },
  { name: "Los Angeles", coordinates: [-118.2437, 34.0522], amt:0, population: 15058000, avg: 2 },
  { name: "Bangkok", coordinates: [100.5018, 13.7563], amt:0, population: 14998000, avg: 2 },
  { name: "Kolkata", coordinates: [88.3639, 22.5726], amt:0, population: 14667000, avg: 2 },
  { name: "Buenos Aires", coordinates: [-58.3816, -34.6037], amt:0, population: 14122000, avg: 2 },
  { name: "Tehran", coordinates: [51.3890, 35.6892], amt:0, population: 13532000, avg: 2 },
  { name: "Istanbul", coordinates: [28.9784, 41.0082], amt:0, population: 13287000, avg: 2 },
  { name: "Lagos", coordinates: [3.3792, 6.5244], amt:0, population: 13123000, avg: 2 },
  { name: "Shenzhen", coordinates: [114.0579, 22.5431], amt:0, population: 12084000, avg: 2 },
  { name: "Rio de Janeiro", coordinates: [-43.1729, -22.9068], amt:0, population: 11727000, avg: 2},
  { name: "Kinshasa", coordinates: [15.2663, -4.4419], amt:0, population: 11587000, avg: 2 },
  { name: "Tianjin", coordinates: [117.3616, 39.3434], amt:0, population: 10920000, avg: 2 },
  { name: "Paris", coordinates: [2.3522, 48.8566], amt:0, population: 10858000, avg: 2 },
  { name: "Lima", coordinates: [-77.0428, -12.0464], amt:0, population: 10750000, avg: 2 }, */
]



class WorldMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 2,
      markerScale: 2,
      scale: 500,
      width: 700,
      height: 100,
      center: [10, 45],
      supplyLocations: [],
      demandLocations: [],
      geographies: [],
      marketId: 0,
      colorScale: scaleLinear()
                      .domain([0.29, 0.68])
                      .range(["#ffedea", "#ff5233"])    
    }

    //this.projection = this.projection.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleMoveStart = this.handleMoveStart.bind(this);
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.refCallback = this.refCallback.bind(this);
    this.refZoomableMap = React.createRef();

    this.getGeoMap = this.getGeoMap.bind(this);
    this.getGeoMap();
    this.getData = this.getData.bind(this);

  }
  componentDidMount() {
    this.handleResize();
    this.getData();
    window.addEventListener('resize', this.handleResize);
    this.intervalHandle = setInterval(this.getData, 4678); // every 2s
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.intervalHandle); 
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    if(nextProps.market.id!==this.state.marketId)
    {
      this.setState({marketId:nextProps.market.id})
      this.getData();
      //console.log(nextProps, nextState);
    } 

    return true;
  } 

  UNSAFE_componentWillUpdate() {
    if(this.state.mark)
    if (this.state.height !== this.props.height) {
        this.setState({ height: this.props.height });
    }
    if (this.state.width !== this.props.width) {
      this.setState({ width: this.props.width });
      this.forceUpdate();
    }
  }

  handleResize() {
    /*console.log(this._refMap);
    console.log(this._wrapper);
    if(this._refMap===undefined) return;
    //const box = this._wrapper.querySelector("svg").getBoundingClientRect();
    const box = this._wrapper.getBoundingClientRect();
    //console.log(box);

    this.setState({height: box.height});
    this.setState({width: box.width});
    //this.setState({ zoom: this.state.zoom, center: this.state.center });
    //var width = svg.getBoundingClientRect();

    //this.setState({scale: width / MAP_MAX_WIDTH});
    this.forceUpdate();
    this._refMap.forceUpdate();*/
  }
  /* handleScroll(event) 
   {
     const { deltaY } = event;
     const clampedDeltaY = clamp(-50, 50, deltaY);
     const newZoom = this.state.zoom * (1 + (clampedDeltaY / 200));
     const clampedNewZoom = clamp(1, 10, newZoom);
 
     this.setState({zoom: clampedNewZoom});
     console.log("scroll: ", this.state.zoom);
   } */
  handleWheel2(event) {
    event.stopPropagation();
    var oldZoom = this.state.zoom;
    const zoomDirectionFactor = event.deltaY > 0 ? -1 : 1;
    var newZoom = oldZoom + zoomDirectionFactor; // Set new zoom level
    console.log("zoom ", this.state.zoom);
    // Ignore nonsens
    if (newZoom > 18 || newZoom < 0.00015) return;
    // Set new center so to location under the cursor in the new map is the same as the location under the cursor in the old map
    const cursor = this.getCursorLocation(event.clientX, event.clientY);
    const oldCenter = this.state.center;
    const newCenter = [
      oldCenter[0] +
      ((cursor[0] - oldCenter[0]) / newZoom) * zoomDirectionFactor,
      oldCenter[1] +
      ((cursor[1] - oldCenter[1]) / newZoom) * zoomDirectionFactor
    ];
    this.setState({ zoom: newZoom, center: newCenter });
  }
  handleWheel(e) {
    //console.log("wheel", e)
    //this.setState({ center: newCenter/*, bypassClick: true*/ }); //console.log(this.state.center);
  }
  handleMoveStart(e) {
    //console.log("start", e)
    //this.setState({ center: newCenter/*, bypassClick: true*/ }); //console.log(this.state.center);
  }
  handleMoveEnd(e) {
    //console.log("end", e)
    this.setState({markerScale:e.zoom})
    //debugger;
    //this.setState({center: newCenter /*bypassClick: JSON.stringify(newCenter) !== JSON.stringify(this.state.center) */ });
    
    //console.log(this.state.center);
  }
  handleCountryClick(geo) {
    //console.log("Country: ", this.state.geographies[countryIndex]);
    //debugger;
    // var objects = this.state.geographies.objects;
    // var countries;// = undefined;
    // if (objects.ne_110m_admin_0_countries !== undefined) countries = objects.ne_110m_admin_0_countries.geometries;
    // if (objects.ne_50m_admin_0_countries !== undefined) countries = objects.ne_50m_admin_0_countries.geometries;
    // if (objects.ne_10m_admin_0_countries !== undefined) countries = objects.ne_10m_admin_0_countries.geometries;
    if (geo !== undefined) // && geo!=null)
        console.log("Country: ", geo.properties.NAME);
  }
  handleMarkerSupplyClick(location)  { 
    console.log("Marker: ", location); 
    window.appComponent.displayPopupInfo(
        <div className="popup-left">
            <h4>{location.city}</h4>
            <h3>{location.name}</h3> 
            {location.amt} {"kg"} <strong>Available</strong> in {location.cnt} orders<br/> 
            {location.min} {"€"} <strong>Min</strong><br/> 
            {location.max} {"€"} <strong>Max</strong><br/> 
            {location.avg} {"€"} <strong>Avg</strong>
        </div>, "toast-info-popup-supply"); 
  }
  handleMarkerDemandClick(location)  { 
    console.log("Marker: ", location); 
    window.appComponent.displayPopupInfo(
        <div className="popup-left">
            <h4>{location.city}</h4>
            <h3>{location.name}</h3>
            <strong>Requires</strong> {location.amt} {"kg"} <br/>
            {location.cnt} <strong>orders</strong><br/> 
            {location.min} {"€"} <strong>Min</strong><br/> 
            {location.max} {"€"} <strong>Max</strong><br/> 
            {location.avg} {"€"} <strong>Avg</strong>
        </div>, "toast-info-popup-demand"); 
  }

  handleLineClick(city) { console.log("City: ", city.name); }
  async getGeoMap() {
    try {
      let response = await fetch(geoUrl);
      let responseJson = await response.json();
      this.setState({ geographies: responseJson });
      console.log(responseJson); 
    } catch (error) {
      console.error(error);
    }
    this.setState({ supplyLocations: supplyLocations });
  }
  /*projection() {
    return geoCylindricalEqualArea()
          .translate([this.props.width / 2, this.props.height / 2])
          .scale(this.state.scale);
  }*/
  refCallback(element) // this one fires a lot
  {
    console.log("worldMap refCallback", element);
    /*
    if (element) {
      //this.props.getSize(element.getBoundingClientRect());
      const box = element.getBoundingClientRect();
      console.log("box", box);
      //this.setState({width : box.width});
      //this.setState({height : box.height});
    }*/
  }

  setSupplyLocations(data) {
    //console.log("setSupplyLocations")
    this.setState({supplyLocations:data});
  }
  setDemandLocations(data) {
    //console.log("setSupplyLocations")
    this.setState({demandLocations:data});
  }


  getData() {  
    if(this.props.comm!==undefined)
    {
       const latitude = 46.0;
       const longitude = 14.0; 
       const range = 1000;
       //console.log("Get SupplyLocation ");
       this.props.comm.getSupplyLocations(this, this.props.market.id, latitude, longitude, range); 
       this.props.comm.getDemandLocations(this, this.props.market.id, latitude, longitude, range); 
       //this.props.comm.getSupplyLocations(marketId, latitude, longitude, range); 
    }
    //debugger;   
    
  } 


  render() {
    var geo = this.state.geographies;
    if (geo === undefined || geo.length === 0)
      return null;

    return <div ref={wrapper => (this._wrapper = wrapper)}
                // onWheel={(e) => this.handleWheel(e)}
                style={{ border: "1px solid black" }}> 

          {/* <ReactTooltip /> */}
          {/* projection="geoCylindricalEqualArea">  */}
          <ComposableMap>
            <defs>
                <radialGradient id="gradSupply" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                  <stop offset="0%" style={{stopColor:"var(--exchangeColor)", stopOpacity:0.5}} />
                  <stop offset="100%" style={{stopColor:"var(--supplyLocationColor)", stopOpacity:1}} />
                </radialGradient>
            </defs>
            <defs>
                <radialGradient id="gradDemand" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                  <stop offset="0%" style={{stopColor:"var(--exchangeColor)", stopOpacity:0.5}} />
                  <stop offset="100%" style={{stopColor:"var(--demandLocationColor)", stopOpacity:1}} />
                </radialGradient>
            </defs>                           
            <ZoomableGroup zoom={this.state.zoom} center={this.state.center} maxZoom={512} minZoom={0.915} 
                onWheel={(e)=>this.handleWheel(e)}
                onMoveStart={(e)=>this.handleMoveStart(e)} 
                onMoveEnd={(e)=>this.handleMoveEnd(e)} 
               > 

              <Graticule stroke="#A0A0A0F0" fill="transparent" strokeWidth={0.1} strokestyle='#ccc' linewidth={0.1} />

              <Geographies geography={this.state.geographies}>
                {({ geographies, i }) =>
                  geographies.map((geo, y) => (
                    <Geography key={"geo"+ y} 
                               geography={geo} 
                               data-tip="country"
                               style={{
                                default: { fill: "#DCDFE1", outline: "none" },
                                hover: { fill: "#607D8B", outline: "#FFB6C1" },
                                pressed: { fill: "#FF5722", outline: "#607DFF" }
                               }}
                               //  const d = data.find((s) => s.ISO3 === geo.properties.ISO_A3);
                               // fill={d ? this.state.colorScale(d["2017"]) : "#F5F4F6"}
                               onClick={() => this.handleCountryClick(geo)}
                    />
                  ))
                }
              </Geographies>
                          
              

                {this.state.supplyLocations.map((location, i) => (
                  <Marker key={"sup-mrk"+ i} coordinates={location.coordinates} onClick={() => this.handleMarkerSupplyClick(location)}>
                    <circle r={location.amt/(this.state.markerScale*21)} fill="url(#gradSupply)"  stroke="var(--textColorActive)" strokeWidth={0} />
                    {/* <rect x={-0.5} y={-0.5} width={1} height={1} fill="#F3E300" /> */}
                    {/* <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "2px" }}>
                      {city.name}
                    </text> */}
                  </Marker>
                ))}

               {this.state.demandLocations.map((location, i) => (
                  <Marker key={"dmd-mrk"+ i} coordinates={location.coordinates} onClick={() => this.handleMarkerDemandClick(location)}>
                    <circle r={location.amt/(this.state.markerScale*21)} fill="url(#gradDemand)"  stroke="var(--textColorActive)" strokeWidth={0} />
                    {/* <rect x={-0.5} y={-0.5} width={1} height={1} fill="#F3E300" /> */}
                    {/* <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "var(--logoInnerColor)", fontSize: "2px" }}>
                      {city.name}
                    </text> */}
                  </Marker>
                ))}

                {/* {this.state.supplyLocations.map((city, i) =>
                  <Line key={"line"+i} 
                              from={city.coordinates}
                              to={[14.5058, 46.0569]}
                              strokeLinecap="round"
                              strokeWidth={1 / this.state.zoom}
                              stroke= "var(--exchangeHighliteColor)" 
                              style={{
                                default: { stroke: "#335722", fill: "#225799", strokewidth: 0.01 / this.state.zoom },
                                hover: { stroke: "#1157FF", fill: "#1157FF" },
                                pressed: { stroke: "#2257FF", fill: "#2257FF" }
                              }}
                              onClick={() => this.handleLineClick(city)}
                  />
                )} */}

            </ZoomableGroup>
          </ComposableMap>
{/* 
          <ComposableMap ref={refMap => (this._refMap = refMap)}
                         //projection={this.projection}
                         //projectionConfig={this.state.scale}
                         projection="geoCylindricalEqualArea" //{this.projection} 
                         //projectionConfig={{
                         //    rotate: [-20.0, -52.0, 0],
                         //    scale: this.state.scale,
                         //    center: this.state.center 
                         //}}
                         height={this.props.height}
                         width={this.props.width}
                         id='composableMap'
                         style={{ 
                          width: '100%',
                          height: 'auto'
                         }}>

            <ZoomableGroup zoom={this.state.zoom} center={this.state.center}
                           onMoveStart={this.handleMoveStart}
                           onMoveEnd={this.handleMoveEnd}>
              <Geographies geography={this.state.geographies} showcenter>
                          {(geographies, projection) =>
                            geographies.map(
                              (geography, i) =>
                                geography.id !== "ATA" && (
                                  <Geography
                                    key={i}
                                    data-tip="country"
                                    geography={geography}
                                    projection={projection}
                                    style={{
                                      default: { fill: "#DCDFE1", outline: "none" },
                                      hover: { fill: "#607D8B", outline: "none" },
                                      pressed: { fill: "#FF5722", outline: "none" }
                                    }}
                                    onClick={() => this.handleCountryClick(i)}
                                  />
                                )
                            )
                          }
              </Geographies> 

               <Markers>
                    {this.state.cities.map((city, i) => (
                      <Marker key={i} marker={{ coordinates: city.coordinates }}>
                        <circle r={city.totalAmount / 3000000} fill="#F00" stroke="#fff" strokeWidth={1} />
                        <rect x={-2} y={-2} width={2} height={2} fill="#3E3" />
                        <text textAnchor="middle" style={{ fontFamily: "system-ui", fill: "#5D5A6D", size: "12" }}>
                          {city.name}
                        </text>
                      </Marker>
                    ))}
              </Markers>   

              <Graticule stroke="#A0A0A0A0" fill="transparent" strokeWidth={0.01} strokeStyle='#ccc' lineWidth={0.01} />

              <Lines>
                {this.state.cities.map((city, i) =>
                  <Line key={i} line={{
                    coordinates: {
                      start: city.coordinates,
                      end: [14.5058, 46.0569]
                    },
                    curveStyle: "forceDown"
                  }}
                    tabable={true}
                    preserveMarkerAspect={false}
                    style={{
                      default: {
                        stroke: "#FF5722",
                        fill: "#FF5722",
                        strokeWidth: 2 / this.state.zoom
                      },
                      hover: { stroke: "#FF5722", fill: "#FF5722" },
                      pressed: { stroke: "#FF5722", fill: "#FF5722" }
                    }}
                    onClick={() => this.handleLineClick(city)}
                  />
                )}
              </Lines> 
            </ZoomableGroup>
          </ComposableMap>
           */}
      </div>
  }

  getCursorLocation(clientPosX, clientPosY) {
    const zoom = this.state.zoom;

    console.log("Zoom: " + zoom);

    const { width, height } = this.state;
    const projection = this.projection();
    const box = this._wrapper.querySelector("svg").getBoundingClientRect();
    const { top, left } = box;

    const resizeFactorX = box.width / width;
    const resizeFactorY = box.height / height;

    // position cursor as position within width and height of composableMap
    const clientX = (clientPosX - left) / resizeFactorX;
    const clientY = (clientPosY - top) / resizeFactorY;

    const originalCenter = [width / 2, height / 2];

    // position in Composable map that current center has when map is centered
    const currentCenter = projection(this.state.center);
    console.log(currentCenter);

    // compensation in "Composable map units" needed due to being off-center(panned)
    const offsetX = currentCenter[0] - originalCenter[0];
    const offsetY = currentCenter[1] - originalCenter[1];

    console.log("offsetX: " + offsetX + " - offsetY: " + offsetY);

    // position in Composable map that cursor would have been if the map was centered at this zoom level???
    let x = clientX + offsetX;
    let y = clientY + offsetY;

    console.log("Corrected x: " + x + " - Corrected y: " + y);

    const uncompensatedCursor = projection.invert([x, y]);

    const cursor = [
      this.state.center[0] +
      (uncompensatedCursor[0] - this.state.center[0]) / zoom,
      this.state.center[1] +
      (uncompensatedCursor[1] - this.state.center[1]) / zoom
    ];

    return cursor;
  }
}

export default WorldMap;
/*
            <g className="markers">
                    {cities.map((city, i) => (
                        <circle
                            key={ `marker-${i}` }
                            cx={ this.projection(city.coordinates)[0] }
                            cy={ this.projection(city.coordinates)[1] }
                            r={ city.totalAmount / 3000000 }
                            fill="#E91E63"
                            stroke="#FFFFFF"
                            className="marker"
                            onClick={ () => this.handleMarkerClick(i) }
                        />
                    ))}
                />
            </g>
            */
/*
              /*
              ({ geographies, projection }) =>
                geographies.map( geo=> {})
                geographies.map(geo => (
                <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#9998A3"
                    stroke="#EAEAEC"
                />
                ))*/

/*
let geographies = this.state.geographies;

        return (
            <svg width={ 800 } height={ 450 } viewBox="0 0 800 450">
            <g className="countries">
                {
                geographies.map((d,i) => (
                    <path
                    key={ `path-${ i }` }
                    d={ geoPath().projection(projection)(d) }
                    className="country"
                    fill={ `rgba(38,50,56,${ 1 / geographies.length * i})` }
                    stroke="#FFFFFF"
                    strokeWidth={ 0.5 }
                    onClick={ () => this.handleCountryClick(i) }
                    />
                ))
                }
            </g>
            <g className="markers">
                {
                cities.map((city, i) => (
                    <circle
                    key={ `marker-${i}` }
                    cx={ projection(city.coordinates)[0] }
                    cy={ projection(city.coordinates)[1] }
                    r={ city.totalAmount / 3000000 }
                    fill="#E91E63"
                    stroke="#FFFFFF"
                    className="marker"
                    onClick={ () => this.handleMarkerClick(i) }
                    />
                ))
                }
            </g>
            </svg>
        )
*/