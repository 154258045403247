import React from 'react';
//import './../../App.css';

class ClientSupportView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
           balance:"",
        }
      }
  
    componentDidMount() { 
    }
    componentDidUpdate(prevProps) {
         if(this.state.info==="")
         {
         }
    } 
    setBalances(balances) {
        this.setState({balance:balances});
    }
    render () {
        return <div className="pageViewScrollable">
                  <div className="pageNormalViewContents">
                     <h1>SUPPORT</h1>
                  </div>
               </div>
    }
  }

  export default ClientSupportView;