import React from 'react';
import './../../App.css';
  
class TransportationContract extends React.Component {
   
    constructor(props){
        super(props)
        this.state = {
          status: null,
          buyerImporter: 'Importer (Buyer) company',
          buyerBank: 'Buyer bank',
          buyerShipping: 'Buyer shipping company',
          buyerAuthority: 'Buyer Customs Authority',
          buyerCertification: 'Buyer Certification Authority',
          buyerPlaceOfEntry: 'Place Of Entry',
          buyerInsurance: 'Buyer Insurance Company',

          sellerExporter: 'Exporter (seller) company',
          sellerBank: 'Seller bank',
          sellerAuthority: 'Seller Customs Authority',
          sellerCertification: 'Seller Certification Authority',
          sellerPlaceOfLoading: 'Place Of Loading',
          sellerInsurance: 'Seller Insurance Company',

          sellerSignDate: '26.6.2019 11:32:05',
          buyerSignDate: '26.6.2019 12:20:29',
        }
        this.getData = this.getData.bind(this);
    }
  
    componentDidMount() { 
        console.log("ContractView", this.props.location.search);

        if(this.props.account!==undefined && this.props.account!==null) {
           this.getData();
           this.intervalHandle = setInterval(this.getData, 1000); // every 2s
        }
    }
    componentWillUnmount() { clearInterval(this.intervalHandle); }
    componentDidUpdate(prevProps) {} 
    getData() {  /*this.props.comm.getClientBalances();*/ }
    
    handleChange(event) { }
    handleEntering(event) { }

    setOpen(t)  { this.setState({open:t})  }
    
    handleCancel() { /*setOpen(false);*/ }
    handleClose() { this.setOpen(false); }
    handleOpen()  { /*setOpen(true);*/  }

    render () {
        //if(this.props.balances===undefined || this.props.balances===null) return <h3 className="pageViewScrollable">No input data</h3>
        return <div className="pageViewScrollable">
                  <div className="pageViewContents">
                  <h1>Transportation Contract</h1>
                  Configure your routes and prefered delivery methods<br/>

                     <div> 
                         <table className="contractsSection w3-bordered">
                             <thead>
                                 <tr>
                                     <th>Importer</th>
                                     <th>Exporter</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                    <td> {this.state.buyerImporter}</td>  
                                    <td> {this.state.sellerExporter}</td>                                    
                                 </tr>
                                 <tr>
                                    <td> <strong>Buyer Bank</strong></td>  
                                    <td> <strong>Seller Bank</strong></td>                                    
                                 </tr>
                                 <tr>
                                    <td> {this.state.buyerBank}</td>  
                                    <td> {this.state.sellerBank}</td>                                    
                                 </tr>
                                 <tr>
                                    <td> <strong>Buyer Authority</strong></td>  
                                    <td> <strong>Seller Authority</strong></td>                                    
                                 </tr>
                                 <tr>
                                    <td> {this.state.buyerAuthority}</td>  
                                    <td> {this.state.sellerAuthority}</td>                                    
                                 </tr>
                                 <tr>
                                    <td> <strong>Buyer Insurance Company</strong></td>  
                                    <td> <strong>Seller Insurance Company</strong></td>                                    
                                 </tr>
                                 <tr>
                                    <td> {this.state.buyerInsurance}</td>  
                                    <td> {this.state.sellerInsurance}</td>                                    
                                 </tr>                                 
                                 <tr>
                                    <td> <strong>Place of Entry</strong></td>  
                                    <td> <strong>Place of Loading</strong></td>                                    
                                 </tr>
                                 <tr>
                                    <td> {this.state.buyerPlaceOfEntry}</td>  
                                    <td> {this.state.sellerPlaceOfLoading}</td>                                    
                                 </tr>
                                 <tr>
                                    <td> <strong>Buyer Bought Date</strong></td>  
                                    <td> <strong>Seller Sold Date</strong></td>                                    
                                 </tr>
                                 <tr>
                                    <td> {this.state.buyerSignDate}</td>  
                                    <td> {this.state.sellerSignDate}</td>                                    
                                 </tr>

                                </tbody>
                         </table>
                     </div>
                     
                  </div>
               </div>
    }
  }

  export default TransportationContract;
