import React from 'react';
import './../App.css';
/*
const buyOrders1 = 
[
   {
    id:0, 
    price:"0.00000040",
    amount:"10.92500000",
    total:"0.01198397"
   },
   { 
    id:1,
    price:"0.00000400",
    amount:"20.92500000",
    total:"0.03198397"
   },
   { 
    id:2,
    price:"0.00004000",
    amount:"30.92500000",
    total:"0.10198397"
   }
];
*/
/*
const buyOrders = 
[
   {
    id:0, 
    price: "0.00000000",
    amount:"0.00000000",
    total: "0.00000000"
   }
];
 */
//const sellOrders = buyOrders;


class BuyOrders extends React.Component {
  handleOnClick(order) {
    //console.log('Buy order was clicked:' + order.price);
    this.props.app.setClickBuyOrder(order);
  }

  render() {
    let orders = this.props.orders;
    if(!Array.isArray(orders)) return null;

    return  orders.map(t =>
      <tr key={t.price} className="orderBuyItem" onClick={() => this.handleOnClick(t)}>
        <td className="header-text-left">{t.price}</td>
        <td className="header-text-right">{t.total}</td> 
        <td className="header-text-center">{t.amount}</td>
      </tr>) 
  }
}

class OrderbookViewBids extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }

    renderHeader() {
      return  <tr className="orderHeader">
                <th className="header-left">PRICE</th> 
                <th className="header-center">AMOUNT</th> 
                <th className="header-right">TOTAL</th>
              </tr>
    }   
    //
    render () {
        return <div className="viewOrderbookBuys">
                   <div className="tableHeader">BUY ORDERS</div>
                      <table>
                        <thead>
                          {this.renderHeader()}
                        </thead>     
                        <tbody>
                          {<BuyOrders orders={this.props.bids} app={this.props.app}/>}
                        </tbody>
                      </table>
                   </div>
    }
}

export default OrderbookViewBids;
