import React from 'react';
import './../App.css';
/*
const buyOrders1 = 
[
   {
    id:0, 
    price:"0.00000040",
    amount:"10.92500000",
    total:"0.01198397"
   },
   { 
    id:1,
    price:"0.00000400",
    amount:"20.92500000",
    total:"0.03198397"
   },
   { 
    id:2,
    price:"0.00004000",
    amount:"30.92500000",
    total:"0.10198397"
   }
];
*/
/*
const buyOrders = 
[
   {
    id:0, 
    price: "0.00000000",
    amount:"0.00000000",
    total: "0.00000000"
   }
];
 */
//const sellOrders = buyOrders;

class SellOrders extends React.Component {
  handleOnClick(order) {
    //console.log('Sell order was clicked:' + order.price);
    this.props.app.setClickSellOrder(order);
  }
  render() {
    let orders = this.props.orders;
    if(!Array.isArray(orders)) return null;
    
    return  orders.map(t =>
      <tr key={t.price} className="orderSellItem" onClick={() => this.handleOnClick(t)}>
            <td className="header-text-left">{t.price}</td>
            <td className="header-text-right">{t.total}</td> 
            <td className="header-text-center">{t.amount}</td>
      </tr>) 
  }
}

class OrderbookViewAsks extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }

    renderHeader() {
      return  <tr className="orderHeader">
                <th className="header-left">PRICE</th> 
                <th className="header-right">TOTAL</th>
                <th className="header-center">AMOUNT</th> 
              </tr>
    }   
    //
    render () {
        return <div className="viewOrderbookSells">
                  <div className="tableHeader">SELL ORDERS</div> 
                     <table>
                        <thead>
                          {this.renderHeader()}
                        </thead>     
                        <tbody>
                          <SellOrders orders={this.props.asks} app={this.props.app}/>
                        </tbody>
                      </table>

                </div>

    }
}
export default OrderbookViewAsks;
