import React from 'react';

class ClientProfileLimitsView extends React.Component {
  constructor(props){
     super(props)
     this.state = {
     }
  }
  render()
  {
    return <div>
            <h3>LIMITS</h3>
            <div>Note: you may only have one profile per location. If you have more than one location, you need to link them under one profile.</div>
           </div>
  }
}

export default ClientProfileLimitsView;