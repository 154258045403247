import React from 'react';
//import './../../App.css';
import { Link } from "react-router-dom";
import * as enums from "./DeliveryEnums.js"; 


class SealedDeliveriesInProgress extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        this.getData();
    }
    componentWillUnmount() {
    }

    getData() { 
      if(this.props.comm!==undefined)
      {
          this.props.comm.getInProgressSealedDeliveries(this.props.match.params.id); 
      }
    }


    render () {
        let data = this.props.sealedDeliveries;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Waiting for data for deliveries in progress</h3>
        if(data.length===0) return <h2 className="pageViewScrollable">No Deliveries In Progress</h2>
        //let o = data.order;
        //if(o===null || o===undefined) return <h3 className="pageViewScrollable">No trade detail</h3>

        return <div className="pageViewScrollable">
                <h2>Deliveries In Progress</h2>
                View Statuses of Incoming Shipments 
                <div className="pageNormalViewContents">
                    <hr/>
                    <table className="profileInformationSection balancesSection tableFinancialView"> 
                                <thead>
                                    <tr>
                                        <th><strong>NAME</strong></th> 
                                        <th><strong>FROM</strong></th>
                                        <th><strong>TO</strong></th>
                                        <th><strong>TOTAL</strong></th>
                                        <th><strong>CYCLE</strong></th>
                                        <th><strong>STATE</strong></th>
                                        <th><strong>STATUS</strong></th>
                                    </tr> 
                                </thead>  
                              <tbody>
                                    {data.map(o => 
                                        <tr key={o.id}>
                                          <td>  {this.props.markets[o.marketId-1].name} </td>
                                          <td> <Link to={"/delivery/view/"+o.id} >{o.sellerExporter.legalName} </Link> </td>
                                          <td> <Link to={"/delivery/view/"+o.id} >{o.buyerImporter.legalName} </Link> </td>
                                          <td> {o.total} </td>
                                          <td> {o.cycle}{enums.CycleType[o.cycle]}</td>
                                          <td> {o.state}{enums.DeliveryState[o.state]} </td>
                                          <td> {o.status}{enums.StatusType[o.status]} </td>
                                        </tr>
                                      )}
                              </tbody>
                            </table>                  
                </div>
               </div>
    }
  }

export default SealedDeliveriesInProgress;
