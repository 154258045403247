import React from 'react';
import './../App.css';
import MarketSummary from './View_MarketSummary.js';
import MarketsAvailable from './View_MarketsAvailable.js';


class MarketsView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
    }
    selectMarket(market)
    {
        //this.props.app.setMarket(market);
        this.props.exchangeView.selectMarket(market);
    }      
    render () {
        let allMarkets = this.props.allMarkets;
        let markets = this.props.markets;
        let market = this.props.market;
        //let marketSummary = this.props.app.state.summary;
        //let summary = markets.length>0 ? <MarketSummary market={market} summary={marketSummary}/> : null;
        //let summary = <MarketSummary market={market} summary={marketSummary}/>;

        return <div className="marketsInfo">
                    {/* {summary} */}
                    <MarketsAvailable markets={markets} marketsView={this} market={market} allMarkets={allMarkets}/>
               </div>
    }
}

export default MarketsView;