import React from 'react';
import './../../App.css';

import { Stepper, Step, StepLabel, StepContent, Typography, Button, Paper } from '@material-ui/core/';

const deliverySteps = [ 
   { id:0,
     text:'Request Letter Of Credit', 
     content:'Exporter bank creates and sends Letter Of Credit, when received request Inspection',
     actor:'Exporter Bank',
     player:'Exporter Company'
   }, 
   { id:3,
     text:'Request Inspect Goods', 
     content:'After inspection is completed Certification Authority will issue and send required Certificates',
     actor:'Exporter Certification Authority',
     player:'Exporter Company'
   },
   { id:6,
     text:'Issue Bill Of Lading', 
     content:'Transportation Company will receive it and load cargo',
     actor:'Transportation Company',
     player:'Exporter Company'
   }, 
   { id:7,
     text:'Transporting to Place Of Loading', 
     content:'Cargo is being transported to a Place of Loading',
     actor:'Exporter Company',
     player:'Transportation Company'
   }, 
   { id:8,
     text:'Confirmed shipment loading', 
     content:'Cargo is loading at Place of Loading',
     actor:'Exporter Company',
     player:'Transportation Company'
   }, 
   { id:9,
     text:'Confirmed shipment received', 
     content:'Cargo arrived at Place of Entry',
     actor:'Exporter Company',
     player:'Transportation Company'
   }, 
   { id:10,
     text:'Request Inspect Goods', 
     content:'Request from Exporter Company at Place of Entry for Customs Authority',
     actor:'Customs Authority',
     player:'Exporter Company'
   }, 
   { id:11,
     text:'Pay fees to Customs Authority', 
     content:'Exporter company',
     actor:'Customs Authority',
     player:'Exporter Company'
   }, 
   { id:12,
     text:'Transporting to Importer company', 
     content:'Please wait while cargo is transported',
     actor:'Exporter Company',
     player:'Transportation Company'
   },
   { id:13,
     text:'Cargo Arrival Confirmed', 
     content:'Transfering funds to Exporter Company and Transportation Company',
     actor:'Exporter company, Transportation Company',
     player:'Importer Company'
   }
];

class DeliveryDetail extends React.Component {
  
   constructor(props){
       super(props)
       this.state = {
          activeStep:0
       }
       this.getData = this.getData.bind(this);
   }
 
   componentDidMount() { 
       if(this.props.account!==undefined && this.props.account!==null) {
          this.getData();
          this.intervalHandle = setInterval(this.getData, 5000); // every 2s
       }
   }
   componentWillUnmount() { clearInterval(this.intervalHandle); }
   componentDidUpdate(prevProps) {} 
   getData() {  /*this.props.comm.getClientBalances(); */ }

   handleNext() {
      this.setState({activeStep: this.state.activeStep + 1});
   }
  
   handleBack() {
      this.setState({activeStep: this.state.activeStep - 1});
   }
  
   handleReset()  {
      this.setState({activeStep: 0});
   }
   

   render () {
       //if(this.props.balances===undefined || this.props.balances===null) return <h3 className="pageViewScrollable">No input data</h3>
       let data = deliverySteps;
       return <div>
                 <div style={{width:"100%"}}>
                    <Stepper activeStep={this.state.activeStep} orientation="vertical" style={{backgroundColor:'#ffffff11', color:"#DDDDDD"}}>
                    {data.map((s, index) => (
                        <Step key={s.id}>
                           <StepLabel color="primary" style={{textAlign:"left", color:"#DDDDDD" }}>{s.text}</StepLabel>

                           <StepContent>
                              <Typography style={{textAlign:"left", color:"#DDDDDD"}}>{s.content}</Typography>
                              <div className="{classes.actionsContainer}">

                                 <div style={{textAlign:"right", color:"#DDDDDD"}}>
                                 
                                    

                                    <Button color="primary" disabled={this.state.activeStep === 0} onClick={()=>this.handleBack()} className="{classes.button}">
                                       Back
                                    </Button>

                                    <Button variant="contained" color="primary" onClick={()=>this.handleNext()} className="{classes.button}"> 
                                          {this.state.activeStep === data.length - 1 ? 'Finish' : 'Next'}
                                    </Button>

                                    <Typography style={{textAlign:"right", color:"#AAAAAA"}}>{s.player} => {s.actor}</Typography>
                                 </div>
                              </div>
                           </StepContent>

                        </Step>
                    ))}

                    </Stepper>

                        {this.state.activeStep === data.length && (
                        <Paper square elevation={0} className="{classes.resetContainer}" style={{textAlign:"center", backgroundColor:'#ffffff11', color:"#DDDDDD"}}>
                           <Typography>Delivery has been succesfully completed</Typography>
                           <Button onClick={()=>this.handleReset()} className="{classes.button}" style={{ color:"#FFDDDD" }}>
                              Reset
                           </Button>
                        </Paper>
                        )}

                 </div>
              </div>
   }
 }

 export default DeliveryDetail;