import React from 'react';
import './../../App.css';
import DeliveryDetail from './Deliver_Detail.js';

class DeliveryDashboard extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
           status:null
        }
        this.getData = this.getData.bind(this);
    }
  
    componentDidMount() { 
        if(this.props.account!==undefined && this.props.account!==null) {
           this.getData();
           this.intervalHandle = setInterval(this.getData, 1000); // every 2s
        }
    }
    componentWillUnmount() { clearInterval(this.intervalHandle); }
    componentDidUpdate(prevProps) {} 
    getData() {  this.props.comm.getClientBalances(); }
    

    render () {
        //if(this.props.balances===undefined || this.props.balances===null) return <h3 className="pageViewScrollable">No input data</h3>
        return <div className="pageViewScrollable">
                  <div className="pageNormalViewContents">

                  <h1>Delivery</h1>
                     Status of Delivery

                     <DeliveryDetail />

                  </div>
               </div>
    }
  }

  export default DeliveryDashboard;