import React from 'react';
import './../App.css';
import { Link } from "react-router-dom";
import { formatMoney } from './helpers/Formating';
class ContractShipmentFooter extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
      }

    }

    render () {
        let data = this.props.shipment; //
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <table className="balancesSection tableFinancialView fixed">
                        <thead>
                            <tr>
                              <th># Contracts</th>
                              <th>Total quantity</th>
                              <th>SubTotal</th>
                              <th>TOTAL</th>
                              <th>Fees</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td style={{textAlign:'right'}}>{data.contracts.length}</td>
                              <td style={{textAlign:'right'}}>{data.quantity}</td>
                              <td style={{textAlign:'right'}}>{data.subTotal}</td>
                              <td style={{textAlign:'right'}}><strong>{formatMoney(data.total)}</strong></td>
                              <td style={{textAlign:'right'}}>{data.fees}</td>
                            </tr>
                        </tbody>
                    </table>
    }
  }

export default ContractShipmentFooter;
