import React from 'react';
//import './../../App.css';
import { Link } from "react-router-dom";

class ClientCompletedTradeDetail extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        //console.log("props", this.props);
        this.getData();
        //console.log("props", this.props.match.params);
        //console.log("type", this.props.match.params.type);
        //console.log("id", this.props.match.params.id);
        //this.intervalHandle = setInterval(this.getData, 42000); // every 50s
        //if(this.props.tradeInfo!=null)
        //   this.setState({numPages:Math.ceil(this.props.tradeInfo.completedBuys/25)});             
    }
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }

    getData() { 
      if(this.props.comm!==undefined)
      {
         if(this.props.match.params.type==="buy")
           this.props.comm.getClientCompletedBuy(this.props.match.params.id); 
         if(this.props.match.params.type==="sell")
           this.props.comm.getClientCompletedSell(this.props.match.params.id); 
      }
    }


    render () {
        let data = this.props.tradeDetail;
        
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">No data</h3>
        let o = data.order;
        if(o===null || o===undefined) return <h3 className="pageViewScrollable">No trade detail</h3>

        return <div className="pageViewScrollable">
                <h2>Trade Details</h2>
                <div className="pageNormalViewContents">

                    <h3>{data.description}</h3>
                    <table className="profileInformationSection"> 
                                <thead>
                                    <tr> 
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr> 
                                </thead>                       
                              <tbody>
                                    <tr>
                                        <td>From</td>
                                        <td><strong><Link to={"/companyclientview/"+ o.boughtFromClientId} >{data.otherPartyName} </Link></strong></td>
                                    </tr>
                                    <tr><td>Amount </td><td><strong>{o.amount} of {this.props.assets[o.assetId-1].symbol}</strong></td></tr>
                                    <tr><td>Price </td><td><strong>{o.price} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</strong></td></tr>
                                    <tr><td>Fee </td><td><strong>{o.fee} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</strong></td></tr>
                                    <tr><td>Total </td><td><strong>{o.total} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</strong></td></tr>
                                    <tr><td>Processed </td><td><strong>{o.processed===0 ? "No" : "Yes"}</strong></td></tr>
                                    <tr><td>Date </td><td><strong>{new Date(o.created).toLocaleString()} </strong></td></tr>
                              </tbody>
                    </table>
                    <h4><Link to={"/companyshipment/"+ o.boughtFromClientId} > View open shipment </Link></h4>
                    <h4><Link to={"/companydeliver/"+ o.soldToClientId} > View open delivery </Link></h4>
                </div>
               </div>
    }
  }

  export default ClientCompletedTradeDetail;
/*
  <Link to={"/companydetail/"+ o.id} >{data.otherPartyName} </Link>
  {this.props.markets[o.marketId-1].name}
  {this.props.assets[o.assetId-1].symbol}
*/