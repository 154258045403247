import React from 'react';
import './../../App.css';
import {
   Container,
   Grid,
   makeStyles
 } from '@material-ui/core';

import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import MoneyIcon from '@material-ui/icons/Money';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import DashboardCard from './dashboard/DashboardCard';
import DashboardPercentageCard from './dashboard/DashboardPercentageCard';
import DashboardProgressCard from './dashboard/DashboardProgressCard';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

 const useStyles = makeStyles((theme) => ({
   root: {
     backgroundColor: theme.palette.background.dark,
     minHeight: '100%',
     paddingBottom: theme.spacing(3),
     paddingTop: theme.spacing(3)
   }
 }));

class AnalysisDashboard extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
           status:null
        }
        this.getData = this.getData.bind(this);
    }
  
    componentDidMount() { 
        //if(this.props.account!==undefined && this.props.account!==null) 
        {
           this.getData();
           this.intervalHandle = setInterval(this.getData, 2000); // every 2s
        }
    }
    componentWillUnmount() { clearInterval(this.intervalHandle); }
    componentDidUpdate(prevProps) {} 
    getData() {  
      console.log("Getting data");
      if(this.props.comm!==undefined)
         this.props.comm.getClientAnalytics(); 
    }
    

    render () { 
       // clientAnalytics 
       var ca = this.props.clientAnalytics;
       if(ca===null || ca===undefined) return <div className="pageViewScrollable"><h3>Waiting for data</h3></div>;

       var peopleIcon = <PeopleIcon />;
       var moneyIcon = <MoneyIcon />;
       var attachMoneyIcon = <AttachMoneyIcon />;
       var chartIcon = <InsertChartIcon />;
        //if(this.props.balances===undefined || this.props.balances===null) return <h3 className="pageViewScrollable">No input data</h3>
        return <div className="pageViewScrollable">
                  <h1>Analysis</h1>
                  <div className="pageNormalViewContents">
                     Analysis of your activities.
     <Container maxWidth="xl">
           {/* <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardPercentageCard title="TOTAL ORDERS" amount={ca.totalOrders} percentage={ca.totalOrdersSinceLastMonth} caption="Since last month" icon={peopleIcon}/>
           </Grid>
           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardProgressCard title="PROGRESS" amount="42" percentage={42} caption="Since last month" icon={moneyIcon}/>
           </Grid> */}
         <Grid container spacing={2}>
           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="COSTUMERS" amount={ca.costumers} percentage={ca.numSales} caption="Number of sales" icon={peopleIcon} />
           </Grid>

           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="SALES" amount={ca.sales} percentage={ca.salesSinceLastMonth} caption="Since last month" />
           </Grid>

         {/* </Grid>

         <Grid container spacing={2}> */}
           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="VENDORS" amount={ca.vendors} percentage={ca.numBoughts} caption="Number of buys" icon={peopleIcon}/>
           </Grid>

           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="EXPENSES" amount={ca.expenses} percentage={ca.expensesSinceLastMonth} caption="Since last month" />
           </Grid>           
         </Grid>

         <Grid container spacing={2}>
           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="DELIVERIES" amount={ca.deliveriesInProgress} percentage={ca.deliveriesInProgressSinceLastMonth} caption="Since last month" icon={chartIcon}/>
           </Grid>

           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="COMPLETED" amount={ca.deliveries} percentage={ca.deliveriesSinceLastMonth} caption="Since last month" />
           </Grid>
         </Grid>

         <Grid container spacing={2}>
           <Grid item lg={3} sm={6} xl={4} xs={12} >
               <DashboardCard title="AUCTIONS" amount={ca.auctions} percentage={ca.auctionsSinceLastMonth} caption="Since last month" icon={chartIcon}/>
           </Grid>
           <Grid item lg={3} sm={6} xl={4} xs={12} >
               <DashboardCard title="AUCTIONS BIDS" amount={ca.auctionBids} percentage={ca.auctionBidsSinceLastMonth} caption="Since last month" />
           </Grid>
         </Grid>

         <Grid container spacing={2}>
           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="SELL" amount={ca.sellOrdersInProgress} percentage={ca.sellOrdersInProgress} caption="In progress" />
           </Grid>             
           <Grid item lg={3} sm={6} xl={4} xs={12} >
              <DashboardCard title="BUY" amount={ca.buyOrdersInProgress} percentage={ca.buyOrdersInProgress} caption="In progress" />
           </Grid>             
         </Grid>

     </Container>
                  </div>
               </div>
    }
  }

  export default AnalysisDashboard;
/*
actions: 0
actionsSinceLastMonth: 0
auctionBids: 0
auctionBidsSinceLastMonth: 0
auctions: 0
auctionsSinceLastMonth: 0
buyOrdersInProgress: 0
costumers: 0
deliveries: 0
deliveriesInProgress: 0
deliveriesInProgressSinceLastMonth: 0
deliveriesSinceLastMonth: 0
expenses: 4228
expensesSinceLastMonth: 4228
numBoughts: 1
numSales: 0
sales: 0
salesSinceLastMonth: 4228
sellOrdersInProgress: 0
totalOrders: 0
totalOrdersSinceLastMonth: 0
vendors: 1*/