import React from 'react';
//import './../../App.css';
import { Link } from "react-router-dom";
import ContractHeader from "./../Contract_Header.js";

import ContractShipmentHeader  from "./../Contract_ShipmentHeader.js";
import ContractShipmentContracts from "./../Contract_ShipmentContracts.js";
//import ContractOrders from "./../Contract_Orders.js";
import ContractShipmentFooter  from "./../Contract_ShipmentFooter.js";

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class SealedDelivery extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        sealedDelivery:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
       this.getData();
       this.intervalHandle = setInterval(this.getData, 5000); // every 11s
    }
    componentDidUpdate(prevProps) {
      if(this.props.sealedDelivery !== null && this.state.sealedDelivery===null)
      {
         this.setState({sealedDelivery:this.props.sealedDelivery});
      }
    } 

    componentWillUnmount() {
       clearInterval(this.intervalHandle); 
    }

    requestDelivery(fromClientId) {
        if(this.props.comm!==undefined)
        {
            this.props.comm.requestDeliveryForShipment(fromClientId, this.state.shipment); 
        }  
    }

    getData() { 
      if(this.props.comm!==undefined)
      {
          this.props.comm.getSealedDelivery(this.props.match.params.id); 
      }
    }

    // createPdf = (html) => Doc.createPdf(html);


    render () {
        let data = this.state.sealedDelivery;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents">
                    <h2>Delivery</h2>

                    <Accordion>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}> 
                            <Typography>
                                Buyer:  <strong>{data.buyerImporter.legalName}</strong> &nbsp; 
                                Seller: <strong>{data.sellerExporter.legalName}</strong> 
                                {/* <Link to={"/companyview/" + data.buyerImporter.id} >👁 </Link><Link to={"/companyview/" + data.sellerExporter.id}>👁 </Link> */}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ContractShipmentHeader shipment={data} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}> 
                               <Typography>View Contracts ({data.contracts.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ContractShipmentContracts contracts={data.contracts} markets={this.props.markets} assets={this.props.assets} />

                                <ContractShipmentFooter shipment={data} />
                            </AccordionDetails>
                    </Accordion>

                    
                    <hr />                    
                </div>
               </div>
    }
  }

export default SealedDelivery;
