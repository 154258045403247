import React from 'react';
import './../App.css';
/*
const trades = 
[
  { 
    id:0,
    date:"2019-07-05",
    time:"11:34:59",
    type:"Buy",
    price:"2.10000000",
    amount:"299.92500000",
    total:"627.01198397"
   }
] */

/*class Trade extends React.Component {
  render() {
    let trade = this.props.trade;
    let trade = t.type==="Buy";
    return  <td className="textLeft">{t.date} {t.time}</td>
            <td className="textRight">{t.price}</td> 
            <td className="textRight">{t.amount}</td> 
            <td className="textRight">{t.total}</td> 
   }  */
  /*render() {
    let t = this.props.trade;
    return <label>{t.date} {t.time} {t.type} {t.price} {t.amount} {t.total} </label>
    // 2019-07-05 11:34:59	Buy	0.00000040	29959.92500000	0.01198397
  }*/
//}

class Trades extends React.Component {
  render() {
    let trades = this.props.trades;
    if(trades===null) return null; 
    
    return  trades.map(t =>
      <tr key={t.timestamp+""+t.id} className={t.tradeType===0 ? "orderBuyItem" : "orderSellItem"}  >
          <td className="textCenter">{t.price}</td> 
          <td className="textRight">{t.amount}</td> 
          <td className="textLeft">{new Date(t.timestamp).toLocaleTimeString("de-DE")} </td>
      </tr>
      ) 
  }  
}

class TradebookView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }

      renderHeader() {
        return <tr className="orderHeader">
                   <th className="textCenter">PRICE</th>
                   <th className="textCenter">AMOUNT</th>
                   <th className="textLeft">TIME</th>
               </tr>
      }       

      render () {
        let trades = this.props.trades;
        return <div className="viewTradebook">
               <div className="tableHeader">TRADEBOOK</div>
               <table >
                 <thead>
                    {this.renderHeader()}
                 </thead>
                 <tbody>
                  <Trades trades={trades}/>
                </tbody>     
               </table>
               </div>
    }
}

export default TradebookView;