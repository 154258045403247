import React from 'react';
import { Link } from "react-router-dom";
import './../../App.css';

class Pager extends React.Component {
    async setPage(p) { this.props.component.setPage(p); }
    nextPage()       { this.props.component.nextPage(); }
    prevPage()       { this.props.component.prevPage(); }
    firstPage()      { this.props.component.firstPage(); }
    lastPage()       { this.props.component.lastPage(); }

    render () {
        let data = this.props.component;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">component in pager missing</h3>

        return <div>
                     <hr/>         
                     Page {this.props.component.state.page} / {this.props.component.state.numPages} <br/>
                     <Link to="#" onClick={()=>this.firstPage()}> first </Link>
                     <Link to="#" onClick={()=>this.prevPage()}> prev </Link>
                     <Link to="#" onClick={()=>this.nextPage()}> next </Link>
                     <Link to="#" onClick={()=>this.lastPage()}> last </Link>
               </div>
    }
  }

  export default Pager;