import React from 'react';
import './../../App.css';

import { Link } from "react-router-dom";
import {Grid, Card, CardHeader, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core/';
import AccountSelector from "./AccountSelector.js";

class RegisterMarketplaceAccountSelector extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      page:0
    }
  }

  render () {
    return <div>
              <Grid container spacing={0} direction="row" alignItems="flex-start" justify="center">
                <AccountSelector title="Producer" image="./img/liquid-heart.png"
                                 subheader="Register as producer"
                                 subheaderLink="/register/producer"
                                 text="Producer creates hydrogen and sells it on the market"
                                 />
                <AccountSelector title="Distributor" image="./img/liquid-drops.png"
                                 subheader="Register as distributor" 
                                 subheaderLink="/register/distributor"
                                 text="Distributor delivers Hydrogen From A to B, using different means of transportation."
                                 />
                <AccountSelector title="Consumer" image="./img/liquid-c.png"
                                 subheader="Register as consumer"
                                 subheaderLink="/register/consumer"
                                 text="Consumer buys hydrogen for consumption and not for resale or commercial purpose." 
                                 />
                <AccountSelector title="Analytics" image="./img/water-splat.png"
                                 subheader="Register as data user" 
                                 subheaderLink="/register/analyticsprovider"
                                 text="Analytics users consume data from the platform and perform analysis for their costumers."
                                 />
                <AccountSelector title="Provider" image="./img/water-pin.png"
                                 subheader="Register as data provider" 
                                 subheaderLink="/register/dataprovider"
                                 text="Data providers supply data to the platform. Anyone can become a data provider by following the guidelines."  
                                 /> 
            </Grid>
           </div>
  }
}

export default RegisterMarketplaceAccountSelector;