import React from 'react';
import './../App.css';
import Markdown from 'markdown-to-jsx';

class PageView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:"",
        }
      }
  
    componentDidMount() { 
        //console.log("PageView", this.props.location.search);
        this.getData();
    }
    componentDidUpdate(prevProps) {
         if(this.props.location.search !== prevProps.location.search || this.state.info==="")
         {
            //console.log("PageViewRefresh", this.props);
            this.getData();
         }
    } 
    getData()
    {
      this.props.comm.getPageInfo(this.props.location.search.substr(1), this);
    }
    setPageInfo(md) {
        this.setState({info:md});
    }
    render () {
  
        return <div className="pageViewScrollable">
                  <div className="pageViewContents">
                        <Markdown>{this.state.info}</Markdown>
                  </div>
                  {this.props.location.search.substr(1)}<br/>
               </div>
    }
  }

  export default PageView;