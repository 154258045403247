import React from 'react';
import { Link } from "react-router-dom";
// import ReactToPrint from 'react-to-print';

import '../../App.css';
import { InitialMenuSelector } from './../project/InitialView';

//import {Card, CardActionArea, CardActions, CardContent, CardMedia, CardHeader, Collapse, Papar, Typography, Button, Grid } from '@material-ui/core/';
import { Grid } from '@material-ui/core/';

import Chart from 'react-apexcharts';
import NewsCardView from '../Small/NewsCardView.js'; 
import StaticMarketAnalysis from '../Analysis/StaticMarketAnalysis.js';   
//import Modal from './node_modules/react';

const goGlobal = 
[
  {
    id: 0,
    title: "Secure",
    description: "Secure Trading platform for Hydrogen supported with next level technology for Hydrogen supply and demand, backed by secure invoicing, smart contracts, certified vendors.",
    image: ""
  },
  // {
  //   id: 1,
  //   title: "Renewable",
  //   description: "Energy supply was controllable and demand did not need to be flexible. As share of wind and solare renewable nergy increases, supply becoms less controllable and demand needs to become flexible.",
  //   image: ""
  // },
  {
    id: 2,
    title: "Markets",
    description: "Available spot markets for 'Green', 'Gray' and 'Blue' hydrogen. Later we will support short, medium and long-term contracts with pegged prices and swap operations.",
    image: ""
  },
  {
    id: 3,
    title: "Purity",
    description: "Standard purity products in gaseous or liquid form with basic environmental certificates issued by certification facility",
    image: ""
  },
  {
    id: 4,
    title: "Green",
    description: "'Green' hydrogen produced from green renewable sources, ie. electricity wind, solar, hydro or any other technology defined in EU as carbon free process.",
    image: ""
  },
  {
    id: 5,
    title: "Gray",
    description: "'Gray' hydrogen produced are produced from fossil stock without sequestration but have basic environmental certificates issued by certification facility.",
    image: ""
  },
  {
    id: 6,
    title: "Blue",
    description: "'Blue' hydrogen produced with sequestration in various forms but certified.",
    image: ""
  },
  {
    id: 7,
    title: "Certificates",
    description: "Exchange will trade complementary gray, blue, or green certificates as paradigm demands associated to hydrogen linked products. For example certified carbon free produced electricity employed in hydrogen electrolysis or linked processes that bound clean energy supply chain to be efficient.",
    image: ""
  },
  {
    id: 8,
    title: "Connect",
    description: "Seamlessly connecting Hydrogen producers with Consumers and Trading Demand network.",
    image: ""
  },  
  {
    id: 9,
    title: "Integration",
    description: "Offer functional integration for Hydrogen producers and consumer where network enables value creation together for people and companies on an energy transition avenue",
    image: ""
  },
  {
    id: 10,
    title: "Extensible",
    description: "Solutions for grid balancing, energy storage and production of green hydrogen for transport, renewable heat and chemicals will be supported as expansion to the platform.",
    image:""
  },
  {
    id: 11,
    title: "Partnerships",
    description: "Additional functionality can be incorporated by 3rd parties on protocol level or through API support.",
    image:""
  },
  {
    id: 12,
    title: "Soft cost",
    description: "The entry will be as frictionless as legally permitted by EU regulations and soft costs are absolutely minimized",
    image: ""
  },
  {
    id: 13,
    title: "Made for users",
    description: "Asset builders, service providers and technology creators will find secure platform husbanding certified vendors an efficient value creation Environment.",
    image: ""
  },
  {
    id: 14,
    title: "Onboarding",
    description: "Users will be guided seamlessly to quickly and easily join a platform and begin participating in the value formation that platform facilitates",
    image: ""
  },
  {
    id: 15,
    title: "Licensed",
    description: "Platform based on production certification on EU level and will be European Union fully licensed entity.", 
    image:""
  }
];


const rolesOfDemand = 
[
  {
    id: 0,
    title: "New Era",
    description: "We have entered the period in which renewable energy system will transform society at unparalleled speed.",
    image: ""
  }, 
  {
    id: 1,
    title: "Flexible",
    description: "Energy supply was controllable and demand did not need to be flexible. As share of wind and solar renewable energy increases, supply becomes less controllable and demand needs to become more flexible.",
    image: ""
  },
  {
    id: 2,
    title: "Tools",
    description: "As flexibility of supply and demand increases, so will trading tools simplify, become more convenient and mature.",
    image: ""
  }, 
  {
    id: 3,
    title: "Drive",
    description: "Growth of renewable energy will bring benefits and new jobs in sector",
    image: ""
  }
];

const workingOn = 
[
  {
    id: 0,
    title: "Tech meets Energy",
    description: "With the help of blockchain technology, we are working for a market to novelize energy market approaches, rules for local electricity prices, and market integration of distributed renewable energy resources.",
    image: ""
  }, 
  {
    id: 1,
    title: "Times, they are changing",
    description: "Recently, high-profile business and political events have repeatedly underlined the importance of hydrogen utilization for the energy transition. ",
    image: ""
  },
  {
    id: 2,
    title: "We'll take it higher",
    description: "We are building the best solutions to use blockchain for ancillary services. Foresee the use of excess energy to produce hydrogen and make it broadly available.",
    image: ""
  }
];


class GoGlobalHydrogen extends React.Component {
  render() {
   let data = this.props.data;
   let title = this.props.title;
    return <div>
           <h1>{title}</h1>
            <table className="profileInformationSection" style={{ width:"85%" }}>
              <thead>
                <tr >
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(o => (
                    <tr key={o.id} >
                      <td><h3 style={{ verticalAlign:"top", textAlign:"right", margin:"1rem" }}>{o.title} </h3> </td>
                      <td  style={{ verticalAlign:"center" }}> {o.description}</td>
                    </tr>
                  )
                )}
                </tbody>
            </table>
          </div>
  }
}


const weBelieve = 
[
  {
    id: 0,
    title: "A clean energy",
    description: "Advantages of hydrogen as an energy source lies in the fact that its combustion does not emit carbon dioxide (CO2), the main cause of the greenhouse effect.",
    image: ""
  },
  {
    id: 1,
    title: "Pure Zero Emission",
    description: "Possiblity to obtain hydrogen itself from water by electrolysis where only by-product of this process is pure oxygen. Clean energy from hydrogen has no polluting by-products and zero emissions.",
    image: ""
  },  
  {
    id: 2,
    title: "It's storage",
    description: "Hydrogen is very versatile energy carrier, capable of absorbing energy; a reason why it may solve one of the main problems related to energy management: its storage.",
    image:""
  },
  {
    id: 3,
    title: "Availability",
    description: "The availability of hydrogen is almost unlimited; it exists in several compounds and its molecule has highest density of energy per weight.",
    image:""
  },
  {
    id: 4,
    title: "An ally of renewable energy sources",
    description: "According to experts from the Hydrogen Council approximately 23% of our energy production comes from renewable sources and this amount will grow to 68% by 2050. The efficiency of solar- or wind-powered plants depends on the variable presence of sun and wind, with considerable discrepancies between energy production and its demand: for example in Europe, during the winter, just when the need is higher, the production of solar energy drops by 60%.Thanks to its capacity to store energy, hydrogen could be used in integrated renewable source systems to recover excess energy, which would otherwise be wasted, and make it available as and when required.",
    image:""
  },
  {
    id: 5,
    title: "Environmental impact",
    description: "The environmental impact of using fossil fuels and growing demand for energy make it essential that we find renewable and clean sources of energy.  ",
    image:""
  },
  {
    id: 6,
    title: "Produce and Store",
    description: "The most widely available element in the universe, Hydrogen is a crucial element in solving our planet's energy needs and environment. Can easly be produced, efficiently stored and safely transported.", 
    image:""
  },
  {
    id: 7,
    title: "Transportation",
    description: "Current methods permit hydrogen to be stored as a gas in high-pressure tanks, as a liquid in cryogenic containers, and as a solid in combination with other elements (metal hydrides).", 
    image:""
  }
  
]

const whyHydrogenTemplate = 
[
  {
    id:0, 
    name: "Density (g/L)",
    rpi:"813",
    methane:"422",
    hydrogen:"70"
   },
   {
    id:1, 
    name: "O : F Ratio (as used)",
    rpi:"2.7 : 1",
    methane:"3.7 : 1",
    hydrogen:"6 : 1"
   },
   {
    id:2, 
    name: "1 liter of LOX : X Liter of Fuel",
    rpi:"1 : 0.52",
    methane:"1 : 0.73",
    hydrogen:"1 : 2.7"
   },
   {
    id:3, 
    name: "Efficiency (s)",
    rpi:"370s",
    methane:"459s",
    hydrogen:"532s"
   },
   {
    id:4, 
    name: "Combustion Temp (K)",
    rpi:"3670",
    methane:"3550",
    hydrogen:"3070"
   },
   {
    id:5, 
    name: "Boiling Point",
    rpi:"490",
    methane:"111",
    hydrogen:"20"
   },
   {
    id:6, 
    name: "Combustion Byproducts",
    rpi:"CO₂ & H₂O (icky stuff)",
    methane:"CO₂ & H₂O",
    hydrogen:"H₂O"
   },
   {
    id:7, 
    name: "Manufacturable on Mars?",
    rpi:"No",
    methane:"Yes",
    hydrogen:"Yes"
   }
];
class ProjectPlan extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h1>Plan</h1>
             <p>EnergonX is a Marketplace where producers and consumers ranging from household to large enterprises can buy and sell hydrogen. It's engineered from ground up with security, efficiency, speed and scalability in mind, built to high standards. Our current and unique geo-located matching trading engine is capable of 7.000 orders per second.  Right now parts of it are centralized, while the transaction  parts use blockchain and are fully ready for tokenization. Our plan is to go toward a fully permissionless trustless system using distributed ledger technology.
             </p>
             <p>Second segment of the marketplace is information relevant to transportation companies with "data as a service" access  to the transportation (logistics) sector. Built-in automated supply chain tracking will first support the EU area (within EU Customs Union) and later to be expanded to fully support all principal modes of global logistics. Users will have a choice to decide whether their deliveries are managed by EnergonX marketplace or choose their preferred transportation company that are users of the platform.  
             </p>
             <p>Guarantees of Origin will be required and tracked with complete public traceability. Later we will add trustless issuance of  Guarantees Of Origin and Certificates, they will be multi signed by trusted validators . Certificates authorized by authorization authorities will be signed by certification validators and made available with complete public traceability adding transparency and reducing costs.  This comes as a third segment of the marketplace and still needs to be developed.
             </p>
             <p>Understanding our users' needs is crucial in the energy market. Support across the entire user base will be provided directly by builders of the system. Language support will be built-in, primary release will be English only and then extended to EU languages and later to Chinese, Japanese, Korean and Arabic languages with more to be added later.
             </p>
              <p>Develop platform planned features rollout:</p>
                <ul>
                <li>Geolocated Marketplace For Green, Blue, Gray Hydrogen</li>
                <li>Access to Transportation Data For Transportation Companies</li>
                <li>Guarantees Of Origin On-Chain Tracking with EECS support</li>
                <li>Certificates On-Chain Tracking</li>
                <li>Validators for Certificates And Guarantees</li>
                <li>Trading for Certificates And Guarantees Of Origin </li>
                <li>Planned marketplace launch: 2023</li>
                <li>Diversification and fully functional location aware trading: 2026</li> 
                <li>Decentralized trustless trading system 2030. </li>
                </ul>
           </div>
  }
}

class TableWhyHydrogen extends React.Component {
   render() {
    let data = whyHydrogenTemplate;
     return <div>
              <table className="profileInformationSection">
                <thead>
                  <tr className="orderHeader">
                      <th></th>
                      <th>Hydrogen</th>
                      <th>Methane</th>
                      <th>RP-I</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(o => 
                    <tr key={o.id} >
                      <td> {o.name} </td>
                      <td> {(o.hydrogen)} </td>
                      <td> {(o.methane)} </td>
                      <td> {o.rpi} </td>
                    </tr>
                  )}
                  </tbody>
              </table>
            </div>
   }
}

class ChartTokenDistribution extends React.Component {
  // EH2 Token Allocation Summary,
  constructor(props){
    super(props)
    this.state = {
      series: [0.8, 19.2, 25, 20, 35],
      options: {
        chart: {
          align: 'left',
          foreColor: '#c8c8c8',
          width: 300,
          type: 'pie',
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#084Cc9',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        title: {
          text: "", 
          align: 'left',
          style: {
            fontSize:  '25px',
            fontWeight:  'bold',
            color:  '#c8c8c8',
            margin: '50px'
          }
        },
        labels: ['Bootstrap sale', 'Core Dev Team', 'Reserve', 'Private Investors', 'Public Contributors'],
        fill: {
          type: 'gradient',
        },
        legend: {
          position: 'bottom',
          margin: '50px',
          labels: {
            color: '#c8c8c8',
            useSeriesColors: false,
          },
        },
        responsive: [{
          breakpoint: 400,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  }
  render() {
    return <div>
             <Chart options={this.state.options}  series={this.state.series} type="pie"  height="300"/>
           </div>
  }
}

class TokenSale extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Token Allocation</h2>
             <table className="profileInformationSection">
                <thead>
                  <tr className="orderHeader">
                      <th></th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>Token Sale Start Time</td>
                    <td>June 26, 2020 at 14:00 UTC</td>
                  </tr> */}
                  <tr>
                    <td>Hard Cap</td>
                    <td>7,899,920 EUR</td>
                  </tr>                  
                  <tr>
                    <td>Total Token Supply</td>
                    <td>201,600,000 EH2</td>
                  </tr>                  
                  <tr>
                    <td>Total Bootstrap Circulating Supply and Allocation</td>
                    <td>500,000 EH2</td>
                  </tr>
                  {/* <tr>
                    <td>Initial Circulating Supply</td>
                    <td>500,000 EH2</td>
                  </tr>                   */}
                  <tr>
                    <td>Bootstrap Sale Start Token Price Bonding Curve</td>
                    <td>Start: 1 EH2 = 0.0000846038 ETH  &nbsp;&nbsp;&nbsp; 1 ETH = 11819.8 EH2</td>
                  </tr>
                  {/* <tr>
                    <td>Total Bootstrap Sale Allocation</td>
                    <td>0.8%</td>
                  </tr>         */}
                  {/* <tr>
                    <td>Public Sale Vesting Period</td>
                    <td>No lockup</td>
                  </tr>
                  <tr>
                    <td>Individual Cap</td>
                    <td>No cap</td>
                  </tr> */}
                  <tr>
                    <td>Minimum Purchase Amount</td>
                    <td>Boostrap: N/A Public Investors: 1 ETH Private Investors:100 ETH</td>
                  </tr>
                  <tr>
                    <td>Token Type</td>
                    <td>ERC-20 Token</td>
                  </tr>                                                                                                                 
                </tbody>
              </table>
           
             <ChartTokenDistribution />
           </div>
  }
}


class ProjectVision extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h1>Vision</h1>
             <p>
EnergonX aims to build a fair Hydrogen location aware trading platform system that provides a set of easy-to-use and transparent services for all its members. 
The time to amplify support for hydrogen is now.
              </p>
             <p>
Decisions and investments made now will have long-term impact. Many 
energy infrastructure decisions take a long time to implement and we plan to be ready.
             </p>
             <p>
Hydrogen is critical for a low-carbon energy mix. It can be used broadly across several industries,
including transport, steel, ammonia, methanol, refining, in residential and commercial
buildings, data centers, hospitals, shipping, aviation, railroads, transportation, construction, mining, and in the power system for grid balance or micro grids, 
           
             </p>

           </div>
  }
}

class ProjectUniqueness extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Hydrogen is a unique energy carrier</h2>
             <p>
             Hydrogen is critical for a lower carbon energy mix. It can be used broadly across several industries:
             </p>
            <ul>
              <li>In buildings</li>
              <li>In transport</li>
              <li>In industrial processes</li>
              <li>As backup power or off-grid power</li>
              <li>In the power systems</li>
            </ul>
             <p>
                1 kg of hydrogen contains 33.33 kWh of usable energy, whereas petrol and diesel only hold about 12 kWh/kg  
                <a rel="noopener noreferrer" href="http://www.h2data.de" target="_blank">(see www.h2data.de)</a>.
             </p>
             <p>
               In terms of volumetric energy density however, hydrogen is outperformed by liquid fuels. 
               This poses a challenge when hydrogen must be transported from the place of its generation
               to a refuelling station.
             </p>
             <p>Petrol and diesel carry around 8.8 and 10 kWh/litre, respectively.</p>
             <p>Under ambient conditions, a cubic metre of hydrogen provides some 3 kWh, equivalent to 0.003 kWh per litre. 
             Pressurised hydrogen contains about 0.5 kWh/litre at 200 bar, 1.1 kWh/litre at 500 bar and 1.4 kWh/litre at 700 bar.</p>
             <p>Volumetric energy density of Li-ion battery is 250-370 Wh/liter, while gravimetric energy density is 150 - 250 Wh/kg. 
             Comparing "hydrogen is energy carrier" with diesel or gasoline fuel based on hydrocarbon 
             chemistries is inapproriate. We should not compare apples with oranges per se. </p> 
             <p>The visible spectrum of light from hydrogen displays four wavelengths, 410 nm, 434 nm, 486 nm, and 656 nm, that correspond to emissions of photons by electrons in excited states transitioning to the quantum level.</p>
             </div>
  }
}
class ProjectWhy extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() { 
    return <div>
             <h2>Why</h2>
             <p>
             Increasing concerns about CO2 equivalent emissions and the diminishing fortune of the oil-based economy, 
investments in hydrogen (H2) technology will be worthwhile. Implementation of this power source will 
facilitate transition to clean energy, reduce emissions, create jobs, and spur further innovation. This closed-loop system 
of energy generation is entirely green, with no harmful byproducts. In its liquid form hydrogen can be transported great distances by 
either land or sea transportation networks or pipelines.  <br/>
Energy from Hydrogen and the growing commercial application of hydrogen energy to create electricity (in fuel cells) 
is leaving heat to be utilized productively elsewhere and pure water.
             </p>             
           </div>
  }
}
class ProjectBenefits extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Benefits</h2>
             <p>
             Implementation of the hydrogen exchange project will bring significant social and economic benefits for Slovenia, and Europe and all other countries or regions, reach is not limited to one Continent. The project is fully compliant with the European Union energy policy guidelines to ensure safe, affordable and sustainable energy supplies. 
             </p>
             <p>
                In case of acceptance of proposition regarding hydrogen market development, planing - cost minimization, revenue maximization approach and with due 
                considerations regarding dependence on elemental granularity (area size), cost savings as high as 22% might be expected, and up to 9.9% are viable 
                in cases of 4 tier constructs, the former consequently leads to eventual 49% improvement in "profitability" for private, and analogous in public 
                sector compared with the present day cost projections.
               </p>                          
             <p>
             The investment will contribute to better integration of gas markets and higher competitiveness of biogas, 'green' gas, or 'green' liquids and primarily hydrogen, inroads are enabled by trading platform for all types of characterized hydrogen production paths conforming with EU certification scheme.  
             </p>
             <p>
             Specifiably, we emphasize European Green deal, European Climate Law enshrining the 2050 climate neutrality objective and The European Climate Pact.   
             </p>
           </div>
  }
}

class ProjectDelivery extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Delivery</h2>
                <ul>
                  <li>Pathways: Gaseous hydrogen by pipelines, gaseous hydrogen by truck tube trailers, and cryogenic hydrogen by truck</li>
                  <li>Components Model including; Pipelines, compressors, truck tube trailers, cryogenic liquid trucks, liquefaction, gaseous tube storage, geologic storage, gaseous hydrogen terminals, liquid hydrogen terminals</li>
                  <li>Scenario Model: Geographic-specific scenarios for a complete delivery infrastructure</li>    
                </ul>
           </div>
  }
}
class ProjectDistributedProduction extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Distributed Production of Hydrogen</h2>
             <p>The sizes of the facilities are between 10 to 1500 kg/day</p>
               <ul>
                <li>Electrolysis</li>
                <li>Reforming of Ethanol: Sourced from fermentation from corn grain or from cellulosic biomass (to be posted at a later date...with investments up to 250 mio Eur - 55.000 t yearly production, 50 mio Eur supply chain costs)</li> 
                <li>Reforming of Methanol: Sourced from biomass gasification or from fossil fuels</li>
               </ul>
             <h2>Technologies Characterized</h2>             
               <ul>
                 <li>Central Production of Hydrogen (Central is defined as >50,000 kg/day of hydrogen. The production cost will stop at 300 psi hydrogen with minimal storage for production purposes only</li>
                 <li>Biomass Gasification Hydrogen Production</li>
                 <li>Wind Electrolysis Hydrogen Production</li>
                 <li>Solar PV Electrolysis Hydrogen Production</li>
                 <li>Current Nuclear Energy Using Standard Electrolysis Hydrogen Production</li>
               </ul>
           </div>
  }
}
class ProjectEnvironmentalProtection extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Environmental protection</h2>
             <p>
             EnergonX promotes extensive use of hydrogen, biomethane, power to gas and power to liquids technologies in all 'green' Hydrogen Economy.
             As the platform for energy and heat production is universal with pan European reach extended to any region of the world replacing
             detrimental fossil fuels with carbon free alternatives. 
             </p>             
             <p>
             Project conforms, and supports directly, indirectly or by induction: 
             </p>
               <ul>
                <li>EU Biodiversity Strategy for 2030</li> 
                <li>8th Environmental Action Programme</li> 
                <li>Chemicals strategy for sustainability</li>
                <li>Strategy for sustainable and smart mobility</li> 
                <li>ReFuelEU Aviation - Sustainable Aviation Fuels</li> 
                <li>FuelEU Maritime - Green European Maritime Space</li>
               </ul>
           </div>
  }
}
class ProjectFeatures extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Key Features</h2>
             <p>
                To the best of the ability of the management and all participating users, the project will enhance conformity and support progressively to serve 
                its customers and society in alignment with "Economy that Works for People" and "Fair Data Society Principles".
             </p>
             <ul>
                <li>EH2 An utility token that is used for services provided by EnergonX, such as fees, collateral, discovery and information access.</li>
                <li>EnergonX is efficient low-fee geo-location aware trading platform with services for hydrogen producers, transportation companies and buyers.</li>
                <li>Will eventually transform from centeralized solution to fully decentralized global solution</li>
                <li>Use of Swarm decentralized storage for transparent storage of orders, transport tracking, storing of certificates and guarantees of origin.</li>
             </ul>
           </div>
  }
}

class ProjectRoadmap extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Roadmap</h2>
             <p>Each phase has specific milestones for the deployment of hydrogen across applications.and describes 
               categorized policy enablers, hydrogen supply, end-use equipment enablers and consumers. Policy enablers 
               are needed to create the right incentives to enable the private sector to invest in and develop the 
               hydrogen market. Hydrogen supply is required for market liquidity. Equipment enablers have interest 
               in the large economic scale of the market and consumers will have access to competitive markets and prices.
             </p>
             <table>
               <thead>
                 <tr>
                   <th>2020 to 2022</th>
                   <th>2023 to 2025</th>
                   <th>2026 to 2030</th>
                   <th>2031 and beyond</th>
                 </tr>

               </thead>
               <tbody>
                 <tr>
                   <td><h3>Immediate next steps</h3></td>
                   <td><h3>Early scale-up</h3></td>
                   <td><h3>Diversification</h3></td>
                   <td><h3>Broad presence</h3></td>
                 </tr>
                 <tr>
                   <td>Bridge barriers to initial market launch</td>
                   <td>Transition from direct support to scalable market-based mechanism</td>
                   <td>Broaden support for transport to pipelines</td>
                   <td>Decentralized trading system</td>
                 </tr>                 

                 <tr>
                   <td>Policy/Regulatory compliance</td>
                   <td>Spread initial market launch with spot trading and transportation</td>
                   <td>Fully functional location aware trading</td>
                   <td></td>
                 </tr>                 
                 <tr>
                   <td>Workforce development programs</td>
                   <td>Partnerships expansion</td>
                   <td></td>
                   <td></td>
                 </tr>  
                 <tr>
                   <td>Development of underlying support systems</td>
                   <td>Support for cross-sectoral distributed energy resources</td>
                   <td></td>
                   <td></td>
                 </tr> 
                 <tr>
                   <td>Establishing Partnerships</td>
                   <td></td>
                   <td></td>
                   <td></td>
                 </tr>
                 <tr>
                   <td>Focus on Green Hydrogen</td>
                   <td></td>
                   <td></td>
                   <td></td>
                 </tr>  
               </tbody>
             </table>
           </div>
  }
}

class ProjectAccesibility extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Accessibility</h2>
             <p>
             Platform will create additional, numerous channels for hydrogen supply network across Europe, which will contribute to a strategic change in the existing 
             structure of gas transmission in Europe generally, based on not only one direction - but a network enabling green electricity producers, 
             hydrogen producers, storage providers, pipe line, liquified hydrogen gas carriers - large vessels as the industry and the global market evolves, or container 
             vessel transport providers in gaseous or in liquid cryogenic form. Implementation will provide much needed diversification of green gas supply sources but also 
             supply directions in any region. It will guarantee higher energy security for hydrogen gas users, both businesses, prosumers and households as case might be.
             </p>
             <p>
             The hydrogen users will benefit from a new or existing source of supply due to an extended competitive environment for all, supply and as well demand providers. 
             The dynamic supply sources will open up price differentiation on regional basis between individual markets in favour of the accessibility and security, moreover, 
             the flexibility coupled with feasibility will inevitably flatten the price spreads providing lower volatility and risks, this norm will apply to supply of commodity, 
             to transport providers in any form, to storage providers, or dynamic upkeep of state energy reserves (hydrogen) which will complement storage providers with additional,
             strategic value.
             </p>
           </div>
  }
}

class ProjectColorCoding extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Standards</h2>
             <p>
                  The primary codes and standards, along with the most recent publication date, that station developers in the next grant funding program should follow include:
             </p>
             <ul >
                    <li>NFPA 2-2016: Fundamental safeguards for safe generation, storage, and handling of hydrogen; requirements can affect station design considerations</li>
                    <li>CSA HGV 4.9-2016: Design, installation, operation, and maintenance standards for hydrogen fueling stations, based on United States and international 
                        codes and standards. Several component-specific standards are also incorporated into these requirements.</li>
                    <li>SAE J2601-2016: Industry standard fueling protocol that ensures safe, fast fills are provided to customers</li>
                    <li>SAE J2719-2015: Standards for hydrogen fuel quality for FCEVs</li>
                    <li>SAE J2799-2014: Design requirements for interfacing with FCEV fueling receptacle, including communications standards</li>
                    <li>ANSI/CSA HGV 4.3-2016: Test method to validate conformance to SAE J2601</li>
             </ul>             
           </div>
  }
}

class ProjectDecarbonizingEnergy extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Decarbonizing energy</h2>
             <p>
             Strategy for smart sector integration translates to renovation wave, empower the customer, or prosumer for green transition, it supports 
             EU Biodiversity Strategy for 2030, and enables directly and indirectly sustainable and smart mobility. 
             </p>
             <p>
             To uphold Europe's industrial leadership, a new Industrial Strategy will help deliver on three key priorities: maintaining European 
             industry's global competitiveness and a level playing field, at home and globally, making Europe climate-neutral by 2050 and 
             shaping Europe's digital future.
             </p>
             <p>
             Comprehensive measures to modernise and decarbonise energy-intensive industries, support sustainable and smart mobility industries, 
             to promote energy efficiency, strengthen current carbon leakage tools and secure a sufficient and constant supply of low-carbon
              energy at competitive prices.
             </p>
           </div>
  }
}

class ProjectDeepDecarbonisation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Deep decarbonization</h2>
             <p>
             Deep decarbonization requires insights and access to low carbon fuels.  Fuels (molecules) are and will continue to be important 
             supplements to electricity for end users.  Even with increased electrification, there are several applications that will continue 
             to rely on molecules, also in a carbon neutral energy system, thus electricity + complementary hydrogen. Low carbon fuels entail 
             both decarbonization of fossil fuels with CCS, as well as synthetic fuels generated from biomass, waste materials or low carbon power. 
             All low carbon fuels contain hydrogen in some form. Hence the production, conversion and transport of hydrogen and different hydrogen 
             carriers are fundamental building blocks for low carbon fuel
             </p>
           </div>
  }
}

class ProjectEnergySecurity extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Energy security</h2>
             <p>
             The project is the basis for the European Union energy alliance. Diversification is providing access to hydrogen sources by improving 
             extensively national energy security, e.g., independence. Appearance of market participants, large or small across Europe will increase 
             competitiveness and ensure continuity of robust, secure supply. Project will also contribute to enhanced, diversified supply sources in 
             all regions of  Europe, the 'gas crisis' of the last decade will become past, and risk of supply disruptions will be largely eliminated.
             </p>
           </div>
  }
}

class ProjectSupportForDigitalAge extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Support for Digital Age</h2>
             <ul>
               <li>Project follows up paradigm "Europe fit for the digital Age"</li>
               <li>A Strategy for Europe - Fit for the Digital Age</li>
               <li>European Strategy for Data</li>
               <li>A European approach to Artificial Intelligence</li>
               <li>Increasing cybersecurity</li>
               <ul>
                  <li>Review of the Directive on security of network and information systems </li>
               </ul>
               <li>Digital finance</li>
               <ul>
                <li>Action Plan on FinTech including a Strategy on an Integrated EU Payments Market</li>
                <li>Proposal on Crypto Assets (legislative, incl. impact assessment, Article 114 TFEU, Q3 2020);</li>
                <li>Cross-sectoral financial services act on operational and cyber resilience (legislative, incl. impact assessment, Article 114 TFEU, Q3 2020) </li>
                <li>Strengthening Europe's Economic and Financial Sovereignty</li>
               </ul>

               <li>Fostering Europe's security</li>
               <ul>
                  <li>Proposal for additional measures on Critical Infrastructure Protection (legislative, incl. impact assessment, Q4 2020).</li>
               </ul>

             </ul>
           </div>
  }
}

class ProjectPrivateInvestment extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Private investment in key sectors and technologies</h2>
             <p>Urgent action is needed to kick-start the economy and create the conditions for a recovery led by private 
               investment in key sectors and technologies. Investment is particularly crucial to the success of Europe's
                green and digital transitions.  Investment in key sectors and technologies, from 5G to artificial intelligence
                 and from clean hydrogen to offshore renewable energy, holds the key to Europe's future. The Commission is also 
                 proposing to strengthen InvestEU, Europe's flagship investment programme, to mobilise investment across the Union 
                 in areas such as sustainable infrastructure and digitisation. As part of this, the Commission proposes to create 
                 a new Strategic Investment Facility to invest in key value chains crucial for Europe's future resilience and 
                 strategic autonomy in the context of the green and digital transitions. 
              </p>
              <p>By strengthening the Single Market and adapting it to the digital age, project actors, i.e., partners will need
               to invest extensively in connectivity and into strong industrial and technological presence in strategic sectors,
                including artificial intelligence, cybersecurity, computing and cloud, all the transactions advocated by EnergonX 
                are blockchain technology enabled, and cyber resiliency build into is paramount. Europe will 
                enhance its strategic autonomy in a number of specific areas, including in strategic value chains and reinforce full respect of rule of law. 
              </p>
              <p>
                Join and develop a coordinated strategy, a comprehensive investment plan to allow an enhanced functioning of our 
                societies and economies and get to sustainable growth, integrating inter alia the green transition and the digital transformation.
              </p>
             
           </div>
  }
}

class TokenUsageInfo extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Token usage</h2>
             <p>EH2 is utility token and will be used to pay for EnergonX services </p>
              <ul>
                <li>Marketplace fee, per trade will be reduced with time</li>
                <li>Withdrawal fee, fiat fees depend on bank transfer, a small fee for withdrawals  </li>
                <li>Certificate Issuance fee, per transaction</li>
                <li>Guarantees of Origin fee, per transaction</li>
                <li>Transportation Access fee, per transaction</li>
                <li>Other fees, we may collect fees for various services included in platform such as market analysis, notification systems</li>
              </ul>
              <dt><Link to="/gettoken">GET UTILITY TOKEN</Link></dt> 
              <p>EH2 Token is available on Uniswap until <b>boostrap</b> phase is completed.</p>
           </div>
  }
}

class ProjectTransitionIntoHydrogenEconomy extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Transition into Hydrogen Economy</h2>
             <p>With soaring shares of intermittent renewable energy sources at profoundly accelerating scales (wind and solar power, 
               like double exponential growth) in the energy mix, high flexibility provided by the gas infrastructure will be essential 
               for the efficient operation of the energy systems of certain European countries and regions. Power-to-gas, power to liquids 
               and other innovative technologies, including enhanced interconnectivity between gas and electricity systems. Europe's gas 
               infrastructure, with its large storage capacity, will support further, accelerated deployment of renewable energy. However, 
               this promising potential for integrating and coupling electricity and gas sector could only be fully realized if there is a 
               clear and stable regulatory framework that will support the modernization and repurpose of the existing infrastructure to 
               support in all respects Hydrogen Economy.
              </p>
              <p>Gas infrastructure should be therefore considered as one of enablers of sustainable and swift transition towards
                 cleaner heat and electricity generation, transport, industrial processes and residential heating and cooling. 
                 Thus, regulatory changes aimed at strengthening sector coupling and integration should help to utilise synergies 
                 and enable optimal use of the available potentials of all technologies limiting the threat of stranded assets from 
                 gas infrastructure in the future. Advocates seeing this approach negate fear, that fossil fuels, gas including, 
                 will persist, lock in will step in and decoupling will be postponed and extremely hard to realize due to massive 
                 inertia within allocated 30 year period, or earlier according to desiderata. 
              </p>
              <p>Respective investments cannot be subject to discriminatory treatment neither from the EU funding perspective, nor from the taxonomy 
              perspective. Companies operating gas infrastructure should have the same access to external capital as the ones of electricity, however,
               investments must contain a firm clause that grid improvement lead to step up increasing percentage of hydrogen fraction being carried 
               by the gas grid. The scheme should allocate on time schedule new hydrogen only network build up across Europe, and full conversion of 
               the gas grid into hydrogen only facility.  Decreasing emissions from economy should be made with maximum cost-effectivity and pragmatism, 
               maximising all the synergy effects based on evidence and available solutions.
              </p>
              <p>Repurposing existing gas networks could help reduce transition cost to dedicated hydrogen infrastructure. Natural gas and LNG 
                infrastructure offers usable path for hydrogen, green hydrogen being the ultimate goal - Hydrogen Economy, dual use to make green 
                hydrogen affordable in the future. "Pure hydrogen backbone" will connect the industrial clusters in Europe, that way, production 
                and demand for hydrogen could be balanced at all times and avoid costly infrastructure development, reconversion of existing pipelines
                 is technically absolutely possible. Pragmatic and inclusive approach by Commission is applauded, real options design in versatile gas 
                 infrastructure are all on offer, it is an enabler for renewables and efficient way to reduce emissions, but real treat of gas lock 
                 s a no-go, direct de-carbonization path calls Commission to exclude any kind of EU funding for hydrogen produced from fossil gas, 
                 without such a guaranty, hydrogen is the gas industry with 'novel, dressed up definition'. Methane is a "chronic blind spot" in climate 
                 policy, to mitigate rogue direct methane leaks into the atmosphere, reliable measurements needed to cover the "two million kilometres 
                 of pipes in Europe alone" that distribute gas to consumers. Gas industry itself doesn't have a full picture yet of where the emissions
                 take place, thus fraternizing the Hydrogen Economy with vintage industrial epoch is no-go and one must distance itself unconditionally 
                 from prolonging the transition into a carbon neutral society. 
              </p>
              <p>
                 Climate impact of piped gas is markedly lower than that of gas which is shipped to Europe over longer distances using big LNG - 
                 liquid natural gas tankers (future LH2 tankers), or dedicated hydrogen pipeline deliveries perceive no environmental issues).
                 Issue of present day vented, flared and fugitive methane emissions in the energy sector and elsewhere needs to be 
                 categorically addressed in Europe and globally.
              </p>
           </div>
  }
}

class ProjecCertificationScheme extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Certification Scheme</h2>
             <p>Implementation of EU certification scheme calls for transparency, data is needed and there have to be certification for emissions, 
               e.g., certification by value chain in relation to hydrogen, critical is supply from a lot of different places and trough fairly 
               long value chains. EU needs to have a home standard, this will enable positions to accept non-EU gas only if it meets the set criteria. 
               The market for Guarantees of Origin (GOs) linked to renewable gas is currently in its development, but with demand building up, 
               industry figures  are calling for existing certification schemes to be harmonised and made mandatory across the European Union. 
               Supply of Guarantees of Origin for hydro, wind and solar electricity reaching nearly 600 terawatt hours (TWh) last year, and need 
               to be extended to "green" gases, where the green hydrogen in being the forerunner, electricity + complementary hydrogen, of hard 
               to decarbonize sectors of economy.
              </p>
             
           </div>
  }
}
class ProjecCryogenicHydrogen extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h3>Low Adiabatic Expansion Energy</h3>
             <ul>
               <li>CGH2 is "compressed hydrogen gas"</li>
               <li>LH2 is "liquified hydrogen"</li>
               <li>CcH2 is "cryo compressed hydrogen"</li>
             </ul>
             
             <p>Cryogenic hydrogen contains a fairly low adiabatic expansion energy and thus, can mitigate implications of a sudden pressure vessel failure, in particular during refueling.
             </p>
             <ul>
              <li>Hydrogen and Fuel cell technology has a set of advantages over other zero emission technologies</li> 
              <li>Liquid hydrogen is most efficient especially for larger FC power units required for long distance hauling trucks, buses, cargo handling equipment in enclosed spaces, trains, ships, energy storage systems, drones, short range helicopters and commuter planes, taxi fleets, etc.</li>
              <li>Supply chains for LH2 have to be well thought out, in order to reach an optimum between CAPEX and OPEX</li>
             </ul>

             <h3>Propulsion</h3>
             <p>Hydrogen propulsion is two to three times more effective than synthetic fuels.</p>
             
           </div>
  }
}


class ProjecGuaranteesOfOrigin extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Guarantee of Origin</h2>
             <p>The issuing of GOs (GO - Guarantee of Origin) for biogas, hydrogen, and heating & cooling only started since December last year,
                when the EU formally adopted its revised Renewable Energy Directive, that opens a platform for commercializing GOs for gas 
                that's injected in the pipeline network. Green Gas Certificates enable companies to make 100% renewable gas claims. In Europe, 
                these are documented by the European Renewable Gas Registry (ERGaR), which brings together national registries across Europe. 
                In order to validate the "renewable origin" claim, the green gas producer and the green gas consumer should be attached to the same 
                pipeline network so that a physical link between producer and consumer can be ascertained. Certification is more developed on the 
                electricity side, where the issuing of GOs is done in a standardised way, under oversight from the Association of Issuing Bodies 
                (AIB, https://www.aib-net.org/; European Renewable Gas Registry (ERGaR), http://www.ergar.org/), which brings together national 
                registries under a harmonised system. While the directive obliges member states to issue GOs for renewable gas, it makes it 
                optional to issue GOs for non-renewable, decarbonized gases, such as hydrogen produced from fossil gas with Carbon Capture and 
                Storage (CCS), e.g., blue hydrogen.
              </p>
             
           </div>
  }
}

class ProjecInterRegistry extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Inter-Registry Hub</h2>
             <p>Within trading platform provides blockchain secure inter-registry communications hub to maintain reliable operation of international 
               certification schemes satisfying criteria ob objectivity, non-discrimination, transparency and cost effectiveness, all in order to facilitate
               the international hydrogen exchange linked to guarantees of origin.  The EnergonX maintains:
              </p>
              <ul>
                 <li>Updates to national registries, certificates are effectively transferred and tracked</li>
                 <li>Energy is effectively recorded and transactions tracked, focal point is provided to actors </li>
                 <li>Certified energy records are tracked in near real time from cradle to grave, within all network of registries, including central registry to be established for EU member states</li>
              </ul>
           </div>
  }
}

class ProjecCertificationPrinciples extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Identifiable Certificates</h2>
             <p>Certificates accompanying each trade are identifiable (blockchain process), transferable and therefore tradable. 
               The Principles and Rules of Operation of the European Energy Certificate System (the EECS® Rules, https://www.aib-net.org/eecs/eecsr-rules) 
               defines a certificate as an electronic document which identifies the source and method of production of a unit of energy, and relates to a 
               specific purpose - such as energy source disclosure or compliance with an obligation. It also prohibits certificate holders from separately 
               claiming or conferring rights or title to any element of this benefit, and for this purpose. Certificates are created, change owners and are 
               eventually made non transferable under a carefully developed and managed control infrastructure, the EECS® Rules, as interpreted by each 
               ountry or region according to its "Domain Protocol". The adequacy of this interpretation is assured by the other members as a condition of 
               membership. The EECS Rules provide the foundation, and if other, hydrogen specific domain certification body is at the EU level appointed 
               during the legislative process, then these rules will apply to the proposed CEN standard. 
              </p>
              <p><small>
              <strong>Note:</strong> TÜV SÜD provide certification services for traditional ecopower and ecogas products, generation of tradable certificates 
              from renewable sources, for sustainability of biofuels and even for green hydrogen. The “TÜV SÜD Generation EE” label certifies electricity 
              produced from renewable resources where generation can be attributed to a clearly identifiable source. The certification of the generation of 
              electricity can be used as a private-sector proof of origin in electricity trading or serves as a basis for the issuance of national, 
              EU-conforming guarantees of origin (GoO).
              </small>

              </p>
           </div>
  }
}

class ProjecLinkingUp extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Linking up electricity, gas, bio-methane, PtG, PtL and hydrogen</h2>
             <p>Pan European system of GOs should be interoperable to allow trading of certificates between different energy carriers - electricity, 
               gas and hydrogen - as well as between countries. Such a system must be based on an EU-wide methodology on how to issue, register, 
               transfer and cancel GOs or convert them between energy carriers. Rules must be clear and avoid double counting or fraud, standardised 
               minimum information on GOs across Europe is paramount to reflect things like the types of installations used, their location, the energy 
               carrier, or whether the installation received financial support. The electronic document used to transfer information related to individual 
               renewable hydrogen gas consignments will be gathered in a virtual database allowing to track the cross-border transfer of respective 
               certificates. The content and attributes of the European Renewable Gas CoOs will correspond to the harmonised view of the national 
               registries and contain the minimum information necessary for acceptance of the consignment into the European mass balancing administration, 
               compliance with RED national targets, renewable energy obligations, and eligibility for financial schema, system governing "green hydrogen"
                (Hydrogen Economy). 
              </p>
              <p>About 50% of renewable power production in Europe in 2020 will be certified, and that's going to increase - we're looking at 1 600 TWh 
                certified power in 2030. By 2050, "clean gases" - mainly hydrogen and biomethane - could make up between 30% and 70% of total gas use 
                depending on scenario, i.e., according to Smart sector integration: Promoting clean energy. The energy markets in EU should be designed 
                so that lock-ins to certain energy forms or carriers could be avoided, EU to fund only "climate-proof" energy infrastructure. 
              </p>
              <p>Our aim is that this project with blockchain enabled trading platform will support the GOs transfers, and issues of non harmonized 
                EU green gas, e.g., hydrogen will be enshrined in the past, advances in security will be exploited fully to provide cyber security 
                to all value chain actors. 
              </p>
           </div>
  }
}

class ProjecPosition extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return <div>
             <h2>Linking up electricity, gas, bio-methane, PtG, PtL and hydrogen</h2>
             <p>The European Green Deal emphasizes the need to mobilize significant investments, directing the EU to become a climate-neutral 
               continent by 2050. In doing so, EU policies ensure synergies and system flexibility, while not hampering competitiveness,
               stability of energy supplies and affordability of energy to industry, to prosumer large or small and to households. While 
               transitioning expeditiously away from fossil fuels, we need to ensure the security of energy supplies and as well to address 
               the social and economic aspects of this process. 
              </p>
              <p>Member States and their regions vary in their landscape, beauty of nature  and economic development significantly, thus 
                the EU energy and climate policy should recognise the existence of national and regional differences and allow tailored 
                solutions to be implemented leading to a climate-neutral European Union by 2050. A transition based on renewable energy 
                sources need to consider a step approach for a diversified energy mix in the EU, however, existential threat of climate 
                change does not allow for any "wait and see" approach, all efforts - resources have to be directed to zest full, 
                uncompromising energy transition. Such forced implementation cannot be done overnight thus it will be economical to 
                combine electricity + gas  solutions (hydrogen is energy carrier and complements electricity, it is most efficient 
                storage (molecule) known in physics). Furthermore, for the long term decarbonisation we already have zero-emission 
                technologies that could be build up at the necessary scale with socially, and economically acceptable timetable 
                (with an existential threat looming, one considers least cost as a last in a list of attributes) to produce the sufficient 
                volumes of electricity and heat for economy. Net-zero emission scenarios for 2050 laid out in the "Clean Planet for All" 
                communication published by the Commission in 2018 acknowledge transition and all implications of down-scaling the fraction 
                of fossil origin sources while ramping up green hydrogen, its precursors and bio characterized energy sources to provide 
                a balanced share of energy consumed in the EU.
              </p>
              <p>When replacing fossil fuels, bio-methane and decarbonized gases can reduce emissions significantly with well-known and 
                proven technologies. Natural gas can curtail greenhouse gas emissions, but this claim is highly controversial, rogue 
                methane emissions documented recently by dedicated satellites and direct measurement point to and negate natural gas of 
                being appropriate transition fuel, human activity caused direct methane emissions cannot be neglected and are hard to mitigate. 
                Bio-methane has a neutral greenhouse gas emission impact by definition, however, in the short term it is detrimental to climate 
                change as a molecule (CH4) as well. 
              </p>
           </div>
  }
}


class AboutProject extends React.Component {
 render() {
    return <div>
      <AboutProjectContainer ref={el => (this.componentRef = el)}/>
      {/* <div style={{zIndex:11000, top:"100px"}}>
      <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <a  href="#">Print this out!</a>;
          }}
          content={() => this.componentRef}
        />
      </div> */}
      </div> 
 }
}

class AboutProjectContainer extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        page:0,
        card: {
          maxWidth: 345,
        },
      }
    }

    componentDidMount() {  }
    componentWillUnmount() { }

    render () {
        
        return <div className="pageViewScrollable" id="printablePageViewContents">
                <div className="pageViewContents">
                   {/* style={{textAlign:"left"}} */}
                   <ProjectPlan/>
                   <ProjectVision />
                   <StaticMarketAnalysis connectedMarkets={true}/> 

                   <ProjectUniqueness />
                   <StaticMarketAnalysis levelizedCosts={true}/> 
                  
                   <ProjectWhy />
                   <ProjectDistributedProduction />
                   <StaticMarketAnalysis costOfProduction={true}/> 

                   <ProjectBenefits />

                   <ProjectDelivery />
                   <StaticMarketAnalysis transportation={true}/> 

                   <ProjectEnvironmentalProtection />
                   <StaticMarketAnalysis hydrogenMarket={true}/> 

                   <ProjectFeatures />
                   <StaticMarketAnalysis hubs={true}/> 

                   <ProjectAccesibility />
                   <StaticMarketAnalysis storage={true}/> 

                   <hr/>
                   <GoGlobalHydrogen data={goGlobal} title="Trading Platform of Emission-Free Energy"/>
                   
                   <StaticMarketAnalysis growthForecast={true}/> 
                   <ProjectTransitionIntoHydrogenEconomy />

                   <ProjectRoadmap />
                   <StaticMarketAnalysis consumption={true}/> 

                   <hr/>
                   <GoGlobalHydrogen data={workingOn} title="Building for tomorow"/>
                   <StaticMarketAnalysis costs={true}/> 
                   
                   
                   <ProjectSupportForDigitalAge />
                   <StaticMarketAnalysis energyReturn={true}/> 

                   <hr/> 
                   <GoGlobalHydrogen data={rolesOfDemand} title="Future is near"/>
                   <StaticMarketAnalysis resourcesParticipation={true}/> 


                   <hr/> 
                   <ProjectPrivateInvestment/> 
                   <TokenUsageInfo/>
                  
                   {/* <TokenSale /> */}
                  
                   <hr/>

                  
                   <StaticMarketAnalysis tables={true}/> 
                   <TableWhyHydrogen />

                   <ProjectColorCoding />
                   <StaticMarketAnalysis colorCoding={true}/> 

                   <ProjectEnergySecurity />
                   <ProjecCryogenicHydrogen />
                   <StaticMarketAnalysis cryogenicCompression={true}/> 

                   <ProjecCertificationPrinciples/>

                   <ProjecCertificationScheme />
                   <StaticMarketAnalysis certifications={true}/> 

                   <ProjecGuaranteesOfOrigin />
                   <StaticMarketAnalysis guaranteesOfOrigin={true}/> 

                   <ProjecInterRegistry />
                   <StaticMarketAnalysis infrastructure={true}/> 
                   
                   
                   

                   <ProjectDecarbonizingEnergy/>
                   <StaticMarketAnalysis pathwayToDecarbonisaion={true}/> 

                   <ProjectDeepDecarbonisation /> 

                   <StaticMarketAnalysis impactOnPrices={true}/>  
                   <ProjecLinkingUp />

                   <br/>
                   <p style={{textAlign:"center"}}>
                      EnergonX Team <br/><br/><br/>
                   </p>

                   {/* <hr/>
                   <StaticMarketAnalysis /> 
                   <hr/> */}
                  
                   {/* <div className="pageNormalViewContents">
                    <InitialMenuSelector />
                  </div> */}
                  
                </div> 
                
               </div>
    }
  }

export default AboutProject;


// https://www.forbes.com/sites/arielcohen/2020/05/12/can-rice-universitys-hydrogen-leaf-technology-be-a-game-changer/#4ba6cf6b396b
/*
With oil markets in turmoil amidst the global economic shutdown, competing green fuel sources are getting extra attention. 
One such promising alternative energy is hydrogen (H2) power. Hydrogen fuel cells – the growing commercial application of 
hydrogen energy – are a battery-like technology that use H2 as an input to create electricity, leaving heat and water as 
the only waste. Recently, Texas’ Rice University unveiled a potential breakthrough in hydrogen fuel generation – the "artificial leaf."

As I’ve discussed before, traditional hydrogen fuel cells can be thought of as batteries that never run flat as long as the H2 keeps coming. 
Pressurized hydrogen is the ‘fuel’ in the tank, which then interacts with Oxygen (O2) in the air to create electricity through a chemical reaction. 
Fuel cells are inherently more efficient than internal combustion engines (ICE), which must first convert chemical potential energy into heat, 
and then mechanical work. Fuel cells are also cleaner than ICEs: the only byproduct is H2O – water—and its clean enough to drink. But that’s once 
the hydrogen is harvested.  

The majority of hydrogen is generated through fossil fuel processes like the steam reforming of natural gas and coal gasification. 
Emission free hydrogen can also be produced through biofuels and green water electrolysis – where (clean) electricity is used to split 
water into hydrogen and oxygen.

Of course, this type of frontier tech is still in the experimental phase, years away from achieving scale or even commercial viability. 
Widespread adoption of this kind of technology would require massive build-outs of infrastructure – like hydrogen pipeline systems – as well 
as the extensive adoption of downstream products that require hydrogen fuel, like hydrogen fuel cell vehicles. Demand for H2 in chemical 
refining alone simply isn't enough.

Hydrogen fuel cell vehicles (HFCVs) are already in operation around the world, with over 60,000 on Germany’s roads. China is aiming for 
more than 1 million HFCVs in service by 2030. That compares with just 1,500 or so now, most of which are buses. Japan, a market of more
than 5 million vehicles annually, wants to have 800,000 HFCVs sold by that time, up from 3,400 currently. But demand is not yet enough 
to justify the billions upon billion of dollars needs for adequate hydrogen fueling infrastructure .

These targets are ambitious, but given the increasing concerns about CO2 pollution and the precarious future of the oil-based economy, 
investments in hydrogen technology would be worth the effort. Research, development, and implementation of this power source would 
facilitate transition to clean energy, reduce emissions, create jobs, and spur further innovation. This potentially closed-loop system 
of energy generation is entirely green, with no harmful byproducts. In its liquid form hydrogen can be transported great distances by 
either pipelines or more traditional land and sea transportation networks re-purposed for hydrogen shipment. 

Of course, excitement over progress in hydrogen and other renewable energy adoption should be tempered with a great deal of planning, 
policy making, and investment. This will ensure that new technology is developed and integrated properly. That said, these difficult 
times have created an unprecedented opportunity to chart a new direction for the energy industry.
*/