import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, useHistory } from "react-router-dom";

//import cssVars from 'css-vars-ponyfill';
//import { CssVarsPonyfill } from 'css-vars-ponyfill-react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//const postcssCustomProperties = require('postcss-custom-properties');
//postcssCustomProperties.process('./App.css' /*, processOptions, pluginOptions */);
import './App.css';

import CommunicationLib from './comm/communications.js';
import Notfound from './components/NotFound.js';

import ViewProcurementNetwork from './components/HydrogenX/ViewProcurementNetwork';
import SearchProcurementNetwork from './components/HydrogenX/SearchProcurementNetwork';

import InitialView from './components/project/InitialView.js';
import AboutProject from './components/project/AboutProject.js';
import AboutExchange from './components/project/AboutExchange.js';
import ProjectContactInfo from './components/project/ProjectContactInfo.js';
import WelcomeGetTokens from './components/ethereum/Welcome_GetTokens.js';

import LoginScreen from './components/LoginScreen.js';
import TopBanner from './components/BannerTop';
import NewsView from './components/News/NewsView.js';
import BlogListView from './components/Blog/BlogList.js';
import Footer from './components/BannerFooter.js';

import TransportationDashboard from './components/Transportation/Transportation_Dashboard.js';
import TransportationContract from './components/Transportation/Transportation_Contract.js';
import DistributionsView from './components/Distribution/View_Distributions.js';
import DataplaceView from './components/Dataplace/View_Dataplace.js';

import DeliveryDashboard from './components/Delivery/Delivery_Dashboard.js';
import AnalysisDashboard from './components/Analysis/Analysis_Dashboard.js';
import MarketplaceAnalyticsDashboard from './components/Analysis/MarketplaceAnalyticsDashboard.js';
import Analysis_AmountPerCountry from './components/Analysis/Analysis_AmountPerCountry.js';

import PageView from './components/View_PageView.js';

import ClientBalancesView from './components/profile/Client_BalancesView.js';
import ClientManagementView from './components/profile/ClientManagementView.js'; 
import ClientOrdersViewBuys from './components/profile/Client_OrdersViewBuys.js';
import ClientOrdersViewSells from './components/profile/Client_OrdersViewSells.js';
import ClientOrdersCompletedBuys from './components/profile/Client_OrdersCompletedBuys.js';
import ClientOrdersCompletedSells from './components/profile/Client_OrdersCompletedSells.js';
import ClientCompletedTradeDetail from './components/profile/Client_CompletedTradeDetail.js';
import ClientOrdersViewTrades from './components/profile/Client_OrdersViewTrades.js';
import ClientProfileView from './components/profile/Client_Profile_View.js';
import ClientSupportView from './components/profile/Client_SupportView.js';
import ClientWalletView from './components/profile/Client_WalletView.js';


import SellerCompanies from './components/Company_CompaniesSellers.js';
import BuyerCompanies from './components/Company_CompaniesBuyers.js';
import CompanyShipment from './components/Company_Shipment.js';
import CompanyDelivery from './components/Company_Delivery.js';


import SealedDeliveriesCompleted from './components/DeliveryShipment/SealedDeliveriesCompleted.js';
import SealedDeliveryInProgress from './components/DeliveryShipment/SealedDeliveriesInProgress.js';
import SealedDelivery from './components/DeliveryShipment/SealedDelivery.js';

import CompanyClientView from './components/Company_ClientView.js';
import CompanyView from './components/Company_View.js';
import PlaceView from './components/Place_View.js';
//import ExchangeOverview from './components/Exchange_Overview.js';
import ExchangeView from './components/View_Exchange.js';

import RegisterNewUser from './components/registrations/RegisterNewUser.js';

import Apply from './components/registrations/Apply.js';
import ApplyBank from './components/registrations/ApplyBank.js';
import ApplyCertificationAuthority from './components/registrations/ApplyCertificationAuthority.js';
import ApplyCustomsAuthority from './components/registrations/ApplyCustomsAuthority';
import ApplyCompany from './components/registrations/ApplyCompany.js'; 
import ApplyPlaceOfLoading from './components/registrations/ApplyPlaceOfLoading.js';
import ApplyTransportationCompany from './components/registrations/ApplyTransportationCompany.js';
import ApplyCertificates from './components/registrations/ApplyCertificates';

import AuctionsView from './components/Auction/View_Auctions.js';

import GuaranteeOfOrigin from './components/GuaranteesOfOrigin/View_GuaranteeOfOrigin'; 

const Web3 = require('web3');
const ethUtil = require('ethereumjs-util');

//window.energonUrl = 'https://localhost:44303/';
window.energonUrl = 'https://localhost:5001/';
//window.energonUrl = 'https://localhost:44338/';
//window.energonUrl = 'https://vex:44338/';


const ordersTemplate = 
[
 /* {
    id:0, 
    price: "0.00000000",
    amount:"0.00000000",
    total: "0.00000000"
   } */
];
/*const tradesTemplate = 
[
  { 
    id:0,
    timestamp:"00:00:00",
    type:"Buy",
    price: "0.00000000",
    amount:"0.00000000"
   }
];*/
const buySellOrderTemplate = 
{
  price: "price",
  amount: "amount",
  total: "total"
};

window.addEventListener('load', async () => {
  if(window.energonxapp.state.isExchangeEnabled==false) {
    console.log("web3 disabled");
    return;
  }

  if (window.ethereum) { // Modern dapp browsers...
      window.web3 = new Web3(window.ethereum);
      try { // Request account access if needed
          await window.ethereum.enable();
          window.ethereum.on('accountsChanged', function (accounts) {
            // Time to reload your interface with accounts[0]!
            //console.log(accounts);
            window.energonxapp.newAccountSet(accounts[0]);
            window.energonxapp.logout();
          });
          // Acccounts now exposed
      } catch (error) { // User denied account access...
      }
  }
  else if (window.web3) { // Legacy dapp browsers...
      // window.web3 = new Web3(web3.currentProvider);
      // Acccounts always exposed
      window.energonxapp.displayError("Legacy browser detected. Consider upgrading your browser!");
  }
  else { // Non-dapp browsers...
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');

      if(window.energonxapp.state.isExchangeEnabled==true)
         window.energonxapp.displayError("Non-Ethereum browser detected. You should consider trying MetaMask!");
  }
});


class App extends Component {  
  constructor(props) {
    super(props);

    window.appComponent = this; // quick hack to show messages, not very react-ihs way 

    this.state =  { 
       isExchangeEnabled: false,
       isProcurementEnabled: false,
       web3:null,  
       account:null,
       accessToken:null,
       login:false, // display login windows 
       comm: new CommunicationLib(this), // signalR start 
       redirectToLogin: true,
       redirectToProfile: false,
       status:"",    
       error:"",
       timeoutID:0,
       graphTimeScale: 60,// seconds
       //////////////////////////////////////////////////
       client:null,
       profile:null,
       balances:null,
       tradeInfo:null, // complete trade info
       buyOrders:null,  // buy orders 
       sellOrders:null, // sell orders
       completedBuys:null, // completed trades
       completedSells:null, // completed trades
       completedTrades:null, // completed trades
       completedTradeDetail:null, // display completed trade detail
       sellerCompanies:null,
       buyerCompanies:null,
       companyShipment: null,
       companyDelivery: null,
       companyDetail: null,
       sealedDeliveries: null,
       sealedDelivery:null,
       clickBuyOrder:null,
       clickSellOrder:null,
       ///////////////////////////////////////////////// 
       alerts:null, // alerts for user 
       deposits:null, // deposits by user
       withdrawals:null, // withdrawals by user
       loginInfo:null, // login info 
       legalInfo:null, // legal info 
       ////////////////////////////////////////////////// 
       assets:[], // all assets 
       pairs:[], // all pairs
       summary:null, // current summary
       market:null, // current market
       marketTrades:null,
       ohlc:null,
       asks:ordersTemplate, // asks in market
       bids:ordersTemplate, // bids in market
       buyOrder:buySellOrderTemplate, // buy orders in market
       sellOrder:buySellOrderTemplate, // sell orders in market
       trades:[], // trades in current market
       markets:[],  // markets in current exchange
       marketsStats:[], // status of markets 
       exchange:null, // current exchange
       exchanges:[],   // all exchanges    
       allMarkets:[],
       clientAnalytics:null,
       marketplaceAnalytics: null,
       auction:null,
       marketAuctions:[],
       clientAuctions:[],
       clientMarketAuctions:[],
       transportation:null, // current transportation
       marketTransportations:[], // deliveries available for transport in current market 
       yourTransportations:[],
       fuelStations:[] // where are fuel stations
    };
    window.energonxapp = this;

    //CssVarsPonyfill();
  }

  // this.state.comm.sendData("user",s); 
  setStatus(s) { this.setState({status:s}); }
  setError(s) { 
    if (typeof s === 'string' || s instanceof String) this.setState({error:s}); 
    else this.setState({error:JSON.stringify(s)}); 
  }
  setTimeoutID(timeout) { this.setState({timeoutID:timeout}); }

  setExchanges(exchanges) { 
    this.setState({exchanges:exchanges});
    this.setExchange(exchanges[0]);
  }
  setExchange(exchange) { 
    console.log("Exchange set", exchange);
    this.setState({exchange:exchange});
    this.setExchangeMarkets(exchange.markets); 
  }
  setAssets(a) { this.setState({assets:a}); /*console.log("assets", a);*/ }
  setPairs(p) { this.setState({pairs:p}); }
  setSummary(s) 
  { 
    this.setState({summary:s});  
    // TODO this should be pushed from server on each change
    this.state.comm.getMarketOHLC(this.state.market.id, this.state.graphTimeScale);
  }
  setBids(bo) { this.setState({bids:bo.reverse()});  } // .reverse()
  setAsks(so) { this.setState({asks:so});  }
  setTrades(t) { this.setState({trades: [...t, ...this.state.trades]} );  
       let l = this.state.trades.length;
       if(l>42) this.setState({trades: this.state.trades.slice(0, 42) }); // only show last 42 of trades 
  }
  clearTrades(t) { this.setState({trades: []});  }
  clearBids(t) { this.setState({bids: []});  }
  clearAsks(t) { this.setState({asks: []});  }
  clearMarketData() {
    this.clearTrades([]);
    this.clearBids([]);
    this.clearAsks([]);
    this.setState({auction:null});
    this.setMarketAuctions(null);
  }
  setAllMarkets(markets) { 
    this.setState({allMarkets:markets}); 
  }
  setExchangeMarkets(markets) { 
    this.setState({markets:markets}); 
    this.setMarket(markets[0]);
  }
  setMarket(market) { 
    console.log("setMarket ", market);
    this.setState({market:market}); 
//    this.setSummary(market.summary);
    
    //this.state.comm.getMarketOHLC(market.id, this.state.graphTimeScale);
    this.clearMarketData();
    this.state.comm.setGroup(market.id);
  }
  setMarketsStatus(marketsStats) { 
    if(this.state.markets.length===0) return;
    //this.setState({marketStatus:marketsStats}); 

    //console.log(marketsStats[0].i);
    
    var first = this.state.markets[0].id;
    var last  = this.state.markets[this.state.markets.length-1].id; 

    var newMarkets = this.state.allMarkets;

    marketsStats.map((m) => { 
      if(m.i>=first && m.i<=last)
        this.onUpdateMarket(m); 

      newMarkets[m.i-1].summary.last = m.p;
      newMarkets[m.i-1].summary.change = m.c;
    });

    this.setAllMarkets(newMarkets);
  }
  onUpdateMarket = mStats => {
    this.setState(state => {
      const markets = state.markets.map((market) => {
        if (market.id === mStats.i) { // console.log("onUpdateMarket", market.name);
           market.summary.last = mStats.p; // the problem is that state.markets has only selected markets and all other markets are acctually missed even though data was being sent from server 
           market.summary.change = mStats.c;
        }
        return market;
      });
      
      return { markets, };
    });
  }
  setOHLC(data) { this.setState({ohlc:data}); }
  setProfile(data) { this.setState({profile:data}); }
  setBalances(data) { this.setState({balances:data}); }
  setTradeInfo(data) { this.setState({tradeInfo:data}); }
  setBuyOrders(data) { this.setState({buyOrders:data}); }
  setSellOrders(data) { this.setState({sellOrders:data}); }
  setCompletedBuys(data) { this.setState({completedBuys:data}); }
  setCompletedSells(data) { this.setState({completedSells:data}); }
  setCompletedTrades(data) { this.setState({completedTrades:data}); }
  setClickSellOrder(data) { this.setState({clickSellOrder:data}); }
  setClickBuyOrder(data) { this.setState({clickBuyOrder:data}); }
  setOrderState(data) { 
  //  console.log("setOrderState", data);
    toast.info(data.description + " " + data.info, 
    {
      className: 'toast-info',
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
    //this.setState({completedTrades:data}); 
  }
  displayError(text) { 
      toast.error(text, 
      {
        className: 'toast-error',
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
    }
  displayInfo(text) { 
    toast.info(text, 
    {
      className: 'toast-info',
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  }
  displayPopupInfo(text, defclass='toast-info-popup') { 
    toast.info(text, 
    {
      className: defclass, //'toast-info-popup',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  }
  setCompanyShipment(data) { this.setState({companyShipment:data}); }
  setCompanyDelivery(data) { this.setState({companyDelivery:data}); }
  setCompanyDetail(data) { this.setState({companyDetail:data}); }
  setSealedDeliveries(data) { this.setState({sealedDeliveries:data}); }
  setSealedDelivery(data) {  this.setState({sealedDelivery:data}); 
    //const history = useHistory();
    //history.push("/delivery/view/"+data.id);
  }
  setClientAnalytics(data) { this.setState({clientAnalytics:data}); }
  setMarketplaceAnalytics(data) { this.setState({marketplaceAnalytics:data}); }

  setBuyerCompanies(data)  { this.setState({buyerCompanies:data}); }
  setSellerCompanies(data) { this.setState({sellerCompanies:data}); }
  setCompletedTradeDetail(data) { this.setState({completedTradeDetail:data}); }
  setMarketTrades(data) { this.setState({marketTrades:data}); }
  setAlerts(data) { this.setState({alerts:data}); }
  setDeposits(data) { this.setState({deposits:data}); }
  setWithdrawals(data) { this.setState({withdrawals:data}); }
  setLoginInfo(data) { this.setState({loginInfo:data}); }
  setLegalInfo(data) { this.setState({legalInfo:data}); }
  // auctions
  setAuction(data) { this.setState({auction:data}); }
  setMarketAuctions(data) { this.setState({marketAuctions:data}); }
  setClientMarketAuctions(data) { this.setState({clientMarketAuctions:data}); }
  setClientAuctions(data) { this.setState({clientAuctions:data}); }

  // transportations
  setTransportation(data) { this.setState({transportation:data}); }
  setMarketTransportations(data) { this.setState({marketTransportations:data}); }
  setYourTransportations(data) { this.setState({yourTransportations:data}); }

  setFuelStations(data) { this.setState({fuelStations:data}); }

  /*onUpdateMarketStatus = market => {
    this.setState(state => {
      const markets = state.marketStatus.map((mStats) => {
        if (market.id === mStats.i) {
           market.summary.last = mStats.p;
           market.summary.change = mStats.c;
        }
        return market;
      });
      
      return { markets, };
    });
  }*/


  request(obj) {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open(obj.method || "GET", obj.url);
        //console.log(xhr); 
        if (obj.headers) {
            Object.keys(obj.headers).forEach(key => {
                xhr.setRequestHeader(key, obj.headers[key]);
            });
        }
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject(xhr.statusText);
            }
        };

        xhr.onerror = () => reject(xhr.statusText);
        xhr.send(obj.body);
    });
  }

  componentDidMount() { 
    this.init();
    this.state.comm.setup(); // signalr
  }

  async init() { // init metamask 
    const web3 = new Web3(Web3.givenProvider); //givenProvider); //currentProvider); 
    let context = this;
    if (typeof web3 !== 'undefined') {
         //web3.currentProvider.publicConfigStore.on('update', this.init); // detect account change and reinit
         this.setState({web3:web3}); 
         try 
         {
         var accounts = await web3.eth.getAccounts();
         if(ethUtil.isValidAddress(accounts[0]))
         {
            context.newAccountSet(accounts[0]);
         }
         else 
         {
            web3.eth.getCoinbase(function (err, account) {
              if (err === false && ethUtil.isValidAddress(account)) 
              {
                    context.newAccountSet(account);
              } else {
                    context.setError("Please unlock your account and refresh the page:" + err);
                    console.error("app init", err);
              }
            });
         }
        } catch (e)
        {
          if(this.props.isExchangeEnabled==true)
             context.displayError("Please install or unlock Metamask browser or plugin another web3 browser");
        }
    } else {
      if(this.props.isExchangeEnabled==true)
         context.setError("Please install or unlock Metamask browser plugin or navigate this page with Mist or another web3 browser");
         
      context.setStatus("no web3 provider found");
    }
  } 
  
  newAccountSet(account)
  {
    this.setState({account:account});
    this.setStatus("Web3 " + account);
  }
  
  // accountChanged()
  // {
  //   debugger;
  //   this.logout();
  // }

  logout() {
    //debugger;
    this.setState({profile:null});
    this.setState({accessToken:null});
    this.setState({balances:null});
    this.setState({completedBuys:null});
    this.setState({completedSells:null});
    this.setState({completedTrades:null});
    this.setState({completedTradeDetail:null});
    this.setState({sellOrders:null});
    this.setState({buyOrders:null});
    this.setState({sellerCompanies:null});
    this.setState({buyerCompanies:null});
    this.setState({companyShipment:null});
    this.setState({companyDelivery:null});
    this.setState({companyDetail:null});
    this.setState({trades:null});

    this.state.comm.sendData("logout", "logout");
    this.setState({redirectToLogin:true});
  }
  login() {
    //this.logout();
    this.setState({login:true})
    this.state.comm.sendData("login", "login");
  }
  justTry() {
    this.logout();
    this.setState({login:false})
    //this.state.comm.sendData("justTry", "hello");
  }

  loadData = async (e) => {
    this.request({
        url: window.energonUrl + 'api/values',
        headers: {
            'Authorization': 'Bearer ' + this.state.accessToken
        }
    }).then(data => {
        console.log("loadData", data);
        var json = JSON.parse(data);
        console.log(json);
        /*
        for (let i = 0; i < json.length; i++) {
           data_list.innerHTML += '<li>' + json[i] + '</li>';
        } */
    }).catch(error => {
        console.error(error);
    });

}

  render ()
  {
    if (this.state.redirectToLogin===true) {
      this.setState({redirectToLogin:false});
      //return <Router><Redirect push to="/" /></Router>;
    }
    if (this.state.redirectToProfile===true) {
      this.setState({redirectToProfile:false});
      return <Router><Redirect push to="/profile" /></Router>;
    }

    let error = null;
    if(this.state.web3!==null)
      if(this.state.web3._currentProvider===null) error = <div>No web3 provider. Please install web3 provider like MetaMask.</div>;

    let login = this.state.accessToken===null && this.state.login===true ?  <LoginScreen app={this} accessToken={this.state.accessToken} web3={this.state.web3}/> : null;

    if(this.state.account===null) error =  <div>{error}No account.</div>;
    if(this.state.timeoutID!==0)  error = <div>{error} No connection to server</div>;

    //let exchangeView = this.state.accessToken===null ?  <ExchangeView app={this} data={this.state}/> : <ExchangeOverview app={this} data={this.state}/>;
    let exchangeView = <ExchangeView app={this} data={this.state} markets={this.state.markets} allMarkets={this.state.allMarkets} />;
    //let auctionView = <AuctionsView app={this} data={this.state}/>;
    let newsBanner = <NewsView app={this}/>;
    let footerBanner = <Footer app={this} isExchangeEnabled={this.state.isExchangeEnabled}/>;

   return (
      
      <div className="App">
        <Router>
          {<TopBanner app={this} isExchangeEnabled={this.state.isExchangeEnabled}/>}
          {/*<NewsBanner app={this}/>*/}
          {login}
          {/*exchangeView*/}

          <Switch>
            <Route path="/" exact render={(props) => (<InitialView markets={this.state.allMarkets} />) }/>
            <Route path="/hydrogenx" exact render={(props) => (<ViewProcurementNetwork/>) }/>
            <Route path="/hydrogenx/search" exact render={(props) => (<SearchProcurementNetwork/>) }/>

            <Route path="/register/supplier" exact render={(props) => (<RegisterNewUser userType="Supplier"/>) }/>
            <Route path="/register/buyer" exact render={(props) => (<RegisterNewUser userType="Buyer"/>) }/>

            <Route path="/register/producer" exact render={(props) => (<RegisterNewUser userType="Producer"/>) }/>
            <Route path="/register/consumer" exact render={(props) => (<RegisterNewUser userType="Consumer"/>) }/>
            <Route path="/register/distributor" exact render={(props) => (<RegisterNewUser userType="Distributor"/>) }/>
            <Route path="/register/analyticsprovider" exact render={(props) => (<RegisterNewUser userType="AnalyticsProvider"/>) }/>
            <Route path="/register/dataprovider" exact render={(props) => (<RegisterNewUser userType="DataProvider"/>) }/>

            <Route path="/verify/email" exact render={(props) => (<RegisterNewUser userType="email"/>) }/>
            <Route path="/verify/phone" exact render={(props) => (<RegisterNewUser userType="phone"/>) }/>
            <Route path="/verify/certificate" exact render={(props) => (<RegisterNewUser userType="certificate"/>) }/>
           
            <Route path="/apply" exact render={(props) => (<Apply/>) }/> 
            <Route path="/apply/company" render={(props) => (<ApplyCompany/>) }/>
            <Route path="/apply/bank" render={(props) => (<ApplyBank/>) }/>
            <Route path="/apply/cerficationAuthority" render={(props) => (<ApplyCertificationAuthority/>) }/>
            <Route path="/apply/customsAuthority" render={(props) => (<ApplyCustomsAuthority/>) }/>
            <Route path="/apply/placeOfLoading" render={(props) => (<ApplyPlaceOfLoading/>) }/>
            <Route path="/apply/transportationCompany" render={(props) => (<ApplyTransportationCompany/>) }/>
            <Route path="/apply/certificates" render={(props) => (<ApplyCertificates/>) }/>

            <Route path="/aboutproject" render={(props) => (<AboutProject/>) }/>
            <Route path="/aboutexchange" render={(props) => (<AboutExchange/>) }/>
            <Route path="/aboutprojectcontactinfo" render={(props) => (<ProjectContactInfo/>) }/>
            
            <Route path="/gettoken" render={(props) => (<WelcomeGetTokens/>) }/>

            <Route path="/news" render={(props) => newsBanner}/>
            <Route path="/blog" render={(props) => (<BlogListView /> )}/>
          
            <Route path="/marketplace" render={(props) => exchangeView}/>
            <Route path="/auctions" render={(props) => <AuctionsView app={this} data={this.state} markets={this.state.markets} allMarkets={this.state.allMarkets}/> }/>
            <Route path="/distributions/" render={(props) => <DistributionsView app={this} data={this.state} markets={this.state.markets} allMarkets={this.state.allMarkets}/>}/> 
            <Route path="/dataplace/" render={(props) => <DataplaceView app={this} data={this.state} markets={this.state.markets} allMarkets={this.state.allMarkets}/>}/> 

            <Route path="/page" render={(props) => ( <PageView comm={this.state.comm} {...props}/> )} />
          
            <Route path="/wallet" render={(props) => ( <ClientWalletView comm={this.state.comm} {...props}/> )} />
            <Route path="/profile" render={(props) => ( <ClientProfileView profile={this.state.profile} comm={this.state.comm} {...props}/> )} />
            <Route path="/balances" render={(props) => ( <ClientBalancesView balances={this.state.balances} comm={this.state.comm} account={this.state.account} appstate={this.state} {...props}/> )} />

            <Route path="/management" render={(props) => ( <ClientManagementView comm={this.state.comm} tradeInfo={this.state.tradeInfo} buys={this.state.buyOrders} sellOrders={this.state.sellOrders} completedTrades={this.state.completedTrades} {...props}/> )} />

            <Route path="/trades" render={(props) => ( <ClientOrdersViewTrades comm={this.state.comm} tradeInfo={this.state.tradeInfo} completedTrades={this.state.completedTrades} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />
            <Route path="/buyorders" render={(props) => ( <ClientOrdersViewBuys comm={this.state.comm} tradeInfo={this.state.tradeInfo} buys={this.state.buyOrders} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />
            <Route path="/sellorders" render={(props) => ( <ClientOrdersViewSells comm={this.state.comm} tradeInfo={this.state.tradeInfo} sells={this.state.sellOrders} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />

            <Route path="/completedbuys" render={(props) => ( <ClientOrdersCompletedBuys comm={this.state.comm} tradeInfo={this.state.tradeInfo} buys={this.state.completedBuys} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />
            <Route path="/completedsells" render={(props) => ( <ClientOrdersCompletedSells comm={this.state.comm} tradeInfo={this.state.tradeInfo} sells={this.state.completedSells} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />

            <Route path="/completedtradedetail/:type/:id" render={(props) => ( <ClientCompletedTradeDetail comm={this.state.comm} tradeDetail={this.state.completedTradeDetail} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />

            <Route path="/support" render={(props) => ( <ClientSupportView comm={this.state.comm} {...props}/> )} />

            <Route path="/transportation/settings" render={(props) => ( <TransportationDashboard comm={this.state.comm} {...props}/> )} />
            <Route path="/transportation/contract" render={(props) => ( <TransportationContract comm={this.state.comm} {...props}/> )} />

            <Route path="/analysis" render={(props) => ( <AnalysisDashboard clientAnalytics={this.state.clientAnalytics} comm={this.state.comm} {...props}/> )} />
            <Route path="/marketplaceanalytics" render={(props) => ( <MarketplaceAnalyticsDashboard marketplaceAnalytics={this.state.marketplaceAnalytics} comm={this.state.comm}  {...props}/> )} />
            <Route path="/analytics/percountry/:id" render={(props) => ( <Analysis_AmountPerCountry comm={this.state.comm} {...props}/> )} />
            

            <Route path="/companyshipment/:id" render={(props) => ( <CompanyShipment comm={this.state.comm} companyShipment={this.state.companyShipment} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />
            <Route path="/companydeliver/:id" render={(props) => ( <CompanyDelivery comm={this.state.comm} companyDelivery={this.state.companyDelivery} markets={this.state.allMarkets} assets={this.state.assets} {...props}/> )} />

            <Route path="/delivery/dashboard" render={(props) => ( <DeliveryDashboard comm={this.state.comm} {...props}/> )} />

            <Route path="/delivery/inprogress" render={(props) => (  <SealedDeliveryInProgress comm={this.state.comm} sealedDeliveries={this.state.sealedDeliveries} markets={this.state.allMarkets} {...props}/> )} />
            <Route path="/delivery/completed" render={(props) => (  <SealedDeliveriesCompleted comm={this.state.comm} sealedDeliveries={this.state.sealedDeliveries}  markets={this.state.allMarkets} {...props}/> )} />
            <Route path="/delivery/view/:id" render={(props) => ( <SealedDelivery comm={this.state.comm} sealedDelivery={this.state.sealedDelivery} markets={this.state.allMarkets} assets={this.state.assets} {...props} /> )} />

            <Route path="/companybuyers" render={(props) => ( <BuyerCompanies comm={this.state.comm} companies={this.state.buyerCompanies} {...props}/>  )} />
            <Route path="/companysellers" render={(props) => ( <SellerCompanies comm={this.state.comm} companies={this.state.sellerCompanies} profile={this.state.profile} {...props}/> )} />

            <Route path="/companyclientview/:id" render={(props) => ( <CompanyClientView comm={this.state.comm} company={this.state.companyDetail} {...props} /> )} />
            <Route path="/companyview/:id" render={(props) => ( <CompanyView comm={this.state.comm} company={this.state.companyDetail} {...props} /> )} />
            <Route path="/bank/:id" render={(props) => ( <CompanyView comm={this.state.comm} company={this.state.companyDetail} {...props}/> )} />
            <Route path="/authority/:id" render={(props) => ( <CompanyView comm={this.state.comm} company={this.state.companyDetail} {...props}/> )} />
            <Route path="/certification/:id" render={(props) => ( <CompanyView comm={this.state.comm} company={this.state.companyDetail} {...props}/> )} />
            <Route path="/placeview/:id" render={(props) => ( <PlaceView comm={this.state.comm} place={this.state.placeDetail} {...props}/> )} />

            <Route path="/guaranteeoforigin/:id" render={(props) => ( <GuaranteeOfOrigin comm={this.state.comm} guaranteeOfOrigin={this.state.guaranteeOfOrigin} {...props}/> )} />

            
         
            <Route component={Notfound} />
          </Switch>

          <Route path="/" render={(props) => ( footerBanner )}/>
          { /*<Footer app={this}/>*/ }
        </Router>
        
        {this.state.isExchangeEnabled ? 
        <div>
            <div className="statusNormalWindow">{this.state.status}</div>
            <div className="statusErrorWindow">{this.state.error} {error}</div>
        </div> : null }

        <ToastContainer />
      </div>
    );
  }
}

/*
        <div className="dataRequest">
            <button type="submit" id="load_data_btn" onClick={() => this.loadData()}>Request secured data</button>
            <ul id="data_list">
            </ul>
        </div> 
*/



export default App;
