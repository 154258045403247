import React from 'react';
import './../../App.css';
import { formatMoney } from '../helpers/Formating';
import {
   Container,
   Grid,
   makeStyles
 } from '@material-ui/core';

 import { ResponsiveBar } from '@nivo/bar'
 
 class Analysis_AmountPerCountry extends React.Component {
    constructor(props){
        super(props);
        this.state = { 
            amountsPerCountry: [] 
        }
        this.getData = this.getData.bind(this);
        this.setData = this.setData.bind(this);
    }
  
    componentDidMount() { 
         this.getData();
         this.intervalHandle = setInterval(this.getData, 13987); // every 2s
    }
    componentDidUpdate(prevProps) {
      /*if(this.props.marketplaceAnalytics !== null && this.state.marketplaceAnalytics===null)
      {
         this.setState({marketplaceAnalytics:this.props.marketplaceAnalytics});
      }*/
    } 
    componentWillUnmount() { 
       clearInterval(this.intervalHandle); 
    }
    getData() {  
      if(this.props.comm!==undefined)
         this.props.comm.getCompletedBuysPerCountry(this,parseInt(this.props.match.params.id)); 
    } 
    setData(data) {
        var arr = []; // we get data as a dictionary, we have to rebuild it into array
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                //arr.push( [ key, data[key] ] );
                arr.push( data[key] );
            }
        }

        this.setState({amountsPerCountry:arr});
        //console.log("got data ", arr);
    }

    render () {
       var d = this.state.amountsPerCountry;
       if(d==null || d.length===0) return <div className="pageViewScrollable">Please Wait</div>

        return <div className="pageViewScrollable" style={{ background: "rgb(115, 122, 151" }}>
                <div className="pageNormalViewContents documentInformationSection">
                    <h2>{window.appComponent.state.allMarkets[parseInt(this.props.match.params.id)-1].name}</h2>
                    <h3>Quantity per country</h3>
                    <div style={{ height: "400px", width: "80%" }}>
                       <ResponsiveBar
                            data={d}
                            keys={[ 'amount' ]}
                            indexBy="name"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'nivo' }}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 1,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'amount'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'orders'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Country',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Quantity',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            legends={[
                                {
                                    itemTextColor: "#ffffff", // <= this worked for me in the end
                                    textColor: "#ffffff",
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    </div>

                    <hr/>
                    <h3>Deals per country</h3>
                    <div style={{ height: "400px" }}>
                     <ResponsiveBar
                            data={d}
                            keys={[ 'orders' ]}
                            indexBy="name"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'nivo' }}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 1,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'amount'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'orders'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Country',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Num orders',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />    
                        
                 </div>
                   
                    <hr/>
                    <h3>Prices per country</h3>
                    <div style={{ height: "400px" }}>
                     <ResponsiveBar
                            groupMode="grouped"
                            data={d}
                            keys={[ 'average', 'min', 'max' ]}
                            indexBy="name"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'nivo' }}
                            
                            fill={[
                                {
                                    match: {
                                        id: 'average'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'min'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Country',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'amt',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            legends={[
                                {

                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />    
                    </div>

               </div>
            </div>
    }
  }

  export default Analysis_AmountPerCountry;