import React from 'react';
import { withRouter } from 'react-router-dom';

class ApplyCertificates extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          enabled: true,
          info:"",
          bankName: "", 
          address: "", 
          city: "", 
          country: "", 
          accountNumber: "", 
          swiftNumber: ""
        }
      }
    
    cancel() {
      this.props.history.push('/apply')
    }
    applyInvoke() {
      if(this.state.bankName.length<2 || 
         this.state.address.length<2 ||
         this.state.swiftNumber.length<6) 
        {
          this.onRegisterComplete("Invalid input");
          return;
        }
        if(this.state.accountNumber.length<9)
        {
          this.onRegisterComplete("Invalid number");
          return;
        }

        var certificate = { 
           certName: this.state.certName,
           data: this.state.data,
        }; 
        console.log(certificate);
        this.setState({enabled:false});
        window.energonxapp.state.comm.newClientCertificate(certificate, this);
    }
    onRegisterComplete(information)
    {
      this.setState({info:information});
      this.setState({enabled:true});
      if(information==="OK")
      {
        //this.props.loginScreen.register();
      }
    }

    hCertName(e) { this.setState({ certName: e.target.value }); }
    hBankAddress(e) { this.setState({ address: e.target.value }); }
    hBankCity(e) { this.setState({ city: e.target.value }); }
    hBankCountry(e) { this.setState({ country: e.target.value }); }
    hAccountNumber(e) { this.setState({ accountNumber: e.target.value }); }
    hSWIFT(e) { this.setState({ swiftNumber: e.target.value }); }
  
    render () {
      return <div className="settingsWindow">
                  <h3>Upload certificates</h3>

                  <div>Please upload certificates sequentially</div> <br/>
                  Certificate Name<br/>
                  <input type="text" placeholder="upload file here" defaultValue={this.state.certName} value={this.state.certName} onChange={(e)=>this.hCertName(e)} className="inputBox"/><br/>

                  {this.state.info.length>0 ? <div style={{color:"red"}}>{this.state.info}</div> : null}
                  {this.state.enabled==false ? <div className="settingsWindow">
                                                    <h1>EnergonX</h1>          
                                                    <h2>Communicating</h2> 
                                                    <h3>Please wait ...</h3> 
                                              </div> : null }
                  <br/>
                  {this.state.enabled==true ? <button onClick={() => this.applyInvoke()}>Upload</button> : null }
                   <br/>
                  {this.state.enabled==true ? <button onClick={() => this.cancel()}>Cancel</button> : null }
            </div>
    }
}

export default withRouter(ApplyCertificates);