import React from 'react';
//import './../../App.css';
import { Link } from "react-router-dom";

class ClientManagementView extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
          this.getData();
          this.intervalHandle = setInterval(this.getData, 10000); // every 50s
    }
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }

    componentDidUpdate(prevProps) {
    } 

    getData() { 
      if(this.props.comm!==undefined)
         this.props.comm.getClientTradeInfo(); 
    }

    render () {
        let tradeInfo = this.props.tradeInfo;
        //console.log(tradeInfo);
        if(tradeInfo===null) return <h3 className="pageViewScrollable">No data</h3>

        return <div className="pageViewScrollable">
                  <h1>MANAGEMENT</h1>
                  <div className="pageNormalViewContents">
                  <table className="profileVerificationSection tableFinancialView" style={{verticalAlign:'top'}}> 
                          <thead>
                               <tr> 
                                   <th></th>
                                   <th></th>
                                   <th></th>
                              </tr> 
                           </thead>                       
                        <tbody>
                              <tr>
                                 <td>
                                    <h2>ORDERS</h2>
                                    <Link to="buyorders"><h3>BUY ORDERS</h3></Link>
                                    Active buy orders: {tradeInfo.buys}
                                    <Link to="sellorders"><h3>SELL ORDERS</h3></Link>
                                    Active sell orders: {tradeInfo.sells}
                                 </td>
                                 <td>
                                    <h2>CONTRACTS</h2>
                                    <Link to="completedbuys"><h3>COMPLETED BUYS</h3></Link>
                                    Succesfully bought: {tradeInfo.completedBuys}          
                                    <Link to="completedsells"><h3>COMPLETED SELLS</h3></Link>
                                    Succesfully sold: {tradeInfo.completedSells} 
                                 </td>
                                 <td><h2>COSTUMERS</h2>
                                    <Link to="companysellers"><h3>VENDORS</h3></Link>
                                    Awaiting delivery: {tradeInfo.ordersAwaitingDelivery}            
                                    <Link to="companybuyers"><h3>BUYERS</h3></Link>
                                    Awaiting shipment: {tradeInfo.ordersAwaitingShipment}            
                                 </td>
                               </tr>

                               <tr>
                                 <td>
                                   <h2>DELIVERY</h2>
                                    <Link to="/delivery/inprogress"><h3>IN PROGRESS</h3></Link>
                                    {tradeInfo.inProgressDeliveries} In progress                                   
                                    <Link to="/delivery/completed"><h3>COMPLETED</h3></Link>
                                    {tradeInfo.completedDeliveries} Completed

                                    <Link to="delivery/dashboard"><h3>DASHBOARD</h3></Link>
                                 </td>

                                 <td><h2>TRANSPORTATION</h2>
                                    <Link to="transportation/settings"><h3>SETTINGS</h3></Link>
                                    Status: {tradeInfo.transportationInProgress}
                                    <Link to="transportation/contract"><h3>CONTRACT</h3></Link>
                                    In progress: {tradeInfo.transportationContracts}
                                 </td>

                                 <td></td>
                               </tr>

                               
                               <tr>
                                 <td><h2>ANALYSIS</h2>
                                  <Link to="analysis"><h3>DASHBOARD</h3></Link>
                                  <Link to="marketplaceanalytics"><h3>MARKETPLACE STATISTICS</h3></Link>
                                 </td>
                                 <td><h2>HISTORY</h2>
                                  <Link to="trades"><h3>TRADES</h3></Link>
                                  Trades completed: {tradeInfo.trades}
                                 </td>                                 
                                 <td></td>
                               </tr>
                        </tbody>
                       </table>
                  </div>
               </div>
    }
  }

  export default ClientManagementView;

  /*
 
*/