import React from 'react';
import { Link } from "react-router-dom";
import './../App.css';

class TopBanner extends React.Component {
    

    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }
    
    cancel() {
      this.props.loginScreen.register();
    }

    viewWallet() {

    }
    viewProfile() {

    }
    viewBalances() {

    }
    viewOrders() {

    }
    viewSupport() {

    }

  
    render () {
      let token = this.props.app.state.accessToken;
      // let wallet  = token!==null ?  <Link className="bannerLink" to="/wallet">WALLET</Link> : <span/>; 
      let exchange =                 <Link className="bannerLink" to="/marketplace">MARKETPLACE</Link>;
      let auction =  token!==null ?  <Link className="bannerLink" to="/auctions">AUCTIONS</Link> : <span/>; 

      let distribution =  token!==null ?  <Link className="bannerLink" to="/distributions">DISTRIBUTION</Link> : <span/>; 
      let dataplace =  token!==null ?  <Link className="bannerLink" to="/dataplace">DATAPLACE</Link> : <span/>; 

      let account = token!==null ?   <Link className="bannerLink" to="/profile">PROFILE</Link> : <span/>;
      let balances = token!==null ?  <Link className="bannerLink" to="/balances">BALANCES</Link> : <span/>; 
      let orders = token!==null ?    <Link className="bannerLink" to="/management">MANAGE</Link> : <span/>; 
      // let support = token!==null ?  <Link className="bannerLink" to="/support">SUPPORT</Link> : <span/>;  
      //let login   = token===null ?  <span className="topLink" onClick={()=>this.props.app.login()}>Login</span> : <span className="topLink" onClick={()=>this.props.app.logout()}>Logout</span>;
      let login   = token===null ?  <Link className="bannerLink" to="#" onClick={()=>this.props.app.login()}>LOGIN</Link> : 
                                    <Link className="bannerLink" to="#" onClick={()=>this.props.app.logout()}>LOGOUT</Link>;   

      if(this.props.isExchangeEnabled==false)
      {
        exchange = null;
        login = null;
      }
      // <span className="topLink" onClick={()=>this.props.app.login()}>Login</span> : <span className="topLink" onClick={()=>this.props.app.logout()}>Logout</span>;
      // 💧
      return <div className="topBanner">
                <div className="topLogo"> <Link to="/"><strong>EnergonX</strong></Link> </div>
                {exchange}
                {auction}
                {distribution}
                {dataplace} 
                {/* {<span/>} */}
                {/* {<span/>} */}
                {<span/>}

                    {/* {wallet} */}
                    {account}
                    {balances}
                    {orders}
                    {/* {support} */}
                    {/*login*/} 
                    {login}
              </div>

    }
  }

  export default TopBanner;
/*
      let wallet  = token!==null ?  <span className="topLink" onClick={()=>this.viewWallet()}><Link to="wallet">Wallet</Link></span> : <span/>; 
      let account = token!==null ?  <span className="topLink" onClick={()=>this.viewProfile()}>Profile</span> : <span/>; 
      let balances = token!==null ? <span className="topLink" onClick={()=>this.viewBalances()}>Balances</span> : <span/>; 
      let orders = token!==null ?   <span className="topLink" onClick={()=>this.viewOrders()}>Orders</span> : <span/>; 
*/