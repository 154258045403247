import React from 'react';
//import './../../App.css';
import Pager from "./../helpers/Pager.js";

class ClientOrdersViewTrades extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
            numPages:0,
            page:0
    }

    this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        this.getData();
        this.intervalHandle = setInterval(this.getData, 50000); // every 50s

        if(this.props.tradeInfo!=null)
            this.setState({numPages:Math.ceil(this.props.tradeInfo.trades/25)});             
    }
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }

    getData() { 
    if(this.props.comm!==undefined)
        this.props.comm.getClientCompletedTrades(this.state.page); 
    }
    async setPage(p) { if(p>0 && p<=this.state.numPages) { await this.setState({page:p}); this.getData(); }}
    nextPage() { this.setPage(this.state.page+1); }
    prevPage() { this.setPage(this.state.page-1); }
    firstPage(){ this.setPage(1); }
    lastPage() { this.setPage(this.state.numPages); }

    render () {
        let data = this.props.completedTrades;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">No data</h3>

        return <div className="pageViewScrollable">
        <h2>TRADE HISTORY</h2>
         Page {this.state.page} / {this.state.numPages}
         <div className="pageNormalViewContents">
            <div>
                <table className="balancesSection"> 
                  <thead>
                      <tr> 
                          <th>Market</th>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Total Value</th>
                          <th>Actions</th>
                     </tr> 
                  </thead>
                  <tbody>
                  {/*data.map(o => 
                     <tr key={o.id}>
                       <td> {o.marketId} </td>
                       <td> {o.price} </td>
                       <td> {o.amount} </td>
                       <td> {(o.price * o.amount).toFixed(8)} </td>
                       <td> Cancel </td>
                     </tr>
                  )*/}
                  </tbody>
                </table> 

                <Pager component={this}/>   
            </div>

         </div>
      </div>
    }
  }

  export default ClientOrdersViewTrades;