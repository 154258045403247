// set these per locale of the user
export var formatingThousands = ".";
export var formatingDecimal = ",";
export var formatingDecimalCount = 2;

export function formatMoney(amount, decimalCount = formatingDecimalCount, decimal = formatingDecimal/*"."*/, thousands = formatingThousands/*","*/) {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
}
  /*
  document.getElementById("b").addEventListener("click", event => {
    document.getElementById("x").innerText = "Result was: " + formatMoney(document.getElementById("d").value);
  });*/


  /* returns HH:MM:ss*/
  export function timeDuration(timestampFrom, toFutureTimestamp)
  {
    var dateFuture = new Date(toFutureTimestamp);
    var dateNow = new Date(timestampFrom);

    var seconds = Math.floor((dateFuture - (dateNow))/1000);
    var minutes = Math.floor(seconds/60);
    var hours = Math.floor(minutes/60);
    var days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return "" + hours + ":" + minutes + ":" + seconds;
  }

  /* returns DD:HH:MM:ss*/
  export function timeDurationWeeks(timestampFrom, toFuturetimestamp)
  {
    var d = Math.abs(toFuturetimestamp - timestampFrom) / 1000;                // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
        //year: 31536000,
        //month: 2592000,
        //week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function(key){
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });

    //console.log(r);
    return r.day + ":" + r.hour + ":" + r.minute + ":" + r.second;
  }