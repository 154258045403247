import React from 'react';
import './../../App.css';

import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Grid, Card, CardHeader, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core/';
import AccountSelector from "../registrations/AccountSelector.js"; 

// import TreeView from '@material-ui/lab/TreeView';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import TreeItem from '@material-ui/lab/TreeItem';

class SupplierType extends React.Component {
    render() 
    {
        let t = this.props.t;
        return (<span className="left"> 
                  &nbsp;&nbsp;<Link to="#">{t.name}</Link><br/>
               </span>)
    }
}

class SupplierGroup extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          enabled: false
        }
        
    }
    onToggle(g)
    {
        this.setState({enabled:!this.state.enabled}); 
    }
    render() 
    {
         let g = this.props.group;
         let t = g.storeSupplierTypes !== undefined ? g.storeSupplierTypes : g.storeProductTypes;

         return <div className=""> <Link to="#" onClick={()=>this.onToggle(g)}><strong>{g.name}</strong></Link>
                      {this.state.enabled ? 
                            <> <br/> { t.map((t,i) => (<SupplierType key={"st"+i} t={t}/>)) } </>
                            : null } 
                </div>
    }
}

class SearchProcurementNetwork extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          enabled: true,
          info:"",
          searchQuery: "", 
          supplierGroups:  [], 
          productGroups: [], 
          searchResults: "", 
        }
        this.getData = this.getData.bind(this);
      }

    componentDidMount()
    {
        this.getData();
        this.intervalHandle = setInterval(this.getData, 543); // every 2s
    }
    componentWillUnmount() { 
        clearInterval(this.intervalHandle); 
    }

    getData()
    {
        if(this.state.productGroups.length !== 0) return;
        if(window.energonxapp.state.comm.connection.connectionStarted===true)
           window.energonxapp.state.comm.getSearchGroups(this); 
    }
    applyInvoke() {
        /*if(this.state.searchQuery.length<2) 
        {
          this.onRegisterComplete("Invalid input");
          return;
        } */
        var query = { 
           searchQuery: this.state.searchQuery,
        }; 
        console.log(query);
        this.setState({enabled:false});
        window.energonxapp.state.comm.newStoreSearch(this.state.searchQuery, this);
    }
    onSearchComplete(information)
    {
      this.setState({info:information});
      this.setState({enabled:true});
      if(information==="OK")
      {
        //this.props.loginScreen.register();
      }
    }
    setProductGroups(groups)
    {
        this.setState({productGroups: groups});
    }
    setSupplierGroups(groups)
    {
        this.setState({supplierGroups: groups});
    }
    setSearchResults(result)
    {
        this.setState({searchResults: result});
    }

    hSearchQuery(e) { this.setState({ searchQuery: e.target.value }); }
  
    render () {
        

        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents">
                    <div className="viewSearchSummary">
                        {/* <h3>What are you looking for ?</h3> */}
                         &nbsp;  <input type="text" placeholder="Search" value={this.state.fullName} onChange={(e)=>this.hSearchQuery(e)} className="inputBox" style={{width:"70%"}}/> 

                        {this.state.enabled==true ? <button onClick={() => this.applyInvoke()} className="inputBox"> &nbsp; Search &nbsp; </button> : null }
                        {this.state.info.length>0 ? <div style={{color:"red"}}>{this.state.info}</div> : null}                                
                    </div>
                        
                        {this.state.enabled==false ? <div className="settingsWindow">
                                                        <h2>Communicating</h2> 
                                                        <h3>Please wait ...</h3> 
                                                    </div> : null }


                        {/* {this.state.enabled==true ? <button onClick={() => this.cancel()}>Cancel</button> : null } */}

                        {/* <TreeView
                            className={classes.root}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpanded={['root']}
                            defaultExpandIcon={<ChevronRightIcon />}
                            >
                            {renderTree(data)}
                        </TreeView> */}

                        <br/>
                        <div className="viewSearchGroups">
                            {this.state.supplierGroups.map((g,i) => (<SupplierGroup key={"sg_"+i} group={g} />) )}
                            <br/> Products <br/>
                            {this.state.productGroups.map((g,i) => (<SupplierGroup key={"pg_"+i} group={g} />) )}
                        </div>
              </div> 
             </div>
      }
}


export default withRouter(SearchProcurementNetwork);