import React from 'react';
import '../../App.css';
import { Link } from "react-router-dom";
//import {Card, CardActionArea, CardActions, CardContent, CardMedia, CardHeader, Collapse, Papar, Typography, Button, Grid } from '@material-ui/core/';
import { Grid } from '@material-ui/core/';

import Chart from 'react-apexcharts';
import NewsCardView from '../Small/NewsCardView.js'; 
import StaticMarketAnalysis from '../Analysis/StaticMarketAnalysis.js';   
//import Modal from './node_modules/react';


class ProjectContactInfo extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        page:0,
        email:"info@energonx.com"
      }
    }

    componentDidMount() {  }
    componentWillUnmount() { }

    render () {
        
        return <div className="pageViewScrollable">
                <div className="pageViewContents">

                   <h2>Contact Info</h2>
                   <p>Project conceptualized and initiated by MediaAtlas d.o.o.</p>
                      <table className="profileInformationSection">
                        <thead>
                          <tr className="orderHeader">
                              <th></th>
                              <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Company:</td>
                            <td>MediaAtlas d.o.o.</td>
                          </tr>
                          <tr>
                            <td>Contact person:</td>
                            <td>Tadej Fius</td>
                          </tr>
                          <tr>
                            <td>Address:</td>
                            <td>Kajuhova 11, 8290 Sevnica, Slovenia, EU</td>
                          </tr>
                          <tr>
                            <td>Tax ID: </td>
                            <td>SI34092234</td>
                          </tr>
                          <tr>
                            <td>Reg.Num: </td> 
                            <td>6942733000</td>
                          </tr>
                          </tbody>
                      </table>
                   

                   <p>Project will be under the umbrella of MediaAtlas until end of first phase, estimated at end of 2022.<br/>
                    When regulatory compliances are known and met a new legal and compliant entity will be established to handle platform business development. <br/>
                  </p>

                   <p><a href={"mailto:" + this.state.email}>Send us email</a></p> 
                </div> 
               </div>
    }
  }

export default ProjectContactInfo;