import React from 'react';
import './../App.css';
import NumberFormat from 'react-number-format';
import PriceRender from './helpers/PriceRender.js';
import {Tooltip} from '@material-ui/core/';

/*
class PriceRender extends React.Component {
  render() {
    let s = this.props.summary;
    //console.log(s);
    let p = s.change > 0 ? <span style={{ color: 'var(--greenColor)' }}> <NumberFormat value={s.last} format="#######" displayType={'text'} decimalScale={3} fixedDecimalScale={true}/> </span> : 
                           <span style={{ color: 'var(--redColor)' }} >  <NumberFormat value={s.last} format="#######" displayType={'text'} decimalScale={3} fixedDecimalScale={true}/> </span>

    return  p; 
  }
}*/
/*
class PercentageRender extends React.Component {
  render() {
    let s = this.props.summary;
    let p = s.change >= 0 ? <span style={{ color: 'green' }} > <NumberFormat value={s.last} format="####" displayType={'text'} decimalScale={2} fixedDecimalScale={true}/> </span> : 
                            <span style={{ color: 'red' }} > <NumberFormat value={s.last} format="####" displayType={'text'} decimalScale={2} fixedDecimalScale={true}/>  % </span>

    return  p; 
  }
}

class MarketShortRender extends React.Component {
  render() {
    //console.log("Render", this.props.app.state.exchange, this.props.exchange);
    let e = this.props.market.id === this.props.id ?
     <strong style={{ color: 'rgb(0, 141, 223)' }} >{this.props.market.name}</strong> :
     <small> {this.props.market.name}</small>; 

     return e;
  }
}
*/
export class MarketsAvailable extends React.Component {
  selectMarket(market) {
    //console.log("MarketsAvailable select", market);
    this.props.marketsView.selectMarket(market);
  }
  render() {
    let markets = this.props.markets;
    let market = this.props.market;
    let allMarkets = this.props.allMarkets;
    let viewMarkets=null;
    if(allMarkets===undefined || allMarkets.length===0) return null;

    if (markets.length !== 0 && market !== null)
    {
      viewMarkets = markets.map(m => 
        <tr key={m.id} className="marketItem">
              <Tooltip title={m.pair.quoteAsset.purityLevelQualification.typicalUses} placement="bottom">
                <td className="textLeft" onClick={() => this.selectMarket(m)}>   {market.id===m.id ? <strong style={{ color: 'var(--itemHighliteColor)' }} >{m.name}</strong> : m.name} </td>
              </Tooltip>
              <td className="textRight" onClick={() => this.selectMarket(m)}>  {market.id===m.id ? 
                    <strong> <PriceRender summary={allMarkets[m.lookupIdx].summary}/> </strong> : 
                             <PriceRender summary={allMarkets[m.lookupIdx].summary}/>} 
              </td> 
          </tr>
        );
    }

    return <div className="viewMarkets">
      <table width="100%">
         <thead> 
          <tr>
            <th className="orderHeader marketName">MARKET</th>
            <th className="orderHeader marketPrice">PRICE</th>
          </tr>
          </thead>
          <tbody>
            {viewMarkets}
         </tbody>

      </table>
    </div>;
  }
}
// <MarketShortRender id={m.id} market={m} onClick={() => this.selectMarket(m)}/>

export default MarketsAvailable;