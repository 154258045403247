import React from 'react';
import './../../App.css';

import { Link } from "react-router-dom";
import {Grid, Card, CardHeader, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core/';

class AccountSelector extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      page:0
    }
  }

  render () {
    return <Card >
                <CardHeader variant="h5" component="h1"
                  title= {this.props.title}
                  subheader={<Link to={this.props.subheaderLink}> {this.props.subheader} </Link>}
                />
                <CardActionArea style={{height:"25vmin", minWidth:"150px", maxWidth:"250px"}}>
                    <Link to={this.props.subheaderLink}> 
                        <CardMedia > 
                            <img src={this.props.image} alt={this.props.alt} style={{maxHeight:"150px", height:"25vmin", minHeight:"32px"}}/>
                        </CardMedia>
                    </Link>
                   
                    <CardContent style={{textAlign:"-webkit-center"}}>
                        <Typography variant="body2" component="" >
                        {this.props.text}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                </CardActions>                    
          </Card>
  }
}

export default AccountSelector;