import React from 'react';
//import './../../App.css';
import { Link } from "react-router-dom";
import Pager from "./../helpers/Pager.js";    

class ClientOrdersCompletedSells extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        this.getData();
        this.intervalHandle = setInterval(this.getData, 42000); // every 50s

        if(this.props.tradeInfo!=null)
           this.setState({numPages:Math.ceil(this.props.tradeInfo.completedSells/25)});             
    }
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }

    getData() { 
      //console.log("ClientOrdersCompletedBuys", this.props);
      if(this.props.comm!==undefined)
         this.props.comm.getClientCompletedSells(this.state.page); 
    }

    async setPage(p) { if(p>0 && p<=this.state.numPages) { await this.setState({page:p}); this.getData(); }}
    nextPage() { this.setPage(this.state.page+1); }
    prevPage() { this.setPage(this.state.page-1); }
    firstPage() { this.setPage(1); }
    lastPage() { this.setPage(this.state.numPages); }

    render () {
      let data = this.props.sells;
      if(data===null || data===undefined) return <h3 className="pageViewScrollable">No data</h3>
      if(data.length===0) return <h3 className="pageViewScrollable">No trades</h3>
      
      return <div className="pageViewScrollable">
               <h2>SOLD GOODS</h2>
                
                <div className="pageNormalViewContents">
                    <table className="balancesSection tableFinancialView"> 
                      <thead>
                          <tr > 
                                {/* <th>Time</th>  */}
                                <th>MARKET</th>
                                {/* <th>ASSET</th> */}
                                {/* <th>Bought from</th> */}
                                <th>AMOUNT</th>
                                <th>PRICE</th>
                                <th>TOTAL</th>
                                <th>FEE</th>
                                <th>SENT</th>
                                <th>ACTION</th>
                        </tr> 
                      </thead>
                      <tbody>
                      {data.map(o => 
                        <tr key={o.id}>
                          {/* <td> {new Date(o.created).toLocaleString()}</td>  */}
                          <td>{this.props.markets[o.marketId-1].name} {this.props.assets[o.assetId-1].symbol}</td>
                          {/* <td> {o.soldToClientId} </td> */}
                          <td> {o.amount} </td>
                          <td> {o.price} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                          <td> {o.total} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                          <td> {o.fee} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                          <td> {o.processed} </td>
                          <td> 
                            <Link to={"/completedtradedetail/sell/"+ o.id} >View | </Link>
                            <Link to={"/companydeliver/"+ o.soldToClientId}> Deliver </Link> 
                          </td>
                        </tr>
                      )}
                      </tbody>
                    </table> 

                    <Pager component={this}/>                             
                </div>
             </div>
    }
  }

  export default ClientOrdersCompletedSells;