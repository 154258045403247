import React from 'react';
import './../App.css';
import CompanyDetailView from './Company/CompanyDetailView';
import ClientProfileCompanyView from './profile/Client_Profile_View.js';

class CompanyClientView extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
        this.getData();
    }
    componentWillUnmount() {
    }
    getData() { 
      console.log("CompanyClientView", this.props);
      if(this.props.comm!==undefined)
      {
          this.props.comm.getClientCompanyView(this.props.match.params.id); 
      }
    }


    render () {
        let company = this.props.company;
        if(company===null || company===undefined) return <h3 className="pageViewScrollable">Awaiting company data</h3>

        return <div className="pageViewScrollable">
                  <h3>Company Detail</h3>
                  <CompanyDetailView company={company} />
               </div>
    }
  }

export default CompanyClientView;
