import React from 'react';
import { Link } from 'react-router-dom';

import { formatMoney } from './../helpers/Formating';
import ExchangesAvailable from '../View_ExchangesAvailable.js';
import MarketsView from '../View_Markets.js';
import DistributionMap from '../Distribution/View_DistributionMap.js';
import DataplaceCategoriesView from './View_DataplaceCategories.js';

import {Grid, Card, CardHeader, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core/';



class DataplaceView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          info:"",
          height: 595,
          width: 500,
          categories: [],
          selectedCategory: null,
          dataObjects: []
        };

        this.getData = this.getData.bind(this);
        this.getDataplaceMarkets = this.getDataplaceCategories.bind(this);
    }

    componentDidMount() { 
        this.getData();
        this.getDataplaceCategories(); 
        
        this.intervalHandle = setInterval(this.getData, 10000); // every n
    }
    componentWillUnmount() { 
      clearInterval(this.intervalHandle); 
    }
    setData(categories) {
      this.setState({categories:categories});
    }
    getData() {  
      var app = this.props.app;
      var m = app.state.market;
      if(app.state.comm!==undefined && m!==null)
      {
         //app.state.comm.getMarketTransportations(m.id); 
         //app.state.comm.getYourTransportations(m.id); 
      }
    }

    getDataplaceCategories() {  
      var app = this.props.app;
      if(app.state.comm!==undefined)
      {
         app.state.comm.getDataCategories(this, ""); 
      }
    }
    async selectExchange(exchange)
    {
        await this.props.app.setExchange(exchange);
        this.getData();
    }  
    async selectMarket(market)
    {
        await this.props.app.setMarket(market);
        this.getData();
    } 
    async selectDataplaceCategory(category)
    {
        var app = this.props.app;
        var m = app.state.market;
        if(app.state.comm!==undefined && m!==null)
        {
          app.state.comm.getDataObjectsForMarket(this, m.id, category.id); 
        }
    } 
    async setDataObjectsForMarket(data)
    {
      this.setState({dataObjects:data});
    }
    /*
    selectDataplaceMarket(dataplaceMarket, index)
    {
        console.log("DataplaceMarket selected", dataplaceMarket, index);
    }
    
    selectYourDataplaceMarket(dataplaceMarket)
    {
        console.log("Your dataplace market selected", dataplaceMarket);
    } */
      
    render () {
        let app = this.props.app;
        let data = this.props.data;
        let comm = app.state.comm; 
        let transportation = data.transportation; // view all auctions for market

        let market = data.market;
        let transportationBook = null;
        let yourTransportationBook = null;
        let transportationControl = null;
        if(this.props.app.state.accessToken!==null && this.props.app.state.accessToken!==undefined)
        {
            //transportationBook = <TransportationsBookView app={app} transportations={marketTransportations} distributionView={this}/>; 
            // yourTransportationBook = <YourTransportationsView app={app} transportations={yourTransportations} distributionView={this}/>; 
        }

        var company = data.profile !=null ? data.profile.company : { name: "",  addressInfo: { coordinate: { latitude: 46, longitude: 21 }}};
        if(transportation!=null)  
        {
           // transportationControl = <TransportationControlView transportation={transportation} comm={comm} distributionView={this} />
        } 
        else 
        {
          transportationControl = <div className="viewAuctionTextBottom" >
                                    <ol>Available data from providers:
                                      <li>Display available data</li>
                                      <li>Bids for access rights</li>
                                      <li>Each bidder is allowed to bid and update bid price until expiration.</li>
                                    </ol>
                                  </div>
        } 

        return <div className="viewExchange">
                 <ExchangesAvailable app={app} exchangeView={this} /> 
                 <MarketsView allMarkets={this.props.allMarkets} markets={this.props.markets} market={market} exchangeView={this}/>
                 <DataplaceCategoriesView categories={this.state.categories} 
                                          app={app}
                                          allMarkets={this.props.allMarkets} markets={this.props.markets} market={market} exchangeView={this}
                                          /> 

                  <div className="viewMarketSummary"> <h2>&nbsp;&nbsp;&nbsp;Dataplace </h2> &nbsp;&nbsp; 
                      <div className="rightSide topPadding">
                        <Link className="bannerLink" to="#">FREE</Link> &nbsp; 
                        <Link className="bannerLink" to="#">DIRECT</Link> &nbsp;
                        <Link className="bannerLink" to="#">DAILY</Link> &nbsp; 
                        <Link className="bannerLink" to="#">MONTHLY</Link> &nbsp; 
                        <Link className="bannerLink" to="#">QUARTERLY</Link> &nbsp; 
                        <Link className="bannerLink" to="#">YEARLY</Link> &nbsp; 
                        <Link className="bannerLink" to="#">SUBSCRIPTIONS</Link> &nbsp; 
                      </div>
                  </div> 
                  
                  <div className="viewDataObjectsCards">
                    <div className="dataplaceViewScrollable">
                      <div className="dataplaceContents">
                        <div className="viewDataObjectsCardsFlex">
                          {this.state.dataObjects.map(d => 
                              <DataObjectCard key={"doc"+d.id} dataobject={d} parent={this}/> 
                            )}
                          </div>
                      </div>
                    </div>
                  </div>
               </div>
    }
}

export default DataplaceView;

class DataObjectCard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      page:0,
      showBuyChoice: false,
      isBuying: false,
      error: null,
      bought: false,
    }
  }

  onBuyFail(errordata)
  {
    this.setState({showBuyChoice:false})
    this.setState({isBuying: false});
    this.setState({error:errordata});
  }
  onBuyCompleted(data)
  {
    this.setState({showBuyChoice:false})
    this.setState({isBuying: false});
    this.setState({error:null});
    this.setState({bought:true});
    this.props.dataobject.bought = true;
    this.forceUpdate();
  }
  onClickBuy(dataobject)
  {
    console.log("buy", dataobject)
    this.setState({showBuyChoice:true})
  }
  onClickBuyYes(dataobject)
  {
    this.setState({showBuyChoice:false})
    this.setState({isBuying:true})
    console.log(this.props.parent.props.app.state.comm);
    this.props.parent.props.app.state.comm.buyDataObject(this, this.props.parent, dataobject.id)
    //this.props.parent.props.app.comm.buyDataObject(this, dataobject.id);
  }
  onClickBuyNo(dataobject)
  {
    this.setState({showBuyChoice:false})
  }
  onClickInfo(dataobject)
  {
    console.log("info", dataobject)
    //this.setState({showBuyChoice:false})
  }

  render () {
    var dataobject = this.props.dataobject; 
    var priceFormat = formatMoney(dataobject.price) + " Ħ";
    // process of buying
    if(this.state.isBuying)
    {
      return <Card style={{height:"25vmin", minHeight:"25vmin", margin:"10px", width:"25vmin"}}>
                    <CardActionArea style={{height:"25vmin", minWidth:"150px", maxWidth:"250px"}}>
                        <CardHeader variant="h5" component="h1" style={{textAlign:"-webkit-center"}} 
                          title={dataobject.title}
                        />
                    <CardContent style={{textAlign:"-webkit-center",maxHeight:"100px", height:"100px", overflow: "hidden", bottom:"0px"}}>
                        <Typography variant="body1" component="" >
                          please wait...  
                          {this.state.error}
                        </Typography>
                    </CardContent> 
                    </CardActionArea>
             </Card>
    }
    // showing yes/no buy card
    if(this.state.showBuyChoice)
    {
      return <Card style={{height:"25vmin", minHeight:"25vmin", margin:"10px", width:"25vmin"}}>
                    <CardActionArea style={{height:"25vmin", minWidth:"150px", maxWidth:"250px"}}>
                        <CardHeader variant="h5" component="h1" style={{textAlign:"-webkit-center"}} 
                          title={dataobject.title}
                          subheader={dataobject.description}
                          onClick={(e)=>this.onClickInfo(dataobject)}
                        />

                    <CardContent style={{textAlign:"-webkit-center",maxHeight:"100px", height:"100px", overflow: "hidden", bottom:"0px"}}>
                        <br/>
                        <Typography variant="body1" component="" >
                        Buy for {priceFormat} ? <br/><br/>
                        </Typography>
                        <Typography variant="h5" component="" >
                                  <Link to="#" onClick={(e)=>this.onClickBuyNo(dataobject)}>NO</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Link to="#" onClick={(e)=>this.onClickBuyYes(dataobject)}>YES</Link>
                        </Typography>
                    </CardContent>

                    </CardActionArea>
             </Card>
    }

    // displays card
    return <Card style={{height:"25vmin", minHeight:"25vmin", margin:"10px", width:"25vmin"}}>
                <CardActionArea style={{height:"25vmin", minWidth:"150px", maxWidth:"250px"}}>
                  <CardHeader variant="h5" component="h1" style={{textAlign:"-webkit-center", maxHeight:"30px", height:"25vmin", minHeight:"32px"}}
                      title= {dataobject.title}
                      subheader={dataobject.description}
                      onClick={(e)=>this.onClickInfo(dataobject)}
                    />

                    <CardContent style={{textAlign:"-webkit-center",maxHeight:"50px", height:"40px", overflow: "hidden"}} onClick={(e)=>this.onClickInfo(dataobject)}>
                        <Typography variant="body2" component="" >
                        {/* {dataobject.description} */}
                        </Typography>
                    </CardContent>

                    {this.props.dataobject.bought===true ? 
                    <>
                      {/* onClick={(e)=>this.onClickBuy(dataobject)} */}
                      <CardHeader variant="h5" component="h1" style={{textAlign:"-webkit-center"}} subheader={<Link to="#" >download</Link>} />
                    </> 
                    :
                    <CardHeader variant="h5" component="h1" style={{textAlign:"-webkit-center"}}
                      subheader={<Link to="#" onClick={(e)=>this.onClickBuy(dataobject)}> {priceFormat} </Link>} 
                    />}
                </CardActionArea>

                <CardActions>
                </CardActions>                    
          </Card>
  }
}
