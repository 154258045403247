import React from 'react';
import { Link } from "react-router-dom";
import './../../App.css';
import { InitialMenuSelector } from '../project/InitialView';
import { isThisTypeNode } from 'typescript';
import Markdown from 'markdown-to-jsx';

const newsData = 
[
  {
    id: 0,
    title: "Announcement",
    description: "This is one of the most energizing occasions that has occurred. Today we are proud to announce we are bringing the usability of decentralized blockchain technology to the hydrogen market. We are building a fair marketplace for producers of hydrogen, hydrogen transportation companies and hydrogen consumers. Oh and did we mention, it's geo-location aware so deliveries are always optimal.",
    image: "",
    date:"8th July 2020",
    md: null,
    expand: false
  },
  {
    id: 1,
    title: "Prototype",
    description: "We have prototype ready for internal show case to potential partners and investors. Featuring Green, Gray And Blue location aware spot markets, euro denominated transactions. Please get in contact if interested.",
    image: "",
    date:"8th July 2020",
    md: null,
    expand: false
  },
  {
    id: 2,
    title: "Looking for Partners",
    description: "We are looking for organisations and individuals looking to discuss ideas, exchange good practices, establish partnerships and funding.",
    image: "",
    date:"10th July 2020",
    expand: false,
    md: 
    `##DEVELOPMENT WITH PARTNERS\n\
In relation to the program, EnergonX  has drawn up a technology development approach that we are assessing together with international hydrogen industry stakeholders.\n
To further develop this technology, EnergonX is inviting partners from the following sectors to participate:\n
\
Hydrogen producers:\n
 - Locations interested to host the HRS, hydrogen.\n
 - All that see mutual benefits in partnership\n\n
Natural gas suppliers and producers;\n
 - Technology developers and licensors active in the hydrogen value chain;\n
 - Industrial gases producers;\n
 - Operators in the refinery, petrochemical, fertilizer, solid carbon, steel and aluminum industry;\n`
  },
  {
    id: 3,
    title: "European Clean Hydrogen Alliance",
    description: "We are pleased to announce that we have been accepted into European Clean Hydrogen Alliance.",
    image: "",
    date:"9th October 2020",
    expand: false,
    md: 'Download European Clean Hydrogen Alliance Members - Fifth Batch download from [EU Commission](https://ec.europa.eu/docsroom/documents/43486/attachments/1/translations/en/renditions/native)'
  },
  {
    id: 4,
    title: "Grant from Ethereum Swarm",
    description: "We received a grant to implement certificates and guarantees of origin tracking on Swarm decentralized storage network.",
    image: "",
    date:"31th December 2020",
    expand: false,
    md: 'Swarm is a distributed storage platform and content distribution service, a native base layer service of the ethereum web3 stack that aims to provide a decentralized and redundant store for dapp code, user data, blockchain and state data. Read more about Swarm and grants [Medium Post](https://medium.com/ethereum-swarm/come-together-swarm-beta-wave-grant-recipients-3a8510591ed6)'
  },
  {
    id: 5,
    title: "Oxygen Applications",
    description: "The oxygen market is already existing and well organized for both production and distribution.",
    image: "",
    date:"18th August 2021",
    expand: false,
    md: `##The oxygen\n 
Oxygen produced by electrolysis can be placed on the market. 
The global Oxygen market size was about 37.1 billion EUR in 2019 and will be expected to reach 42.6 billion EUR by 2025.
If Oxygen is sold at 2 Euro/kg, the cost of Hydrogen is strongly reduced and the investment is profitable.\n
A new concept of poly-generation from RES could be introduced: simultaneous production of energy and goods, in our case O2/H2.\n
### Potential oxygen applications 
1. Food and drink industry
        - Packaging with a protective atmosphere.
        - Fish farm tub oxygenation.
        - Disinfection and sterilization (ozone).
2. Chemical industry
        - Raw materials oxidation, e.g. production of nitric acid, 
        - ethylene oxide, 
        - propylene oxide, 
        - vinyl chloride monomer,
        - and other bulk chemicals.
        - Increasing the capacity and efficiency of waste incinerators.
3. Oil industry
        - Cracking catalysts regeneration.
        - Increasing efficiency of sulfur removing
4. Waste treatment
        - Air enrichment for firing (lower pollution).
5. Pyrolysis and gasification processes.
6. Wastewater purification
7. Glass production and processing
        - Increasing combustion efficiency and reducing NOx emissions.
8. Metal production and processing
        - Air mixture over-oxygenation to increase the combustion temperatures.
        - Increasing metal temperatures in electric arc furnaces for steel making.
        - Oxy-fuel cutting, welding, and heating of metals
9. Paper industry
        - Increasing quality/efficiency of the bleaching process.
10. Medical applications
        - Reviving gas mixtures for anesthesia and emergency.
        - Long-term treatments of patients with respiratory failure.
            `
  }


  /*,
  {
    id: 1,
    title: "Support",
    description: "Spot markets functionality for 'Green', 'Gray' and 'Blue' hydrogen are completed and will be available when platfrom launches.",
    image: "",
    date:"04/10/2020"
  },
  {
    id: 2,
    title: "Adjustments",
    description: "Launch of first phase of Available Products at 2021/10/10 12:00 UTC, where products will be made available. Check here for further details ",
    image: "",
    date:"05/01/2020"
  },
  {
    id: 7,
    title: "Certificates",
    description: "Exchange will provide complementary gray, blue, or green certificates for associated hydrogen producers through our partner network.",
    image: "",
    date:"06/01/2020"
  }*/
];
newsData.reverse();
class ListNews extends React.Component {
  constructor(props)
  {
    super(props);
    
  }
  onSeeMore(md)
  {
    this.props.parent.setState({md:md});
    console.log(md);
  }
  render() {
   let data = newsData;
    return <div>
           <h1 style={{ textAlign:"center" }}>News</h1>
            <table className="profileInformationSection" style={{ width:"85%" }}>
              <thead>
                <tr >
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(o => (
                    
                    <tr key={o.id} style={{ margin:"4em" }}>
                      <td style={{ verticalAlign:"top"}}>  
                        <h1>{o.title}</h1> 
                        <span className="MuiTypography-body2" style={{ verticalAlign:"top"}}> 
                          {o.description} 
                          <br/><br/>
                          {o.md!=null ? <div className="#textLeft">
                              <a href="#" onClick={(e)=>{ o.expand = !o.expand; this.forceUpdate();} }>{o.expand!=true?"See More": "Collapse"}</a>
                          </div>: null} 
                        {o.expand==true ?   <Markdown>{o.md}</Markdown> : null}  
                        </span>
                      </td>
                      <td style={{ verticalAlign:"top", width:"5.5em"}}>
                        <br/>
                         <small>{o.date}</small> <br/>
                      </td>
                      
                    </tr>
                  )
                )}
                </tbody>
            </table>
          </div>
  }
}
class NewsBanner extends React.Component {
  
  constructor(props){
      super(props)
      this.state = {
        info:""
      }
    }

    
  render () {

      return <div className="newsBanner">
                  News banner {this.state.info}
             </div>
  }
}

class NewsView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:"",
          md: null
        }
      }

      
    render () {
        return <div>
                  {/* <NewsBanner /> */}
                  <div className="pageViewScrollable">
                    <div className="pageNormalViewContents">
                        <ListNews parent={this}/>

                        <div>
                            {this.state.md!=null ? 
                            <div>
                              <Markdown>{this.state.md}</Markdown> 
                            </div> : null}
                        </div>
                    </div>
                  </div>

                 
               </div>
    }
}

export default NewsView;