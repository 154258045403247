import React from 'react';
import './../App.css';
import { Redirect } from 'react-router-dom' 
import ContractHeader from "./Contract_Header.js";

import ContractShipmentHeader  from "./Contract_ShipmentHeader.js";
import ContractShipmentContracts from "./Contract_ShipmentContracts.js";
import ContractShipmentFooter  from "./Contract_ShipmentFooter.js";

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class CompanyShipment extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        numPages:0,
        page:1,
        trade:null,
        shipment:null,
        redirect: false
      }

      this.getData = this.getData.bind(this);
    }

    componentDidMount() { 
       this.getData();
       
    }
    componentDidUpdate(prevProps) {
      if(this.props.companyShipment !== null && this.state.shipment===null)
      {
         this.setState({shipment:this.props.companyShipment});
         //console.log(this.props.companyShipment);
      }
    } 

    componentWillUnmount() {
    }

    requestDelivery(fromClientId) {
        if(this.props.comm!==undefined)
        {
            this.props.comm.requestDeliveryForShipment(fromClientId, this.state.shipment); 
            this.setState({redirect:true});
        }  
    }

    getData() { 
      if(this.props.comm!==undefined)
      {
          this.props.comm.getCompanyShipment(this.props.match.params.id); 
      }
    }

    // createPdf = (html) => Doc.createPdf(html);


    render () {
        if (this.state.redirect === true) {
          return <Redirect to='/delivery/inprogress' />
        }

        let data = this.state.shipment;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        return <div className="pageViewScrollable">
                <div className="pageNormalViewContents">
                    <h2>Shipment</h2>

                    <ContractShipmentHeader shipment={data} />
                    <Accordion>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}> 
                            <Typography>View Included Contracts ({data.contracts.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ContractShipmentContracts contracts={data.contracts} markets={this.props.markets} assets={this.props.assets} />
                            </AccordionDetails>
                    </Accordion>

                    <ContractShipmentFooter shipment={data} />

                    <div onClick={()=>this.requestDelivery(data.sellerExporter.id)}>  
                         <button to={"#"}>Delivery request</button> 
                    </div>
                    <hr />                    
                </div>
               </div>
    }
  }

export default CompanyShipment;
/*
                        <thead>
                            <tr>
                              <th></th>
                              <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td></td></tr>
                            <tr><td style={{textAlign:'right'}}>CONTRACTS:</td><td>{data.contracts.length}</td></tr>
                            <tr><td style={{textAlign:'right'}}>FEES:</td><td>{data.fees}</td></tr>
                            <tr><td style={{textAlign:'right'}}>SUBTOTAL:</td><td>{data.subTotal}</td></tr>
                            <tr><td style={{textAlign:'right'}}>TOTAL:</td><td>{data.total}</td></tr>
                            <tr><td></td></tr>
                            <tr><td></td></tr>
                        </tbody>
*/