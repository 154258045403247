import React from 'react';
import './../../App.css';
import ReCAPTCHA from "react-google-recaptcha";

import { withRouter } from "react-router-dom";

class RegisterNewUser extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          enabled: true,
          firstName: "", 
          lastName: "", 
          dateOfBirth: "", 
          phoneNumber: "", 
          email: "", 
          ethAddress: window.energonxapp.state.account, 
          info:"",
          userType:""
        }
      }

    componentDidMount()
    {
        //this.setState({ethAddress: window.energonxapp.state.account});
    }

    cancel() {
      if(this.props.loginScreen)
         this.props.loginScreen.register();
      else 
         this.props.history.goBack();
    }
    createAccount() {
      if(this.state.firstName.length<2 || 
         this.state.lastName.length<2 ||
         this.state.phoneNumber.length<6 ||
         this.state.email.length<5) 
        {
          this.onRegisterComplete("Invalid input");
          return;
        }
        if(this.state.dateOfBirth.length<9)
        {
          this.onRegisterComplete("Invalid Date Of Birth");
          return;
        }

        if(this.state.ethAddress.length<42)
        {
          this.onRegisterComplete("Invalid Ethereum Address");
          return;
        }

        var client = { 
           firstName: this.state.firstName,
           lastName: this.state.lastName,
           dateOfBirth: this.state.dateOfBirth,
           phoneNumber: this.state.phoneNumber,
           email: this.state.email,
           ethAddress: this.state.ethAddress,
           userType: this.props.userType
        }; 
        console.log(client);

        this.setState({enabled:false})
        window.energonxapp.state.comm.newRegistration(client, this);
    }
    onCaptchaChange(value) {
      //console.log("Captcha value:", value);
    }

    onRegisterComplete(information)
    {
      this.setState({info:information});
      this.setState({enabled:true});
      if(information==="OK")
      {
        if(this.props.loginScreen)
           this.props.loginScreen.register();

        window.energonxapp.logout();
        window.energonxapp.login();
      }
    }

    hFirstName(e) { this.setState({ firstName: e.target.value }); }
    hLastName(e) { this.setState({ lastName: e.target.value }); }
    hDateOfBirth(e) { this.setState({ dateOfBirth: e.target.value }); }
    hPhoneNumber(e) { this.setState({ phoneNumber: e.target.value }); }
    hEmail(e) { this.setState({ email: e.target.value }); }
    hEthAddress(e) { this.setState({ ethAddress: e.target.value }); }
  
    render () {
      return <div className="settingsWindow"> 
                  <small>Registering as '<strong>{this.props.userType}</strong>'</small>
                  <h3>Enter account details</h3>

                  <div>To create your account, please enter following details</div> <br/>
                  First Name<br/>
                  <input type="text" placeholder="first name" defaultValue={this.state.FirstName} value={this.state.FirstName} onChange={(e)=>this.hFirstName(e)} className="inputBox"/><br/>
                  Last Name<br/>
                  <input type="text" placeholder="last name" value={this.state.LastName} onChange={(e)=>this.hLastName(e)} className="inputBox"/><br/>
                  Date of Birth<br/>
                  <input type="text" placeholder="DD/MM/YYYY" value={this.state.DateOfBirth} onChange={(e)=>this.hDateOfBirth(e)} className="inputBox"/><br/>
                  Phone Number<br/>
                  <input type="text" placeholder="phone number" value={this.state.PhoneNumber} onChange={(e)=>this.hPhoneNumber(e)} className="inputBox"/><br/>
                  Email <br/>
                  <input type="text" placeholder="Email" value={this.state.Email} onChange={(e)=>this.hEmail(e)} className="inputBox"/><br/>
                  Ethereum address <br/>
                  <input type="text" placeholder="Ethereum address" defaultValue={this.state.EthAddress} value={this.state.EthAddress} onChange={(e)=>this.hEthAddress(e)} className="inputBox"/>
                  <div> You will have to prove ownerership of this address to log in.</div> <br/>
                  
                  {/* <ReCAPTCHA sitekey="6Lc1VvEUAAAAABo8Z6ady2TFHjHVgBapCPEE6zuY" onChange={this.onCaptchaChange} /> */}
                  {this.state.info.length>0 ? <div style={{color:"red"}}>{this.state.info}</div> : null}
                  {this.state.enabled==false ? <div className="settingsWindow">
                                                    <h1>EnergonX</h1>          
                                                    <h2>Registering your account</h2> 
                                                    <h3>Please wait ...</h3> 
                                              </div> : null }

                  <br/>
                  {this.state.enabled==true ? <button onClick={() => this.createAccount()}>Continue</button> : null }
                   <br/>
                  {this.state.enabled==true ? <button onClick={() => this.cancel()}>Cancel</button> : null }
            </div>
    }
  }

  export default withRouter(RegisterNewUser);