import React from 'react';
import './../App.css';
import { formatMoney } from './helpers/Formating';
class ContractOrders extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
      }
    }

    requestDelivery(fromClientId) {
        if(this.props.comm!==undefined)
        {
            //this.props.comm.getCompanyShipment(this.props.match.params.id); 
        }  
    }

    render () {
        let data = this.props.contract;
        if(data===null || data===undefined) return <h3 className="pageViewScrollable">Awaiting data</h3>

        //console.log(this.props.markets);
        //console.log(this.props.assets);
        //console.log(data.orders);
       
        return <div> 
                    <table className="balancesSection tableFinancialView fixed"> 
                        <thead>
                            <tr> 
                                <th>Market</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Value</th>
                                <th>Fees</th>
                            </tr> 
                        </thead>
                        <tbody>
                              {data.orders.map(o => 
                                <tr key={"order"+o.id}>
                                  {/* <td> {new Date(o.created).toLocaleString()}</td>  */}
                                  {/* <td> {o.marketId-1} {o.assetId-1} </td> */}
                                  <td> {this.props.markets[o.marketId-1].name} {this.props.assets[o.assetId-1].symbol} </td>
                                  <td> {o.amount} </td>
                                  <td> {o.price} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                                  <td> {formatMoney(o.total)} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                                  <td> {o.fee} {this.props.markets[o.marketId-1].pair.baseAsset.symbol}</td>
                                </tr>
                              )}
                            <tr >
                                <td>SUBTOTAL #{data.serialNum}</td>
                                <td>{data.quantity}</td>
                                <td>x&#772; {data.avgPricePerAmount.toFixed(3)}</td>
                                <td><strong>{formatMoney(data.total)} </strong></td>
                                <td>{data.fees}</td>
                            </tr>
                        </tbody>        
                    </table>
                    <hr />
                </div>
    }
  }

export default ContractOrders;
