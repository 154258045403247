import React from 'react';
import { timeDurationWeeks } from './../helpers/Formating';
import { formatMoney } from './../helpers/Formating';

class AuctionsListView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          info:"",
        };
        this.update = this.update.bind(this);
    }
  onClickAuction(auctionId) 
  {
     this.props.auctionsView.selectAuction(auctionId);
  }
  componentDidMount() { 
    this.timerHandle = setInterval(this.update, 333); // every 1s
  }
  componentWillUnmount() { 
    clearInterval(this.timerHandle); 
  }  
  update() {  
    this.forceUpdate(); 
  }    

  render() {
    let auctions = this.props.auctions;
    if(auctions===null) return null; 
    var now = Date.now();
    
    return  auctions.map(a =>
      <tr key={"auc_"+a.id} className={a.expires>now ? "orderBuyItem" : "orderSellItem"} onClick={()=>this.onClickAuction(a)}>
          <td className="textLeft"> {timeDurationWeeks(a.expires,now)} {/*a.expires*/} </td>
          <td className="textCenter">{a.price}</td> 
          <td className="textRight">{a.amount}</td> 
          <td className="textRight">{formatMoney(a.total)}</td> 
          {/* <td className="textLeft">{new Date(a.expires).toLocaleTimeString("de-DE")} </td> */}
      </tr>
      ) 
  }

}

class AuctionsBookView extends React.Component {
  
    constructor(props){
        super(props)
        this.state = {
          info:""
        }
      }

      renderHeader() {
        return <tr className="orderHeader">
                   <th className="textLeft">EXPIRES</th>
                   <th className="textCenter">PRICE</th>
                   <th className="textCenter">AMOUNT</th>
                   <th className="textCenter">TOTAL</th>
               </tr>
      }       
      render () {
        let auctions = this.props.auctions;
        return <div className="viewAuctionBook">
               <div className="tableHeader">AVAILABLE AUCTIONS</div>
               <table> 
                 <thead>
                   {this.renderHeader()}
                 </thead>
                 <tbody>
                   <AuctionsListView auctions={auctions} auctionsView={this.props.auctionsView}/>
                </tbody>     
               </table>
               </div>
    }
}

export default AuctionsBookView;