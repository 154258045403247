import React from 'react';
import './../App.css';

const openTrades = 
[
  { 
    id:0,
    type:"Buy",
    price:"0.00000040",
    amount:"29959.92500000",
    total:"0.01198397",
    date:"2019-07-05",
    time:"11:34:59",
    action:"cancel",
    rateStop:"0.00000040",
   }
] 

class OpenMarketOrders extends React.Component {

  cancelBuyOrder(orderId) {
    console.log("cancel buy order ", orderId);
    if(this.props.comm!==undefined)
    {
       this.props.comm.cancelBuyOrder(orderId); 
       var component = this;
       setTimeout(function() { component.getData(); }, 3000);
    }
  }

  cancelSellOrder(orderId) {
    console.log("cancel sell order ", orderId);
    if(this.props.comm!==undefined)
    {
       this.props.comm.cancelSellOrder(orderId); 
       var component = this;
       setTimeout(function() { component.getData(); }, 3000);
    }
  }

  getData() { 
    if(this.props.comm!==undefined)
    {
        this.props.comm.getClientMarketTrades(this.props.page); 
    }
  }

  render() {
    let data = this.props.marketTrades;
    if(data===undefined || data===null) return null; 

    return <tbody>
              {data.b.map(o => 
                  <tr key={o.id} className="orderBuyItem">
                    {/* <td > {o.marketId} </td> */}
                    <td> {o.price} </td>
                    <td> {o.amount} </td>
                    <td> {(o.price * o.amount).toFixed(8)} </td>
                    <td className="textRight"> {new Date(o.created).toLocaleString()}  </td>
                    <td> {o.filled} </td>
                    <td className="textRight textAsLink"> <label onClick={()=>this.cancelBuyOrder(o.id)}>Cancel</label> </td>
                  </tr>
                )}

              {data.s.map(o => 
                  <tr key={o.id} className="orderSellItem">
                    {/* <td> {o.marketId} </td> */}
                    <td className="textRight"> {o.price} </td>
                    <td className="textRight"> {o.amount} </td>
                    <td className="textRight"> {(o.price * o.amount).toFixed(8)} </td>
                    <td className="textRight"> {new Date(o.created).toLocaleString()}  </td>
                    <td className="textRight"> {o.filled} </td>
                    <td className="textRight textAsLink"> <label onClick={()=>this.cancelSellOrder(o.id)}>Cancel</label> </td>
                  </tr>
                )}
        </tbody>
   } 
}


class OpenOrdersView extends React.Component {
      constructor(props){
        super(props)
        this.state = {
          numPages:0,
          page:1
        }
        this.getData = this.getData.bind(this);
      }

      componentDidMount() { 
          this.getData();
          //this.intervalHandle = setInterval(this.getData, 10000); // every 10s
      }
      componentWillUnmount() {
        //clearInterval(this.intervalHandle);
      }

      getData() { 
        if(this.props.app.state.comm!==undefined)
        {
          this.props.app.state.comm.getClientMarketTrades(this.state.page); 
           //console.log("remove this, no need to pull data");
        }
      }

      renderHeader() {
        return  <tr className="orderHeader">
                  {/* <th className="openTradesText">TYPE</th>  */}
                  <th className="openTradesText">PRICE</th> 
                  <th className="openTradesText">AMOUNT</th>
                  <th className="openTradesText">TOTAL</th>
                  <th className="openTradesText">DATE/TIME</th>
                  <th className="openTradesText">FILLED</th> 
                  <th className="openTradesText">ACTION</th>
                </tr>
      }       
      render () {
        return <div className="viewOpenTrades">
               <div className="tableHeader">OPEN ORDERS</div> 
               <table className="openTradesView">
                 <thead>
                  {this.renderHeader()}
                 </thead>   
                  <OpenMarketOrders marketTrades={this.props.marketTrades} comm={this.props.app.state.comm} page={this.state.page}/>
               </table>
               </div>
    }
}

export default OpenOrdersView;

/*
                  <th className="textLeft">TYPE</th> 
                  <th className="textCenter">PRICE</th> 
                  <th className="textRight">AMOUNT</th>
                  <th className="textRight">TOTAL</th>
                  <th className="textRight">DATE/TIME</th>
                  <th className="textRight">RATE/STOP</th>
                  <th className="textRight">ACTION</th>

*/